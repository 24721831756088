import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { message, Modal, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {
  MSLayoutContentRow, LoadingContainer,
  showMSConfirm, showMSErrorMessage, showMSSuccessMessage, getClientHierarchyNode, IClientHierarchyName, MSNavigationBlocker, useRouteParams,
  useRouteNavigation, useMindshareSelector
} from '@mindshare/layout'
import { useMapSettings } from '@mindshare/services'
import { collapseAllFlightGroups, expandAllFlightGroups } from 'Actions/appActions'
import {
  setSelectedCalculatedField,
  buildGrid,
  addAvailableField,
  onSelectedAvailableField,
  removePlanField,
  updateMediaPlanRevised,
  saveMediaPlanVersionRevised,
  addChosenFlightField,
  updateCalendarPlanDate,
  cleanUpView,
  initialiseMediaPlanContainer,
  toggleGoalSeekModalVisible,
  updatePlanStage,
  syncMediaPlanVersionFromTemplate,
  setRedistributeModalVisible,
  collapseAllFlightGroupRows,
  expandAllFlightGroupRows,
  resetUnmodifiedMediaPlanVersion,
  reorderFlightGroups
} from 'Actions/mediaPlansActions'
import { getMediaPlanTemplateById } from 'Actions/mediaPlanTemplatesActions'
import { Client } from 'Apis/generated/clientsApi'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { ChosenFlightField } from 'Apis/generated/mediaPlanVersionsApi'
import CalculationHeaderRevisedContainer from 'Containers/MediaPlanVersion/CalculationHeaderRevisedContainer'
import GoalSeekModalContainer from 'Containers/MediaPlanVersion/GoalSeekModalContainer'
import PlanLevelContainer from 'Containers/MediaPlanVersion/PlanLevelContainer'
import MediaPlanTableContainer from 'Containers/MediaPlanVersion/MediaPlanTableContainer'
import PlanLevelRichTextFieldsContainer from 'Containers/MediaPlanVersion/PlanLevelRichTextFieldsContainer'
import MediaPlanEditModalContainer from 'Containers/MediaPlanVersion/MediaPlanEditModalContainer'
import MediaPlanVersionToolbarComponent from 'Components/MediaPlanVersion/MediaPlanVersionToolbarComponent'
import WrapperAnimationComponent from 'Components/EasterEgg/WrapperAnimationComponent'
import { IMediaPlanVersion, CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import {
  IMediaPlanVersionField,
  buildMediaPlanTemplateFields,
  IMediaPlanMetaField,
  fieldsInPlanByLevel,
  IMediaPlanTemplateFields,
  isAggregatedOrCalculated
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { IMAPSettings } from 'Constants/entities/IMapSettings'
import { useFilteredHierarchies, useIsReadyWhenIdle } from 'Hooks/CustomHooks'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useMasteredListFieldsData } from 'Hooks/useMasteredListFieldsData'
import { filterRichText, filterRichTextHeaderFields, filterRichTextFooterFields } from 'Helpers/richTextHelpers'
import { getUserPreferences } from 'Helpers/userPreferenceHelper'
import { getExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { getExpandedHierarchyFlightGroupsFields } from 'Components/Hierarchies/helpers/getExpandedHierarchiesFlightGroupFields'
import { usePlanMasteredFieldDataHelperValues } from 'Components/MediaPlanVersion/hooks/usePlanMasteredFieldDataHelperValues'
import { useMasteredDataFieldsValues } from 'Components/MediaPlanVersion/hooks/useMasteredDataFieldsValues'
import { usePlanMasteredHierarchiesFields, usePlanFinanceListFields } from 'Components/MediaPlanVersion/hooks/usePlanMasteredData'
import { getFlightGroupsWithSubtotals } from 'Components/Subtotals/helpers/getFlightGroupsWithSubtotals'
import { useHierarchies } from 'Hooks/useHierarchies'
import { getUserModifiableParseDataPartial } from 'Components/MediaPlanVersion/helpers/getUserModifiableParseDataPartial'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import { appPaths } from 'Constants/appPaths'
import { selectPlanStartDate, selectPlanEndDate } from '../../selectors/mediaPlansSelectors/currentMediaPlanVersionSelectors'
import { selectParseData, selectUnmodifiedStringifiedParseData } from '../../selectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { RootState } from '../../redux/reducers'

interface IProps {
  mediaPlanVersionId?: number
}
const messageKey = 'mediaPlanVersionEditorrMessageKey'

export const MediaPlanVersionContainer: React.FC<IProps> = ({ mediaPlanVersionId }) => {
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()

  // Cleanup..
  useEffect(() => () => {
    message.destroy()
    Modal.destroyAll()
    dispatch(cleanUpView())
  }, [dispatch])

  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  const { data: clientSettings } = useClientSettings()

  const currentMediaPlanTemplate = useMindshareSelector((state: RootState) => state.mediaPlanTemplates.currentMediaPlanTemplate) as MediaPlanTemplate
  const selectedMediaPlan = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlan) as IMediaPlans
  const currentMediaPlanVersion = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion, () => true) as IMediaPlanVersion
  const mediaPlanVersionFields = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields) as IMediaPlanVersionField[]
  const memoizedMediaPlanVersionFields = useDeepEqualMemoized(mediaPlanVersionFields)
  const parseData = useAppSelector(selectParseData)
  const unmodifiedStringifiedParseData = useAppSelector(selectUnmodifiedStringifiedParseData)
  const masteredDataHelperValues = usePlanMasteredFieldDataHelperValues({ fields: memoizedMediaPlanVersionFields, parseData })
  const { getGeographyHierarchyFieldId, getMediaHierarchyFieldId } = useMasteredDataFieldsValues({ fields: memoizedMediaPlanVersionFields, parseData })

  const approvalDisabled = useMindshareSelector((state: RootState) => state.mediaPlans.approvalDisabled) as boolean
  const calculationIssues = useMindshareSelector((state: RootState) => state.mediaPlans.calculationIssues) as boolean
  const selectedCalculatedField = useMindshareSelector((state: RootState) => state.mediaPlans.selectedCalculatedField) as IMediaPlanMetaField
  const versionControlDisabled = useMindshareSelector((state: RootState) => state.mediaPlans.versionControlDisabled) as boolean
  const goalSeekModalVisible = useAppSelector((state) => state.mediaPlans.goalSeek.goalSeekModalData.modalVisible)
  const goalSeekWizardProgress = useAppSelector((state) => state.mediaPlans.goalSeek.goalSeekModalData.wizardProgress)
  const richTextPlanLevelFields = useMindshareSelector((state: RootState) =>
    fieldsInPlanByLevel(state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields, FieldLevelType.PLAN).filter(filterRichText)
  ) as IMediaPlanTemplateFields[]
  const subtotals = useAppSelector((state) => state.mediaPlans.subtotals)
  const startDate = useAppSelector(selectPlanStartDate)
  const endDate = useAppSelector(selectPlanEndDate)
  const subtotalConfigurationFields = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion?.mediaPlan?.subtotalConfiguration?.subtotalConfigurationFields)

  const clickableFieldProps = useMemo(() => ({
    goalSeekWizardProgress,
    goalSeekModalVisible,
    selectedCalculatedField,
    mediaPlanVersionFields: memoizedMediaPlanVersionFields
  }), [goalSeekWizardProgress, goalSeekModalVisible, selectedCalculatedField, memoizedMediaPlanVersionFields])

  const geographyFieldHierarchyId = getGeographyHierarchyFieldId()
  const mediaFieldHierarchyId = getMediaHierarchyFieldId()

  const masteredListsData = useMasteredListFieldsData(currentClient?.id)
  const { data: unfilteredRegularHierarchies } = useHierarchies(currentClient?.id)

  // load template used by plan and have a filtered hierarchies as defined in the template
  // load media plan and have a filtered hierarchies as defined in the plan
  const { filteredHierarchies: templateHierarchies, isLoadingHierarchies: isLoadingTemplateHierarchies } = useFilteredHierarchies(
    currentMediaPlanTemplate?.brandHierarchyId,
    currentMediaPlanTemplate?.businessHierarchyId,
    currentMediaPlanTemplate?.geographyHierarchyId,
    currentMediaPlanTemplate?.mediaHierarchyId
  )
  const { filteredHierarchies: planHierarchies, isLoadingHierarchies: isLoadingPlanHierarchies } = useFilteredHierarchies(
    selectedMediaPlan?.brandHierarchyId ?? currentMediaPlanTemplate?.brandHierarchyId,
    selectedMediaPlan?.businessHierarchyId ?? currentMediaPlanTemplate?.businessHierarchyId,
    selectedMediaPlan?.geographyHierarchyId ?? currentMediaPlanTemplate?.geographyHierarchyId,
    selectedMediaPlan?.mediaHierarchyId ?? currentMediaPlanTemplate?.mediaHierarchyId
  )
  const isReady = useIsReadyWhenIdle()
  const [dataReady, setDataReady] = useState<boolean>()
  const hasUnsavedChanges = useMemo(() => {
    return unmodifiedStringifiedParseData && unmodifiedStringifiedParseData !== JSON.stringify(getUserModifiableParseDataPartial(parseData))
  }, [parseData, unmodifiedStringifiedParseData])
  const [calculationMode, setCalculationMode] = useState<boolean>()
  const [updatingFromTemplate, setUpdatingFromTemplate] = useState(false)
  const [isTemplateUpdated, setIsTemplateUpdated] = useState(false)
  const [currentMediaPlan, setCurrentMediaPlan] = useState<IMediaPlans>(selectedMediaPlan)
  const [updatePlanModalVisible, setUpdatePlanModalVisible] = useState(false)
  const [updatePlanModelClosable, setUpdatePlanModelClosable] = useState(true)
  const richTextHeaderFields = useMemo(() => filterRichTextHeaderFields(richTextPlanLevelFields), [richTextPlanLevelFields])
  const richTextFooterFields = useMemo(() => filterRichTextFooterFields(richTextPlanLevelFields), [richTextPlanLevelFields])
  const { settings, updateSetting } = useMapSettings<IMAPSettings, 'planList'>('planList', currentClient.id, {})
  const { userSettings = {} } = settings
  const {
    collapseCalculated,
    collapseNonMandatory,
    collapseRows,
    collapsedViewField,
    displayFlightDates,
    expandHierarchies,
    compressedCalendarView
  } = getUserPreferences(userSettings, currentMediaPlanTemplate?.mediaPlanTemplateId)
  const {
    currentMasteredHierarchies,
    isLoading: isLoadingMasteredHierarchies
  } = usePlanMasteredHierarchiesFields({
    geographyHierarchyId: geographyFieldHierarchyId,
    mediaHierarchyId: mediaFieldHierarchyId
  })
  const financeList = usePlanFinanceListFields({ geographyHierarchyId: geographyFieldHierarchyId, mediaHierarchyId: mediaFieldHierarchyId, currentField: null })

  const hierarchies = useMemo(() => ({ ...planHierarchies, ...currentMasteredHierarchies }), [currentMasteredHierarchies, planHierarchies])

  const unfilteredHierarchies = useMemo(() => ({ ...unfilteredRegularHierarchies, ...currentMasteredHierarchies }), [unfilteredRegularHierarchies, currentMasteredHierarchies])
  const expandedHierarchiesFields = useMemo(() => {
    if (!expandHierarchies) {
      return parseData.flightGroups
    }

    const expandedHierarchiesFlightGroupsValues = getExpandedHierarchiesFlightGroupsValues(parseData.flightGroups, unfilteredHierarchies)
    const allFlightGroupFields = getExpandedHierarchyFlightGroupsFields(mediaPlanVersionFields, unfilteredHierarchies, expandedHierarchiesFlightGroupsValues)
    return allFlightGroupFields.filter(field => field.isExpandedHierarchyField)
  }, [expandHierarchies, mediaPlanVersionFields, parseData.flightGroups, unfilteredHierarchies])

  const flightGroupsWithSubtotals = useMemo(
    () =>
      getFlightGroupsWithSubtotals(
        parseData.flightGroups,
        subtotals,
        mediaPlanVersionFields,
        startDate,
        endDate,
        subtotalConfigurationFields,
        hierarchies,
        masteredListsData,
        financeList
      ),
    [
      subtotals,
      mediaPlanVersionFields,
      startDate,
      endDate,
      parseData.flightGroups,
      subtotalConfigurationFields,
      hierarchies,
      masteredListsData,
      financeList
    ]
  )
  const subtotalGroups = useMemo(() => flightGroupsWithSubtotals.filter((f) => f.subtotal), [flightGroupsWithSubtotals])

  const setRedistributionModalVisible = useCallback((modalVisible) => dispatch(setRedistributeModalVisible(modalVisible)), [dispatch])

  useEffect(() => {
    if (selectedMediaPlan?.mediaPlanTemplateId) {
      dispatch(getMediaPlanTemplateById(selectedMediaPlan.mediaPlanTemplateId, currentClient.id))
    }
  }, [dispatch, currentClient.id, selectedMediaPlan?.mediaPlanTemplateId])

  const updateMediaPlanVersion = useCallback(async () => {
    setDataReady(false)
    message.destroy()
    message.loading({ content: 'Loading...', key: messageKey })
    const result = await dispatch(updateMediaPlanRevised(currentClient.id, mediaPlanVersionId, isTemplateUpdated))
    if (result === null) {
      message.success({ content: 'Media Plan saved successfully', key: messageKey })
    } else {
      message.error({ content: result, duration: 10, key: messageKey })
    }
    setUpdatingFromTemplate(false)
    setIsTemplateUpdated(false)
    setDataReady(true)
  }, [dispatch, currentClient.id, mediaPlanVersionId, isTemplateUpdated])

  const saveCurrentMediaPlanVersion = useCallback(async () => {
    setDataReady(false)
    message.destroy()
    message.loading({ content: 'Loading...', key: messageKey })
    const saveResult: IMediaPlanVersion | string = await dispatch(saveMediaPlanVersionRevised(currentClient.id))
    if (typeof saveResult !== 'string') {
      message.success({ content: 'Media Plan saved successfully', key: messageKey })
    } else {
      message.error({ content: saveResult, duration: 10, key: messageKey })
    }
    setDataReady(true)
  }, [dispatch, currentClient.id])

  const tokenHandler = useCallback((token: string) => {
    let newSelectedCalculatedField: IMediaPlanMetaField
    if (selectedCalculatedField) {
      if (!selectedCalculatedField.calculation) {
        newSelectedCalculatedField = { ...selectedCalculatedField, calculation: '' }
      }
      newSelectedCalculatedField = {
        ...selectedCalculatedField,
        calculation: (selectedCalculatedField.calculation === null ? '' : selectedCalculatedField.calculation) + token
      }
      dispatch(setSelectedCalculatedField(newSelectedCalculatedField))
    }
  }, [dispatch, selectedCalculatedField])

  const onAvailableFieldSelected = useCallback((mediaPlanFieldSelected: string, flightGroupIndex?: number) => {
    const selectedAvailableField = JSON.parse(mediaPlanFieldSelected) as IMediaPlanVersionField
    dispatch(onSelectedAvailableField(selectedAvailableField, flightGroupIndex))
  }, [dispatch])

  const handleAddField = useCallback((fieldType: FieldLevelType, flightGroupIndex?: number) => {
    const newItemField = buildMediaPlanTemplateFields()
    const newFieldWithLevel: IMediaPlanMetaField = { ...newItemField, clientMediaPlanField: ({ mediaPlanField: { fieldLevelId: fieldType as number } } as any) }

    dispatch(addAvailableField(newFieldWithLevel, flightGroupIndex))
    if (flightGroupIndex >= 0) {
      const chosenFlightField: ChosenFlightField = { clientMediaPlanFieldId: 0 }
      dispatch(addChosenFlightField(flightGroupIndex, chosenFlightField))
    }
  }, [dispatch])

  const handleRemovePlanField = useCallback((mediaPlanField: IMediaPlanMetaField, flightGroupIndex?: number) => {
    dispatch(removePlanField(mediaPlanField, flightGroupIndex))
  }, [dispatch])

  const handleSetCalendarDate = useCallback((planStartDate: string, planEndDate: string, selectedCalendarView: CalendarView, startDayOfWeek: string) => {
    dispatch(updateCalendarPlanDate(planStartDate, planEndDate, selectedCalendarView, startDayOfWeek))
  }, [dispatch])

  const handleUpdateFromTemplate = useCallback(async () => {
    setUpdatingFromTemplate(true)

    const sync = await dispatch(syncMediaPlanVersionFromTemplate(mediaPlanVersionId))

    if (sync.error) {
      showMSErrorMessage({ message: 'There has been a problem syncing this Media Plan Version.' })
    } else if (sync.diff) {
      showMSConfirm({
        title: <span>There are changes to this media plan. <br/>You will need to verify and save the changes.</span>,
        cancelButtonProps: { style: { display: 'none' } }
      })
    } else {
      showMSSuccessMessage({ message: 'Media Plan synced successfully.' })
    }
    setIsTemplateUpdated(true)
    setUpdatingFromTemplate(false)
  }, [dispatch, mediaPlanVersionId])

  const setCollapsedAllFlightGroups = useCallback(() => {
    const flightGroupIds = [
      ...mediaPlanVersionFields.map(item => item.mediaPlanVersionFieldId),
      ...expandedHierarchiesFields.map(item => item.mediaPlanVersionFieldId)
    ]
    dispatch(collapseAllFlightGroups(flightGroupIds))
  }, [dispatch, mediaPlanVersionFields, expandedHierarchiesFields])

  const setExpandAllFlightGroups = useCallback(() => {
    dispatch(expandAllFlightGroups())
  }, [dispatch])

  const getNonMandatoryFlightGroupFieldIds = useCallback(() => {
    const nonMandatoryFieldIds = mediaPlanVersionFields
      .filter(f => f.templateFieldAvailabilityId !== TemplateFieldAvailability.MANDATORY)
      .map(f => f.mediaPlanVersionFieldId)
    return [
      ...nonMandatoryFieldIds,
      ...expandedHierarchiesFields.map(item => item.mediaPlanVersionFieldId)
    ]
  }, [mediaPlanVersionFields, expandedHierarchiesFields])

  const collapseMandatoryFlightGroups = useCallback(() => {
    const nonMandatoryFieldIds = getNonMandatoryFlightGroupFieldIds()
    dispatch(collapseAllFlightGroups(nonMandatoryFieldIds))
  }, [dispatch, getNonMandatoryFlightGroupFieldIds])

  const getCalculatedFlightGroupFieldIds = useCallback(
    () => mediaPlanVersionFields.filter(f => isAggregatedOrCalculated(f)).map(f => f.mediaPlanVersionFieldId),
    [mediaPlanVersionFields]
  )

  const collapseCalculatedFlightGroups = useCallback(() => {
    const calculatedOrAggregatedFieldIds = getCalculatedFlightGroupFieldIds()
    dispatch(collapseAllFlightGroups(calculatedOrAggregatedFieldIds))
  }, [dispatch, getCalculatedFlightGroupFieldIds])

  useEffect(() => {
    let fieldIdsToCollapse: number[] = []
    if (collapseNonMandatory) {
      fieldIdsToCollapse = [...fieldIdsToCollapse, ...getNonMandatoryFlightGroupFieldIds()]
    }
    if (collapseCalculated) {
      fieldIdsToCollapse = [...fieldIdsToCollapse, ...getCalculatedFlightGroupFieldIds()]
    }
    if (fieldIdsToCollapse.length) {
      dispatch(collapseAllFlightGroups(fieldIdsToCollapse))
    }
    if (collapseRows && parseData.calendarView !== 'table') {
      dispatch(collapseAllFlightGroupRows(subtotalGroups))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, collapseNonMandatory, collapseCalculated, collapseRows, getCalculatedFlightGroupFieldIds, getNonMandatoryFlightGroupFieldIds])

  const setGoalSeekModalVisible = useCallback(() => dispatch(toggleGoalSeekModalVisible()), [dispatch])

  useEffect(() => {
    const hierarchiesLoaded = !!(
      templateHierarchies.clientBrandHierarchies.length ||
      templateHierarchies.clientBusinessHierarchies.length ||
      templateHierarchies.clientGeographyHierarchies.length ||
      templateHierarchies.clientMediaHierarchies.length
    )
    if (selectedMediaPlan && hierarchiesLoaded) {
      const blankoutDeletedHierarchyValue = (hierarchyID: number, hierarchyType: IClientHierarchyName): number => {
        if (hierarchyID) {
          const hierarchyNode = getClientHierarchyNode(templateHierarchies, hierarchyType, hierarchyID)
          if (!hierarchyNode) {
            return null
          }
        }
        return hierarchyID
      }
      const geographyHierarchyId = blankoutDeletedHierarchyValue(selectedMediaPlan.geographyHierarchyId, 'geography')
      const mediaHierarchyId = blankoutDeletedHierarchyValue(selectedMediaPlan.mediaHierarchyId, 'media')
      const businessHierarchyId = blankoutDeletedHierarchyValue(selectedMediaPlan.businessHierarchyId, 'business')
      const brandHierarchyId = blankoutDeletedHierarchyValue(selectedMediaPlan.brandHierarchyId, 'brand')

      setCurrentMediaPlan({
        ...selectedMediaPlan, geographyHierarchyId, mediaHierarchyId, businessHierarchyId, brandHierarchyId
      })

      setDataReady(true)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    selectedMediaPlan?.geographyHierarchyId,
    selectedMediaPlan?.mediaHierarchyId,
    selectedMediaPlan?.businessHierarchyId,
    selectedMediaPlan?.brandHierarchyId,
    templateHierarchies
  ])

  useEffect(() => {
    if (
      dataReady &&
      mediaPlanVersionId > 0 &&
      parseData.mediaPlanVersionId > 0 &&
      parseData.mediaPlanVersionId !== mediaPlanVersionId
    ) {
      const reloadPageWithNewVersion = (currentClientId?: number) =>
        navigate(`/mediaplanversion/${parseData.mediaPlanVersionId}`, false, { clientId: currentClientId })

      reloadPageWithNewVersion(currentClient.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReady, mediaPlanVersionId])

  useEffect(() => {
    if (dataReady && parseData.calendarView && parseData.calendarView !== 'table') {
      dispatch(buildGrid(
        parseData.planStartDate,
        parseData.planEndDate,
        parseData.calendarView,
        parseData.startDayOfWeek
      ))
    }
  }, [dispatch, dataReady, parseData.calendarView, parseData.planStartDate, parseData.planEndDate, parseData.startDayOfWeek])

  useEffect(() => {
    if (!calculationMode && Object.keys(selectedCalculatedField).length > 0) {
      dispatch(setSelectedCalculatedField({} as any))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationMode])

  useEffect(() => {
    if (parseData.calendarView === 'table') {
      dispatch(expandAllFlightGroupRows())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parseData.calendarView])

  const handleUpdatePlanStage = useCallback((selectedPlanningState: number, planningStageName: string) => {
    return dispatch(updatePlanStage(mediaPlanVersionId, currentClient.id, selectedPlanningState, planningStageName))
  }, [currentClient.id, dispatch, mediaPlanVersionId])

  const handleGoBack = useCallback(() => navigate(appPaths.plansList), [navigate])

  useEffect(() => {
    return () => dispatch(resetUnmodifiedMediaPlanVersion())
  }, [mediaPlanVersionId, dispatch])

  const handleSort = useCallback((sortedFlightGroups: IFlightGroup[]) => {
    const filteredFlightGroups = sortedFlightGroups.filter((f) => !f.subtotal)

    dispatch(reorderFlightGroups(filteredFlightGroups))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaPlanVersion, dispatch])

  return (
    <MSNavigationBlocker isActive={hasUnsavedChanges}>
      <div className='mediaplan-container'>
        <MSLayoutContentRow extraClass='calculation-header sticky-row'>
          <CalculationHeaderRevisedContainer
            calculationMode={calculationMode}
            clientId={currentClient.id}
            displayFlightDates={displayFlightDates}
            enableToggle={true}
            expandHierarchies={expandHierarchies}
            hasUnsavedChanges={hasUnsavedChanges}
            planName={parseData.planName}
            planVersion={parseData.versionNumber}
            setCalculationMode={setCalculationMode}
            setUpdatePlanModalVisible={setUpdatePlanModalVisible}
            updatingFromTemplate={updatingFromTemplate}
          />
        </MSLayoutContentRow>

        <MSLayoutContentRow extraClass='sticky-row' widthOverride='calc(100% - 10px)'>
          <PlanLevelContainer
            calculationMode={calculationMode}
            unfilteredHierarchies={unfilteredHierarchies}
            masteredListsData={masteredListsData}
            tokenHandler={tokenHandler}
            onAvailableFieldSelected={onAvailableFieldSelected}
            removePlanField={handleRemovePlanField}
            masteredDataHelperValues={masteredDataHelperValues}
            clickableFieldProps={clickableFieldProps}
          />
        </MSLayoutContentRow>

        {richTextHeaderFields.length > 0 && <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
          <PlanLevelRichTextFieldsContainer
            onAvailableFieldSelected={onAvailableFieldSelected}
            removePlanField={handleRemovePlanField}
            richTextPlanLevelFields={richTextHeaderFields}
          />
        </MSLayoutContentRow>}

        <div className='sticky-row row-wrapper'>
          <MSLayoutContentRow widthOverride='calc(100% - 10px)'>
            <MediaPlanVersionToolbarComponent
              calculationMode={calculationMode}
              disabledCalendar={false}
              fields={mediaPlanVersionFields}
              handleAddField={handleAddField}
              handleSetCalendarDate={handleSetCalendarDate}
              handleUpdateFromTemplate={parseData.isTemplateUpdateAvailable && handleUpdateFromTemplate}
              inputCalendarView={parseData.calendarView}
              inputDayOfWeek={parseData.startDayOfWeek}
              inputEndDate={parseData.planEndDate}
              inputStartDate={parseData.planStartDate}
              openByDefault={!parseData.calendarView}
              planStartYear={selectedMediaPlan?.planYear}
              setCollapsedAllFlightGroups={setCollapsedAllFlightGroups}
              setExpandAllFlightGroups={setExpandAllFlightGroups}
              collapseMandatoryFlightGroups={collapseMandatoryFlightGroups}
              collapseCalculatedFlightGroups={collapseCalculatedFlightGroups}
              setGoalSeekModalVisible={setGoalSeekModalVisible}
              setRedistributionModalVisible={setRedistributionModalVisible}
              footerProps={{
                updatePlan: updateMediaPlanVersion,
                planningStageId: parseData.planningStageId,
                currentMediaPlanVersion,
                clientPlanningStages: clientSettings.planningStages,
                handleUpdatePlanStage,
                saveCurrentMediaPlanVersion,
                approvalDisabled,
                calculationIssues,
                versionControlDisabled,
                preserveApprovedPlans: clientSettings.preserveApprovedPlans
              }}
              userSettings={{
                updateSetting,
                settings: userSettings
              }}
              mediaPlanTemplateId={currentMediaPlanTemplate?.mediaPlanTemplateId}
              hierarchies={hierarchies}
              masteredListsData={masteredListsData}
              financeList={financeList}
            />
          </MSLayoutContentRow>
        </div>

        <MSLayoutContentRow extraClass='flight-levels' widthOverride='100%'>
          {!isReady || updatingFromTemplate ? (
            <div className='flights-loading'><LoadingOutlined />Loading...</div>
          ) : (
            <MediaPlanTableContainer
              calculationMode={calculationMode}
              planHierarchies={planHierarchies}
              hierarchies={hierarchies}
              unfilteredHierarchies={unfilteredHierarchies}
              masteredListsData={masteredListsData}
              tokenHandler={tokenHandler}
              onAvailableFieldSelected={onAvailableFieldSelected}
              handleAddField={handleAddField}
              removePlanField={handleRemovePlanField}
              displayFlightDates={displayFlightDates}
              isExpandedHierarchiesMode={expandHierarchies}
              collapsedViewField={collapsedViewField}
              compressedCalendarView={compressedCalendarView}
              masteredDataHelperValues={masteredDataHelperValues}
              financeList={financeList}
              clickableFieldProps={clickableFieldProps}
              flightGroupsWithSubtotals={flightGroupsWithSubtotals}
              isLoadingHierarchies={isLoadingTemplateHierarchies || isLoadingPlanHierarchies || isLoadingMasteredHierarchies}
              handleSort={handleSort}
            />
          )}
        </MSLayoutContentRow>

        {richTextFooterFields.length > 0 && <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
          <PlanLevelRichTextFieldsContainer
            onAvailableFieldSelected={onAvailableFieldSelected}
            removePlanField={handleRemovePlanField}
            richTextPlanLevelFields={richTextFooterFields}
          />
        </MSLayoutContentRow>}

        <MSLayoutContentRow extraClass='sticky-row' widthOverride='calc(100% - 10px)'>
          <div className="mediaplan-bottom-button-container">
            <Button type='primary' onClick={handleGoBack}>Back</Button>
          </div>
        </MSLayoutContentRow>

        <MediaPlanEditModalContainer
          mediaPlan={currentMediaPlan}
          mediaPlanVersion={currentMediaPlanVersion}
          mediaPlanTemplate={currentMediaPlanTemplate}
          closable={updatePlanModelClosable}
          visible={updatePlanModalVisible}
          hideModal={() => {
            setUpdatePlanModalVisible(false)
            setUpdatePlanModelClosable(true)
          }}
        />

        {goalSeekModalVisible && <GoalSeekModalContainer
          setGoalSeekModalVisible={setGoalSeekModalVisible}
        />}

        <WrapperAnimationComponent />
      </div>
    </MSNavigationBlocker>
  )
}


export const MediaPlanLoadingContainer: React.FC = (props) => {
  const mediaPlanVersionId = useRouteParams('mediaPlanVersionId', Number)
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const clientId = useAppSelector((state) => state.app.currentClient.id)
  const dispatch = useAppDispatch()

  return (
    <LoadingContainer
      appDataSuccess={true}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={(done) => initialiseMediaPlanContainer(done, dispatch, clientId, { mediaPlanVersionId })}
    >
      <MediaPlanVersionContainer {...props} mediaPlanVersionId={mediaPlanVersionId} />
    </LoadingContainer>
  )
}

export default MediaPlanLoadingContainer
