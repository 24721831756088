import React from 'react'
import { parse } from 'date-fns'
import { getPixelsPerDay, isWeeklyView, weekDays } from 'Helpers/calendarHelper'
import { CalendarView, setMomentLocale } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import Weeks from './WeekComponent'
import Quarters from './QuartersComponent'

export interface ICalendar {
  startDate: string
  endDate: string
  dayStartOfWeek: string
  calendarView: CalendarView
  compressedCalendarView: boolean
}

export const CalendarComponent: React.FunctionComponent<ICalendar> = ({ startDate, endDate, dayStartOfWeek, calendarView, compressedCalendarView }) => {
  if (!startDate) {
    return null
  }

  const pixelsPerDay = getPixelsPerDay(calendarView)
  const weekStateOn = { weekStartsOn: weekDays[dayStartOfWeek] }
  const parseStartDate = parse(startDate.substring(0, 10), 'yyyy-MM-dd', new Date())
  const parseEndDate = parse(endDate.substring(0, 10), 'yyyy-MM-dd', new Date())

  if (dayStartOfWeek) {
    setMomentLocale(dayStartOfWeek)
  }

  return (
    <div data-testid='calendar' className="flightHeader">
      <Quarters
        startDate={parseStartDate}
        endDate={parseEndDate}
        pixelsPerDay={pixelsPerDay}
        weekStateOn={weekStateOn}
        compressedCalendarView={compressedCalendarView}
      />
      {isWeeklyView(calendarView) &&
        <Weeks
          startDate={parseStartDate}
          endDate={parseEndDate}
          calendarView={calendarView}
          weekStateOn={weekStateOn}
          compressedCalendarView={compressedCalendarView}
        />
      }
    </div>
  )
}

export default CalendarComponent
