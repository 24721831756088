import { baseApi as hierarchiesApi } from './generated/hierarchiesApi'
import { baseApi as clientMediaPartnersApi } from './generated/clientMediaPartnersApi'
import { baseApi as clientSettingsApi } from './generated/clientSettingsApi'
import { baseApi as fieldTagsApi } from './generated/fieldTagsApi'
import { baseApi as fieldDataTypesApi } from './generated/fieldDataTypesApi'
import { baseApi as fieldLevelsApi } from './generated/fieldLevelsApi'
import { baseApi as templateFieldTypesApi } from './generated/templateFieldTypesApi'
import { baseApi as templateFieldAvailabilitiesApi } from './generated/templateFieldAvailabilitiesApi'
import { baseApi as functionsApi } from './generated/functionsApi'
import { baseApi as userPermissions } from './userPermissionsApi'
import { baseApi as templateSyncApi } from './generated/templateSyncApi'

type IQueryCacheValidTime = Record<
keyof typeof hierarchiesApi['endpoints']
| keyof typeof clientMediaPartnersApi['endpoints']
| keyof typeof clientSettingsApi['endpoints']
| keyof typeof fieldTagsApi['endpoints']
| keyof typeof fieldDataTypesApi['endpoints']
| keyof typeof fieldLevelsApi['endpoints']
| keyof typeof templateFieldTypesApi['endpoints']
| keyof typeof templateFieldAvailabilitiesApi['endpoints']
| keyof typeof functionsApi['endpoints']
| keyof typeof userPermissions['endpoints']
| keyof typeof templateSyncApi['endpoints'],
number
>

export const queryCacheValidTime: IQueryCacheValidTime = {
  hierarchies: 30 * 60,
  clientMediaPartners: 30 * 60,
  getClientSettings: Infinity,
  postClientSettings: undefined,
  getFieldTags: Infinity,
  getFieldDataTypes: Infinity,
  getFieldLevels: Infinity,
  getTemplateFieldTypes: Infinity,
  getTemplateFieldAvailabilities: Infinity,
  getFunctions: Infinity,
  getUserPermissions: Infinity,
  getSyncLinkedTemplatesByBaseMediaPlanTemplateId: 60,
  getSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplates: undefined,
  getSyncTemplateOptions: undefined,
  getSyncTemplateJob: undefined,
  postSyncTemplateJob: undefined,
  getSyncTemplateJobByTemplateJobId: undefined,
  getSyncTemplateUpdateResultByTemplateSyncUsageId: undefined
}
