const text = `# List Field's Connections

Area to configure/maintain the connections between list fields. 
Set values are valid for one another to ensure users do not enter invalid combinations within their media plans.

---

## List Field's Connections

This is a list of all of the lookup connections already set up for this client. 

If a lookup connection has not already been set up for this client it will not be displayed in this list.

**Field Name** - The name of the field which valid values are restricted by the values in the field selected in ‘Dependent On’.  A field can only be in this list once. 

**Enforced vs Encouraged** - The way in which this lookup connection is controlled.

* **Enforced:** Only valid combinations can be set together inside a plan. Users will not be able to set an invalid combination of values
* **Encouraged**: Within a plan, users will be guided to select valid values, though invalid combinations are possible. 
This is allows users to select unlikely combinations on the occasion that they are required. 

**Dependent On** - The field which the ‘Field Name’ field value's are controlled by.

---

## Edit

This will take you to configure/edit the values that are valid for one another 

**Specified Field Name in the First Column** - The list of values within the ‘Field Name’ field. 

**Valid For** - The list of values the specified value is valid for. Selecting the edit button will allow you to change the valid values. 

**Back** - Navigate back to the List of Field connections. Any changes made will be lost unless you have selected submit.

**Submit** - Any changes made to this page will be saved and applied to all plans. 

---

## Delete Connection

This will take delete the entire connection.

---
## Add Connection

This will add a new connection for you to configure at the bottom of the list.

---

## Back

Navigate back to the Client Setup. Any changes made will be lost unless you have selected submit. 

---

## Submit

Any changes made to this page will be saved and applied to all plans.`

export default text
