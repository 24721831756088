import React, { useCallback } from 'react'
import { Button } from 'antd'
import { FrontSheet, FrontSheetViewDisplay } from 'Apis/generated/frontSheetsApi'
import { MSLayoutContentRow, MSTabs, useRouteNavigation } from '@mindshare/layout'
import { MediaPlanNamesAndIds } from 'Containers/views/FrontSheet/FrontSheetViewContainer'
import { MsMarkdownEditorComponent } from 'Components/MarkdownEditor/MsMarkdownEditorComponent'
import frontSheetViewText from 'Components/tooltipContent/FrontSheets/Front Sheet View'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import FrontSheetIncludedPlansComponent from './FrontSheetIncludedPlansComponent'
import FrontSheetViewTableComponent from './FrontSheetViewTableComponent'

interface IProps {
  currentClientId: number
  currentFrontSheet: FrontSheet
  currentFrontSheetView: FrontSheetViewDisplay
  getFrontSheetView: (frontSheetViewId: number) => void
  mediaPlanNamesAndIds: MediaPlanNamesAndIds[]
  onNotesUpdate: (Notes: string) => void
  saveFrontSheet: () => void
  savingFrontSheet: boolean
}

export const FrontSheetViewComponent: React.FC<IProps> = ({
  currentClientId,
  currentFrontSheet,
  currentFrontSheetView,
  getFrontSheetView,
  mediaPlanNamesAndIds,
  onNotesUpdate,
  saveFrontSheet,
  savingFrontSheet
}): React.ReactElement => {
  const navigate = useRouteNavigation()
  const handleEditFrontSheet = useCallback(() => {
    navigate(`/edit-front-sheet/${currentFrontSheet.frontSheetId}?clientId=${currentClientId}`, false)
  }, [navigate, currentClientId, currentFrontSheet.frontSheetId])
  const handleViewAllFrontSheets = useCallback(() => {
    navigate('/front-sheets', false, { clientId: currentClientId })
  }, [navigate, currentClientId])

  return (
    <>
      <TitleAndButtonsComponent
        buttons={[{
          buttonClick: handleEditFrontSheet,
          buttonText: 'Edit Front Sheet'
        }]}
        title={currentFrontSheet.frontSheetName}
        tooltipText={frontSheetViewText}
      />

      <MSLayoutContentRow extraClass='front-sheet-view-plan-list-row'>
        <FrontSheetIncludedPlansComponent
          currentClientId={currentClientId}
          mediaPlanNamesAndIds={mediaPlanNamesAndIds}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow>
        <MSTabs
          className='frontSheets__tabs'
          onChange={(key) => {
            getFrontSheetView(Number(key))
          }}
          items={
            currentFrontSheet.frontSheetViews.map(view => ({
              label: view.frontSheetViewName,
              key: String(view.frontSheetViewId),
              children: (
                <FrontSheetViewTableComponent
                  currentClientId={currentClientId}
                  currentFrontSheetView={currentFrontSheetView}
                  frontSheetViewId={view.frontSheetViewId}
                  frontSheetId={view.frontSheetId}
                  frontSheetName={currentFrontSheet.frontSheetName}
                />
              )
            }))
          }
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow extraClass='front-sheet-view-plan-notes-row'>
        <h3>Notes</h3>
        <MsMarkdownEditorComponent
          value={currentFrontSheet.notes ?? ''}
          onChange={onNotesUpdate}
          data-testid='front-sheet-view-plan-notes'
        />
        <Button
          onClick={saveFrontSheet}
          disabled={savingFrontSheet}
          type='primary'
          data-testid='front-sheet-view-save-btn'
        >Save Notes</Button>
      </MSLayoutContentRow>

      <MSLayoutContentRow>
        <Button
          onClick={handleViewAllFrontSheets}
          disabled={savingFrontSheet}
        >Back to Front Sheets</Button>
      </MSLayoutContentRow>
    </>
  )
}

export default FrontSheetViewComponent
