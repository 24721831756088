import React, { memo, useCallback, useMemo } from 'react'
import { IMSHierarchies } from '@mindshare/layout'
import classNames from 'classnames'
import { setFlightPasteModalStartDate, setSelectedDates, setSelectedFlights, expandFlightGroupRow } from 'Actions/mediaPlansActions'
import FlightCellComponent from 'Components/MediaPlanVersion/FlightCellComponent'
import { IMediaPlanTemplateFields, getFieldColumnName } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { getCompressedFlightWidth } from 'Helpers/calendarHelper'
import { useFlightSelectHelper } from 'Components/MediaPlanVersion/hooks/useFlightSelectHelper'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectCopiedFlight } from '../../selectors'

interface IProps {
  valid: boolean
  calculationMode: boolean
  flightGroupId: number
  flightGroupIndex: number
  planStartDate: string
  planEndDate: string
  mediaPlanField: IMediaPlanTemplateFields
  masteredListsData: IMasteredListsData
  unfilteredHierarchies: IMSHierarchies
  tokenHandler: (token: string) => void
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => void
  clearFlightData?: (flightGroupIndex: number, flightIndex: number) => void
  copySelectedFlight: () => void
  mergeFlightSelection: (startDate: Date, enDate: Date, mergeByWeek: boolean, flightGroupIndex: number, flightIndex: number, mergeByMonth: boolean) => void
  isSelectedFlightCellEmpty: boolean
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  isCollapsed?: boolean
  compressedCalendarView: boolean
  selectedFlights: string[]
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  isSubtotalGroup?: boolean
  flights?: IFlight[]
  flightsDates: IFlight[]
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightFieldContainer = (props: IProps) => {
  const {
    valid,
    flightGroupId,
    flightGroupIndex,
    calculationMode,
    masteredListsData,
    mediaPlanField,
    tokenHandler,
    unfilteredHierarchies,
    planStartDate,
    planEndDate,
    deleteFlight,
    clearFlightData,
    copySelectedFlight,
    mergeFlightSelection,
    isSelectedFlightCellEmpty,
    linkedLookupFieldsHelperValues,
    isCollapsed,
    compressedCalendarView,
    selectedFlights,
    masteredDataHelperValues,
    isSubtotalGroup,
    flights,
    flightsDates,
    financeListFieldsData
  } = props

  const dispatch = useAppDispatch()
  const copiedFlight = useAppSelector(selectCopiedFlight)
  const openFlightPasteModalWithStartDate = useCallback((startDate: string) => {
    dispatch(setFlightPasteModalStartDate(startDate))
  }, [dispatch])
  const columnName = getFieldColumnName(mediaPlanField)

  const {
    firstSelectedFlight,
    lastSelectedFlight
  } = useFlightSelectHelper({
    selectedFlights,
    flightsDates
  })

  const expressionKey = (index: number, field: IMediaPlanTemplateFields) =>
    `${
      field.mediaPlanVersionFieldId > 0
        ? field.mediaPlanVersionFieldId
        : field.clientMediaPlanFieldId
    }-${index}-${flightGroupIndex}`

  const availableFlights = useMemo(() => isSubtotalGroup ? flights : flightsDates, [isSubtotalGroup, flights, flightsDates])

  return (
    <div className='flight__grid -readonly'>
      {
        availableFlights?.map((flightDate, index) => {
          const key = expressionKey(index, mediaPlanField)
          const isPartOfSelectedFlight = selectedFlights?.length === 1 &&
            !isSelectedFlightCellEmpty &&
            index.toString() >= selectedFlights[0].split('-')[1] &&
            index.toString() <= selectedFlights[selectedFlights.length - 1].split('-')[1] &&
            flightGroupIndex.toString() === selectedFlights[selectedFlights.length - 1].split('-')[2]
          const isCellSelected = selectedFlights.includes(key)
          const isPartOfCopiedFlight = index === copiedFlight?.flightIndex && flightGroupIndex === copiedFlight?.flightGroupIndex

          const width = compressedCalendarView ? getCompressedFlightWidth(flightDate.width) : flightDate.width

          return (
            <div
              className={classNames(
                'flight__gridItem',
                { '-selected': isPartOfSelectedFlight || isCellSelected },
                { '-copied': isPartOfCopiedFlight },
                { '-compressed': compressedCalendarView }
              )}
              data-key={key}
              key={key}
              style={{ width }}
              onContextMenu={() => {
                if (isCellSelected || isSubtotalGroup) {
                  return
                }
                dispatch(setSelectedDates([flightDate.flightStartDate, flightDate.flightEndDate]))
                dispatch(setSelectedFlights([key]))
              }}
              onClick={() => {
                if (isSubtotalGroup) {
                  return
                }

                dispatch(setSelectedFlights([key]))
                dispatch(setSelectedDates([flightDate.flightStartDate, flightDate.flightEndDate]))
                if (flightDate.merge) {
                  if (isCollapsed) {
                    dispatch(expandFlightGroupRow(flightGroupId))
                  }
                }
              }
              }
            >
              <FlightCellComponent
                calculationMode={calculationMode}
                merge={flightDate.merge}
                unfilteredHierarchies={unfilteredHierarchies}
                masteredListsData={masteredListsData}
                tokenHandler={tokenHandler}
                valid={valid}
                startDate={flightDate.flightStartDate}
                endDate={flightDate.flightEndDate}
                planStartDate={planStartDate}
                planEndDate={planEndDate}
                mediaPlanField={mediaPlanField}
                deleteFlight={deleteFlight}
                clearFlightData={clearFlightData}
                copySelectedFlight={copySelectedFlight}
                mergeFlightSelection={mergeFlightSelection}
                flightGroupIndex={flightGroupIndex}
                flightIndex={index}
                canCopy={isPartOfSelectedFlight}
                firstSelectedFlight={firstSelectedFlight}
                lastSelectedFlight={lastSelectedFlight}
                isCellWithinSelectedRange={isCellSelected}
                canPaste={!!copiedFlight}
                openFlightPasteModalWithStartDate={openFlightPasteModalWithStartDate}
                linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                isCollapsed={isCollapsed}
                masteredDataHelperValues={masteredDataHelperValues}
                isSubtotalGroup={isSubtotalGroup}
                subtotalFieldValue={flightDate[columnName]}
                financeListFieldsData={financeListFieldsData}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default memo(FlightFieldContainer)
