import React, { memo, useEffect, useState } from 'react'
import { getRichTextOutputHtml } from 'Helpers/richTextHelpers'
import ModalComponent from './ModalComponent'
import ReactDraftEditor from './ReactDraftEditor'

interface IProps {
  value: string
  title: string
  showEditModal?: boolean
  setShowEditModal?: (show: boolean) => void
  updateValue?: (value: string) => void
}

export const RichTextComponent = ({ value, title, showEditModal, setShowEditModal, updateValue }: IProps) => {
  const [content, setContent] = useState(value)

  useEffect(() => {
    if (updateValue && value !== content) {
      updateValue(content)
    }
  }, [content, updateValue, value])

  return <>
    <div dangerouslySetInnerHTML={getRichTextOutputHtml(value)} />
    {
      showEditModal && setShowEditModal && <ModalComponent
        width='750px'
        title={title}
        modalBody={<ReactDraftEditor value={value} setContent={setContent} onClose={() => setShowEditModal(false)} />}
        onCancel={ () => setShowEditModal(false)}
        open={showEditModal}
        footer={false}
      />
    }
  </>
}

export default memo(RichTextComponent)
