import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFieldDataTypes: build.query<
    GetFieldDataTypesApiResponse,
    GetFieldDataTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/fieldDataTypes`,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetFieldDataTypesApiResponse =
  /** status 200 Returns list of Field Data Types */ FieldDataTypeRead[];
export type GetFieldDataTypesApiArg = {
  clientId: number;
};
export type MappingDestinationOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 100 | 200;
export type FieldDataType = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldDataTypeRead = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  isLookupType?: boolean;
  isClientDefinedListOrUniqueString?: boolean;
  isHierarchy?: boolean;
  isClientHierarchy?: boolean;
  isClientList?: boolean;
  isFinance?: boolean;
  isFinanceAudience?: boolean;
  isCostDatabase?: boolean;
  isParameterizedByGeographyAndMedia?: boolean;
  isNumeric?: boolean;
  isLongInt?: boolean;
  isDecimalType?: boolean;
  isString?: boolean;
  isRichText?: boolean;
  isDate?: boolean;
  cSharpDataType?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export const { useGetFieldDataTypesQuery, useLazyGetFieldDataTypesQuery } =
  injectedRtkApi;
