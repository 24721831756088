import React, { memo, useCallback } from 'react'
import { DeleteFilled, StarFilled } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import {
  IMediaPlanTemplateFields,
  isFieldAvailableOrDefault,
  getCssClassByInputType,
  getCorrectDisplayField
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

interface IProps {
  valid: boolean
  flightGroupIndex: number
  mediaPlanField: IMediaPlanTemplateFields
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields, flightGroupIndex: number) => void
  error?: string
  isSubtotalGroup?: boolean
}

export const FlightLabel = (props: IProps) => {
  const {
    valid,
    flightGroupIndex,
    mediaPlanField,
    removePlanField,
    error,
    isSubtotalGroup
  } = props
  const handleRemove = useCallback(() => {
    removePlanField(mediaPlanField, flightGroupIndex)
  }, [removePlanField, mediaPlanField, flightGroupIndex])

  const defaultOrAvailable = isFieldAvailableOrDefault(mediaPlanField)
  const fieldTypeClasses = `${getCssClassByInputType(mediaPlanField)} ${valid ? '-valid' : ''}`

  return (
    <Tooltip title={error}>
      <div
        className={classNames('flight__itemLabel', `${fieldTypeClasses}`, { '--error': error })}
      >
        {!isSubtotalGroup && (
          defaultOrAvailable ? (
            <Tooltip title='Delete flight field'>
              <Button
                className='btn-unset-default'
                icon={<DeleteFilled />}
                size='small'
                data-testid='btn-delete-field'
                onClick={handleRemove}
              />
            </Tooltip>
          ) : (
            <Tooltip placement='top' title='Mandatory flight field'>
              <Button
                className='btn-unset-default'
                tabIndex={-1}
                size='small'
                icon={<StarFilled />}
              />
            </Tooltip>
          ))}
        <div className={fieldTypeClasses} data-testid='flight-label'>
          {getCorrectDisplayField(mediaPlanField)}
        </div>
      </div>
    </Tooltip>
  )
}

export default memo(FlightLabel)
