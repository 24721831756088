import React from 'react'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import RichTextAvailableComponent from 'Components/RichTextAvailableComponent'
import { filterRichText } from 'Helpers/richTextHelpers'
import TemplateExamplePlanLevelRichTextFieldComponent from './TemplateExamplePlanLevelRichTextFieldComponent'

interface IProps {
  onAvailableFieldSelected: (c: string,
    mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[]) => void
  availablePlanFields: IMediaPlanTemplateFields[]
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  richTextPlanLevelFields: IMediaPlanTemplateFields[]
}

export const TemplateExamplePlanLevelRichTextFieldsComponent = ({
  availablePlanFields,
  onAvailableFieldSelected,
  removePlanField,
  setFunction,
  richTextPlanLevelFields
}: IProps) => {
  const richTextAvailablePlanFields = availablePlanFields.filter(filterRichText)

  return (
    <>
      {
        richTextPlanLevelFields.map((mediaPlanField) => {
          const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId

          if (id === 0) {
            return (
              <RichTextAvailableComponent
                key={mediaPlanField.clientMediaPlanFieldId}
                availableMediaPlanFields={richTextAvailablePlanFields}
                onAvailableFieldSelected={(selected: string) => onAvailableFieldSelected(selected, richTextPlanLevelFields, setFunction, availablePlanFields)} />
            )
          } else {
            return (
              <TemplateExamplePlanLevelRichTextFieldComponent
                key={mediaPlanField.clientMediaPlanFieldId}
                defaultValue={mediaPlanField.defaultValue?.toString()}
                mediaPlanField={mediaPlanField}
                removePlanField={removePlanField}
              />
            )
          }
        })
      }
    </>
  )
}

export default TemplateExamplePlanLevelRichTextFieldsComponent
