import { combineReducers } from '@reduxjs/toolkit'
import AuthReducer from 'mindshare.usermanager/lib/redux/reducers/userManagerReducer'
import { baseApi } from 'Apis/generated/baseApi'
import AppReducer from './appReducer'
import LinkedLookupsReducer from './linkedLookupsReducer'
import MediaPlanFieldReducer from './mediaPlanFieldReducer'
import ClientMediaPlanFieldReducer from './clientMediaPlanFieldReducer'
import MediaPlansReducer from './mediaPlansReducer'
import MediaPlanTemplatesReducer from './mediaPlanTemplatesReducer'
import TemplatesReducer from './templatesReducer'
import FrontSheetReducer from './frontSheetReducer'
import ImportReducer from './importReducer'

const rootReducer = combineReducers({
  app: AppReducer,
  userManager: AuthReducer,
  frontSheets: FrontSheetReducer,
  imports: ImportReducer,
  linkedLookups: LinkedLookupsReducer,
  mediaPlanDataTerms: ClientMediaPlanFieldReducer,
  mediaPlanField: MediaPlanFieldReducer,
  mediaPlans: MediaPlansReducer,
  mediaPlanTemplates: MediaPlanTemplatesReducer,
  templates: TemplatesReducer,
  [baseApi.reducerPath]: baseApi.reducer
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
