import React from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { Card, Checkbox, List, Typography } from 'antd'
import { GetSyncTemplateOptionsApiResponse } from 'Apis/generated/templateSyncApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { ITemplateSyncFormData, ITemplateSyncOptionPerField } from './TemplateSyncModalComponent'
import TemplateSyncPerFieldOptionsTable from './TemplateSyncPerFieldOptionsTable'
import { emptyItem } from './helpers/common'

const { Paragraph } = Typography

interface ITemplateSyncOptionsSelectionStepComponentProps {
  control: Control<ITemplateSyncFormData>
  setValue: UseFormSetValue<ITemplateSyncFormData>
  isClientManaged: boolean
  selectedTemplateOptionsPerFields: Record<number, ITemplateSyncOptionPerField[]>
  templateFieldsList: IMediaPlanTemplateFields[]
  templateOptionsList: GetSyncTemplateOptionsApiResponse
}

const TemplateSyncOptionsSelectionStepComponent: React.FC<ITemplateSyncOptionsSelectionStepComponentProps> = ({
  control,
  setValue,
  isClientManaged,
  selectedTemplateOptionsPerFields,
  templateFieldsList,
  templateOptionsList
}) => (
  <>
    <Paragraph>What changes do you want to sync?</Paragraph>
    <Card title='Actions'>
      <div className='template-sync__list-wrapper'>
        <List
          dataSource={templateOptionsList}
          renderItem={(item) => (
            <List.Item key={item.templateSyncOptionId}>
              <Controller name='selectedTemplateOptions' control={control} render={({ field }) => {
                const isChecked = !!field.value.find(value => value.templateSyncOptionId === item.templateSyncOptionId)
                return (
                  <div className='template-sync__option-wrapper'>
                    <div className='template-sync__option'>
                      <Checkbox
                        checked={isChecked}
                        value={{ ...item }}
                        onChange={(e) => {
                          field.onChange(
                            e.target.checked
                              ? [...field.value, e.target.value]
                              : field.value.filter(value => value.templateSyncOptionId !== e.target.value.templateSyncOptionId)
                          )
                          if (item.isPerField) {
                            setValue('selectedTemplateOptionsPerFields', { [item.templateSyncOptionId]: [{ ...emptyItem }] })
                          }
                        }}
                      >
                        {item.templateSyncOptionName}
                      </Checkbox>
                      {isClientManaged && !item.isForGlobalClients && (
                        <div className='template-sync__list-item -align-right'>Does not apply to client-managed instances</div>
                      )}
                    </div>
                    {item.isPerField && isChecked && (
                      <TemplateSyncPerFieldOptionsTable
                        control={control}
                        selectedTemplateOptionsPerField={selectedTemplateOptionsPerFields[item.templateSyncOptionId]}
                        templateFieldsList={templateFieldsList}
                        templateSyncOptionId={item.templateSyncOptionId}
                      />
                    )}
                  </div>
                )
              }} />
            </List.Item>
          )}
        />
      </div>
    </Card>
  </>
)

export default React.memo(TemplateSyncOptionsSelectionStepComponent)
