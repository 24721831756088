import React, { FunctionComponent, useRef } from 'react'
import CreatableSelect from 'react-select/creatable'
import { Tooltip } from 'antd'
import { generateTemporaryId } from 'Helpers/commonUtils'

interface IProps {
  id: number
  options: IOptions[]
  defaultValue: number | string
  newValueObject: {
    id: number
    value: number
    label: string
  }
  setFieldValue: (item: IOptions) => void
  onClick: () => void
  disabled?: boolean
}

interface IOptions {
  value: string | number | string[]
  label: string | string[] | React.ReactNode
  id?: number
}

const customStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 4
  })
}

const SelectCreateComponent: FunctionComponent<IProps> = ({ id, options, setFieldValue, newValueObject, defaultValue, onClick, disabled }) => {
  const selectCreate = useRef(null)

  const defaultValueOption = options.find(x => x.value === defaultValue)
  const addedUnavailableOption = {
    id: generateTemporaryId(),
    label: ['[Unavailable value]'],
    value: ['[Unavailable value]']
  }

  const setValue = () => {
    if (defaultValueOption) {
      return defaultValueOption
    } else if (newValueObject.value && newValueObject.id === id) {
      return newValueObject
    } else if (defaultValue) {
      return addedUnavailableOption
    }
  }

  const allOptions = [
    ...options,
    ...(defaultValue && !defaultValueOption ? [addedUnavailableOption] : [])
  ]

  return (
    <div ref={selectCreate} onClick={() => onClick && onClick()} className='select-create-wrapper' data-testid='select-create'>
      <CreatableSelect
        className='select-create'
        classNamePrefix='select-create'
        isClearable={true}
        styles={customStyles}
        options={allOptions}
        placeholder='Select Value'
        value={setValue()}
        createOptionPosition='first'
        menuPortalTarget={document.querySelector('body')}
        formatCreateLabel={userInput => `Add '${userInput}'`}
        onChange={option => setFieldValue(option)}
        getNewOptionData={(option, inputValue) => ({ value: null, label: inputValue })}
        filterOption={(option, inputValue) => option.label.toLowerCase?.().includes(inputValue.toLowerCase?.())}
        isDisabled={disabled}
        formatOptionLabel={({ label }) => <Tooltip title={label}>{label}</Tooltip>}
      />
    </div>
  )
}

export default SelectCreateComponent
