
import { IMSHierarchies } from '@mindshare/layout'
import { IMediaPlanTemplateFields, IMediaPlanTemplateFieldsExtended } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { getExpandedHierarchyFlightGroupField } from 'Components/MediaPlans/constants/entities/ExpandedHierarchyGroupField'
import { IExpandedHierarchiesFlightGroupsValues } from './getExpandedHierarchiesValues'
import { getCurrentExpandedHierarchiesLevels } from './getCurrentExpandedHierarchiesLevels'

export const getExpandedHierarchyFlightGroupsFields =
  (
    flightGroupFields: Array<IMediaPlanTemplateFieldsExtended | IMediaPlanTemplateFields>,
    hierarchies: IMSHierarchies,
    flightGroupHierarchiesValues: IExpandedHierarchiesFlightGroupsValues
  ): Array<IMediaPlanTemplateFieldsExtended | IMediaPlanTemplateFields> => {
    if (!flightGroupHierarchiesValues) {
      return []
    }

    const currentExpandedHierarchiesLevels = getCurrentExpandedHierarchiesLevels(flightGroupHierarchiesValues, hierarchies)
    const newFlightGroupFields = [...flightGroupFields]

    for (let i = flightGroupFields.length - 1; i >= 0; i--) {
      const field = flightGroupFields[i]
      if (!field.clientMediaPlanField.mediaPlanField.fieldDataType?.isHierarchy) {
        continue
      }

      const hierarchyTypeId = field.clientMediaPlanField.mediaPlanField.fieldDataType.fieldDataTypeId
      const hierarchyLevelsFieldsToAdd = currentExpandedHierarchiesLevels[hierarchyTypeId]
        .map(hierarchyLevel => getExpandedHierarchyFlightGroupField(
          field,
          hierarchyLevel
        ))

      // eslint-disable-next-line functional/immutable-data
      newFlightGroupFields.splice(i, 0, ...hierarchyLevelsFieldsToAdd)
    }

    return newFlightGroupFields
  }
