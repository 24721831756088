import { useMemo } from 'react'
import { useGetCostMasterDataBuyingRouteHierarchyQuery, GetCostMasterDataBuyingRouteHierarchyApiResponse } from 'Apis/generated/costMasterDataApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useGetCostMasterDataBuyingRouteHierarchy = ({
  clientGeographyHierarchyId,
  clientMediaHierarchyId,
  shouldSkip = false
}): UseQueryCommand<GetCostMasterDataBuyingRouteHierarchyApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCostMasterDataBuyingRouteHierarchyQuery(
    { clientId: currentClient.id, clientGeographyHierarchyId, clientMediaHierarchyId },
    { skip: !currentClient.id || shouldSkip }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
