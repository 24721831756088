import React, { useRef } from 'react'
import Moment from 'react-moment'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Typography, Carousel } from 'antd'
import { TemplateSyncUsage } from 'Apis/generated/templateSyncApi'
import { useSyncTemplateUpdateResult } from 'Components/TemplateSync/hooks/useSyncTemplateUpdateResult'
import { useSyncTemplateOptions } from 'Components/TemplateSync/hooks/useSyncTemplateOptions'
import { TemplateSyncResultListComponent } from 'Components/TemplateSync/TemplateSyncResultListComponent'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import ModalComponent from '../ModalComponent'

const { Text } = Typography

interface ITemplateSyncResultModalProps {
  visible: boolean
  hideModal: () => void
  templateSyncUsageId: number
  templates: TemplateSyncUsage[]
  setTemplateSyncUsageId: (id: number) => void
  clientMediaPlanFields: IClientMediaPlanField[]
}

export const TemplateSyncResultModalComponent: React.FC<ITemplateSyncResultModalProps> = ({
  visible,
  hideModal,
  templateSyncUsageId,
  templates,
  setTemplateSyncUsageId,
  clientMediaPlanFields
}) => {
  const { data: templateUpdateResult } = useSyncTemplateUpdateResult({ templateSyncUsageId })
  const { data: templateSyncOptions } = useSyncTemplateOptions()
  const carousel = useRef(null)

  const handleNextTemplate = () => {
    const currentIndex = templates.findIndex(
      t => t.templateSyncUsageId === templateSyncUsageId
    )
    const nextItem = templates[currentIndex + 1]?.templateSyncUsageId

    if (!nextItem) {
      setTemplateSyncUsageId(templates[0]?.templateSyncUsageId)
    } else {
      setTemplateSyncUsageId(nextItem)
    }
    carousel?.current?.next()
  }

  const handlePreviousTemplate = () => {
    const currentIndex = templates.findIndex(
      t => t.templateSyncUsageId === templateSyncUsageId
    )
    const prevItem = templates[currentIndex - 1]?.templateSyncUsageId

    if (!prevItem) {
      setTemplateSyncUsageId(
        templates[templates.length - 1]?.templateSyncUsageId
      )
    } else {
      setTemplateSyncUsageId(prevItem)
    }
    carousel?.current?.prev()
  }

  return (
    <ModalComponent
      title='Template status'
      className='template-sync-result-modal'
      wrapProps={{ 'data-testid': 'template-sync-result-modal' }}
      modalBody={
        <Carousel ref={carousel}>
          <>
            <div
              className='template-sync-result-modal__header'
            >
              <Text strong={true} className='template-name'>
                {templateUpdateResult?.destinationTemplateName}
              </Text>
              <Text type='secondary'>
                <span>Last update:</span>
                <Moment format='DD MMM YY h:mm:ss'>
                  {templateUpdateResult?.lastUpdated}
                </Moment>
              </Text>
            </div>
            <TemplateSyncResultListComponent
              templateUpdateResult={templateUpdateResult}
              templateSyncOptions={templateSyncOptions}
              clientMediaPlanFields={clientMediaPlanFields}
            />
          </>
        </Carousel>
      }
      footer={
        <div className='template-sync-result-modal__footer'>
          <div className='carousel-btn'>
            <Button type='link' onClick={handlePreviousTemplate} data-testid='previous-template-button'>
              <LeftOutlined />
            </Button>
            <Button type='link' onClick={handleNextTemplate} data-testid='next-template-button'>
              <RightOutlined />
            </Button>
          </div>
          <div>
            <Button type='primary' onClick={hideModal}>
              Done
            </Button>
          </div>
        </div>
      }
      open={visible}
      onCancel={hideModal}
      width={900}
    />
  )
}

export default TemplateSyncResultModalComponent
