import { DataNode } from 'rc-tree/lib/interface'
import { LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'

interface TreeDataNode extends DataNode {
  value?: number
}

export const getSelectedChildrenNodes = (hierarchyList: TreeDataNode[], checkedKeys: React.Key[]) =>
  hierarchyList.reduce((acc, item) =>
    checkedKeys.includes(item.key)
      ? [
        ...acc,
        { title: item.title, value: item.value, key: item.value },
        ...getSelectedChildrenNodes(item.children, checkedKeys)
      ]
      : acc
  , [])

export const getFilteredDataSource = (hierarchyList: TreeDataNode[], selectedValues: TreeDataNode[]) =>
  hierarchyList.reduce((acc, item) => {
    const children = item.children.length ? getFilteredDataSource(item.children, selectedValues) : []
    const isSelected = selectedValues.some((s) => s.value === item.value)

    return isSelected || children.length > 0 ? [...acc, { ...item, children }] : acc
  }, [])

export const getExpandedKeys = (hierarchyList: TreeDataNode[]) =>
  hierarchyList?.reduce((result, item) =>
    item.children?.length > 0 ? [...result, item.key, ...getExpandedKeys(item.children)] : [...result, item.key], [])

export const getSelectedChildrenNodesByLookupValue = (
  hierarchyList: TreeDataNode[],
  linkedLookupValues: LinkedLookupValue[],
  currentFieldId: number,
  includeAllChildren: boolean
) =>
  hierarchyList.reduce((acc, item) => {
    if (includeAllChildren || linkedLookupValues?.some(
      value =>
        item.value === value.referencedValueId &&
        value.dependentValueId === currentFieldId
    )) {
      return [
        ...acc,
        { title: item.title, value: item.value },
        ...(item.children ? getSelectedChildrenNodesByLookupValue(item.children, linkedLookupValues, currentFieldId, true) : [])
      ]
    } else if (item.children && item.children.length > 0) {
      return [...acc, ...getSelectedChildrenNodesByLookupValue(item.children, linkedLookupValues, currentFieldId, false)]
    }
    return acc
  }, [])
