import React, { FunctionComponent } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import { MSTable } from '@mindshare/layout'

import { FieldTag, MediaPlanFieldLevel } from 'Apis/generated/mediaPlanFieldsApi'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import TagListComponent from 'Components/TagListComponent'

export interface IFieldSelectionTableProps {
  visibleFields: IMediaPlanFieldExtended[]
  handleRowSelection: (selectedRows: IMediaPlanFieldExtended[]) => void
}

const FieldSelectionTable: FunctionComponent<IFieldSelectionTableProps> = ({ visibleFields, handleRowSelection }) => {
  const rowSelection: TableRowSelection<IMediaPlanFieldExtended> = {
    onChange: (selectedRowKeys: number[], selectedRows: IMediaPlanFieldExtended[]) => {
      handleRowSelection(selectedRows)
    }
  }
  const columns: Array<ColumnProps<IMediaPlanFieldExtended>> = [
    {
      key: 'MediaPlanFieldName',
      title: 'Name',
      dataIndex: 'fieldLabel',
      sorter: (a, b) => a.fieldLabel.length - b.fieldLabel.length,
      sortDirections: ['descend']
    },
    {
      key: 'MediaPlanFieldDefinition',
      title: 'Definition',
      dataIndex: 'fieldDescription'
    },
    {
      key: 'MediaPlanFieldTags',
      title: 'Tags',
      dataIndex: 'fieldTags',
      render: (tags: FieldTag[]) => (
        <TagListComponent tags={tags} />
      )
    },
    {
      key: 'MediaPlanFieldLevel',
      title: 'Level',
      dataIndex: 'fieldLevel',
      sorter: (a: MediaPlanFieldLevel, b: MediaPlanFieldLevel) => (a.fieldLevel as string).length - (b.fieldLevel as string).length,
      sortDirections: ['descend']
    },
    {
      key: 'MediaPlanDataType',
      title: 'Data Type',
      dataIndex: 'fieldDataType'
    }
  ]

  return (
    <div className='list-table'>
      <MSTable
        rowKey='mediaPlanFieldLevelId'
        columns={columns}
        dataSource={visibleFields}
        rowSelection={rowSelection}
        dynamicPagination={true}
      />
    </div>
  )
}

export default FieldSelectionTable
