import { formatPascalCaseString, getClientHierarchyValueProp, HIERARCHY_VALUE_PROP } from '@mindshare/layout'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { HIERARCHY_TYPES_MAP, CLIENT_HIERARCHY_TYPES_MAP } from '../constants/entities/IHierarchies'
import { IExpandedHierarchiesFlightGroupsValues } from './getExpandedHierarchiesValues'

export const isExpandedHierarchyColumn = (field: IMediaPlanTemplateFields) => {
  return !!field.expandedHierarchyTypeId
}

export const getExpandedHierarchyDataTypeName = (field: IMediaPlanTemplateFields) => {
  const hierarchyName = HIERARCHY_TYPES_MAP[field.expandedHierarchyTypeId]
  return `${formatPascalCaseString(hierarchyName)} hierarchy`
}

export const getExpandedHierarchyValue = (
  field: IMediaPlanTemplateFields,
  flightGroupIndex: number,
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
) => {
  const hierarchyName = CLIENT_HIERARCHY_TYPES_MAP[field.expandedHierarchyTypeId]
  const isMasteredHierarchyType =
    field.expandedHierarchyTypeId === FieldDataType.FINANCE_STATION_HIERARCHY ||
    field.expandedHierarchyTypeId === FieldDataType.FINANCE_PRODUCT_HIERARCHY ||
    field.expandedHierarchyTypeId === FieldDataType.COST_BUYING_ROUTE_HIERARCHY
  const hierarchyValueProp = isMasteredHierarchyType ? HIERARCHY_VALUE_PROP : getClientHierarchyValueProp(hierarchyName)
  return expandedHierarchiesFlightGroupsValues[flightGroupIndex][field.expandedHierarchyTypeId]?.[field.expandedHierarchyLevel]?.[hierarchyValueProp]
}

export const getExpandedHierarchyIdValue = (
  field: IMediaPlanTemplateFields,
  flightGroupIndex: number,
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
) => {
  return expandedHierarchiesFlightGroupsValues[flightGroupIndex]?.[field.expandedHierarchyTypeId]?.[field.expandedHierarchyLevel]?.id
}
