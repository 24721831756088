import React, { useCallback, useEffect, useState } from 'react'
import FileSaver from 'file-saver'
import { MSLayoutContentRow, LoadingComponent, getColumnSearchWithSettingsFunction, useRouteNavigation } from '@mindshare/layout'
import { useMapSettings } from '@mindshare/services'
import { cleanUpTemplateView } from 'Actions/mediaPlanTemplatesActions'
import { useMediaPlanTemplatesQuery } from 'Apis/enhancedTemplatesApi'
import { useTemplateHook } from 'ReduxHooks/templateHook'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import TemplateListTable from 'Components/TemplateList/TemplateListTable'
import { downloadMediaPlanTemplate } from 'Services/mediaPlanService'
import { TemplateListActionType } from 'Constants/enums/TemplateListActionType'
import { IMAPSettings } from 'Constants/entities/IMapSettings'
import templateText from 'Components/tooltipContent/Template List'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { appPaths } from 'Constants/appPaths'
import { useAppDispatch } from '../../../store'

export const TemplateListContainer: React.FunctionComponent = (): React.ReactElement => {
  const { data: currentClient } = useCurrentClient()
  const templateList = useMediaPlanTemplatesQuery({ clientId: currentClient.id })
  const { deleteTemplate } = useTemplateHook(currentClient.id, TemplateListActionType.DELETE)
  const { cloneTemplate } = useTemplateHook(currentClient.id, TemplateListActionType.CLONE)
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()
  const { resetToDefault, settings, settingsLoading, updateSetting } = useMapSettings<IMAPSettings, 'templateList'>('templateList', currentClient.id, {})

  const downloadTemplate = useCallback(async (mediaPlanTemplateId: number, clientId: number) => {
    const file = await downloadMediaPlanTemplate(mediaPlanTemplateId, clientId)
    FileSaver.saveAs(file, `mediaplantemplate-${mediaPlanTemplateId}.csv`)
  }, [])

  const createNewTemplate = useCallback(() => {
    dispatch(cleanUpTemplateView())
    navigate(appPaths.templates)
  }, [navigate, dispatch])

  useEffect(() => {
    if (currentClient.id > 0) {
      setLoading(false)
    }
  }, [currentClient.id])

  return loading || settingsLoading ? (
    <LoadingComponent
      appDataSuccess={!!currentClient.id}
      agencyLoadingGif={ currentClient.agency?.agencyLoadingGifLocation}
    />
  ) : (
    <>
      <TitleAndButtonsComponent
        buttons={[{
          buttonClick: resetToDefault,
          buttonText: 'Clear Filters'
        },
        {
          buttonClick: createNewTemplate,
          buttonText: 'Create New Template'
        }]}
        subtitle='Your latest templates'
        title='Templates'
        tooltipText={templateText}
      />

      <MSLayoutContentRow fillAvailableSpace={true}>
        <TemplateListTable
          createNewTemplate={createNewTemplate}
          currentClient={currentClient}
          cloneTemplate={cloneTemplate}
          deleteTemplate={deleteTemplate}
          downloadRow={(mediaPlanTemplateId: number, clientId: number) => downloadTemplate(mediaPlanTemplateId, clientId)}
          mediaPlanTemplates={templateList.data}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          filterFunction={getColumnSearchWithSettingsFunction(settings, updateSetting)}
        />
      </MSLayoutContentRow>
    </>
  )
}

export default TemplateListContainer
