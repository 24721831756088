import React, { FunctionComponent } from 'react'
import { Select, Checkbox } from 'antd'
import {
  getClientHierarchyTypeDetails,
  isClientHierarchyType,
  IMSHierarchies
} from '@mindshare/layout'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import { getCorrectDisplayField, isAggregatedOrCalculated } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IRowFunctions } from 'Components/TemplateFieldSelection/hooks/useTemplateFieldRows'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { isFinanceProductHierarchyType, isFinanceStationHierarchyType, isCostBuyingRouteHierarchyType } from 'Components/shared/constants/entities/IFieldMetaData'
import { IMasteredHierachiesHelperValues } from './constants/entities/ITemplateMasteredFieldsHelperValues'

interface ITemplateSpecificOptionsModalProps {
  fieldDataType: FieldDataTypeRead
  getMasteredHierarchiesValues: (key: number) => IMasteredHierachiesHelperValues
  hierarchies: IMSHierarchies
  rowFunctions: IRowFunctions
  item: IMediaPlanFieldRow
}

const getOptions = (data) => {
  if (!data) {
    return []
  }

  return data.map(hl => ({
    value: hl.hierarchyLevelId,
    label: hl.hierarchyLevelName
  }))
}

export const TemplateSpecificOptionsModal: FunctionComponent<ITemplateSpecificOptionsModalProps> = ({
  fieldDataType,
  getMasteredHierarchiesValues,
  hierarchies,
  rowFunctions,
  item
}) => {
  const isHierarchy = isClientHierarchyType(fieldDataType.dataTypeName)
  const isMasteredHierarchy =
    fieldDataType &&
    (isFinanceProductHierarchyType(fieldDataType.dataTypeName) ||
      isFinanceStationHierarchyType(fieldDataType.dataTypeName) ||
      isCostBuyingRouteHierarchyType(fieldDataType.dataTypeName))
  const fieldLabel = getCorrectDisplayField(item.mediaPlanField)
  const { hierarchyLevels } = isMasteredHierarchy
    ? getMasteredHierarchiesValues(fieldDataType.fieldDataTypeId)
    : getClientHierarchyTypeDetails(fieldLabel, hierarchies)
  const options = getOptions(hierarchyLevels)

  return (
    <div
      className='template-specific-options-modal'
      data-testid='template-specific-options-modal'
    >
      {isHierarchy && (
        <div className='hierarchy-options'>
          <Select
            data-testid='hierarchy-level-dropdown'
            placeholder='Lowest hierarchy level'
            className='lowest-hierarchy-dropdown'
            aria-label='Lowest hierarchy level'
            optionFilterProp='children'
            onChange={val => rowFunctions.handleHierarchyLevelId(val, item)}
            value={item.requiredHierarchyLevelId}
            options={options}
            allowClear={true}
          />
          <Checkbox
            disabled={!item.requiredHierarchyLevelId}
            data-testid='hierarchy-level-checkbox'
            checked={item.includeLevelsBelowHierarchy}
            onChange={(event: CheckboxChangeEvent) =>
              rowFunctions.handleIncludeLevelsBelow(event, item)
            }
          >
            Include Levels Below
          </Checkbox>
        </div>
      )}
      {isAggregatedOrCalculated(item) && (
        <Checkbox
          disabled={!isAggregatedOrCalculated(item)}
          data-testid='calculation-checkbox'
          checked={item.isComplexCalculation}
          onChange={(event: CheckboxChangeEvent) =>
            rowFunctions.handleCalculation(event, item)
          }
        >
          Complex
        </Checkbox>
      )}
    </div>
  )
}
