import { useMemo } from 'react'
import { GetCustomExportsFieldDataTypeOperatorsApiResponse } from 'Apis/generated/customExportsApi'
import { useGetCustomExportsFieldDataTypeOperatorsQuery } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useFieldOperators = (): UseQueryCommand<GetCustomExportsFieldDataTypeOperatorsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCustomExportsFieldDataTypeOperatorsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
