import React, { useEffect } from 'react'
import { MSLayoutContentRow, LoadingContainer, useRouteNavigation } from '@mindshare/layout'
import { cleanUpFrontSheetListContainer, deleteFrontSheet, initialiseFrontSheetListContainer } from 'Actions/frontSheetActions'
import { RootState } from 'Reducers/index'
import FrontSheetListComponent from 'Components/FrontSheets/FrontSheetList/FrontSheetListComponent'
import { downloadExcelFrontSheet } from 'Helpers/frontSheetHelper'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import frontSheetListText from 'Components/tooltipContent/FrontSheets/Front Sheet List'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useAppDispatch, useAppSelector } from '../../../store'

export const FrontSheetListContainer: React.FC = (): React.ReactElement => {
  const navigate = useRouteNavigation()
  const dispatch = useAppDispatch()
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)
  const currentClientDisplayName = useAppSelector((state) => state.app.currentClient.displayName)
  const frontSheets = useAppSelector((state) => state.frontSheets.frontSheets)

  useEffect(() => {
    dispatch(cleanUpFrontSheetListContainer)
    return () => dispatch(cleanUpFrontSheetListContainer())
  }, [dispatch, currentClientId])

  const buttonFunctions = {
    createNewFrontSheet: () => navigate(`/create-front-sheet?clientId=${currentClientId}`, false),
    downloadFrontSheet: downloadExcelFrontSheet,
    deleteFrontSheet: (frontSheetId: number) => dispatch(deleteFrontSheet(frontSheetId, currentClientId)),
    editFrontSheet: (frontSheetId: number) => navigate(`/edit-front-sheet/${frontSheetId}?clientId=${currentClientId}`, false),
    viewFrontSheet: (frontSheetId: number) => navigate(`/view-front-sheet/${frontSheetId}?clientId=${currentClientId}`, false)
  }
  return (
    <>
      <TitleAndButtonsComponent
        buttons={[{
          buttonClick: buttonFunctions.createNewFrontSheet,
          buttonText: 'Create Front Sheet'
        }]}
        title='Front Sheets'
        tooltipText={frontSheetListText}
      />

      <MSLayoutContentRow fillAvailableSpace={true}>
        <FrontSheetListComponent
          buttonFunctions={ buttonFunctions }
          clientId={ currentClientId }
          currentClientDisplayName={ currentClientDisplayName }
          frontSheets={ frontSheets }
        />
      </MSLayoutContentRow>
    </>
  )
}

export const FrontSheetListLoadingContainer: React.FC = (props) => {
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)

  return (
    <LoadingContainer
      appDataSuccess={true}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={(id) => initialiseFrontSheetListContainer(id, currentClientId)}
      loadingSelector={(state: RootState) => state.frontSheets.frontSheetListContainerLoading}
    >
      <FrontSheetListContainer {...props} />
    </LoadingContainer>
  )
}

export default FrontSheetListLoadingContainer
