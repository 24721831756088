import React from 'react'
import { Select } from 'antd'

export const LatestFlagsSelector = ({ value, onChange, options }) => (
  <Select
    className='select select--fluid'
    onChange={(v) => onChange(Number(v))}
    value={value || null}
    placeholder='Latest Flags'
    data-testid='latest-flag-select'
  >
    {options.map(el => (
      <Select.Option
        key={el.customExportLatestFlagId}
        value={el.customExportLatestFlagId}
      >
        {el.customExportLatestFlagName}
      </Select.Option>
    ))}
  </Select>
)
