import React from 'react'
import { IMSHierarchies, MSTabs } from '@mindshare/layout'
import { TemplateFieldType } from 'Apis/generated/templateFieldTypesApi'
import { TemplateFieldAvailability } from 'Apis/generated/templateFieldAvailabilitiesApi'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { ISelectedParentHierarchiesHelperValues, ISelectedHierarchies } from 'Components/TemplateFieldSelection/constants/entities/ISelectedHierarchies'
import { IRowFunctions } from 'Components/TemplateFieldSelection/hooks/useTemplateFieldRows'
import { ILinkedLookupFieldsHelperValues } from 'Components/LinkedLookup/constants/entities/ILinkedLookupHelperValues'
import { ITemplateMasteredFieldsHelperValues } from 'Components/TemplateFieldSelection/constants/entities/ITemplateMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { IClientSettingExtended } from 'Components/Client/constants/entities/IClientSetting'
import TemplateFieldSelectionComponent from './TemplateFieldSelectionComponent'
import { IValidationResult } from './constants/entities/IValidationResult'

export enum TemplateFieldSelectionTab {
  PLAN = 1,
  FLIGHT_GROUP = 2,
  FLIGHT = 3,
  SUB_FLIGHT = 4
}

export const tabs = {
  PLAN_LEVEL_TAB: {
    key: TemplateFieldSelectionTab.PLAN,
    title: 'Plan Level',
    component: TemplateFieldSelectionComponent,
    permissions: []
  },
  FLIGHT_GROUP_TAB: {
    key: TemplateFieldSelectionTab.FLIGHT_GROUP,
    title: 'Flight Group Level',
    component: TemplateFieldSelectionComponent,
    permissions: []
  },
  FLIGHT_LEVEL_TAB: {
    key: TemplateFieldSelectionTab.FLIGHT,
    title: 'Flight Level',
    component: TemplateFieldSelectionComponent,
    permissions: []
  },
  SUB_FLIGHT_LEVEL_TAB: {
    key: TemplateFieldSelectionTab.SUB_FLIGHT,
    title: 'Subflight Level',
    component: TemplateFieldSelectionComponent,
    permissions: []
  }
}

interface IProps {
  selectedTab: TemplateFieldSelectionTab
  setSelectedTab: (number) => void
  templateFieldRowsForTab: IMediaPlanFieldRow[]
  isTemplateAliasingEnabled: boolean
  availableMetaData: IClientMediaPlanField[]
  hierarchies: IMSHierarchies
  selectedParentHierarchiesHelperValues: ISelectedParentHierarchiesHelperValues
  selectedHierarchies: ISelectedHierarchies
  masteredListsData: IMasteredListsData
  rowFunctions: IRowFunctions
  templateAvailability: TemplateFieldAvailability[]
  templateFieldType: TemplateFieldType[]
  linkedLookupFieldsHelperValues: ILinkedLookupFieldsHelperValues
  masteredDataHelperValues: ITemplateMasteredFieldsHelperValues
  errors: IValidationResult[]
  requiredHierarchies: Partial<IClientSettingExtended>
}

export const TemplateFieldSelectionTabs: React.FunctionComponent<IProps> = ({
  selectedTab,
  setSelectedTab,
  templateFieldRowsForTab,
  hierarchies,
  selectedParentHierarchiesHelperValues,
  selectedHierarchies,
  masteredListsData,
  availableMetaData,
  isTemplateAliasingEnabled,
  templateAvailability,
  templateFieldType,
  rowFunctions,
  linkedLookupFieldsHelperValues,
  masteredDataHelperValues,
  errors,
  requiredHierarchies
}) => (
  <MSTabs
    onChange={(key) => setSelectedTab(Number(key))}
    activeKey={selectedTab?.toString()}
    items={
      Object.keys(tabs).map((tab) => {
        const tabTitle = tabs[tab].title
        const FieldSelectionComponent = tabs[tab].component as typeof TemplateFieldSelectionComponent
        return ({
          label: tabTitle,
          key: tabs[tab].key?.toString(),
          children: (
            <FieldSelectionComponent
              tabLevel={tabs[tab].key}
              itemList={templateFieldRowsForTab}
              hierarchies={hierarchies}
              selectedParentHierarchiesHelperValues={selectedParentHierarchiesHelperValues?.[selectedTab]}
              selectedHierarchies={selectedHierarchies}
              masteredListsData={masteredListsData}
              availableMetaData={availableMetaData}
              isAliased={isTemplateAliasingEnabled}
              templateAvailability={templateAvailability}
              templateFieldType={templateFieldType}
              rowFunctions={rowFunctions}
              linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
              masteredDataHelperValues={masteredDataHelperValues}
              errors={errors}
              requiredHierarchies={requiredHierarchies}
            />
          )
        })
      })
    }
  />
)

export default TemplateFieldSelectionTabs
