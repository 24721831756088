import React, { CSSProperties, FunctionComponent, useCallback } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import FlightTableHeaderComponent from './FlightTableHeaderComponent'

interface IProps {
  flightFields: IMediaPlanTemplateFields[]
  hasAvailableFlightFields: boolean
  handleAddField: (fieldType: FieldLevelType) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  getStickyStyle?: (type: StickyColumnType, id?: number) => CSSProperties
}

export const FlightsHeaderComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    flightFields,
    hasAvailableFlightFields,
    handleAddField,
    removePlanField,
    getStickyStyle
  } = props
  const handleAdd = useCallback(() => handleAddField(FieldLevelType.FLIGHT), [handleAddField])

  return (
    <>
      <th className='plus-button-container' style={getStickyStyle(StickyColumnType.toolbarTable)}>
        <div className='flightGroup__toolbar -header'>
          {hasAvailableFlightFields && (
            <Tooltip title='Add flight field'>
              <Button
                icon={<PlusCircleOutlined />}
                type='link'
                tabIndex={-1}
                size='small'
                data-testid='btn-add-flight-field'
                onClick={handleAdd}
              />
            </Tooltip>
          )}
        </div>
      </th>
      <th className="flightHeader__table">
        {
          flightFields.map((flightField: IMediaPlanTemplateFields, i: number) => (
            <FlightTableHeaderComponent
              key={i}
              mediaPlanField={flightField}
              removePlanField={removePlanField}
            />
          ))
        }
        <div className='plus-button-container'>
          <div className='flightGroup__toolbar' />
        </div>
      </th>

    </>
  )
}
export default FlightsHeaderComponent
