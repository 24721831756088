import { Guid } from 'guid-typescript'
import { ILoadingResult } from '@mindshare/layout'

import { IImportActions } from 'Actions/importActions'
import { DataImport } from 'Apis/generated/dataImportsApi'
import * as actionTypes from 'Constants/actionTypes'

export interface IImportState {
  currentDataImport: DataImport | null
  dataImports: DataImport[] | null
  createImportLoading: ILoadingResult
  importListContainerLoading: ILoadingResult
}

export const initialState: IImportState = {
  currentDataImport: null,
  dataImports: null,
  createImportLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  importListContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  }
}

export default function (state: IImportState = initialState, action: IImportActions): IImportState {
  switch (action.type) {
    case actionTypes.GET_DATA_IMPORT: {
      return {
        ...state,
        currentDataImport: action.payload
      }
    }
    case actionTypes.GET_DATA_IMPORTS: {
      return {
        ...state,
        dataImports: action.payload
      }
    }
    default:
      return state
  }
}
