import React, { useCallback } from 'react'
import { DeleteFilled, StarFilled } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { IMSHierarchies } from '@mindshare/layout'

import {
  IMediaPlanTemplateFields,
  getCorrectDisplayField,
  isFieldAvailableOrDefault,
  getCssClassByInputType
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IMediaPlanVersionMasteredFieldsHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import FieldContainer from './FieldTypeSwitchContainer'

interface IProps {
  valid: boolean
  calculationMode: boolean
  unfilteredHierarchies?: IMSHierarchies
  masteredListsData: IMasteredListsData
  mediaPlanField: IMediaPlanTemplateFields
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  mediaPlanFields: IMediaPlanTemplateFields[]
  onAvailableFieldSelected: (c: string) => void
  tokenHandler: (token: string) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
}
export const PlanLevelFieldContainer: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    valid,
    mediaPlanField,
    availableMediaPlanFields,
    calculationMode,
    unfilteredHierarchies,
    masteredListsData,
    onAvailableFieldSelected,
    removePlanField,
    tokenHandler,
    linkedLookupFieldsHelperValues,
    masteredDataHelperValues
  } = props
  const removeField = useCallback(() => {
    removePlanField(mediaPlanField)
  }, [removePlanField, mediaPlanField])


  const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId
  const fieldLabel = getCorrectDisplayField(mediaPlanField)
  const defaultOrAvailable = isFieldAvailableOrDefault(mediaPlanField)
  const fieldTypeClasses = getCssClassByInputType(mediaPlanField)

  if (id !== 0) {
    return (
      <div
        className='container-row-item'
        data-testid='plan-level-field-container'
      >
        <Tooltip title={fieldLabel}>
          <label data-testid={mediaPlanField.mediaPlanVersionFieldId} className={`input-item-label ${fieldTypeClasses}`}>{fieldLabel}</label>
        </Tooltip>
        {defaultOrAvailable ? (
          <Tooltip placement='top' title="Delete plan field">
            <Button
              className='btn-unset-default'
              data-testid='btn-delete-field'
              icon={<DeleteFilled />}
              tabIndex={-1}
              onClick={removeField}
            />
          </Tooltip>
        ) : (
          <Tooltip placement='top' title="Mandatory plan field">
            {<StarFilled className='ant-btn-icon-only' />}
          </Tooltip>
        )}
        <FieldContainer
          calculationMode={calculationMode}
          field={mediaPlanField}
          masteredListsData={masteredListsData}
          unfilteredHierarchies={unfilteredHierarchies}
          tokenHandler={tokenHandler}
          valid={valid}
          defaultClass={'input-item-value'}
          linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
          masteredDataHelperValues={masteredDataHelperValues}
        />
      </div>
    )
  } else if (availableMediaPlanFields.length) {
    return (
      <div
        className='container-row-item'
        data-testid='plan-level-field-container'
      >
        <label className={`input-item-label ${fieldTypeClasses}`}>Available Fields</label>
        <FieldAvailableComponent
          onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue)}
          availableMediaPlanFields={availableMediaPlanFields}
        />
      </div>
    )
  } else {
    return <></>
  }
}


export default PlanLevelFieldContainer
