import React, { FunctionComponent, useEffect, useState, memo, useCallback } from 'react'
import { Select, Tooltip } from 'antd'
import moment from 'moment'
import classNames from 'classnames'
import { LinkedLookup, LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import { isClientHierarchyType, isBlank, IMSHierarchies } from '@mindshare/layout'
import {
  isDropDownType,
  isDateType,
  isUniqueStringType,
  isMediaPartnersType,
  isFinanceTargetAudienceListType,
  isFinanceBuyingAudienceListType,
  isFinanceBookingCategoryList,
  isClientCampaignsType,
  isClientAgenciesType
} from 'Components/shared/constants/entities/IFieldMetaData'
import {
  IMediaPlanTemplateFields,
  isAggregatedOrCalculated,
  isFlightOrSubFlight
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { INewUniqueStringObject, IUpdateDataValuesFnParams } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { WizardProgress } from 'Components/GoalSeek/constants/entities/IGoalSeekData'
import MasteredListSelectorContainer from 'Containers/MediaPlanVersion/MasteredListSelectorContainer'
import MediaPlanHierarchyFieldContainer from 'Containers/MediaPlanVersion/MediaPlanHierarchyFieldContainer'
import MediaPlanInputComponent from 'Components/MediaPlanInputComponent'
import SelectCreateComponent from 'Components/SelectCreateComponent'
import { dateFormat, isDateBetween } from 'Helpers/calendarHelper'
import { numberFormatter } from 'Helpers/numberHelper'
import { MasteredFieldValidationMessage } from 'Constants/enums/MasteredFieldValidationMessage'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { MASTERED_FIELD_DATA_TYPES } from 'Constants/enums/FieldDataType'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { useAppDispatch } from '../store'
import { runCalculationOnBlur, setSelectedRedistributeByField, removeSelectedRedistributeByField, resetCurrentUniqueString } from '../redux/actions/mediaPlansActions'
import EmptyCellComponent from './MediaPlanVersion/EmptyCellComponent'
import DatePicker from './DatePicker'

const isDisabled = (
  valid,
  isTemplate,
  goalSeekMode,
  calculationMode,
  isAggregatedOrCalculatedField,
  goalSeekWizardProgress,
  redistributeMode,
  isFieldValidToRedistribute,
  isCollapsed
) => {
  let disabled = !!goalSeekMode

  if (redistributeMode) {
    disabled = !isFieldValidToRedistribute
  } else if (isTemplate && calculationMode) {
    disabled = !valid
  } else if ((calculationMode || goalSeekMode) && isAggregatedOrCalculatedField) {
    disabled = false

    if (goalSeekMode && goalSeekWizardProgress === WizardProgress.SetTargetCell) {
      disabled = !valid
    }
  } else if (!calculationMode && isAggregatedOrCalculatedField && !isCollapsed) {
    disabled = true
  } else if (calculationMode || (goalSeekMode && goalSeekWizardProgress === WizardProgress.SetTargetCell)) {
    disabled = !valid
  }

  return disabled
}

interface IRenderByType {
  calculationMode: boolean
  redistributeMode?: boolean
  isFieldSelectedForRedistribution?: boolean
  isFieldValidToRedistribute?: boolean
  clientId?: number
  defaultClass?: string
  endDate?: string
  error: string
  flightGroupIndex?: number
  flightIndex?: number
  goalSeekMode?: boolean
  goalSeekWizardProgress?: WizardProgress
  hierarchies: Partial<IMSHierarchies>
  unfilteredHierarchies?: Partial<IMSHierarchies>
  masteredListsData: IMasteredListsData
  mediaPlanField: IMediaPlanTemplateFields
  merge?: boolean
  createNewUniqueString?: (
    clientId: number,
    clientMediaPlanFieldId: number,
    uniqueStringValue: string,
    updateDataValuesFn: (
      selectedValue: string,
      id: number,
      flightGroupIndex?: number,
      flightIndex?: number,
      subFlightIndex?: number,
      startDate?: Date,
      endDate?: Date
    ) => void,
    updateDataValuesParams: IUpdateDataValuesFnParams,
    mediaPlanFieldId: number
  ) => void
  planEndDate?: string
  planStartDate?: string
  startDate?: string
  subFlightIndex?: number
  tokenHandler: (token: string) => void
  updateDataValues: (selectedValue: string | number | string[]) => void
  valid: boolean
  value: any
  setGoalSeekCell?: (mediaPlanField: IMediaPlanTemplateFields, value: any) => void
  newUniqueStringObject?: INewUniqueStringObject
  isTemplate?: boolean
  instanceId?: number
  collapsedFlightGroup?: boolean
  onClearClipboard?: () => void
  isLinkedFieldDisabled?: boolean
  availableLinkedLookupOptions?: LinkedLookupValue[]
  isFilteredLookupValuesVisible?: boolean
  isEnforcedLink?: boolean
  errorMessage?: string
  linkedLookupConnection?: LinkedLookup
  isMasteredHierarchyFieldPickable?: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  isCollapsed?: boolean
  cachedMasteredHierarchies?: Partial<IMSHierarchies>
  isLoadingHierarchies?: boolean
  referencedMediaPlanField?: IMediaPlanFieldRow | IMediaPlanTemplateFields
}

export const RenderByTypeComponent: FunctionComponent<IRenderByType> = ({
  calculationMode,
  redistributeMode = false,
  isFieldSelectedForRedistribution = false,
  isFieldValidToRedistribute = false,
  clientId,
  defaultClass,
  endDate,
  error,
  flightGroupIndex,
  flightIndex,
  goalSeekMode,
  goalSeekWizardProgress,
  mediaPlanField,
  merge,
  createNewUniqueString,
  planEndDate,
  planStartDate,
  setGoalSeekCell,
  startDate,
  subFlightIndex,
  tokenHandler,
  updateDataValues,
  valid,
  value,
  newUniqueStringObject,
  isTemplate,
  instanceId,
  collapsedFlightGroup,
  onClearClipboard,
  availableLinkedLookupOptions,
  isFilteredLookupValuesVisible,
  isEnforcedLink,
  isLinkedFieldDisabled,
  linkedLookupConnection,
  isMasteredHierarchyFieldPickable,
  financeListFieldsData,
  hierarchies,
  unfilteredHierarchies,
  masteredListsData,
  isCollapsed,
  cachedMasteredHierarchies,
  errorMessage = '',
  isLoadingHierarchies,
  referencedMediaPlanField
}) => {
  const [newValue, setNewValue] = useState<any>()
  const dispatch = useAppDispatch()
  const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId
  const dataType = mediaPlanField.clientMediaPlanField && mediaPlanField.clientMediaPlanField.mediaPlanField &&
    mediaPlanField.clientMediaPlanField.mediaPlanField.fieldDataType
  const dataTypeName = dataType && dataType.dataTypeName
  const isAggregatedOrCalculatedField = isAggregatedOrCalculated(mediaPlanField)
  const clientFieldValues = mediaPlanField.clientMediaPlanField && mediaPlanField.clientMediaPlanField.clientFieldValues
  const columnName = mediaPlanField.clientMediaPlanField && mediaPlanField.clientMediaPlanField.mediaPlanField.columnName

  const showEmptyCell = !isTemplate && !merge && isFlightOrSubFlight(mediaPlanField)
  const showUniqueStringType = !isTemplate && isUniqueStringType(dataTypeName)
  const showHierarchyDropdown = isTemplate
    ? !calculationMode && isClientHierarchyType(dataTypeName)
    : isClientHierarchyType(dataTypeName)
  const isMasteredListType = isMediaPartnersType(dataTypeName) || isClientCampaignsType(dataTypeName) || isClientAgenciesType(dataTypeName)
  const showMasteredListDropdown = isTemplate
    ? !calculationMode && isMasteredListType
    : isMasteredListType
  const showDatePicker = isTemplate
    ? !calculationMode && isDateType(dataTypeName)
    : isDateType(dataTypeName)
  const showDropdown = isTemplate
    ? !calculationMode && isDropDownType(dataTypeName)
    : isDropDownType(dataTypeName)
  const isFinanceTargetAudience = isFinanceTargetAudienceListType(dataTypeName)
  const isFinanceBuyingAudience = isFinanceBuyingAudienceListType(dataTypeName)
  const isFinanceBookingCategory = isFinanceBookingCategoryList(dataTypeName)
  const typeIsMasteredField = MASTERED_FIELD_DATA_TYPES.includes(mediaPlanField.clientMediaPlanField.mediaPlanField.fieldDataTypeId)
  const masteredFieldTooltip = typeIsMasteredField && !isMasteredHierarchyFieldPickable ? MasteredFieldValidationMessage.SetFieldValueErrorMessage : undefined

  const fieldTypeClasses = classNames(
    defaultClass,
    { '-valid': valid },
    { '-available': redistributeMode && isFieldValidToRedistribute },
    { '-error': error || (masteredFieldTooltip && !!value) },
    { 'fieldtype-calculated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.CALCULATED },
    { 'fieldtype-aggregated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.AGGREGATED }
  )

  const disabled = isDisabled(
    valid,
    isTemplate,
    goalSeekMode,
    calculationMode,
    isAggregatedOrCalculatedField,
    goalSeekWizardProgress,
    redistributeMode,
    isFieldValidToRedistribute,
    isCollapsed
  ) || typeIsMasteredField && !isMasteredHierarchyFieldPickable && !value || isLinkedFieldDisabled

  const onClickHandler = useCallback(() => {
    if (typeof onClearClipboard === 'function') {
      onClearClipboard()
    }

    if (redistributeMode) {
      if (!isFieldSelectedForRedistribution) {
        dispatch(
          setSelectedRedistributeByField({
            ...mediaPlanField,
            instanceId,
            value,
            flightGroupIndex,
            flightIndex
          })
        )
      } else {
        dispatch(removeSelectedRedistributeByField(instanceId))
      }
    }

    if (goalSeekMode) {
      setGoalSeekCell(mediaPlanField, value)
    } else if (calculationMode && valid) {
      tokenHandler(mediaPlanField.token)
    } else {
      return null
    }
  }, [
    redistributeMode,
    goalSeekMode,
    calculationMode,
    valid,
    isFieldSelectedForRedistribution,
    dispatch,
    mediaPlanField,
    instanceId,
    value,
    flightGroupIndex,
    setGoalSeekCell,
    tokenHandler,
    onClearClipboard,
    flightIndex
  ])

  const updateDataValuesAndRunCalculation = useCallback((fieldValue: string | number | string[]) => {
    updateDataValues(fieldValue)
    dispatch(runCalculationOnBlur())
  }, [dispatch, updateDataValues])

  const tokenHandlerClick = useCallback(() => {
    if (typeof onClearClipboard === 'function') {
      onClearClipboard()
    }

    if (
      calculationMode && valid
    ) {
      tokenHandler(mediaPlanField.token)
    }
  }
  , [calculationMode, mediaPlanField.token, tokenHandler, valid, onClearClipboard])

  const renderOptionsByAvailability = useCallback(
    (f: any) =>
      f.options.map(option => (
        <Select.Option
          key={option.value}
          value={option.value}
          {...option.optionProps}
        >
          <Tooltip
            title={option.label}
          >
            {option.label}
          </Tooltip>
        </Select.Option>
      )),
    []
  )

  const onDatepickerChange = useCallback((event) => {
    updateDataValuesAndRunCalculation(event && event.format(dateFormat))
  }, [updateDataValuesAndRunCalculation])

  useEffect(() => {
    setNewValue(value)
  }, [value])

  if (showEmptyCell) {
    return (
      <EmptyCellComponent fieldLabel={mediaPlanField.fieldLabel} />
    )
  } else if (showMasteredListDropdown) {
    return (
      <MasteredListSelectorContainer
        masteredListsData={masteredListsData}
        mediaPlanFieldDataType={dataType?.fieldDataTypeId}
        value={value}
        disabled={disabled}
        error={error}
        mediaPlanField={mediaPlanField}
        className={fieldTypeClasses}
        onChange={updateDataValuesAndRunCalculation}
        onClick={tokenHandlerClick}
        collapsedFlightGroup={collapsedFlightGroup}
        availableLinkedLookupOptions={availableLinkedLookupOptions}
        isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
        isEnforcedLink={isEnforcedLink}
        errorMessage={errorMessage}
        isLinkedFieldDisabled={isLinkedFieldDisabled}
        linkedLookupConnection={linkedLookupConnection}
      />
    )
  } else if (showHierarchyDropdown) {
    const linkedLookupFieldTooltip = isLinkedFieldDisabled ? errorMessage : undefined

    return (
      <MediaPlanHierarchyFieldContainer
        value={value}
        disabled={disabled}
        error={error}
        mediaPlanField={mediaPlanField}
        onChange={updateDataValuesAndRunCalculation}
        onClick={tokenHandlerClick}
        collapsedFlightGroup={collapsedFlightGroup}
        hierarchies={hierarchies}
        unfilteredHierarchies={unfilteredHierarchies}
        masteredFieldTooltip={masteredFieldTooltip}
        fieldTypeClasses={fieldTypeClasses}
        cachedMasteredHierarchies={cachedMasteredHierarchies}
        isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
        linkedLookupConnection={linkedLookupConnection}
        availableLinkedLookupOptions={availableLinkedLookupOptions}
        isEnforcedLink={isEnforcedLink}
        linkedLookupFieldTooltip={linkedLookupFieldTooltip}
        isLinkedFieldDisabled={isLinkedFieldDisabled}
        isLoadingHierarchies={isLoadingHierarchies}
        referencedMediaPlanField={referencedMediaPlanField}
      />
    )
  } else if (showUniqueStringType) {
    const displayValue = error || clientFieldValues && clientFieldValues.find(c => c.clientFieldValueId === value)?.valueDisplayName
    return (
      <Tooltip title={displayValue}>
        {!collapsedFlightGroup ? (
          <SelectCreateComponent
            key={value}
            id={id}
            options={clientFieldValues && clientFieldValues.map(item => ({ value: item.clientFieldValueId, label: item.valueDisplayName }))}
            defaultValue={value}
            newValueObject={newUniqueStringObject}
            onClick={tokenHandlerClick}
            setFieldValue={(item) => {
              if (item && isBlank(item.value)) {
                createNewUniqueString(
                  clientId,
                  mediaPlanField.clientMediaPlanFieldId,
                  item.label.toString(),
                  updateDataValuesAndRunCalculation,
                  { id, flightGroupIndex, flightIndex, subFlightIndex, startDate: moment.utc(startDate).toDate(), endDate: moment.utc(endDate).toDate() },
                  mediaPlanField.clientMediaPlanField.mediaPlanField.mediaPlanFieldId
                )
              } else if (item) {
                updateDataValuesAndRunCalculation(item.value)
              } else {
                dispatch(resetCurrentUniqueString(updateDataValuesAndRunCalculation, ''))
              }
            }}
            disabled={disabled}
          />
        ) : (
          <div className='-value' data-testid='collapse-value'>{displayValue}</div>
        )}
      </Tooltip>
    )
  } else if (showDropdown) {
    const notSelectedValues = clientFieldValues.filter((item) => !linkedLookupConnection?.linkedLookupValues?.some((v) => item.clientFieldValueId === v.dependentValueId))
    const filteredValues = clientFieldValues
      ?.filter(item =>
        availableLinkedLookupOptions?.some(option => item.clientFieldValueId === option.dependentValueId)
      )
    const allValues = [...filteredValues, ...notSelectedValues]
    const validOptions = allValues.map(option => ({ value: option.clientFieldValueId, label: option.valueDisplayName }))

    const invalidOptions = clientFieldValues
      ?.filter(
        item =>
          !availableLinkedLookupOptions?.some(
            option => item.clientFieldValueId === option.dependentValueId
          ) && !notSelectedValues.some((v) => item.clientFieldValueId === v.clientFieldValueId)
      ).map(option => ({
        value: option.clientFieldValueId,
        label: option.valueDisplayName,
        optionProps: {
          disabled: isEnforcedLink,
          className: 'not-valid'
        }
      }))

    const options = [
      ...(validOptions.length ? [{
        label: isEnforcedLink ? 'Valid' : 'Recommended',
        options: validOptions
      }] : []),
      ...(invalidOptions.length ? [{
        label: isEnforcedLink ? 'Not valid' : 'Not recommended',
        options: invalidOptions
      }] : [])
    ]

    const isDisplayingLookupOptions =
      isFilteredLookupValuesVisible &&
      (
        !!validOptions.length &&
        !!invalidOptions.length ||
        !!invalidOptions.length
      )

    const lookupError = isLinkedFieldDisabled
      ? errorMessage
      : (
        isDisplayingLookupOptions
          ? ''
          : clientFieldValues && clientFieldValues.find(c => c.clientFieldValueId === value)?.valueDisplayName
      )

    const isDisplayingFinanceFields = isFinanceTargetAudience || isFinanceBuyingAudience || isFinanceBookingCategory
    const renderFinanceOptions = isDisplayingFinanceFields ? financeListFieldsData[mediaPlanField.clientMediaPlanField.mediaPlanField.columnName]?.renderOptions : undefined
    const getFinanceFieldValue = isDisplayingFinanceFields ? financeListFieldsData[mediaPlanField.clientMediaPlanField.mediaPlanField.columnName]?.getCurrentValue : undefined
    const financeFieldValue = isDisplayingFinanceFields ? getFinanceFieldValue(value) : undefined
    const clientFieldValue = clientFieldValues && clientFieldValues.find(c => c.clientFieldValueId === value)?.valueDisplayName
    const collapsedValue = error || lookupError || (isDisplayingFinanceFields ? financeFieldValue : clientFieldValue)
    const optimizeLargeDatasetPerformance = clientFieldValues.length > 600 || options.length > 600 || renderFinanceOptions?.length > 600
    const regularDisplayValue = value && !clientFieldValue ? '[Unavailable value]' : value
    const financeDisplayValue = !isDisplayingFinanceFields || financeListFieldsData[mediaPlanField.clientMediaPlanField.mediaPlanField.columnName]?.isDataLoading
      ? ''
      : (value && !financeFieldValue && !!financeListFieldsData?.[mediaPlanField.clientMediaPlanField.mediaPlanField.columnName]?.data.length
        ? '[Unavailable value]'
        : value)
    const displayValue = isDisplayingFinanceFields ? financeDisplayValue : regularDisplayValue

    return (
      <div className="input-item-select">
        <Tooltip key={displayValue} title={error || lookupError || masteredFieldTooltip} >
          {!collapsedFlightGroup ? (
            <Select
              key={displayValue}
              allowClear={true}
              className={fieldTypeClasses}
              data-testid={mediaPlanField.fieldLabel}
              defaultValue={displayValue}
              disabled={disabled || isLinkedFieldDisabled}
              optionFilterProp='children'
              onChange={updateDataValuesAndRunCalculation}
              onClick={tokenHandlerClick}
              placeholder='Select Value'
              showSearch={true}
              popupMatchSelectWidth={!!optimizeLargeDatasetPerformance}
              dropdownStyle={{ textAlign: 'left' }}
              popupClassName={optimizeLargeDatasetPerformance ? 'dropdown-min-width' : undefined}
            >
              {isDisplayingFinanceFields ? renderFinanceOptions()
                : isDisplayingLookupOptions
                  ? options.map((f, index) => (
                    <Select.OptGroup key={index} label={f.label}>
                      {renderOptionsByAvailability(f)}
                    </Select.OptGroup>
                  ))
                  : clientFieldValues && clientFieldValues.map((cf) => (
                    <Select.Option
                      key={cf.clientFieldValueId}
                      value={cf.clientFieldValueId}
                    >
                      {cf.valueDisplayName}
                    </Select.Option>
                  ))}
            </Select>
          ) : (
            <div className='-value' data-testid='collapse-value'>{collapsedValue}</div>
          )}
        </Tooltip>
      </div>
    )
  } else if (showDatePicker) {
    const validateDisabledDate = (current) =>
      (columnName === 'FlightStartDate' || columnName === 'FlightEndDate') &&
      !isDateBetween(planStartDate, planEndDate, current.toDate())
    const dateFormatted = value ? value.slice(0, value.indexOf('T')) : null

    return !collapsedFlightGroup ? (
      <Tooltip title={error}>
        <DatePicker
          className={fieldTypeClasses}
          data-testid={mediaPlanField.fieldLabel}
          defaultPickerValue={moment(value || planStartDate)}
          disabled={disabled}
          disabledDate={validateDisabledDate}
          format='DD-MMM-YYYY'
          onChange={onDatepickerChange}
          onClick={tokenHandlerClick}
          value={value ? moment(value) : null}
        />
      </Tooltip>
    ) : (
      <Tooltip placement='top' title={dateFormatted}>
        <div className='-value' data-testid='collapse-value'>{dateFormatted}</div>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={error || numberFormatter(value, mediaPlanField.clientMediaPlanField.mediaPlanField.fieldDataTypeId)}>
        {!collapsedFlightGroup ? (
          <div className={`fieldInput ${isFieldSelectedForRedistribution ? 'selected' : ''}`}>
            <MediaPlanInputComponent
              calculationMode={calculationMode}
              disabled={disabled}
              endDate={endDate}
              startDate={startDate}
              error={error}
              fieldTypeClasses={fieldTypeClasses}
              flightGroupIndex={flightGroupIndex}
              flightIndex={flightIndex}
              id={id}
              mediaPlanField={mediaPlanField}
              newValue={newValue}
              setNewValue={setNewValue}
              subFlightIndex={subFlightIndex}
              tokenHandler={tokenHandlerClick}
              updateDataValues={updateDataValues}
              updateDataValuesAndRunCalculation={updateDataValuesAndRunCalculation}
              value={value}
              readOnly={calculationMode || redistributeMode || !!mediaPlanField.isReadOnly || isCollapsed}
              onClickHandler={onClickHandler}
            />
          </div>
        ) : (
          <div className='-value' data-testid='collapse-value'>{error || numberFormatter(value, mediaPlanField.clientMediaPlanField.mediaPlanField.fieldDataTypeId)}</div>
        )}
      </Tooltip>
    )
  }
}

export default memo(RenderByTypeComponent)
