import React, { useCallback, useMemo, useState } from 'react'
import { DeleteFilled, EditOutlined, StarFilled } from '@ant-design/icons'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { formatLowerCaseFirstLetter, useMindshareSelector } from '@mindshare/layout'
import { Button, Tooltip } from 'antd'
import { RootState } from 'Reducers/index'
import { updatePlanLevelValue } from 'Actions/mediaPlansActions'
import {
  getFieldColumnName,
  IMediaPlanTemplateFields,
  isFieldAvailableOrDefault
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import RichTextComponent from 'Components/RichTextComponent'
import RichTextWrapperComponent from 'Components/RichTextWrapperComponent'
import { useFieldValue } from 'Components/MediaPlanVersion/hooks'
import { useAppDispatch } from '../../store'

interface IProps {
  mediaPlanField: IMediaPlanTemplateFields
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
}

export const PlanLevelRichTextFieldContainer = ({
  mediaPlanField,
  removePlanField
}: IProps) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const dispatch = useAppDispatch()
  const fieldValue = useFieldValue({
    fieldLevelId: mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId,
    columnName: getFieldColumnName(mediaPlanField)
  })
  const defaultOrAvailable = isFieldAvailableOrDefault(mediaPlanField)

  const error = useMindshareSelector((state: RootState) => state.mediaPlans.errors.find(e => {
    const fieldLevelId = mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId
    return e.fieldId === mediaPlanField.clientMediaPlanFieldId && e.fieldLevel === fieldLevelId
  })?.error || '') as string

  const updateValue = useCallback((contentValue: string) => {
    const columnName = formatLowerCaseFirstLetter(mediaPlanField.clientMediaPlanField.mediaPlanField.columnName)
    const newPropertyValue = { [columnName]: contentValue }

    dispatch(updatePlanLevelValue(newPropertyValue))
  }, [dispatch, mediaPlanField.clientMediaPlanField.mediaPlanField.columnName])

  const removeField = useCallback(() => {
    removePlanField(mediaPlanField)
  }, [removePlanField, mediaPlanField])

  const showModal = useCallback(() => {
    setShowEditModal(true)
  }, [setShowEditModal])

  const buttons = useMemo(() => (
    <>
      {
        mediaPlanField.isEditable && (
          <Tooltip title='Edit rich text field'>
            <Button
              className='btn-unset-default rich-text__header-button'
              aria-label='Rich text edit button'
              data-testid='rich-text-edit-button'
              icon={<EditOutlined />}
              onClick={showModal}
            />
          </Tooltip>
        )
      }
      {defaultOrAvailable ? (
        <Tooltip title='Delete rich text field'>
          <Button
            className='btn-unset-default rich-text__header-button'
            aria-label='Rich text delete button'
            data-testid='rich-text-delete-button'
            icon={<DeleteFilled />}
            tabIndex={-1}
            onClick={removeField}
          />
        </Tooltip>
      ) : (
        <Tooltip title='Mandatory rich text field'>
          <Button
            className='btn-unset-default rich-text__header-button'
            tabIndex={-1}
            icon={<StarFilled />}
          />
        </Tooltip>
      )}
    </>
  ), [defaultOrAvailable, mediaPlanField, removeField, showModal])

  return (
    <RichTextWrapperComponent
      title={mediaPlanField.fieldLabel}
      buttons={buttons}
      error={error}
    >
      <RichTextComponent
        value={fieldValue}
        title={mediaPlanField.fieldLabel}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        updateValue={updateValue}
      />
    </RichTextWrapperComponent>
  )
}

export default PlanLevelRichTextFieldContainer
