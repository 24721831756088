import { FC } from 'react'
import ReactDOM from 'react-dom'

interface IProps {
  children: React.JSX.Element
}

export const Portal: FC<IProps> = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}
