import * as React from 'react'
import { CSSProperties } from 'react'
import { FrontSheetViewDisplay } from 'Apis/generated/frontSheetsApi'
import CalendarComponent from 'Components/Calendar/CalendarComponent'
import FrontSheetCalendarFlightGroupHeader from './FrontSheetCalendarFlightGroupHeader'

interface FrontSheetCalendarHeadersProps {
  currentFrontSheetView: FrontSheetViewDisplay
  headersRef: React.MutableRefObject<HTMLTableCellElement[]>
  laydownRef: React.MutableRefObject<HTMLTableCellElement>
  getStickyStyle: (index: number) => CSSProperties
  toggleColumnSticky: (index: number) => void
}

function FrontSheetCalendarHeaders (
  props: FrontSheetCalendarHeadersProps
) {
  const { currentFrontSheetView, headersRef, laydownRef, getStickyStyle, toggleColumnSticky } = props
  const { columns, startDate, endDate } = currentFrontSheetView

  return (
    <thead data-testid='flightHeaderRow'>
      <tr>
        <FrontSheetCalendarFlightGroupHeader columns={columns} headersRef={headersRef} getStickyStyle={getStickyStyle} toggleColumnSticky={toggleColumnSticky} />
      </tr>
      <tr>
        <th className='flightHeader__title' ref={laydownRef} style={getStickyStyle(columns.length)}>
          &nbsp;
        </th>
        <th rowSpan={4} className="flightHeader__view">
          <CalendarComponent
            calendarView={'week-iso'}
            startDate={startDate}
            endDate={endDate}
            dayStartOfWeek={'Monday'}
            compressedCalendarView={false}
          />
        </th>
      </tr>
      <tr>
        <th className='flightGroup__header -textRight' style={getStickyStyle(columns.length)}>
          Months
        </th>
      </tr>
      <tr>
        <th className='flightGroup__header -textRight' style={getStickyStyle(columns.length)}>
          Week#
        </th>
      </tr>
      <tr>
        <th className='flightGroup__header -textRight' style={getStickyStyle(columns.length)}>
          Week Start
        </th>
      </tr>
    </thead>
  )
}

export default FrontSheetCalendarHeaders
