import { useCallback, useMemo } from 'react'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'

export const useFlightGroupsDragAndDropHelper = ({
  selectedFlightGroups,
  flightGroups,
  activeDraggableId
}: {
  selectedFlightGroups: Partial<IFlightGroup>
  flightGroups: IFlightGroup[]
  activeDraggableId: number
}) => {
  const filteredFlightGroups = useMemo(() => {
    if (!activeDraggableId || !selectedFlightGroups) return flightGroups

    return flightGroups.filter((item) =>
      item.mediaPlanFlightGroupId === activeDraggableId || !Object.keys(selectedFlightGroups).includes(item.mediaPlanFlightGroupId?.toString())
    )
  }, [flightGroups, selectedFlightGroups, activeDraggableId])
  const filteredFlightGroupsIds = useMemo(() => filteredFlightGroups.map((f) => f.mediaPlanFlightGroupId), [filteredFlightGroups])

  const areSelectedRowsContinuous = useMemo(() => {
    if (!selectedFlightGroups) return false
    if (Object.keys(selectedFlightGroups).length <= 1) return true

    const sortOrders = Object.keys(selectedFlightGroups)
      .map((id) => {
        const item = filteredFlightGroups.find((i) => i.mediaPlanFlightGroupId?.toString() === id)

        return item ? item.sortOrder : null
      })
      .filter(Boolean)
      .sort((a, b) => a - b)

    let isContinuous = true
    sortOrders.slice(1).forEach((order, index) => {
      if (order - sortOrders[index] !== 10) {
        isContinuous = false
      }
    })

    return isContinuous
  }, [filteredFlightGroups, selectedFlightGroups])

  const checkIsFlightGroupSelected = useCallback(
    (flightGroup: IFlightGroup) =>
      selectedFlightGroups &&
      Object.keys(selectedFlightGroups).includes(
        flightGroup.mediaPlanFlightGroupId?.toString()
      ),
    [selectedFlightGroups]
  )

  const checkCanMoveFlightGroup = useCallback(
    (flightGroup: IFlightGroup) => {
      const isFlightGroupSelected = checkIsFlightGroupSelected(flightGroup)

      return !(isFlightGroupSelected && !areSelectedRowsContinuous)
    },
    [checkIsFlightGroupSelected, areSelectedRowsContinuous]
  )

  return useMemo(() => ({
    filteredFlightGroups,
    filteredFlightGroupsIds,
    checkCanMoveFlightGroup
  }), [
    filteredFlightGroups,
    filteredFlightGroupsIds,
    checkCanMoveFlightGroup
  ])
}
