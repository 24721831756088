import FileSaver from 'file-saver'
import { downloadExcel } from 'Services/mediaPlanService'

export const downloadExcelPlan = async (
  planName: string,
  mediaPlanId: number,
  mediaPlanVersionId: number,
  clientId: number,
  isExpandedHierarchies: boolean,
  isFlightDatesShown: boolean
) => {
  const file = await downloadExcel(
    mediaPlanVersionId,
    clientId,
    isExpandedHierarchies,
    isFlightDatesShown
  )
  FileSaver.saveAs(file, `${planName}-${mediaPlanId}.xlsx`)
}
