import { useMemo } from 'react'
import { TOP_LEVEL_HIERARCHIES, isClientHierarchyType } from '@mindshare/layout'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { getFieldLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { ISelectedParentHierarchiesHelperValues } from '../constants/entities/ISelectedHierarchies'

export const useTemplateHierarchiesFields = ({ templateFieldRows, selectedTab }: {
  templateFieldRows: IMediaPlanFieldRow[]
  selectedTab: number
}): ISelectedParentHierarchiesHelperValues => {
  const mediaPlanHierarchyFields = useMemo(
    () => templateFieldRows.filter((item) =>
      selectedTab && item.mediaPlanField.fieldDataType && isClientHierarchyType(item.mediaPlanField.fieldDataType.dataTypeName) && (getFieldLevel(item) < selectedTab)
    ).sort((f1, f2) => getFieldLevel(f2) - getFieldLevel(f1)),
    [templateFieldRows, selectedTab]
  )
  return useMemo(
    () =>
      selectedTab &&
      TOP_LEVEL_HIERARCHIES.reduce(
        (acc, hierarchyName) => ({
          ...acc,
          [selectedTab]: {
            ...acc[selectedTab],
            [hierarchyName]: Number(
              mediaPlanHierarchyFields.find(
                item =>
                  item.mediaPlanField?.columnName.toLowerCase() ===
                    `${hierarchyName}hierarchyvalue` && item.defaultValue
              )?.defaultValue
            )
          }
        }),
        {}
      ),
    [mediaPlanHierarchyFields, selectedTab]
  )
}
