import React from 'react'
import { Navigate } from 'react-router'
import { Callback, SilentRenew } from '@mindshare/services'
import { IPermissionedRouteProps } from '@mindshare/layout'

import NotAuthorised from 'Components/initialisation/NotAuthorised'
import CreateImportLoadingContainer from 'Containers/views/CreateImportContainer'
import FrontSheetListLoadingContainer from 'Containers/views/FrontSheet/FrontSheetListContainer'
import FrontSheetSelectFieldsLoadingContainer from 'Containers/views/FrontSheet/FrontSheetSelectFieldsContainer'
import FrontSheetSelectPlansLoadingContainer from 'Containers/views/FrontSheet/FrontSheetSelectPlansContainer'
import FrontSheetViewLoadingContainer from 'Containers/views/FrontSheet/FrontSheetViewContainer'
import ImportListLoadingContainer from 'Containers/views/ImportListContainer'
import CustomExportListContainer from 'Components/CustomExport/containers/CustomExportListContainer'
import MediaPlanLoadingContainer from 'Containers/views/MediaPlanVersionContainer'
import PlanListLoadingContainer from 'Containers/views/PlanListContainer'
import ClientDataFieldSelectionContainer from 'Containers/views/ClientDataFieldSelectionContainer'
import ClientDataTermsContainer from 'Containers/views/ClientDataTermsContainer'
import UserManagerContainer from 'Containers/views/UserManagerContainer'
import TemplateExampleContainer from 'Containers/views/Template/TemplateExampleContainer'
import TemplateFieldContainer from 'Containers/views/Template/TemplateFieldContainer'
import TemplateListContainer from 'Containers/views/Template/TemplateListContainer'
import ReportContainer from 'Containers/views/ReportContainer'
import AliasSetupContainer from 'Containers/views/AliasSetupContainer'
import { RunCustomExportContainer, CustomExportFieldContainer } from 'Components/CustomExport'
import { LinkedLookupListContainer, LinkedLookupFieldContainer } from 'Components/LinkedLookup'
import { appPaths } from 'Constants/appPaths'

export const appRoutes: IPermissionedRouteProps[] = [
  {
    path: '/callback',
    element: <Callback />
  }, {
    path: '/notauthorised',
    element: <NotAuthorised />
  }, {
    path: '/silentrenew',
    element: <SilentRenew />
  },
  {
    path: '/user-manager',
    element: <UserManagerContainer />
  },
  // Media Plans
  {
    path: '/',
    element: <Navigate to={appPaths.plansList} />
  },
  {
    path: '/plan-list',
    element: <PlanListLoadingContainer />
  },
  {
    path: '/plan-list/',
    element: <PlanListLoadingContainer />
  },
  {
    path: '/mediaplanversion/:mediaPlanVersionId',
    element: <MediaPlanLoadingContainer />
  },
  // Front sheets
  {
    path: appPaths.frontSheets,
    element: <FrontSheetListLoadingContainer />
  },
  {
    path: '/create-front-sheet',
    element: <FrontSheetSelectPlansLoadingContainer />
  }, {
    path: '/view-front-sheet/:frontSheetId',
    element: <FrontSheetViewLoadingContainer />
  }, {
    path: '/edit-front-sheet/:frontSheetId',
    element: <FrontSheetSelectPlansLoadingContainer />
  }, {
    path: '/edit-front-sheet/select-fields/:frontSheetId',
    element: <FrontSheetSelectFieldsLoadingContainer />
  },
  // Imports
  {
    path: appPaths.importList,
    element: <ImportListLoadingContainer />
  }, {
    path: '/create-import',
    element: <CreateImportLoadingContainer />
  }, {
    path: '/edit-import/:importId',
    element: <CreateImportLoadingContainer />
  },
  // Templates
  {
    path: appPaths.templateList,
    element: <TemplateListContainer />
  }, {
    path: appPaths.templates,
    element: <TemplateFieldContainer />
  }, {
    path: `${appPaths.templateEdit}/:templateId`,
    element: <TemplateFieldContainer />
  }, {
    path: `${appPaths.templateExample}/:mediaPlanTemplateId`,
    element: <TemplateExampleContainer />
  },
  // Client
  {
    path: appPaths.clientSetup,
    element: <ClientDataFieldSelectionContainer />
  }, {
    path: appPaths.clientDataTerms,
    element: <ClientDataTermsContainer />
  }, {
    path: appPaths.aliasSetup,
    element: <AliasSetupContainer />
  },
  {
    path: appPaths.fieldConnections,
    element: <LinkedLookupListContainer />
  },
  {
    path: `${appPaths.fieldConnections}/:linkedLookupId/values`,
    element: <LinkedLookupFieldContainer />
  },
  // Report
  {
    path: appPaths.report,
    element: <ReportContainer />
  },
  // Custom Export
  {
    path: appPaths.customExports,
    element: <CustomExportListContainer />
  },
  {
    path: '/custom-export',
    element: <CustomExportFieldContainer />
  },
  {
    path: '/custom-export/:customExportId',
    element: <CustomExportFieldContainer />
  },
  {
    path: '/custom-export/:customExportId/run',
    element: <RunCustomExportContainer />
  }
]
