import { getClientHierarchyValue, getClientHierarchyList, getClientHierarchyIdProp, getClientHierarchyValueProp } from '@mindshare/layout'
import { translateHierarchyTreeDataWithKeys } from 'Helpers/hierarchyHelper'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { CLIENT_HIERARCHY_TYPES_MAP } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const getFieldValues = (field, masteredListsData, hierarchies) => {
  switch (field?.fieldDataType.fieldDataTypeId) {
    case FieldDataType.CLIENT_DEFINED_LIST: {
      return field.clientFieldValues.filter((item) => !item.isDisabled).map((item) => ({ title: item.valueDisplayName, value: item.clientFieldValueId }))
    }

    case FieldDataType.MEDIA_PARTNERS: {
      return masteredListsData.mediaPartners.map((item) => ({ title: item.mediaPartnerValue, value: item.clientMediaPartnerId }))
    }

    case FieldDataType.CLIENT_CAMPAIGNS: {
      return masteredListsData.clientCampaigns.map((item) => ({ title: item.clientCampaignName, value: item.clientCampaignId }))
    }

    case FieldDataType.CLIENT_AGENCY_LIST: {
      return masteredListsData.clientAgencies.map((item) => ({ title: item.agencyDisplayName, value: item.agencyId }))
    }

    case FieldDataType.BUSINESS_HIERARCHY:
    case FieldDataType.GEOGRAPHY_HIERARCHY:
    case FieldDataType.MEDIA_HIERARCHY:
    case FieldDataType.BRAND_HIERARCHY: {
      const hierarchyType = CLIENT_HIERARCHY_TYPES_MAP[field?.fieldDataType.fieldDataTypeId]
      const treeData = getClientHierarchyList(hierarchies, hierarchyType)
      const _treeData = translateHierarchyTreeDataWithKeys(treeData, getClientHierarchyValueProp(hierarchyType), getClientHierarchyIdProp(hierarchyType))

      return _treeData
    }
  }
}

export const formatFieldValue = (field, linkedValues, masteredListsData, hierarchies, hierarchyType) => {
  switch (field?.fieldDataType.fieldDataTypeId) {
    case FieldDataType.BUSINESS_HIERARCHY:
    case FieldDataType.GEOGRAPHY_HIERARCHY:
    case FieldDataType.MEDIA_HIERARCHY:
    case FieldDataType.BRAND_HIERARCHY: {
      const filteredValues = linkedValues?.map((item) => getClientHierarchyValue(hierarchies, hierarchyType, Number(item.referencedValueId)))

      return filteredValues?.join(', ')
    }

    default: {
      const values = getFieldValues(field, masteredListsData, hierarchies)
      const filteredValues = values?.filter((v) => linkedValues?.some((item) => v.value === item?.referencedValueId))

      return filteredValues?.map((item) => item.title).join(', ')
    }
  }
}

export const isFieldOfSameOrHigherLevel = (
  field1: IClientMediaPlanField | IMediaPlanFieldRow | IMediaPlanMetaField,
  field2: IClientMediaPlanField | IMediaPlanFieldRow | IMediaPlanMetaField
): boolean => {
  if (!field1 || !field2) {
    return false
  }
  return getFieldLevel(field1) <= getFieldLevel(field2)
}

export const getFieldLevel = (field: IMediaPlanFieldRow | IMediaPlanMetaField) => {
  if ('mediaPlanField' in field) {
    return field?.mediaPlanField?.fieldLevelId
  }
  return field.clientMediaPlanField.mediaPlanField.fieldLevelId
}

export const isFieldOfSameLevel = (
  field1: IClientMediaPlanField | IMediaPlanFieldRow | IMediaPlanMetaField,
  field2: IClientMediaPlanField | IMediaPlanFieldRow | IMediaPlanMetaField
): boolean => {
  if (!field1 || !field2) {
    return false
  }
  return getFieldLevel(field1) === getFieldLevel(field2)
}

export const isFieldOfHigherLevel = (
  field1: IClientMediaPlanField | IMediaPlanFieldRow | IMediaPlanMetaField,
  field2: IClientMediaPlanField | IMediaPlanFieldRow | IMediaPlanMetaField
): boolean => {
  if (!field1 || !field2) {
    return false
  }
  return getFieldLevel(field1) < getFieldLevel(field2)
}
