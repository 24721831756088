import { baseApi as api } from './baseApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCostMasterDataBuyingRouteHierarchy: build.query<
    GetCostMasterDataBuyingRouteHierarchyApiResponse,
    GetCostMasterDataBuyingRouteHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: '/costMasterData/buyingRouteHierarchy',
        params: {
          clientId: queryArg.clientId,
          clientGeographyHierarchyId: queryArg.clientGeographyHierarchyId,
          clientMediaHierarchyId: queryArg.clientMediaHierarchyId,
        },
      }),
    }),
  }),
  overrideExisting: false
})
export { injectedRtkApi as baseApi }
export type GetCostMasterDataBuyingRouteHierarchyApiResponse =
  /** status 200 OK */ HierarchiesWithLevels
export type GetCostMasterDataBuyingRouteHierarchyApiArg = {
  clientId: number;
  clientGeographyHierarchyId?: number;
  clientMediaHierarchyId?: number;
};
export type Hierarchy = {
  id?: number
  name?: string | null
  parentId?: number | null
  levelNumber?: number
  children?: Hierarchy[] | null
}
export type HierarchyLevel = {
  hierarchyLevelId?: number
  hierarchyLevelNumber?: number
  hierarchyLevelName?: string | null
}
export type HierarchiesWithLevels = {
  hierarchies?: Hierarchy[] | null
  hierarchyLevels?: HierarchyLevel[] | null
}
export const {
  useGetCostMasterDataBuyingRouteHierarchyQuery,
  useLazyGetCostMasterDataBuyingRouteHierarchyQuery
} = injectedRtkApi
