import React, { useCallback, useMemo } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { Checkbox, List } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiResponse } from 'Apis/generated/templateSyncApi'
import { ITemplateSyncChildTemplate, ITemplateSyncFormData } from './TemplateSyncModalComponent'
import { handleCheckboxChange } from './helpers/common'

interface ITemplateSyncLinkedTemplatesComponentProps {
  control: Control<ITemplateSyncFormData>
  setValue: UseFormSetValue<ITemplateSyncFormData>
  isLinkedChildTemplatesLoading: boolean
  linkedChildTemplatesList: GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiResponse
  selectedLinkedTemplatesList: ITemplateSyncChildTemplate[]
}

export const TemplateSyncLinkedTemplatesComponent: React.FC<ITemplateSyncLinkedTemplatesComponentProps> = ({
  control,
  setValue,
  isLinkedChildTemplatesLoading,
  linkedChildTemplatesList,
  selectedLinkedTemplatesList
}) => {
  const handleSelectAllChange = useCallback((e: CheckboxChangeEvent) => {
    setValue('selectedLinkedTemplates', e.target.value
      ? linkedChildTemplatesList.map(item => ({
        clientId: item.clientId,
        clientDisplayName: item.clientDisplayName,
        mediaPlanTemplateId: item.mediaPlanTemplateId,
        mediaPlanTemplateDisplayName: item.templateName,
        isNewTemplate: false
      })) : []
    )
  }, [setValue, linkedChildTemplatesList])

  const isAllChecked = useMemo(
    () => linkedChildTemplatesList?.every((item, i) => selectedLinkedTemplatesList[i]?.mediaPlanTemplateId === item.mediaPlanTemplateId),
    [linkedChildTemplatesList, selectedLinkedTemplatesList]
  )

  return (
    <div className='template-sync__list-wrapper'>
      <List
        dataSource={linkedChildTemplatesList}
        loading={isLinkedChildTemplatesLoading}
        renderItem={(item, index) => (
          <>
            {index === 0 && (
              <List.Item key='select-all'>
                <Checkbox
                  checked={isAllChecked}
                  value={!isAllChecked}
                  onChange={handleSelectAllChange}
                >
                  Select all
                </Checkbox>
              </List.Item>
            )}
            <List.Item key={item.mediaPlanTemplateId}>
              <Controller name='selectedLinkedTemplates' control={control} render={({ field }) => (
                <Checkbox
                  checked={!!field.value.find(value => value.mediaPlanTemplateId === item.mediaPlanTemplateId)}
                  value={{
                    clientId: item.clientId,
                    clientDisplayName: item.clientDisplayName,
                    mediaPlanTemplateId: item.mediaPlanTemplateId,
                    mediaPlanTemplateDisplayName: item.templateName,
                    isNewTemplate: false
                  }}
                  onChange={(e) => handleCheckboxChange(e, field)}
                >
                  {item.clientDisplayName}
                </Checkbox>
              )} />
              <div className='template-sync__list-item -align-right'>{item.templateName}</div>
            </List.Item>
          </>
        )}
      />
    </div>
  )
}

export default TemplateSyncLinkedTemplatesComponent
