import React, { useCallback, useEffect, useState } from 'react'
import { MSLayoutContentRow, LoadingComponent, useRouteNavigation, useMindshareSelector } from '@mindshare/layout'
import { RootState } from 'Reducers/index'
import {
  setMediaPlanFieldData,
  saveMediaPlanFieldData,
  getMediaPlanFields,
  IDispatchMediaPlanField
} from 'Actions/mediaPlanFieldActions'
import { Client } from 'Apis/generated/clientsApi'
import { Button } from 'antd'
import { IMediaPlanFieldExtended, IMediaPlanField } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { ClientDataFilter } from 'Components/ClientDataFilter'
import FieldSelectionTable from 'Components/ClientDataFieldSelection/FieldSelectionTable'
import clientSetupText from 'Components/tooltipContent/Client Setup'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useFieldLevels } from 'Hooks/useFieldLevels'
import { useFieldDataTypes } from 'Hooks/useFieldDataTypes'
import { appPaths } from 'Constants/appPaths'
import { useAppDispatch } from '../../store'

export const ClientDataFieldSelectionContainer: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()
  const mediaPlanFields = useMindshareSelector((state: RootState) => state.mediaPlanField.mediaPlanFields) as IMediaPlanField[]
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  const [mediaPlanFieldsLoading, setMediaPlanFieldsLoading] = useState(true)
  const { data: fieldLevels } = useFieldLevels()
  const { data: dataTypes } = useFieldDataTypes()

  const dispatchMediaPlanFields = useCallback((data: IDispatchMediaPlanField[]) => dispatch(setMediaPlanFieldData(data)), [dispatch])

  useEffect(() => {
    if (currentClient?.id > 0) {
      dispatch(getMediaPlanFields(currentClient.id))
    }
  }, [dispatch, currentClient.id])

  useEffect(() => {
    if (fieldLevels.length > 0 && dataTypes.length > 0) {
      const mediaPlanFieldsForUpdate = mediaPlanFields.map((field) => {
        const fieldLevel = fieldLevels.find(l => l.fieldLevelId === field.fieldLevelId)
        const fieldDataType = dataTypes.find(dt => dt.fieldDataTypeId === field.fieldDataTypeId)
        return {
          ...field,
          fieldLevel: (fieldLevel && fieldLevel.fieldLevelName),
          fieldDataType: (fieldDataType && fieldDataType.dataTypeName)
        }
      })
      setMediaPlanFieldsLoading(false)
      dispatchMediaPlanFields(mediaPlanFieldsForUpdate)
    }
  }, [dispatchMediaPlanFields, dataTypes, fieldLevels, mediaPlanFields])


  function handleFilter (
    textFilter: string,
    dataType: number[],
    level: number[],
    tags: number[]
  ): void {
    const textDoesntMatch = (field: IMediaPlanFieldExtended) =>
      textFilter.length > 0 &&
      !field.fieldLabel?.toLocaleLowerCase().includes(textFilter) &&
      !field.fieldDescription?.toLocaleLowerCase().includes(textFilter)

    const typeDoesntMatch = (field: IMediaPlanFieldExtended) =>
      dataType.length > 0 &&
      dataType.find(t => t === field.fieldDataTypeId) === undefined

    const levelDoesntMatch = (field: IMediaPlanField) =>
      level.length > 0 &&
      level.find(t => t === field.fieldLevelId) === undefined

    const tagsDontMatch = (field: IMediaPlanField) =>
      tags.length > 0 &&
      tags.find(t => field.fieldTags.find(ft => ft.fieldTagId === t)) ===
        undefined

    const fieldDoesNotMatch = (field: IMediaPlanField) =>
      textDoesntMatch(field) ||
      typeDoesntMatch(field) ||
      levelDoesntMatch(field) ||
      tagsDontMatch(field)

    const mediaPlanFieldsForUpdate = mediaPlanFields.map(field => ({
      ...field,
      isVisible: !fieldDoesNotMatch(field)
    }))
    dispatchMediaPlanFields(mediaPlanFieldsForUpdate)
  }

  function handleRowSelection (selectedRows: IMediaPlanField[]): void {
    const mediaPlanFieldsForUpdate = mediaPlanFields.map(planField => {
      if (planField.isVisible) {
        const fieldSelected = selectedRows.find(f => f && f.mediaPlanFieldLevelId === planField.mediaPlanFieldLevelId) !== undefined
        return {
          ...planField,
          isSelected: fieldSelected
        }
      } else {
        return planField
      }
    })
    dispatchMediaPlanFields(mediaPlanFieldsForUpdate)
  }

  async function handleNextButtonClick () {
    await dispatch(saveMediaPlanFieldData(mediaPlanFields, currentClient.id))
    navigate(appPaths.clientDataTerms)
  }

  const visibleFields = mediaPlanFields.filter(f => f.isVisible)

  const loadingGif = useAgencyLoadingGif()

  return mediaPlanFieldsLoading ? (
    <LoadingComponent appDataSuccess={!!currentClient.id} agencyLoadingGif={loadingGif} />
  ) : (
    <>
      <TitleAndButtonsComponent
        title='Client Setup'
        subtitle='Add new fields'
        tooltipText={clientSetupText}
      />

      <MSLayoutContentRow>
        <ClientDataFilter
          handleFilter={handleFilter}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow fillAvailableSpace={true} extraClass='ms-selection-table field-selection-table'>
        <FieldSelectionTable
          visibleFields={visibleFields}
          handleRowSelection={handleRowSelection}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow extraClass='bottom-button-wrapper'>
        <Button
          type='primary'
          onClick={handleNextButtonClick}
        >
          Submit
        </Button>
      </MSLayoutContentRow>
    </>
  )
}

export default ClientDataFieldSelectionContainer
