import React, { CSSProperties, FunctionComponent, memo, useCallback } from 'react'
import { Button, Dropdown, Tooltip, Badge } from 'antd'
import { CopyOutlined, MinusCircleOutlined, ArrowsAltOutlined, ShrinkOutlined, DragOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { useFocus } from 'Hooks/useFocus'
import { IPoint } from 'Helpers/selectionHelper'
import { useFlightGroupSelection } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelection'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'

interface IProps {
  collapse: boolean
  flightGroupArrayLength: number
  flightGroupId: number
  flightGroupIndex: number
  canInsert: boolean
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  onCollapse: (flightGroupId: number) => void
  onDuplicateFlightGroup: (flightGroupIndex: number) => void
  onDeleteFlightGroup: (flightGroupIndex: number) => void
  onSelect: (event: React.MouseEvent<HTMLElement, MouseEvent>, point: IPoint, flightGroupSelection: Partial<IFlightGroup>, isSelected: boolean) => void
  onReset: (flightGroupSelection: Partial<IFlightGroup>) => void
  onCopy: (flightGroupSelection: Partial<IFlightGroup>) => void
  onInsert: (params: { shift: 0 | 1; withFlights: boolean; flightGroupIndex: number; flightGroupSelection: Partial<IFlightGroup> }) => void
  isSubtotalGroup: boolean
  canDragFlightGroup: boolean
  selectedFlightGroupsCount?: number | null
}

export const ButtonFlightGroupComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    collapse,
    flightGroupArrayLength,
    flightGroupId,
    flightGroupIndex,
    onCollapse,
    onDuplicateFlightGroup,
    onDeleteFlightGroup,
    getStickyStyle,
    onSelect,
    onReset,
    onCopy,
    onInsert,
    canInsert,
    isSubtotalGroup,
    canDragFlightGroup,
    selectedFlightGroupsCount
  } = props
  const [ref, focus] = useFocus()
  const { isSelected, flightGroupSelection } = useFlightGroupSelection({ mediaPlanFlightGroupId: flightGroupId }) ?? {
    isSelected: false,
    flightGroupSelection: null
  }
  const {
    listeners,
    setActivatorNodeRef,
    isDragging
  } = useSortable({ id: flightGroupId, disabled: !canDragFlightGroup })

  const handleSelection = useCallback((event) => {
    onSelect(event, { flightGroupIndex, flightGroupFieldIndex: -1 }, flightGroupSelection, isSelected)
  }, [onSelect, flightGroupIndex, flightGroupSelection, isSelected])

  const handleCollapse = useCallback(() => {
    onCollapse(flightGroupId)
  }, [onCollapse, flightGroupId])

  const handleDuplicate = useCallback(() => {
    onDuplicateFlightGroup(flightGroupIndex)
  }, [onDuplicateFlightGroup, flightGroupIndex])

  const handleDelete = useCallback(() => {
    onDeleteFlightGroup(flightGroupIndex)
  }, [onDeleteFlightGroup, flightGroupIndex])

  const handleCopy = useCallback(() => {
    onCopy(flightGroupSelection)
  }, [onCopy, flightGroupSelection])

  const handleInsertAboveWithFlights = useCallback(() => {
    onInsert({ shift: 0, withFlights: true, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleInsertAboveWithoutFlights = useCallback(() => {
    onInsert({ shift: 0, withFlights: false, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleInsertBelowWithFlights = useCallback(() => {
    onInsert({ shift: 1, withFlights: true, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleInsertBelowWithoutFlights = useCallback(() => {
    onInsert({ shift: 1, withFlights: false, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleReset = useCallback(() => {
    onReset(flightGroupSelection)
  }, [onReset, flightGroupSelection])

  return (
    <td style={getStickyStyle(StickyColumnType.toolbar)}>
      {isDragging && selectedFlightGroupsCount > 1 && (
        <div className='sortable-count'>
          <Badge count={selectedFlightGroupsCount} />
        </div>
      )}
      <div className={`flightGroup__toolbar ${collapse ? '-collapsed' : ''}`}>
        <Tooltip placement='topRight' title={collapse ? 'Expand flight group field' : 'Collapse flight group field'}>
          <Button
            data-testid='btn-collapse'
            icon={collapse ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
            onClick={handleCollapse}
            size='small'
            tabIndex={-1}
            type='link'
          />
        </Tooltip>
        {
          !collapse && !isSubtotalGroup &&
          (
            <>
              <Tooltip placement='right' title='Clone flight group'>
                <Button
                  icon={<CopyOutlined />}
                  size='small'
                  type='link'
                  tabIndex={-1}
                  onClick={handleDuplicate}
                  data-testid='btn-duplicated'
                />
              </Tooltip>
              {flightGroupArrayLength > 1 && (
                <Tooltip placement='right' title='Remove flight group'>
                  <Button
                    icon={<MinusCircleOutlined />}
                    size='small'
                    type='link'
                    tabIndex={-1}
                    onClick={handleDelete}
                    data-testid='btn-remove-group'
                  />
                </Tooltip>
              )}
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'copy',
                      label: 'Copy',
                      disabled: !isSelected,
                      onClick: handleCopy
                    },
                    {
                      key: 'insert-above',
                      label: 'Insert Above',
                      disabled: !canInsert,
                      onClick: handleInsertAboveWithFlights
                    },
                    {
                      key: 'insert-above-without-flights',
                      label: 'Insert Above Without Flights',
                      disabled: !canInsert,
                      onClick: handleInsertAboveWithoutFlights
                    },
                    {
                      key: 'insert-below',
                      label: 'Insert Below',
                      disabled: !canInsert,
                      onClick: handleInsertBelowWithFlights
                    },
                    {
                      key: 'insert-below-without-flights',
                      label: 'Insert Below Without Flights',
                      disabled: !canInsert,
                      onClick: handleInsertBelowWithoutFlights
                    },
                    {
                      key: 'clear-data',
                      label: 'Clear Data',
                      disabled: !isSelected,
                      onClick: handleReset
                    }
                  ]
                }}
                dropdownRender={(menu) => (
                  <div className='flightGroup__context-menu' onClick={focus}>
                    {React.cloneElement(menu as React.ReactElement)}
                  </div>
                )}
                trigger={['contextMenu']}
              >
                <Tooltip placement='right' title='Select flight group'>
                  <Button
                    icon={<DragOutlined />}
                    size='small'
                    tabIndex={-1}
                    type='link'
                    data-testid='btn-select-group'
                    ref={(node) => {
                      // eslint-disable-next-line functional/immutable-data
                      ref.current = node
                      setActivatorNodeRef(node)
                    }}
                    style={{ cursor: `${!canDragFlightGroup ? 'not-allowed' : 'move'}` }}
                    onContextMenu={handleSelection}
                    {...listeners}
                    onClick={handleSelection}
                  />
                </Tooltip>
              </Dropdown>
            </>
          )}
      </div>
    </td>
  )
}

export default memo(ButtonFlightGroupComponent)
