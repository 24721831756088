import { useMemo } from 'react'
import { useGetClientMediaPlanFieldsQuery } from 'Apis/enhancedClientFieldAliasApi'
import { getLookupMediaPlanFields, getMediaPlanFieldsWithHierarchies } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useMediaPlanFields } from './useMediaPlanFields'

export const useLinkedLookupFields = () => {
  const { data: mediaPlanFields = [], isLoading: areMediaPlanFieldsLoading } = useMediaPlanFields()
  const { data: currentClient } = useCurrentClient()
  const { data: clientMediaPlanFields = [] } = useGetClientMediaPlanFieldsQuery({ clientId: currentClient.id })

  const linkedLookupFields = useMemo(() => {
    const lookupMediaPlanFields = getLookupMediaPlanFields(mediaPlanFields, clientMediaPlanFields)
    const mediaPlanFieldsWithHierarchies = getMediaPlanFieldsWithHierarchies(mediaPlanFields, clientMediaPlanFields)

    return { lookupMediaPlanFields, mediaPlanFieldsWithHierarchies }
  }, [mediaPlanFields, clientMediaPlanFields])

  return {
    data: linkedLookupFields,
    isLoading: areMediaPlanFieldsLoading
  }
}
