import React, { useMemo } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { Typography } from 'antd'
import { Client } from 'Apis/generated/clientsApi'
import { MediaPlanTemplatesApiResponse } from 'Apis/generated/templatesApi'
import { GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiResponse } from 'Apis/generated/templateSyncApi'
import { ITemplateSyncChildTemplate, ITemplateSyncFormData } from './TemplateSyncModalComponent'
import TemplateSyncAccordion, { ITemplateSyncAccordionProps } from './TemplateSyncAccordion'
import TemplateSyncLinkedTemplatesComponent from './TemplateSyncLinkedTemplatesComponent'
import TemplateSyncClientTemplatesComponent from './TemplateSyncClientTemplatesComponent'
import TemplateSyncSelectedTemplatesComponent from './TemplateSyncSelectedTemplatesComponent'

const { Paragraph } = Typography

interface ITemplateSyncClientSelectionStepComponentProps {
  control: Control<ITemplateSyncFormData>
  setValue: UseFormSetValue<ITemplateSyncFormData>
  clients: Client[]
  clientTemplatesList: MediaPlanTemplatesApiResponse
  isLinkedChildTemplatesLoading: boolean
  linkedChildTemplatesList: GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiResponse
  selectedClientId: number
  selectedClientTemplatesList: ITemplateSyncChildTemplate[]
  selectedLinkedTemplatesList: ITemplateSyncChildTemplate[]
  useExistingTemplate: boolean
}

const TemplateSyncTemplatesSelectionStepComponent: React.FC<ITemplateSyncClientSelectionStepComponentProps> = ({
  control,
  setValue,
  clients,
  clientTemplatesList,
  isLinkedChildTemplatesLoading,
  linkedChildTemplatesList,
  selectedClientId,
  selectedClientTemplatesList,
  selectedLinkedTemplatesList,
  useExistingTemplate
}) => {
  const items: ITemplateSyncAccordionProps['items'] = useMemo(() => [{
    key: '1',
    label: `Linked templates (${selectedLinkedTemplatesList.length})`,
    children: (
      <TemplateSyncLinkedTemplatesComponent
        control={control}
        setValue={setValue}
        isLinkedChildTemplatesLoading={isLinkedChildTemplatesLoading}
        linkedChildTemplatesList={linkedChildTemplatesList}
        selectedLinkedTemplatesList={selectedLinkedTemplatesList}
      />
    )
  }, {
    key: '2',
    label: 'Find clients & templates',
    children: (
      <TemplateSyncClientTemplatesComponent
        control={control}
        setValue={setValue}
        clients={clients}
        client={clients.find(c => c.id === selectedClientId)}
        clientTemplatesList={clientTemplatesList}
        selectedClientTemplatesList={selectedClientTemplatesList}
        useExistingTemplate={useExistingTemplate}
      />
    )
  }, {
    key: '3',
    label: `Selected clients & templates (${selectedLinkedTemplatesList.length + selectedClientTemplatesList.length})`,
    children: (
      <TemplateSyncSelectedTemplatesComponent
        setValue={setValue}
        selectedClientTemplatesList={selectedClientTemplatesList}
        selectedLinkedTemplatesList={selectedLinkedTemplatesList}
      />
    )
  }], [
    control,
    setValue,
    clients,
    clientTemplatesList,
    isLinkedChildTemplatesLoading,
    linkedChildTemplatesList,
    selectedClientId,
    selectedClientTemplatesList,
    selectedLinkedTemplatesList,
    useExistingTemplate
  ])

  return (
    <>
      <Paragraph>Which clients should receive this update?</Paragraph>
      <TemplateSyncAccordion items={items} />
    </>
  )
}

export default TemplateSyncTemplatesSelectionStepComponent
