import { createSelector } from 'reselect'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { findByFieldLevelId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFlightGroupIndex } from 'Helpers/flightHelper'
import { selectMediaPlans, selectSelectedFlights } from './mediaPlansSelectors'

const selectCurrentMediaPlanVersion = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.currentMediaPlanVersion
)

export const selectCurrentMediaPlanVersionFields = createSelector(
  selectCurrentMediaPlanVersion,
  currentMediaPlanVersion => currentMediaPlanVersion.mediaPlanVersionFields
)

export const selectCurrentMediaPlanVersionFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  currentMediaPlanVersionFields => findByFieldLevelId(currentMediaPlanVersionFields, FieldLevelType.FLIGHT)
)

export const selectCurrentMediaPlanVersionSubFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  currentMediaPlanVersionFields => findByFieldLevelId(currentMediaPlanVersionFields, FieldLevelType.SUB_FLIGHT)
)

export const selectParseData = createSelector(
  selectCurrentMediaPlanVersion,
  currentMediaPlanVersion => currentMediaPlanVersion.parseData
)

export const selectCalendarView = createSelector(
  selectParseData,
  parseData => parseData.calendarView
)

export const selectPlanStartDate = createSelector(
  selectParseData,
  parseData => parseData.planStartDate
)

export const selectPlanEndDate = createSelector(
  selectParseData,
  parseData => parseData.planEndDate
)

export const selectFlightGroups = createSelector(
  selectParseData,
  (parseData): IFlightGroup[] => parseData.flightGroups
)

export const createFlightGroupFlightsSelector = () => createSelector(
  selectFlightGroups,
  (state, flightGroupIndex: number) => flightGroupIndex,
  (flightGroups, flightGroupIndex) => flightGroups[flightGroupIndex]?.flights
)

const emptySelectedFlights = []
export const createFlightGroupSelectedFlightsSelector = () => createSelector(
  selectSelectedFlights,
  (_state, flightGroupIndex: number) => flightGroupIndex,
  (selectedFlights, flightGroupIndex) => {
    const filteredSelectedFlights = selectedFlights.filter(selectedKey => getFlightGroupIndex(selectedKey) === flightGroupIndex)
    if (filteredSelectedFlights.length) {
      return filteredSelectedFlights
    }
    return emptySelectedFlights
  }
)

export const selectMediaPlanVersionId = createSelector(
  selectParseData,
  parseData => parseData.mediaPlanVersionId
)
