import { message } from 'antd'
import moment from 'moment/moment'
import {
  GetCustomExportsByIdApiArg,
  PutCustomExportsByIdApiResponse
} from 'Apis/generated/customExportsApi'
import { useLazyGetCustomExportsByIdQuery, usePutCustomExportsByIdMutation } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseMutationCommand } from '../../../redux/types'

export const useDeleteCustomExport = (): UseMutationCommand<
Omit<GetCustomExportsByIdApiArg, 'clientId'>,
PutCustomExportsByIdApiResponse
> => {
  const { data: currentClient } = useCurrentClient()
  const [getDataCustomExport, { isLoading: loadingCustomExport }] = useLazyGetCustomExportsByIdQuery()
  const [updateCustomExport, { isLoading: creatingCustomExport }] = usePutCustomExportsByIdMutation()

  return {
    isLoading: creatingCustomExport || loadingCustomExport,
    execute: async ({ id }) => {
      try {
        const customExport = await getDataCustomExport({ id, clientId: currentClient.id }).unwrap()

        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        const response = await updateCustomExport({
          id,
          clientId: currentClient.id,
          customExport: {
            ...customExport,
            deletedOn: moment().utc().startOf('day').toISOString()
          }
        }).unwrap()

        message.success('Custom export has been successfully deleted')

        return response
      } catch {
        message.error('Something went wrong')
      }
    }
  }
}
