import React, { useState, useCallback } from 'react'
import { Button, Space, List, Checkbox, Alert } from 'antd'

import { defaultContainerHeight } from 'Components/Redistribution/constants/redistribution'
import ModalComponent from 'Components/ModalComponent'
import { UseLinkedLookup } from 'Components/LinkedLookup/hooks/useLinkedLookupConnectionValues'
import { TreeDataNode } from 'Components/LinkedLookup/containers/LinkedLookupFieldContainer'

interface IProps {
  visible: boolean
  hideModal: () => void
  dataSource: TreeDataNode[]
  currentFieldId: number
  linkedLookupModel: UseLinkedLookup
}

export const LinkedLookupCopyModalContainer = ({ visible, hideModal, dataSource, currentFieldId, linkedLookupModel }: IProps) => {
  const availableData = dataSource.filter(item => item.value !== currentFieldId)
  const [selectedValues, setSelectedValues] = useState<TreeDataNode[]>([])

  const setAllValues = useCallback(() => {
    setSelectedValues(availableData)
  }, [availableData])

  const handleChange = useCallback((checked, item) => {
    if (checked) {
      setSelectedValues(previousSelectedValues => [...previousSelectedValues, item])
    } else {
      setSelectedValues(previousSelectedValues => previousSelectedValues.filter((v) => v.value !== item.value))
    }
  }, [])

  const handleClick = useCallback(() => {
    const linkedLookupValues = linkedLookupModel.values.linkedLookupValues
      .filter(
        (item) => item.dependentValueId === currentFieldId)

    const filteredLinkedLookupValues = linkedLookupModel.values.linkedLookupValues.filter(e =>
      !selectedValues.some(
        item =>
          e.dependentValueId === item.value
      ))

    if (linkedLookupValues) {
      let selectedFieldValues = [...filteredLinkedLookupValues]

      selectedValues.forEach((item) => {
        const updatedLinkedLookupValues = linkedLookupValues.map((e) => ({
          ...e,
          dependentValueId: item.value,
          linkedLookupValueId: 0
        }))

        // eslint-disable-next-line functional/immutable-data
        selectedFieldValues = selectedFieldValues.concat(updatedLinkedLookupValues)
      })

      linkedLookupModel.replaceFieldValue(selectedFieldValues, 'linkedLookupValues')
    } else {
      linkedLookupModel.replaceFieldValue([], 'linkedLookupValues')
    }
  }, [selectedValues, currentFieldId, linkedLookupModel])

  return (
    <ModalComponent
      centered={true}
      open={visible}
      onCancel={hideModal}
      width={600}
      className='linked-lookup-edit-modal'
      wrapProps={{ 'data-testid': 'linked-lookup-edit-modal' }}
      modalBody={(
        <>
          <Alert
            message={<>The existing <b>Valid For</b> values of the destination fields will be overridden.</>}
            type='warning'
            showIcon={true}
          />
          <List
            bordered={true}
            dataSource={availableData}
            style={{ height: defaultContainerHeight }}
            renderItem={(item) => {
              const checkedValues = !!selectedValues.find(value => value.value === item.value)

              return (
                <List.Item key={item.value}>
                  <Checkbox
                    className='linked-lookup-edit-checkbox'
                    onChange={e => {
                      handleChange(
                        e.target.checked,
                        item
                      )
                    }}
                    checked={checkedValues}>
                    {item.title}
                  </Checkbox>
                </List.Item>
              )
            }
            }
          ></List>
          <Space style={{ marginTop: 10 }}>
            <Button onClick={setAllValues}>Select all</Button>
            <Button onClick={() => setSelectedValues([])}>Unselect all</Button>
          </Space>
        </>)}
      footer={(
        <Space>
          <Button onClick={hideModal}>
            Cancel
          </Button>
          <Button
            disabled={!selectedValues.length}
            onClick={() => {
              if (selectedValues.length) {
                hideModal()
                handleClick()
              }
            }} type='primary'>
              Ok
          </Button>
        </Space>
      )}
    />
  )
}

export default LinkedLookupCopyModalContainer
