import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { MSLayout, MSRoutesComponent, useRouteNavigation, useMindshareSelector } from '@mindshare/layout'
import { authenticateApp, msAuthHandler } from '@mindshare/services'

import { Client } from 'Apis/generated/clientsApi'
import { getClients } from 'Actions/appActions'
import { RootState } from 'Reducers/index'
import menuItems from 'Constants/menuItems'
import { agencyStyleCfg } from 'Constants/agencyStyleCfg'
import { useDefaultClient, ClientSelectionContainer } from 'Containers/ClientSelectionContainer'
import { authoriseMediaPlanningTool, renderSideNavMenuItemsBasedOnClaims } from 'Helpers/initialisation'
import { useUserPermissions, initialPermissions } from 'Hooks/useUserPermissions'
import { appRoutes } from './providers/AppRoutes'
import { useAppDispatch } from './store'

Spin.setDefaultIndicator(<LoadingOutlined />)

const App = () => {
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()
  const location = useLocation()
  const { getUserPermissions, data: userPermissions = initialPermissions } = useUserPermissions()
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  const [sideNav, setSideNav] = useState(menuItems)
  const [appReady, setAppReady] = useState(false)
  const [appInitialised, setAppInitialised] = useState(false)

  useEffect(() => {
    (async function loadClients () {
      try {
        if (await authenticateApp()) {
          const { userAuthorised } = await authoriseMediaPlanningTool()
          if (userAuthorised) {
            const user = await msAuthHandler.getUser()
            if (user) {
              await dispatch(getClients())
            }
          }
        }
      } catch (err) {
        throw new Error(`Error initialising app: ${err}`)
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (async function loadPermissions () {
      if (currentClient.name) {
        try {
          await getUserPermissions({ clientName: currentClient.name })
        } finally {
          setAppReady(true)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient.name])

  useDefaultClient()

  useEffect(() => {
    if (currentClient.id > 0 && userPermissions.userAuthorised) {
      renderSideNavMenuItemsBasedOnClaims(setSideNav, location.pathname, userPermissions, navigate, currentClient.mptReportUrl, currentClient.name)
      setAppInitialised(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient.id, userPermissions])

  return (
    <MSLayout
      appReady={appReady}
      appName={currentClient.agency?.mediaPlanningToolName || 'Media Activity Plan'}
      appDataSuccess={appInitialised}
      menuItems={sideNav}
      client={currentClient}
      agencyStyleCfg={agencyStyleCfg}
      themeConfig={{ fullWidth: true }}
      baseTheme='WppOs'
    >
      <ClientSelectionContainer />
      <MSRoutesComponent routes={appRoutes} />
    </MSLayout>
  )
}

export default App
