import React, { FC } from 'react'
import { Input, InputProps } from 'antd'

type IProps = Omit<InputProps, 'onChange'> & {
  onChange: (value: string) => void
}

export const CustomExportNameInput: FC<IProps> = ({ value, onChange, disabled = false }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)

  return (
    <div>
      <label htmlFor='customExportName'>Name</label>
      <Input
        disabled={disabled}
        placeholder='Enter name of a custom export'
        name='customExportName'
        id='customExportName'
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}
