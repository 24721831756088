import { useEffect } from 'react'
import moment from 'moment'
import { usePostMediaPlanTemplatesMutation, usePutMediaPlanTemplatesByIdMutation, useLazyGetMediaPlanTemplatesByIdQuery } from 'Apis/enhancedTemplatesApi'
import { TemplateListActionType } from 'Constants/enums/TemplateListActionType'

export const useTemplateHook = (clientId: number, action: TemplateListActionType) => {
  const [getTemplate, getResult] = useLazyGetMediaPlanTemplatesByIdQuery()
  const [updateTemplate, updateResult] = usePutMediaPlanTemplatesByIdMutation()
  const [postTemplate, postResult] = usePostMediaPlanTemplatesMutation()

  useEffect(() => {
    if (getResult.isSuccess) {
      if (action === TemplateListActionType.CLONE) {
        postTemplate({
          clientId,
          mediaPlanTemplateWithMediaTemplateFields: {
            ...getResult.data,
            templateName: `Copy of ${getResult.data.templateName}`
          }
        })
      } else if (action === TemplateListActionType.DELETE) {
        updateTemplate({
          id: getResult.data.mediaPlanTemplateId,
          clientId,
          mediaPlanTemplateWithMediaTemplateFields: {
            ...getResult.data,
            deletedOn: moment().utc().seconds(0).milliseconds(0).toISOString()
          }
        })
      }
    }
  }, [getResult.isSuccess, action, clientId, getResult.data, postTemplate, updateTemplate])

  return {
    deleteTemplate: getTemplate,
    cloneTemplate: getTemplate,
    isLoading: getResult.isLoading || postResult.isLoading || updateResult.isLoading,
    error: getResult.error || postResult.error || updateResult.error,
    isSuccess: getResult.isSuccess && (postResult.isSuccess || updateResult.isSuccess)
  }
}
