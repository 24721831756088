
import React, { FC } from 'react'
import { CustomExportFieldDataTypeOperator } from 'Apis/generated/customExportsApi'
import { NotSupportedOperator } from './NotSupportedOperator'

interface IProps {
  operator: CustomExportFieldDataTypeOperator
  single?: React.JSX.Element
  range?: React.JSX.Element
  multi?: React.JSX.Element
}

export const DisplayValueByOperator: FC<IProps> = ({
  operator,
  single = <NotSupportedOperator operator={operator} />,
  range = <NotSupportedOperator operator={operator} />,
  multi = <NotSupportedOperator operator={operator} />
}) => {
  switch (operator.fieldDataTypeOperator) {
    case 'LIKE':
    case 'UNDER':
    case '=':
    case '<':
    case '>':
    case '<=':
    case '>=': {
      return single
    }

    case 'BETWEEN': {
      return range
    }

    case 'IN':
    case 'NOT IN': {
      return multi
    }

    default: {
      return (
        <NotSupportedOperator operator={operator} />
      )
    }
  }
}
