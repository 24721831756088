import React, { useCallback, useEffect, useState } from 'react'
import { MSLayoutContentRow, LoadingComponent, MSTabs } from '@mindshare/layout'
import { msAuthHandler } from '@mindshare/services'
import { useGetClientMediaPlanFieldsQuery, usePutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasMutation } from 'Apis/enhancedClientFieldAliasApi'
import { useGetMediaPlanTemplateFieldsQuery, usePutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasMutation } from 'Apis/enhancedMediaPlanTemplateFieldsApi'
import { baseApi } from 'Apis/generated/baseApi'
import ClientFieldAliasSetup from 'Components/AliasSetup/ClientFieldAliasSetup'
import TemplateFieldAliasSetup from 'Components/AliasSetup/TemplateFieldAliasSetup'
import { useNotificationMessage } from 'Hooks/CustomHooks'
import { NotificationType } from 'Constants/enums/NotificationType'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useAppDispatch, useAppSelector } from '../../store'

const { SUCCESS, ERROR } = NotificationType

interface IApprovable {
  approvedOn: Date
  approvedBy: string
}

export const AliasSetupContainer: React.FunctionComponent = (): React.ReactElement => {
  const client = useAppSelector((state) => state.app.currentClient)
  const dispatch = useAppDispatch()
  const [userEmail, setUserEmail] = useState<string>()

  const {
    isLoading: isLoadingClientSetupAlias,
    data: clientSetupAliasData,
    isError: clientFieldGetError
  } = useGetClientMediaPlanFieldsQuery(
    { clientId: client.id },
    { refetchOnMountOrArgChange: true }
  )
  const {
    isLoading: isLoadingTemplateAlias,
    data: templateAliasData,
    isError: templateFieldSetupGetError
  } = useGetMediaPlanTemplateFieldsQuery(
    { clientId: client.id },
    { refetchOnMountOrArgChange: true }
  )
  const [
    updateClientFieldAliasApproveStatus,
    { isError: clientFieldUpdateError, isSuccess: clientFieldUpdateIsSuccess }
  ] = usePutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasMutation()
  const [
    updateTemplateFieldAliasApproveStatus,
    {
      isError: templateFieldUpdateError,
      isSuccess: templateFieldUpdateIsSuccess
    }
  ] = usePutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasMutation()

  useNotificationMessage(clientFieldGetError, 'There was an error fetching the client alias fields', ERROR)
  useNotificationMessage(templateFieldSetupGetError, 'There was an error fetching the template alias fields', ERROR)
  useNotificationMessage(clientFieldUpdateError, 'There was an error updating the client alias field', ERROR)
  useNotificationMessage(templateFieldUpdateError, 'There was an error updating the template alias field', ERROR)
  useNotificationMessage(clientFieldUpdateIsSuccess, 'Successfully updated the client alias field', SUCCESS)
  useNotificationMessage(templateFieldUpdateIsSuccess, 'Successfully updated the template alias field', SUCCESS)

  useEffect(() => {
    const getUser = async () => {
      const user = await msAuthHandler.getUser()
      setUserEmail(user.profile.email)
    }
    getUser()
  }, [])

  const updateCache = useCallback(<T extends IApprovable>(endpoint: string, fieldName: string, fieldId: number, approved: boolean) => {
    // optimistically update RTKQ cached values
    dispatch(baseApi.util.updateQueryData(endpoint as never, { clientId: client.id } as never, (draftPosts: T[]) => {
      const item = draftPosts.find(x => x[fieldName] === fieldId)

      if (item) {
        // eslint-disable-next-line functional/immutable-data
        item.approvedOn = approved ? new Date(Date.now()) : null
        // eslint-disable-next-line functional/immutable-data
        item.approvedBy = approved ? userEmail : null
      }
    }))
  }, [dispatch, client.id, userEmail])

  const loadingGif = useAgencyLoadingGif()

  if (isLoadingClientSetupAlias || isLoadingTemplateAlias) {
    return <LoadingComponent appDataSuccess={!!client.id} agencyLoadingGif={loadingGif} />
  }

  return (
    <MSLayoutContentRow fillAvailableSpace={true}>
      <MSTabs
        destroyInactiveTabPane={true}
        className='client-field-alias-tabs'
        items={[
          {
            label: 'Client Field Alias',
            key: 'client-field-alias',
            children: (
              <ClientFieldAliasSetup
                clientId={client.id}
                data={clientSetupAliasData.filter(x => x.fieldLabel && x.fieldLabel !== x.mediaPlanField.fieldLabel)}
                updateStatus={updateClientFieldAliasApproveStatus}
                updateCache={updateCache}
              />
            )
          }, {
            label: 'Template Field Alias',
            key: 'template-field-alias',
            children: (
              <TemplateFieldAliasSetup
                clientId={client.id}
                data={templateAliasData.filter(x => x.templateFieldLabel && x.templateFieldLabel !== x.mediaPlanFieldLabel)}
                updateStatus={updateTemplateFieldAliasApproveStatus}
                updateCache={updateCache}
              />
            )
          }]}
      />
    </MSLayoutContentRow>
  )
}

export default AliasSetupContainer
