import React, { FunctionComponent, useCallback } from 'react'
import { Button, Row, Col, Space, Typography, Tooltip } from 'antd'
import { formatLowerCaseFirstLetter, isNumeral, useMindshareSelector } from '@mindshare/layout'
import { applyRedistribution } from 'Actions/mediaPlansActions'
import { IRedistribution, IRedistributionFormValues, ISelectedRedistributeByField } from 'Components/Redistribution/constants/entities/IRedistribution'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { redistributionTooltipMessage } from 'Constants/enums/TooltipMessages'
import { isDateType } from 'Components/shared/constants/entities/IFieldMetaData'
import { getFieldDataType } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { Portal } from 'Components/shared/Portal'
import MediaPlanInputComponent from 'Components/MediaPlanInputComponent'
import { useRedistributionFlightsData } from 'Components/Redistribution/hooks/useRedistributionFlightsData'
import { formatDate } from 'Helpers/formatDate'
import { RootState } from '../../../redux/reducers'
import { useAppDispatch } from '../../../store'

interface IProps {
  hideModal: () => void
  goBack: () => void
  amountToRedistribute: number
  onChange: (value: React.ChangeEvent<HTMLInputElement> | string | number) => void
  formValues: IRedistributionFormValues
}

const { Text } = Typography

export const ApplyRedistributionComponent: FunctionComponent<IProps> = ({
  hideModal,
  goBack,
  amountToRedistribute,
  onChange,
  formValues
}) => {
  const mediaPlanVersionFields = useMindshareSelector(
    (state: RootState) =>
      state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields
  )
  const currentRedistribution = useMindshareSelector<IRedistribution>(
    (state: RootState) => state.mediaPlans.currentRedistribution
  )
  const selectedRedistributeByFields = useMindshareSelector(
    (state: RootState) => state.mediaPlans.selectedRedistributeByFields
  ) as ISelectedRedistributeByField[]
  const dispatch = useAppDispatch()

  const fieldToRedistributeBy = mediaPlanVersionFields.find(field => field.clientMediaPlanFieldId === formValues.fieldToRedistributeById)
  const fieldToRedistributeByLabel = fieldToRedistributeBy?.fieldLabel || ''
  const fieldToRedistribute = mediaPlanVersionFields.find(field => field.clientMediaPlanFieldId === formValues.fieldToRedistributeId)
  const fieldToRedistributeLabel = fieldToRedistribute?.fieldLabel || ''
  const isFlightGroupLevel = fieldToRedistributeBy?.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP
  const fieldToRedistributeColumnName = formatLowerCaseFirstLetter(fieldToRedistribute.clientMediaPlanField.mediaPlanField.columnName)
  const fieldToRedistributeByColumnName = formatLowerCaseFirstLetter(fieldToRedistributeBy.clientMediaPlanField.mediaPlanField.columnName)
  const selectedRedistributeByFieldsValues = selectedRedistributeByFields.map(
    field => {
      if (isFlightGroupLevel) {
        const flightGroupValue = field.flightGroup[fieldToRedistributeByColumnName]
        return isNumeral(flightGroupValue) ? Number(flightGroupValue) : flightGroupValue
      }

      const flightValue = field.flightGroup.flights[field.flightIndex][fieldToRedistributeByColumnName]
      return isNumeral(flightValue) ? Number(flightValue) : flightValue
    }
  )
  const areAllFlightsSelected = currentRedistribution?.redistributeByValues?.every((item) => selectedRedistributeByFieldsValues.includes(item.valueId))
  const isRedistributionDisabled = !selectedRedistributeByFields.length || !areAllFlightsSelected || !amountToRedistribute
  const { dataTypeName } = getFieldDataType(fieldToRedistributeBy)
  const flightsData = useRedistributionFlightsData({ columnName: fieldToRedistributeColumnName })

  const handleApplyRedistribution = useCallback(() => {
    const newRedistribution = {
      ...currentRedistribution,
      redistributeByValues: currentRedistribution.redistributeByValues?.map(
        item => {
          const value = isDateType(dataTypeName) ? formatDate(item.valueId) : item.valueId

          return {
            percentage: item.percentage,
            redistributeByValueId: item.redistributeByValueId,
            redistributionId: item.redistributionId,
            value: value?.toString() || ''
          }
        }
      )
    }

    const data = {
      redistribution: newRedistribution,
      flights: flightsData,
      amountToRedistribute
    }

    dispatch(applyRedistribution(data))
  }, [
    currentRedistribution,
    dispatch,
    amountToRedistribute,
    flightsData,
    dataTypeName
  ])

  return (
    <Portal>
      <div
        className="apply-redistribution-modal-container"
        data-testid="apply-redistribution-modal"
      >
        <div className="apply-redistribution-modal-title">
          <Text>Redistribute</Text>
        </div>
        <div className="apply-redistribution-modal-component">
          <Text>Select flights to redistribute values in.</Text>
          <div>
            <Text className="apply-redistribution-field-label">Field to redistribute:</Text>
            <b>{fieldToRedistributeLabel}</b>
          </div>
          <div>
            <Text className="apply-redistribution-field-label">
            Field to redistribute by:
            </Text>
            <b>{fieldToRedistributeByLabel}</b>
          </div>
          <div className="apply-redistribution-field-container">
            <Text className="apply-redistribution-field-label">
            Amount to redistribute:
            </Text>
            <MediaPlanInputComponent
              data-testid="apply-redistribution-modal-input"
              id={fieldToRedistribute.mediaPlanTemplateFieldId || fieldToRedistribute.mediaPlanVersionFieldId}
              mediaPlanField={fieldToRedistribute}
              value={amountToRedistribute}
              onChange={onChange}
            />
          </div>
        </div>
        <Row justify="space-between" className="modal-footer">
          <Col>
            <Space>
              <Button aria-label="Cancel" onClick={hideModal}>
              Cancel
              </Button>
              <>
                <Button aria-label="Back" onClick={goBack} type="primary">
                Back
                </Button>
                <Tooltip title={!areAllFlightsSelected ? redistributionTooltipMessage.missingFlights : ''}>
                  <Button
                    aria-label="Last Step"
                    type="primary"
                    htmlType="submit"
                    onClick={handleApplyRedistribution}
                    disabled={isRedistributionDisabled}
                  >
                Redistribute
                  </Button>
                </Tooltip>
              </>
            </Space>
          </Col>
        </Row>
      </div>
    </Portal>
  )
}
