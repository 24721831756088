export const isEmptyValue = (value: any): boolean => value === '' || value === null || value === undefined

export const isArrayType = (obj: unknown): boolean => Object.prototype.toString.call(obj) === '[object Array]'

export const isValueType = (obj: unknown): boolean => {
  const t = typeof obj
  return t === 'string' || t === 'number' || t === 'bigint' || t === 'symbol' || t === 'boolean' || t === 'undefined'
}

export const cloneObject = <T = any>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj))
}

export const findDifference = <T>(obj1: T, obj2: T): Partial<T> => {
  if (isEmptyValue(obj1) || isValueType(obj1)) {
    if (obj1 !== obj2) {
      return obj2
    }
  } else if (isArrayType(obj1)) {
    if (!isArrayType(obj2) || JSON.stringify(obj1) !== JSON.stringify(obj2)) {
      return obj2
    }
  } else {
    let _diff = {}
    Object.keys(obj1).forEach((key) => {
      const propDiff = findDifference(obj1[key], obj2[key])
      if (propDiff) _diff = { ..._diff, [key]: propDiff }
    })
    if (Object.keys(_diff).length) return _diff as T
  }
  return null
}

export const pick = <T extends Record<string, unknown>, K extends keyof T>(obj: T, ...props: K[]): Pick<T, K> => {
  return props.reduce<any>((result, prop) => {
    return {
      ...result,
      [prop]: obj[prop]
    }
  }, {})
}
