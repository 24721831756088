import { useCallback, useState, useEffect } from 'react'
import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { useValueRef } from 'Hooks/useValueRef'
import { linkedLookupsInitialValues } from '../constants/entities'

interface UseLinkedLookupParams {
  getInitialValues: () => LinkedLookup
  skip: boolean
}

export interface UseLinkedLookup {
  values: LinkedLookup
  setFieldValue: (value, id: number, name: string) => void
  replaceFieldValue: (value, name: string) => void
}

export const useLinkedLookupConnectionValues = ({ getInitialValues, skip }: UseLinkedLookupParams): Readonly<UseLinkedLookup> => {
  const [linkedLookup, setLinkedLookup] = useState<LinkedLookup>(linkedLookupsInitialValues)
  const init = useValueRef(getInitialValues)

  useEffect(() => {
    if (!skip) {
      setLinkedLookup(init.current())
    }
  }, [init, skip])

  const setFieldValue = useCallback((value, id, name) => {
    setLinkedLookup(currentLinkedLookup => ({
      ...currentLinkedLookup,
      [name]: [
        ...currentLinkedLookup[name].filter(item => item.dependentValueId !== id),
        ...value
      ]
    }))
  }, [])

  const replaceFieldValue = useCallback((value, name) => {
    setLinkedLookup(currentLinkedLookup => ({
      ...currentLinkedLookup,
      [name]: value
    }))
  }, [])

  return {
    values: linkedLookup,
    setFieldValue,
    replaceFieldValue
  }
}
