import React from 'react'
import { Select } from 'antd'

import { LINKED_LOOKUP_TYPES } from 'Components/LinkedLookup/constants/enums/LinkedLookupTypes'

export const TypeSelectComponent = ({
  value,
  onChange = null
}) => (
  <Select
    className='select select--fluid'
    placeholder='Enforced vs Encouraged'
    onChange={onChange}
    data-testid='linked-lookup-type'
    optionFilterProp='children'
    showSearch={true}
    value={value ? LINKED_LOOKUP_TYPES.Enforced : LINKED_LOOKUP_TYPES.Encouraged}
  >
    <Select.Option
      value={LINKED_LOOKUP_TYPES.Enforced}
    >
      {LINKED_LOOKUP_TYPES.Enforced}
    </Select.Option>
    <Select.Option
      value={LINKED_LOOKUP_TYPES.Encouraged}
    >
      {LINKED_LOOKUP_TYPES.Encouraged}
    </Select.Option>
  </Select>
)
