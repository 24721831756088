import React, { FC } from 'react'

import { Select, SelectProps } from 'antd'
import { Agency } from 'Apis/generated/buyingAgenciesApi'
import { ClientMediaPartner } from 'Apis/generated/clientMediaPartnersApi'
import { ClientCampaign } from 'Apis/generated/clientCampaignsApi'
import { IMasteredListsData, MasteredListConfig } from 'Hooks/useMasteredListFieldsData'

type TMasteredListDataSourceItem = ClientMediaPartner | ClientCampaign | Agency

interface IProps extends SelectProps<number | number[]> {
  masteredListsData: IMasteredListsData
  fieldDataTypeId: number
}

export const MasteredListsFieldSelector: FC<IProps> = ({
  onChange,
  value,
  masteredListsData,
  fieldDataTypeId,
  mode = null
}) => {
  if (!MasteredListConfig[fieldDataTypeId]) {
    return null
  }

  const { dataSourceKey, idKey, displayKey } = MasteredListConfig[fieldDataTypeId]
  const dataSource = masteredListsData[dataSourceKey] as TMasteredListDataSourceItem[]

  return (
    <Select
      placeholder='Select Value'
      onChange={onChange}
      value={value}
      optionFilterProp='children'
      allowClear={true}
      showSearch={true}
      mode={mode}
      style={{ width: '100%' }}
    >
      {dataSource && dataSource.map(cf => (
        <Select.Option
          key={cf[idKey]}
          value={cf[idKey]}
        >
          {cf[displayKey]}
        </Select.Option>
      ))}
    </Select>
  )
}
