import { baseApi as api } from './baseApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    clientCampaigns: build.query<
    ClientCampaignsApiResponse,
    ClientCampaignsApiArg
    >({
      query: (queryArg) => ({
        url: '/clientCampaigns',
        params: {
          clientId: queryArg.clientId,
          includeDeleted: queryArg.includeDeleted
        }
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as baseApi }
export type ClientCampaignsApiResponse =
  /** status 200 Returns Client Campaign list for client */ ClientCampaign[]
export type ClientCampaignsApiArg = {
  clientId: number
  includeDeleted?: boolean
}
export type ClientCampaign = {
  clientCampaignId?: number
  clientId?: number
  clientCampaignUniqueIdentifier?: string | null
  clientCampaignName?: string | null
  clientCampaignCode?: string | null
  createdBy?: string | null
  createdOn?: string
  modifiedBy?: string | null
  modifiedOn?: string | null
  deletedBy?: string | null
  deletedDate?: string | null
}
export const { useClientCampaignsQuery, useLazyClientCampaignsQuery } =
  injectedRtkApi
