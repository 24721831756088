import React from 'react'
import Moment from 'react-moment'
import { MSVirtualTable, formatEmailToName, useRouteNavigation } from '@mindshare/layout'
import { Button, Popconfirm, Tooltip } from 'antd'
import { CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { Client } from 'Apis/generated/clientsApi'
import { TemplateListActionType } from 'Constants/enums/TemplateListActionType'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { ITemplateListSettings } from 'Constants/entities/IMapSettings'
import { compare } from 'Helpers/sortHelper'
import { appPaths } from 'Constants/appPaths'

export interface IProps {
  createNewTemplate: () => void
  currentClient: Client
  cloneTemplate: (arg: {
    id: number
    clientId: number
  }) => void
  deleteTemplate: (arg: {
    id: number
    clientId: number
  }) => void
  downloadRow: (arg1: number, arg2: number) => void
  mediaPlanTemplates: MediaPlanTemplate[]
  filterFunction: (dataIndex: keyof ITemplateListSettings, placeholderString: string) => object
}

const TemplateListTable: React.FC<IProps> = ({
  createNewTemplate,
  currentClient,
  cloneTemplate,
  deleteTemplate,
  downloadRow,
  mediaPlanTemplates,
  filterFunction
}: IProps) => {
  const navigate = useRouteNavigation()
  const buttonClick = (button: TemplateListActionType, item: MediaPlanTemplate) => {
    if (button === TemplateListActionType.EDIT) {
      navigate(`${appPaths.templateEdit}/${item.mediaPlanTemplateId}/`, false, { clientId: currentClient.id })
    } else if (button === TemplateListActionType.CLONE) {
      cloneTemplate({ id: item.mediaPlanTemplateId, clientId: currentClient.id })
    } else if (button === TemplateListActionType.DELETE) {
      deleteTemplate({ id: item.mediaPlanTemplateId, clientId: currentClient.id })
    }
  }

  if (mediaPlanTemplates && mediaPlanTemplates.length > 0) {
    const columns = [
      {
        className: 'template-name-column-sort',
        title: 'Template Name',
        dataIndex: 'templateName',
        key: 'templateName',
        sorter: (a, b) => compare(a.templateName, b.templateName),
        ...filterFunction('templateName', 'Template Name')
      },
      {
        className: 'modified-by',
        title: 'Modified By',
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
        ...filterFunction('modifiedBy', 'Modified By')
      },
      {
        className: 'modified-on-column-sort',
        title: 'Modified On',
        dataIndex: 'modifiedOn',
        key: 'modifiedOn',
        sorter: (a, b) => compare(new Date(a.modifiedOn), new Date(b.modifiedOn)),
        renderCellContent: (modifiedOn: string) => <Moment format='DD MMM YY h:mmA'>{modifiedOn}</Moment>
      },
      {
        title: '',
        dataIndex: 'buttons',
        className: 'button-cell',
        key: 'buttons'
      }
    ]

    const data = mediaPlanTemplates.map((item, i) => {
      return {
        key: i,
        templateName: item.templateName,
        modifiedBy: formatEmailToName(item.modifiedBy || item.createdBy),
        modifiedOn: item.modifiedOn,
        buttons: (
          <>
            <Tooltip title='Edit Template'>
              <Button
                type='link'
                icon={<EditOutlined />}
                onClick={() => buttonClick(TemplateListActionType.EDIT, item)}
              />
            </Tooltip>
            <Tooltip title='Clone Template'>
              <Button
                data-testid='clone-template-button'
                icon={<CopyOutlined />}
                onClick={() => buttonClick(TemplateListActionType.CLONE, item)}
                type='link'
              />
            </Tooltip>
            <Popconfirm
              placement='rightTop'
              title={popoverMessage.confirmAreYouSure}
              onConfirm={() => buttonClick(TemplateListActionType.DELETE, item)}
              okText='Yes'
              cancelText='No'
            >
              <Tooltip title='Delete Template'>
                <Button
                  data-testid='delete-row-button'
                  type='link'
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
            <Tooltip title='Download'>
              <Button
                data-testid='download-template-button'
                icon={<DownloadOutlined />}
                onClick={() => downloadRow(item.mediaPlanTemplateId, currentClient.id)}
                type='link'
              />
            </Tooltip>
          </>
        )
      }
    })

    return (
      <div className='list-table template-list-table'>
        <MSVirtualTable
          columns={columns}
          dataSource={data}
        />
      </div>
    )
  }
  return (
    <div className='list-table'>
      <p>
        No Templates for {currentClient.displayName}. <a onClick={createNewTemplate}>Create a Template</a>
      </p>
    </div>
  )
}

export default TemplateListTable
