import React, { useMemo } from 'react'
import { DeleteFilled, StarFilled } from '@ant-design/icons'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Button, Tooltip } from 'antd'
import { IMediaPlanTemplateFields, isFieldAvailableOrDefault } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import RichTextComponent from 'Components/RichTextComponent'
import RichTextWrapperComponent from 'Components/RichTextWrapperComponent'

interface IProps {
  defaultValue: string
  mediaPlanField: IMediaPlanTemplateFields
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
}

export const TemplateExamplePlanLevelRichTextFieldComponent = ({
  mediaPlanField,
  removePlanField
}: IProps) => {
  const defaultOrAvailable = isFieldAvailableOrDefault(mediaPlanField)

  const buttons = useMemo(() => (
    <>
      {defaultOrAvailable ? (
        <Tooltip title='Delete rich text field'>
          <Button
            className='btn-unset-default rich-text__header-button'
            aria-label='Rich text delete button'
            icon={<DeleteFilled />}
            tabIndex={-1}
            onClick={() => removePlanField(mediaPlanField)}
          />
        </Tooltip>
      ) : (
        <Tooltip title='Mandatory rich text field'>
          <Button
            className='btn-unset-default rich-text__header-button'
            tabIndex={-1}
            icon={<StarFilled />}
          />
        </Tooltip>
      )}
    </>
  ), [defaultOrAvailable, mediaPlanField, removePlanField])

  return (
    <RichTextWrapperComponent
      title={mediaPlanField.displayLabel}
      buttons={buttons}
    >
      <RichTextComponent
        value={mediaPlanField.defaultValue?.toString()}
        title={mediaPlanField.displayLabel}
      />
    </RichTextWrapperComponent>
  )
}

export default TemplateExamplePlanLevelRichTextFieldComponent
