
import { useMemo } from 'react'
import { useGetFieldLevelsQuery } from 'Apis/enhancedFieldLevelsApi'
import { GetFieldLevelsApiResponse } from 'Apis/generated/fieldLevelsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../redux/types'

export const useFieldLevels = (): UseQueryCommand<GetFieldLevelsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data: fieldLevels = [], isLoading } = useGetFieldLevelsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )
  const sortedFieldLevels = useMemo(() => [...fieldLevels].sort((a, b) => a.fieldLevelId - b.fieldLevelId), [fieldLevels])

  return useMemo(
    () => ({ data: sortedFieldLevels, isLoading }),
    [sortedFieldLevels, isLoading]
  )
}
