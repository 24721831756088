import React, { useState, useEffect, useCallback } from 'react'
import { PlanningStage } from 'Apis/generated/clientSettingsApi'
import { Select, Button, Tooltip } from 'antd'
import { CalculationFieldResult } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { PlanningStageOptions } from 'Constants/enums/PlanningStageOptions'
import { mediaPlanTooltipMessage } from 'Constants/enums/TooltipMessages'

const { Option } = Select

interface IProps {
  currentMediaPlan: IMediaPlanVersion
  currentPlanningStageId: number
  disabled: boolean
  planningStages: PlanningStage[]
  handleUpdatePlanStage: (selectedPlanningState: number, planningStageName: string) => Promise<CalculationFieldResult[] | undefined>
}

export const PlanningStageComponent = (props: IProps) => {
  const { currentMediaPlan, currentPlanningStageId, disabled, handleUpdatePlanStage, planningStages } = props
  const [selectedPlanningState, setSelectedPlanningStage] = useState<number>()
  const [tooltipTitle, setToolTipTitle] = useState<string>(null)
  const planningStageName = planningStages.find(c => c.planningStageId === selectedPlanningState)?.planningStageName || ''

  const validateFlights = useCallback((planningStageId: number) => {
    let isValid = true
    setToolTipTitle(null)
    if (currentMediaPlan && (planningStageId === PlanningStageOptions.Approved ||
         planningStageId === PlanningStageOptions.Finalized )) {
      const parsedData = JSON.parse(currentMediaPlan.data)
      isValid = parsedData.flightGroups.some(x => x.flights.length)

      if (!isValid) {
        setToolTipTitle(mediaPlanTooltipMessage.missingPlanFlights)
      }
    }
    return isValid
  }, [currentMediaPlan])

  useEffect(() => {
    setToolTipTitle(null)
    setSelectedPlanningStage(currentPlanningStageId)
  }, [currentPlanningStageId])

  useEffect(() => {
    validateFlights(Number(selectedPlanningState))
  }, [currentMediaPlan, selectedPlanningState, validateFlights])

  const onChangeHandler = (value) => {
    validateFlights(Number(value))
    setSelectedPlanningStage(Number(value))
  }

  const onClick = () => {
    if (validateFlights(Number(selectedPlanningState))) {
      const handleStageUpdate = async () => {
        const validationErrors = await handleUpdatePlanStage(selectedPlanningState, planningStageName)

        if (validationErrors?.length) {
          setToolTipTitle(null)
          setSelectedPlanningStage(currentPlanningStageId)
        }
      }

      handleStageUpdate()
    }
  }

  return (
    <>
      <Select
        className='ms-select'
        popupMatchSelectWidth={false}
        optionFilterProp='children'
        disabled={disabled}
        data-testid='client-select'
        onChange={onChangeHandler}
        value={planningStageName}
      >
        {
          planningStages.map((item) => (
            <Option key={item.planningStageId} value={item.planningStageId}>{item.planningStageName}</Option>
          ))
        }
      </Select>
      <Tooltip title={tooltipTitle}>
        <Button type='primary' disabled={disabled || tooltipTitle !== null} onClick={onClick}>Update Plan Stage</Button>
      </Tooltip>
    </>
  )
}

export default PlanningStageComponent

