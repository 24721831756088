import { useMemo } from 'react'
import { useGetFinanceDataBookingCategoriesQuery, GetFinanceDataBookingCategoriesApiResponse } from 'Apis/generated/financeDataApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useFinanceDataBookingCategories = ({
  clientGeographyHierarchyId,
  clientMediaHierarchyId,
  shouldSkip
}): UseQueryCommand<GetFinanceDataBookingCategoriesApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetFinanceDataBookingCategoriesQuery(
    { clientId: currentClient.id, clientGeographyHierarchyId, clientMediaHierarchyId },
    { skip: !currentClient.id || shouldSkip }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
