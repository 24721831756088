import { IClientHierarchyLevel, formatLowerCaseFirstLetter } from '@mindshare/layout'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { IMediaPlanTemplateFields, IMediaPlanTemplateFieldsExtended } from './IMediaPlanMetaFields'

const numberToBeReplaced = -1 * Number.MAX_SAFE_INTEGER + Math.random() * 10000
const stringToBeReplaced = 'ExpandedHierarchyField'

export const expandedHierarchyFlightGroupField: IMediaPlanTemplateFieldsExtended = {
  mediaPlanVersionFieldId: numberToBeReplaced,
  mediaPlanTemplateId: numberToBeReplaced,
  clientMediaPlanFieldId: numberToBeReplaced,
  expandedHierarchyTypeId: numberToBeReplaced,
  expandedHierarchyLevel: numberToBeReplaced,
  sortOrder: numberToBeReplaced,
  fieldLabel: stringToBeReplaced,
  token: stringToBeReplaced,
  templateFieldAvailabilityId: TemplateFieldAvailability.DEFAULT,
  templateFieldTypeId: TemplateFieldTypes.INTERNAL,
  isInPlan: true,
  functionId: null,
  defaultValue: '',
  calculation: null,
  isComplexCalculation: false,
  requiredHierarchyLevelId: 0,
  includeLevelsBelowHierarchy: false,
  isReadOnly: true,
  isExpandedHierarchyField: true,
  isEditable: false,
  isFooter: false,
  approvedBy: null,
  approvedOn: null,
  clientMediaPlanField: {
    clientMediaPlanFieldId: numberToBeReplaced,
    mediaPlanFieldLevelId: numberToBeReplaced,
    sortOrder: numberToBeReplaced,
    mediaPlanFieldId: 0,
    fieldLabel: 'S',
    isMandatory: false,
    isDisabled: false,
    isApportionmentField: false,
    isApportioned: false,
    approvedOn: null,
    columnName: '',
    isVisible: true,
    key: 0,
    mediaPlanField: {
      mediaPlanFieldId: numberToBeReplaced,
      mediaPlanFieldLevelId: numberToBeReplaced,
      sortOrder: numberToBeReplaced,
      fieldLabel: stringToBeReplaced,
      columnName: stringToBeReplaced,
      fieldDescription: stringToBeReplaced,
      fieldDataTypeId: 20,
      fieldLevelId: 2,
      isMandatory: false,
      defaultValue: '',
      isApportionable: false,
      isDefaultApportioned: false,
      isDefaultApportionmentField: false,
      isSelected: false,
      isVisible: false,
      key: 0,
      fieldDataType: {
        fieldDataTypeId: 20,
        dataTypeName: 'String',
        dataType: 'NVARCHAR(255)',
        label: 'Small strings for names, etc.',
        isLookupType: false,
        isClientDefinedListOrUniqueString: false,
        isHierarchy: false,
        isNumeric: false,
        isLongInt: false,
        isDecimalType: false,
        isString: true,
        isDate: false,
        cSharpDataType: 'string',
        mappingDestination: 200
      },
      fieldLevel: {
        fieldLevelId: 2,
        fieldLevelName: 'FlightGroup'
      },
      fieldTags: []
    },
    clientFieldValues: []
  }
}

const transformIdIntoUniqueTemporaryId = (id: number, hierarchyLevelNumber: number) => {
  return -1 * id * 1000 + hierarchyLevelNumber
}

export const getExpandedHierarchyFlightGroupField = (
  hierarchyField: IMediaPlanTemplateFieldsExtended | IMediaPlanTemplateFields,
  hierarchyLevel: IClientHierarchyLevel
): IMediaPlanTemplateFieldsExtended => {
  const newSortOrder = hierarchyField.sortOrder - 0.5 + hierarchyLevel.hierarchyLevelNumber * 0.01
  const thisColumnLabel = `${hierarchyField.clientMediaPlanField.mediaPlanField.columnName}${hierarchyLevel.hierarchyLevelName.split(' ').join('')}`
  const { hierarchyLevelNumber } = hierarchyLevel

  return {
    ...expandedHierarchyFlightGroupField,
    mediaPlanTemplateId: hierarchyField.mediaPlanTemplateId,
    mediaPlanVersionFieldId: transformIdIntoUniqueTemporaryId(hierarchyField.mediaPlanVersionFieldId, hierarchyLevelNumber),
    clientMediaPlanFieldId: transformIdIntoUniqueTemporaryId(hierarchyField.clientMediaPlanFieldId, hierarchyLevelNumber),
    expandedHierarchyTypeId: hierarchyField.clientMediaPlanField.mediaPlanField.fieldDataTypeId,
    expandedHierarchyLevel: hierarchyLevelNumber,
    expandedHierarchyOriginalColumnName: hierarchyField.clientMediaPlanField.mediaPlanField.columnName,
    expandedHierarchyOriginalClientMediaPlanFieldId: hierarchyField.clientMediaPlanField.clientMediaPlanFieldId,
    sortOrder: newSortOrder,
    fieldLabel: hierarchyLevel.hierarchyLevelName,
    token: `{FlightGroups.${thisColumnLabel}}`,
    clientMediaPlanField: {
      ...expandedHierarchyFlightGroupField.clientMediaPlanField,
      clientMediaPlanFieldId: transformIdIntoUniqueTemporaryId(hierarchyField.clientMediaPlanField.clientMediaPlanFieldId, hierarchyLevelNumber),
      mediaPlanFieldId: transformIdIntoUniqueTemporaryId(hierarchyField.clientMediaPlanField.mediaPlanFieldId, hierarchyLevelNumber),
      sortOrder: newSortOrder,
      mediaPlanField: {
        ...expandedHierarchyFlightGroupField.clientMediaPlanField.mediaPlanField,
        mediaPlanFieldId: transformIdIntoUniqueTemporaryId(hierarchyField.clientMediaPlanField.mediaPlanField.mediaPlanFieldId, hierarchyLevelNumber),
        mediaPlanFieldLevelId: transformIdIntoUniqueTemporaryId(hierarchyField.clientMediaPlanField.mediaPlanField.mediaPlanFieldLevelId, hierarchyLevelNumber),
        fieldLabel: thisColumnLabel,
        columnName: thisColumnLabel,
        fieldDescription: thisColumnLabel,
        sortOrder: newSortOrder
      }
    }
  }
}

export const getExpandedHierarchyValueFieldName = (hierarchyField: IMediaPlanTemplateFieldsExtended | IMediaPlanTemplateFields) => {
  return `${formatLowerCaseFirstLetter(hierarchyField.expandedHierarchyOriginalColumnName)}_${hierarchyField.expandedHierarchyLevel}`
}
