import { useMemo } from 'react'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { getPlanMasteredDataVariables } from 'Components/MediaPlanVersion/hooks/getPlanMasteredDataVariables'
import { IMediaPlanVersionGetMasteredDataValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { FieldLevelType } from 'Constants/enums/FieldLevel'

export const useMasteredDataFieldsValues = ({ fields, parseData }: {
  fields: IMediaPlanVersionField[]
  parseData: any
}): IMediaPlanVersionGetMasteredDataValues => {
  const mediaPlanMasteredHierarchyFields = useMemo(() => fields.filter((item) =>
    (item.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_PRODUCT_HIERARCHY ||
  item.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_STATION_HIERARCHY ||
  item.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.COST_BUYING_ROUTE_HIERARCHY) &&
  item.clientMediaPlanField.mediaPlanField.fieldLevel.fieldLevelId === FieldLevelType.FLIGHT_GROUP
  ), [fields])

  const masteredDataValues = useMemo(
    () => {
      return getPlanMasteredDataVariables({ mediaPlanVersionFields: fields, masteredHierarchyFields: mediaPlanMasteredHierarchyFields, parseData })
    },
    [mediaPlanMasteredHierarchyFields, parseData, fields]
  )


  return masteredDataValues
}
