import React from 'react'
import { Input, Button, Select, Tooltip } from 'antd'
import { FieldLevel } from 'Apis/generated/mediaPlanFieldsApi'
import { Function as IFunction } from 'Apis/generated/functionsApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { mediaPlanTooltipMessage } from 'Constants/enums/TooltipMessages'
import { PlanningStageOptions } from 'Constants/enums/PlanningStageOptions'

const { TextArea } = Input

interface IProps {
  expression: string
  expressionHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleCalculation: (mediaPlanTemplate: IMediaPlanTemplateFields) => void
  selectedCalculatedMediaField: IMediaPlanTemplateFields
  setExpression: any // React.Dispatch<React.SetStateAction<string>>
  updatingFromTemplate: boolean
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]
  fieldLevels: FieldLevel[]
  getFieldLabel: (mediaPlanField: IMediaPlanTemplateFields) => string
  onChangeField: (calculation: string) => void
  calculationFunctions: IFunction[]
  onFunctionSelected: (functionId: number) => void
  preserveApprovedPlans?: boolean
  planningStageId?: number
}

export const CalculationComponent: React.FC<IProps> = ({
  expression,
  expressionHandler,
  handleCalculation,
  selectedCalculatedMediaField,
  setExpression,
  updatingFromTemplate,
  mediaPlanTemplateFields,
  fieldLevels,
  getFieldLabel,
  onChangeField,
  calculationFunctions,
  onFunctionSelected,
  preserveApprovedPlans,
  planningStageId
}: IProps) => {
  const renderOptionsByFieldLevelId = (f: FieldLevel) => mediaPlanTemplateFields
    .filter(c => c.clientMediaPlanField.mediaPlanField.fieldLevelId === f.fieldLevelId)
    .map(c => <Select.Option key={JSON.stringify(c)} value={JSON.stringify(c)}>{getFieldLabel(c)}</Select.Option>)

  const functionDropDownValue = selectedCalculatedMediaField && selectedCalculatedMediaField.functionId > 0
    ? { value: selectedCalculatedMediaField.functionId }
    : undefined
  const shouldPreserveApprovedPlans = preserveApprovedPlans && planningStageId === PlanningStageOptions.Approved
  const setCalculationDisabled = updatingFromTemplate || shouldPreserveApprovedPlans
  const setCalculationTooltip = shouldPreserveApprovedPlans ? mediaPlanTooltipMessage.preserveApprovedPlans : ''

  return (
    <div className='template-example-header-calculation'>
      <div className='template-example-header-calculation__row'>
        <p>Add calculations to your media plan. Below are your flight groups that you have chosen previously,
           please choose the ones you want to create a calculation for.</p>
      </div>

      <div className='template-example-header-calculation__row'>
        <div className='template-example-header-calculation__row__cell'>
          <div className='field-selector-container' data-testid='select-field'>
            <Select
              placeholder='Select a field'
              className='field-select'
              aria-label='calculation field select'
              optionFilterProp='children'
              onChange={onChangeField}
              value={selectedCalculatedMediaField && getFieldLabel(selectedCalculatedMediaField)}
            >
              {fieldLevels.map((f, index) => (
                <Select.OptGroup key={index} label={f.fieldLevelName}>
                  {renderOptionsByFieldLevelId(f)}
                </Select.OptGroup>
              ))}
            </Select>
          </div>

          {selectedCalculatedMediaField && selectedCalculatedMediaField.templateFieldTypeId === TemplateFieldTypes.AGGREGATED && (
            <Select
              placeholder='Select a function'
              className='field-function'
              aria-label='field function select'
              optionFilterProp='children'
              showSearch={true}
              allowClear={true}
              onChange={onFunctionSelected}
              {...functionDropDownValue}
            >
              {calculationFunctions.map(c => (<Select.Option key={c.functionId} value={c.functionId}>{c.functionName}</Select.Option>))}
            </Select>
          )}

          <span className='calculations-equals'>=</span>
        </div>

        <div className='template-example-header-calculation__row__cell'>
          <TextArea
            autoSize={true}
            onBlur={expressionHandler}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setExpression(event.target.value)}
            placeholder='Enter Calculation'
            aria-label='calculation area'
            value={expression}
          />
        </div>

        <Tooltip title={setCalculationTooltip}>
          <div className='template-example-header-calculation__row__cell'>
            <Button
              className='ms-button'
              disabled={setCalculationDisabled}
              type='primary'
              onClick={() => handleCalculation(selectedCalculatedMediaField)}
            >
            Set Calculation
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default CalculationComponent
