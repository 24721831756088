import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Radio, Select, Tooltip } from 'antd'
import { DragOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { v4 as uuid } from 'uuid'
import { MSTable, isClientHierarchyType, getClientHierarchyTypeDetails, IMSHierarchies, getHierarchyTypeDetails } from '@mindshare/layout'

import { FrontSheetViewGroupBy } from 'Apis/generated/frontSheetsApi'
import { HierarchyLevel } from 'Apis/generated/hierarchiesApi'
import { FieldLevel } from 'Apis/generated/mediaPlanFieldsApi'
import { getDisplayLabel, IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import {
  frontSheetValidationError,
  isFinanceProductHierarchyType,
  isFinanceStationHierarchyType,
  isCostBuyingRouteHierarchyType
} from 'Components/shared/constants/entities/IFieldMetaData'
import { IButtonActionsGroupBy } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import { getValidationMessage } from 'Helpers/frontSheetHelper'
import { FrontSheetColumnType } from 'Constants/enums/FrontSheetColumnType'
import { SortableItem } from 'Components/shared/SortableItem'
import FrontSheetTableComponent from './FrontSheetTableComponent'

const { Option, OptGroup } = Select

const getClientHierarchyLevelName = (hierarchyLevels: HierarchyLevel[], row: FrontSheetViewGroupBy) => {
  if (typeof row.hierarchyLevelNumber === 'number') {
    return hierarchyLevels.find((l) => l.hierarchyLevelNumber === row.hierarchyLevelNumber)?.hierarchyLevelName
  }
  return null
}

const DragHandle = () => (
  <Tooltip title='Drag group by field'>
    <DragOutlined data-testid='drag-handle' style={{ cursor: 'pointer', color: '#999' }} />
  </Tooltip>
)

const columns = [
  {
    className: 'drag-visible',
    dataIndex: 'fieldName',
    key: 'fieldName',
    title: 'Field Name'
  },
  {
    className: 'drag-visible',
    dataIndex: 'hierarchyLevel',
    key: 'hierarchyLevel',
    title: 'Hierarchy Level'
  },
  {
    className: 'drag-visible subtotals-column',
    dataIndex: 'subtotals',
    key: 'subtotals',
    title: 'Subtotals',
    width: 100
  },
  {
    className: 'drag-visible button-column',
    dataIndex: 'buttons',
    key: 'buttons',
    title: '',
    width: 120
  },
  {
    className: 'drag-visible',
    dataIndex: 'drag',
    key: 'drag',
    render: () => <DragHandle />,
    title: '',
    width: 50
  }
]

interface IProps {
  buttonActions: IButtonActionsGroupBy
  clientMediaPlanFields: IClientMediaPlanField[]
  fieldLevels: FieldLevel[]
  groupByData: FrontSheetViewGroupBy[]
  hierarchies: IMSHierarchies
  laydownField: IClientMediaPlanField
  validateField: (field: IClientMediaPlanField, columnType: FrontSheetColumnType, laydownField?: IClientMediaPlanField) => frontSheetValidationError
  setGroupByValid: (valid: boolean) => void
}

export const FrontSheetGroupByTableComponent: React.FC<IProps> = ({
  buttonActions,
  clientMediaPlanFields,
  fieldLevels,
  groupByData,
  hierarchies,
  laydownField,
  validateField,
  setGroupByValid
}): React.ReactElement => {
  const dataSource = useMemo(() =>
    [...(groupByData || [])].sort((a, b) => a.sortOrder - b.sortOrder).map((row, i) => {
      const field = clientMediaPlanFields.find(x => x.clientMediaPlanFieldId === row.clientMediaPlanFieldId)
      const validation = validateField(field, FrontSheetColumnType.GROUPBY, laydownField)
      const fieldLabel = field && field.mediaPlanField.fieldLabel
      const isHierarchy = isClientHierarchyType(fieldLabel)
      const fieldDataType = field && field.mediaPlanField.fieldDataType
      const isMasteredHierarchy =
        fieldDataType &&
        (isFinanceProductHierarchyType(fieldDataType.dataTypeName) ||
          isFinanceStationHierarchyType(fieldDataType.dataTypeName) ||
          isCostBuyingRouteHierarchyType(fieldDataType.dataTypeName))
      const hierarchyDisabled = !field || !isHierarchy
      const { hierarchyLevels } = isMasteredHierarchy
        ? getHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)
        : getClientHierarchyTypeDetails(fieldLabel, hierarchies)
      const hierarchyLevelName = hierarchyDisabled ? '' : getClientHierarchyLevelName(hierarchyLevels, row)
      const hierarchyValid = !!(hierarchyDisabled || (!hierarchyDisabled && hierarchyLevelName))

      return {
        valid: validation === undefined && hierarchyValid,
        fieldName: (
          <Tooltip title={getValidationMessage(validation)}>
            <Select
              allowClear={true}
              className={`ms-select front-sheet-select-fields__select ${
                validation !== undefined
                  ? 'front-sheet-select-fields__select-invalid'
                  : ''
              }`}
              onChange={(val: string) => buttonActions.updateFieldName(i, val)}
              optionFilterProp='children'
              placeholder={'Select group by field'}
              showSearch={true}
              value={getDisplayLabel(field)}
              data-testid='front-sheet-select-group-by-field'
            >
              {fieldLevels.map((f, index) => (
                <OptGroup key={index} label={f.fieldLevelName}>
                  {clientMediaPlanFields
                    .filter(
                      c => c.mediaPlanField.fieldLevelId === f.fieldLevelId
                    )
                    .map(c => (
                      <Option
                        disabled={isCostBuyingRouteHierarchyType(
                          c.mediaPlanField?.fieldDataType?.dataTypeName
                        )}
                        key={c.clientMediaPlanFieldId}
                        value={c.clientMediaPlanFieldId}
                      >
                        {c.fieldLabel}
                      </Option>
                    ))}
                </OptGroup>
              ))}
            </Select>
          </Tooltip>
        ),
        hierarchyLevel: (
          <Tooltip
            placement='right'
            title={
              !hierarchyValid
                ? `A Hierarchy Level must be provided to go with the selected ${field?.mediaPlanField?.fieldLabel} field`
                : ''
            }
          >
            <Select
              allowClear={true}
              className={`ms-select front-sheet-select-fields__select ${
                !hierarchyValid
                  ? 'front-sheet-select-fields__select-invalid'
                  : ''
              }`}
              data-testid='hierarchy-level-select'
              disabled={hierarchyDisabled}
              onChange={(val: string) =>
                buttonActions.updateHierarchyLevel(i, Number(val))
              }
              optionFilterProp='children'
              showSearch={true}
              value={hierarchyLevelName}
            >
              {hierarchyLevels?.map((level: HierarchyLevel, j) => (
                <Option key={j} value={level.hierarchyLevelNumber}>
                  {level.hierarchyLevelName}
                </Option>
              ))}
            </Select>
          </Tooltip>
        ),
        key: i,
        itemRowId: uuid(),
        subtotals: (
          <Radio
            data-testid='subtotals-radio'
            checked={row.subtotalCutOff}
            onClick={() => buttonActions.updateSubtotal(i)}
          />
        ),
        buttons: (
          <div className='front-sheet-item-icons'>
            <Tooltip title='Add group by field'>
              <Button
                className='plus-icon btn-unset-default'
                data-testid='groupby-add-row'
                icon={<PlusCircleOutlined />}
                onClick={buttonActions.addRow}
                size='large'
                shape='circle'
              />
            </Tooltip>
            {groupByData.length > 1 && (
              <Tooltip title='Remove group by field'>
                <Button
                  className='minus-icon btn-unset-default'
                  data-testid='groupby-remove-row'
                  icon={<MinusCircleOutlined />}
                  onClick={() => buttonActions.removeRow(i)}
                  size='large'
                  shape='circle'
                />
              </Tooltip>
            )}
          </div>
        )
      }
    })
  , [buttonActions, clientMediaPlanFields, fieldLevels, groupByData, hierarchies, laydownField, validateField])

  useEffect(() => {
    const invalid = dataSource.find(x => !x.valid)
    setGroupByValid(!invalid)
  }, [dataSource, setGroupByValid])


  const onSortEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = dataSource.findIndex((i) => i.itemRowId === active.id)
      const newIndex = dataSource.findIndex((i) => i.itemRowId === over?.id)

      const newData = arrayMove([].concat(...groupByData), oldIndex, newIndex).filter(el => !!el)
      buttonActions.updateSortOrder(newData)
    }
  }, [buttonActions, dataSource, groupByData])

  const DraggableBodyRow = (containerProps) => {
    const index = dataSource?.findIndex(x => x.key === containerProps['data-row-key'])
    const item = dataSource?.find(x => x.key === containerProps['data-row-key'])

    return (
      <SortableItem
        index={index}
        item={item}
        {...containerProps}
      />
    )
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onSortEnd}>
      <SortableContext
        items={dataSource.map((i) => i.itemRowId)}
        strategy={verticalListSortingStrategy}
      >
        <FrontSheetTableComponent title='GROUP BY' data-testid="frontSheets-select-group-by-table">
          <MSTable
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            components={{
              body: {
                wrapper: (containerProps) => (
                  <tbody
                    {...containerProps}
                  />
                ),
                row: DraggableBodyRow
              }
            }}
            rowKey='key'
          />
        </FrontSheetTableComponent>
      </SortableContext>
    </DndContext>
  )
}

export default FrontSheetGroupByTableComponent
