import React, { useEffect, useState } from 'react'
import { LoadingContainer, useRouteParams, useRouteNavigation, useMindshareSelector } from '@mindshare/layout'

import { saveNewImport, updateExistingImport, initialiseCreateImportContainer } from 'Actions/importActions'
import { DataImport } from 'Apis/generated/dataImportsApi'
import { RootState } from 'Reducers/index'
import CreateImportComponent from 'Components/Import/CreateImportComponent'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { appPaths } from 'Constants/appPaths'
import { useAppDispatch, useAppSelector } from '../../store'


export const CreateImportContainer: React.FC<{ dataImportId?: number }> = ({ dataImportId }): React.ReactElement => {
  const navigate = useRouteNavigation()
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)
  const currentDataImport = useMindshareSelector((state: RootState) => state.imports.currentDataImport, null, true) as DataImport
  const [importValues, setImportValues] = useState<DataImport>({
    dataImportName: '',
    cronSchedule: '* * * * *',
    isActive: false
  })
  const [clientSelected, setClientSelected] = useState(null)

  const editMode = dataImportId > 0
  useEffect(() => {
    setClientSelected(currentClientId)
  }, [currentClientId])

  useEffect(() => {
    if (clientSelected && currentClientId !== clientSelected) {
      buttons.back()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClientId, clientSelected])

  useEffect(() => {
    if (
      editMode &&
      currentDataImport &&
      importValues.dataImportId !== currentDataImport.dataImportId
    ) {
      setImportValues({ ...currentDataImport })
    }
  }, [currentDataImport, editMode, importValues.dataImportId])

  const buttons = {
    back: () => navigate(appPaths.importList),
    save: async () => {
      if (editMode) {
        await updateExistingImport(importValues, currentClientId)
      } else {
        await saveNewImport(importValues, currentClientId)
      }
      navigate(appPaths.importList)
    }
  }

  return (
    <CreateImportComponent
      buttons={buttons}
      editMode={editMode}
      importValues={importValues}
      setImportValues={setImportValues}
    />
  )
}

export const ImportListLoadingContainer: React.FC = (props) => {
  const dataImportId = useRouteParams('dataImportId', Number)
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const clientId = useAppSelector((state) => state.app.currentClient.id)
  const dispatch = useAppDispatch()
  return (
    <LoadingContainer
      appDataSuccess={!!clientId}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={(loadingComplete) => initialiseCreateImportContainer(loadingComplete, dispatch, clientId, { dataImportId })}
    >
      <CreateImportContainer {...props} dataImportId={dataImportId} />
    </LoadingContainer>
  )
}

export default ImportListLoadingContainer
