import { sortBy, omitBy } from 'lodash'
import moment from 'moment'
import { Guid } from 'guid-typescript'
import { ILoadingResult } from '@mindshare/layout'

import { MediaPlansActions } from 'Actions/mediaPlansActions'

import { CalculationFieldResult, MediaPlanVersionForList, CalculationResults, SubtotalConfiguration, SubtotalCalculationResult } from 'Apis/generated/mediaPlanVersionsApi'
import { Redistribution } from 'Apis/generated/redistributionsApi'
import * as actionTypes from 'Constants/actionTypes'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { IMediaPlanVersion, INewUniqueStringObject } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import {
  fieldsInPlanByLevel,
  hasAvailableFields,
  IMediaPlanMetaField,
  getFieldColumnName
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightDate } from 'Components/MediaPlanVersion/constants/entities/IFlightDate'
import { IGoalSeekData } from 'Components/GoalSeek/constants/entities/IGoalSeekData'
import { IRedistribution, ISelectedRedistributeByField } from 'Components/Redistribution/constants/entities/IRedistribution'
import * as arrayHelpers from 'Helpers/arrayHelper'
import {
  cloneFlightGroup,
  createDefaultFlightGroup,
  generateFlightGroupId,
  mergeFlightGroups,
  resetFlightGroup,
  IFlightGroup
} from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { getShiftFromSourceToTarget, shiftCopiedFlightGroup } from 'Helpers/pasteHelper'
import { getFlightData } from 'Helpers/flightHelper'
import { getUserModifiableParseDataPartial } from 'Components/MediaPlanVersion/helpers/getUserModifiableParseDataPartial'

export interface IMediaPlansState {
  mediaPlanContainerLoading: ILoadingResult
  planListContainerLoading: ILoadingResult
  mediaPlans: IMediaPlans[]
  planListHistory: MediaPlanVersionForList[]
  currentMediaPlanVersion: IMediaPlanVersion
  currentMediaPlanVersionUnmodified: IMediaPlanVersion & { parseDataStringified: string }
  selectedCalculatedField: IMediaPlanMetaField
  errors: CalculationFieldResult[]
  calculationIssues: boolean
  calculationsRunning: boolean
  selectedFlights: string[]
  selectedDates: string[]
  copiedFlight: {
    flightGroupIndex: number
    flightIndex: number
  } | null
  copiedFlightData: Omit<IFlight, 'mediaPlanFlightGroupId' | 'mediaPlanFlightId'> | null
  flightPasteModalStartDate: string
  approvalDisabled: boolean
  versionControlDisabled: boolean
  invalidFlights: Array<{ flightGroupIndex: number; flightIndex: number }>
  flightGroupWarnings: Array<{ flightGroupIndex: number; hasOverlapFlights: boolean }>
  flightGroupsFieldsWidth: Array<{ mediaPlanField: IMediaPlanMetaField; width: number }>
  weekViewEnable: boolean
  currentMediaPlan: IMediaPlans
  enableCalculations: boolean
  goalSeek: {
    goalSeekModalData: IGoalSeekData
    goalSeekResult: CalculationResults
  }
  newUniqueStringObject: INewUniqueStringObject
  redistributions: Redistribution[]
  currentRedistribution: IRedistribution
  selectedRedistributeByFields: ISelectedRedistributeByField[]
  redistributeModalVisible: boolean
  calculationMessageKey?: number | string
  selectedFlightGroups: Partial<IFlightGroup>
  collapsedFlightGroupRows: number[]
  subtotals: SubtotalCalculationResult
  expandedFlightGroupRow: number
  subtotalConfiguration: SubtotalConfiguration
}

export const initialState: IMediaPlansState = {
  mediaPlanContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  planListContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  mediaPlans: [],
  planListHistory: [],
  currentMediaPlanVersion: null,
  currentMediaPlanVersionUnmodified: null,
  currentMediaPlan: null,
  errors: [],
  calculationIssues: false,
  calculationsRunning: false,
  selectedCalculatedField: {} as any,
  selectedFlights: [],
  selectedDates: [],
  copiedFlight: null,
  copiedFlightData: null,
  flightPasteModalStartDate: null,
  approvalDisabled: false,
  versionControlDisabled: false,
  flightGroupWarnings: [],
  invalidFlights: [],
  flightGroupsFieldsWidth: [],
  weekViewEnable: true,
  enableCalculations: true,
  goalSeek: {
    goalSeekModalData: {
      goal: null,
      goalInstanceId: null,
      goalField: null,
      variableInstanceId: null,
      variableField: null,
      initialVariableValue: null,
      wizardProgress: 0,
      modalVisible: false
    },
    goalSeekResult: null
  },
  newUniqueStringObject: {
    id: null,
    value: null,
    label: null
  },
  redistributions: [],
  currentRedistribution: null,
  selectedRedistributeByFields: [],
  redistributeModalVisible: false,
  calculationMessageKey: null,
  selectedFlightGroups: null,
  collapsedFlightGroupRows: [],
  subtotals: {},
  expandedFlightGroupRow: null,
  subtotalConfiguration: null
}

export default function (state: IMediaPlansState = initialState, action: MediaPlansActions): IMediaPlansState {
  switch (action.type) {
    case actionTypes.DISABLED_APPROVAL: {
      return {
        ...state,
        approvalDisabled: action.payload
      }
    }
    case actionTypes.DISABLED_VERSION_CONTROL: {
      return {
        ...state,
        versionControlDisabled: action.payload
      }
    }
    case actionTypes.SET_MEDIA_PLAN_HISTORY: {
      return {
        ...state,
        planListHistory: action.payload
      }
    }
    case actionTypes.CLEAN_FLIGHT_GROUP_WIDTHS: {
      return {
        ...state,
        flightGroupsFieldsWidth: initialState.flightGroupsFieldsWidth
      }
    }
    case actionTypes.GET_MEDIA_PLANS: {
      return {
        ...state,
        mediaPlans: action.payload
      }
    }
    case actionTypes.SET_MEDIA_PLAN_VERSION_BY_ID: {
      return {
        ...state,
        currentMediaPlanVersion: action.payload
      }
    }
    case actionTypes.SET_MEDIA_PLAN_VERSION_UNMODIFIED: {
      return {
        ...state,
        currentMediaPlanVersionUnmodified: {
          ...action.payload,
          parseDataStringified: JSON.stringify(getUserModifiableParseDataPartial(action.payload.parseData))
        }
      }
    }
    case actionTypes.SYNC_MEDIA_PLAN_VERSION_UNMODIFIED: {
      return {
        ...state,
        currentMediaPlanVersionUnmodified: {
          ...state.currentMediaPlanVersion,
          parseDataStringified: JSON.stringify(getUserModifiableParseDataPartial(state.currentMediaPlanVersion.parseData))
        }
      }
    }
    case actionTypes.RESET_MEDIA_PLAN_VERSION_UNMODIFIED: {
      return {
        ...state,
        currentMediaPlanVersionUnmodified: null
      }
    }
    case actionTypes.SET_CURRENT_MEDIA_PLAN: {
      return {
        ...state,
        currentMediaPlan: action.payload
      }
    }
    case actionTypes.SET_SELECTED_CALCULATED_FIELD: {
      return {
        ...state,
        selectedCalculatedField: action.payload
      }
    }
    case actionTypes.SET_PLAN_ERRORS: {
      return {
        ...state,
        errors: action.payload
      }
    }
    case actionTypes.CALCULATION_ISSUES: {
      return {
        ...state,
        calculationIssues: action.payload
      }
    }
    case actionTypes.CALCULATIONS_RUNNING: {
      return {
        ...state,
        calculationsRunning: action.payload
      }
    }
    case actionTypes.SET_SELECTED_FLIGHTS: {
      return {
        ...state,
        selectedFlights: action.payload
      }
    }
    case actionTypes.SET_SELECTED_DATES: {
      return {
        ...state,
        selectedDates: action.payload
      }
    }
    case actionTypes.COPY_FLIGHT: {
      if (action.payload === null) {
        return {
          ...state,
          copiedFlight: null,
          copiedFlightData: null
        }
      }

      const { selectedFlights } = state
      const allFlightGroups = state.currentMediaPlanVersion?.parseData?.flightGroups

      if (
        selectedFlights?.length !== 1 || !selectedFlights[0]
      ) {
        return state
      }

      const {
        flightData: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mediaPlanFlightId: _discardedFlightId,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mediaPlanFlightGroupId: _discardedFlightGroupId,
          ...selectedFlightData
        },
        flightGroupIndex,
        flightIndex
      } = getFlightData(allFlightGroups, selectedFlights[0])

      return {
        ...state,
        copiedFlight: {
          flightGroupIndex,
          flightIndex
        },
        copiedFlightData: selectedFlightData
      }
    }
    case actionTypes.SET_FLIGHT_PASTE_MODAL_START_DATE: {
      return {
        ...state,
        flightPasteModalStartDate: action.payload
      }
    }
    case actionTypes.SET_PLAN_FIELD_LEVEL_VALUE: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            ...action.payload
          }
        }
      }
    }
    case actionTypes.UPDATE_MEDIA_PLAN_VERSION_FIELDS: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          mediaPlanVersionFields: action.payload
        }
      }
    }
    case actionTypes.ADD_AVAILABLE_FIELD: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          mediaPlanVersionFields: hasAvailableFields(
            state.currentMediaPlanVersion.mediaPlanVersionFields,
            action.payload.clientMediaPlanField.mediaPlanField.fieldLevelId,
            state.currentMediaPlanVersion.parseData?.flightGroups[action.flightGroupIndex]?.chosenFlightFields || []
          ) &&
            !state.currentMediaPlanVersion.mediaPlanVersionFields.some(field => field.clientMediaPlanFieldId === 0 &&
            field.clientMediaPlanField.mediaPlanField.fieldLevelId === action.payload.clientMediaPlanField.mediaPlanField.fieldLevelId)
            ? [...state.currentMediaPlanVersion.mediaPlanVersionFields, action.payload] : state.currentMediaPlanVersion.mediaPlanVersionFields
        }
      }
    }
    case actionTypes.SET_PLAN_FLIGHT_GROUP_VALUE: {
      const newFlightGroups = state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) =>
        action.flightGroupIndex === i ? { ...flightGroup, ...action.payload } : flightGroup
      )
      const orderFlightGroups = [...newFlightGroups].sort((current, next) => current.sortOrder - next.sortOrder)
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: orderFlightGroups
          }
        }
      }
    }
    case actionTypes.ADD_PLAN_FLIGHT_GROUP: {
      const newFlightGroups = [...state.currentMediaPlanVersion.parseData.flightGroups, action.payload]
      const orderFlightGroups = [...newFlightGroups].sort((current, next) => current.sortOrder - next.sortOrder)
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: orderFlightGroups
          }
        }
      }
    }
    case actionTypes.COLLAPSE_FLIGHT_GROUP: {
      const isExpandAction = state.collapsedFlightGroupRows.includes(action.payload)
      return {
        ...state,
        collapsedFlightGroupRows: isExpandAction ? state.collapsedFlightGroupRows.filter(i => i !== action.payload) : [...state.collapsedFlightGroupRows, action.payload],
        expandedFlightGroupRow: isExpandAction ? state.expandedFlightGroupRow : null
      }
    }
    case actionTypes.COLLAPSE_ALL_FLIGHT_GROUP_ROWS: {
      return {
        ...state,
        collapsedFlightGroupRows: [...state.currentMediaPlanVersion.parseData?.flightGroups || [], ...action.payload].map(i => i.mediaPlanFlightGroupId),
        expandedFlightGroupRow: null
      }
    }
    case actionTypes.EXPAND_ALL_FLIGHT_GROUP_ROWS: {
      return {
        ...state,
        collapsedFlightGroupRows: []
      }
    }
    case actionTypes.DUPLICATE_FLIGHT_GROUP: {
      const currentFlightGroups = state.currentMediaPlanVersion.parseData.flightGroups
      const duplicatedFlightGroup = cloneFlightGroup(currentFlightGroups[action.payload], false, {
        mediaPlanFlightGroupId: generateFlightGroupId(currentFlightGroups)
      })
      const insertAfterCurrent = action.payload + 1
      const flightGroups = arrayHelpers
        .insert(currentFlightGroups, insertAfterCurrent, duplicatedFlightGroup)
        .map((flightGroup, index) => ({ ...flightGroup, sortOrder: index * 10 }))

      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups
          }
        }
      }
    }
    case actionTypes.INSERT_FLIGHT_GROUPS: {
      const { mediaPlanVersionFields, parseData } = state.currentMediaPlanVersion
      const flightGroupFields = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT_GROUP)
      const newFlightGroups = action.payload.flightGroups.map((copiedFlightGroup, index) => {
        const step = index + 1
        const mediaPlanFlightGroupId = generateFlightGroupId(parseData.flightGroups, step)

        return mergeFlightGroups(
          createDefaultFlightGroup(flightGroupFields, state.currentMediaPlanVersion, mediaPlanFlightGroupId),
          copiedFlightGroup,
          false
        )
      })

      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: arrayHelpers
              .insert(parseData.flightGroups, action.payload.insertAt, newFlightGroups)
              .map((flightGroup, index) => ({ ...flightGroup, sortOrder: index * 10 }))
          }
        }
      }
    }
    case actionTypes.UPSERT_FLIGHT_GROUPS: {
      const { parseData } = state.currentMediaPlanVersion
      const { flightGroups: copiedFlightGroups, flightGroupFields, flightGroupSelection } = action.payload

      let res = parseData.flightGroups

      Object.entries(flightGroupSelection).forEach(([key, values]) => {
        Object.keys(values).forEach((value) => {
          const flightGroupIndex = res.findIndex((flightGroup) =>
            flightGroup.mediaPlanFlightGroupId === Number(key)
          )
          const flightGroupFieldIndex = flightGroupFields.findIndex((field) => {
            const columnName = getFieldColumnName(field)

            return value === columnName
          })

          const upsertAt = {
            flightGroupIndex,
            flightGroupFieldIndex
          }

          copiedFlightGroups.forEach((copiedFlightGroup, index) => {
            const shiftedCopy = shiftCopiedFlightGroup(
              copiedFlightGroup,
              flightGroupFields,
              getShiftFromSourceToTarget(copiedFlightGroup, upsertAt, flightGroupFields)
            )

            const flightGroup = res[upsertAt.flightGroupIndex + index] ?? createDefaultFlightGroup(
              flightGroupFields,
              state.currentMediaPlanVersion,
              generateFlightGroupId(parseData.flightGroups, index + 1)
            )

            const updatedFlightGroup = mergeFlightGroups(flightGroup, shiftedCopy, true)

            res = arrayHelpers.update(res, upsertAt.flightGroupIndex + index, updatedFlightGroup)
          })
        })
      })

      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: res.map((flightGroup, index) => {
              return { ...flightGroup, sortOrder: index * 10 }
            })
          }
        }
      }
    }
    case actionTypes.FILL_FLIGHT_GROUPS: {
      const { flightGroups } = action.payload

      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map(currentFlightGroup => {
              const updatedFlightGroup = flightGroups.find(newFlightGroup => newFlightGroup.mediaPlanFlightGroupId === currentFlightGroup.mediaPlanFlightGroupId)
              return updatedFlightGroup ?? currentFlightGroup
            })
          }
        }
      }
    }
    case actionTypes.DELETE_FLIGHT_GROUP: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.filter((c, index) => index !== action.payload)
          }
        }
      }
    }
    case actionTypes.RESET_FLIGHT_GROUPS: {
      const { payload: selection } = action
      const { mediaPlanVersionFields, parseData } = state.currentMediaPlanVersion

      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: parseData.flightGroups.map((flightGroup, index) => {
              const flightGroupSelection = selection[flightGroup.mediaPlanFlightGroupId]
              const sortOrder = index * 10

              if (flightGroupSelection) {
                return {
                  ...resetFlightGroup(
                    fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT_GROUP),
                    state.currentMediaPlanVersion,
                    flightGroup,
                    flightGroupSelection
                  ),
                  sortOrder
                }
              }

              return { ...flightGroup, sortOrder }
            })
          }
        }
      }
    }
    case actionTypes.BUILD_FLIGHT_GRID: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: action.payload
          }
        }
      }
    }
    case actionTypes.MERGE_FLIGHT_PLAN: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, index) =>
              action.flightGroupIndex === index ? { ...flightGroup, flights: action.flights } : flightGroup
            )
          }
        }
      }
    }
    case actionTypes.MERGE_SUB_FLIGHT_PLAN: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, index) =>
              action.flightGroupIndex === index ? { ...flightGroup, flights: action.flights } : flightGroup
            )
          }
        }
      }
    }
    case actionTypes.SET_PLAN_FLIGHT_LEVEL_VALUE: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) => {
              if (action.flightGroupIndex === i) {
                let newFlightGroup = {
                  ...flightGroup,
                  flights: flightGroup.flights.map((flight, index) =>
                    action.flightIndex === index ? { ...flight, ...action.payload } : flight
                  )
                }
                if (state.currentMediaPlanVersion.parseData.calendarView === 'table') {
                  newFlightGroup = {
                    ...newFlightGroup,
                    flights: sortBy(newFlightGroup.flights, ['flightStartDate'])
                  }
                }
                return newFlightGroup
              } else {
                return flightGroup
              }
            })
          }
        }
      }
    }
    case actionTypes.SET_PLAN_SUB_FLIGHT_LEVEL_VALUE: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) => {
              if (action.flightGroupIndex === i) {
                return {
                  ...flightGroup,
                  flights: flightGroup.flights.map((flight, index) => {
                    if (action.flightIndex === index) {
                      return {
                        ...flight,
                        subFlights: flight.subFlights.map((subFlight, subFlightIndex) => {
                          if (action.subFlightIndex === subFlightIndex) {
                            return { ...subFlight, ...action.payload }
                          } else {
                            return subFlight
                          }
                        })
                      }
                    } else {
                      return flight
                    }
                  })
                }
              } else {
                return flightGroup
              }
            })
          }
        }
      }
    }
    case actionTypes.DELETE_MEDIAPLAN_FLIGHT: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) => {
              if (action.flightGroupIndex === i) {
                return {
                  ...flightGroup,
                  flights: [
                    ...flightGroup.flights.slice(0, action.flightIndex),
                    ...action.flights,
                    ...flightGroup.flights.slice(action.flightIndex + 1)
                  ]
                }
              } else {
                return flightGroup
              }
            })
          }
        }
      }
    }
    case actionTypes.ADD_CHOSEN_FLIGHT_FIELD: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) =>
              action.flightGroupIndex === i ? { ...flightGroup, chosenFlightFields: [...flightGroup.chosenFlightFields, action.payload] } : flightGroup
            )
          }
        }
      }
    }
    case actionTypes.DELETE_MEDIAPLAN_SUB_FLIGHT: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) => {
              if (action.flightGroupIndex === i) {
                return {
                  ...flightGroup,
                  flights: flightGroup.flights.map((flight, index) => {
                    if (action.flightIndex === index) {
                      return {
                        ...flight,
                        subFlights: [
                          ...flight.subFlights.slice(0, action.subFlightIndex),
                          ...action.subFlights,
                          ...flight.subFlights.slice(action.subFlightIndex + 1)
                        ]
                      }
                    } else {
                      return flight
                    }
                  })
                }
              } else {
                return flightGroup
              }
            })
          }
        }
      }
    }
    case actionTypes.ADD_FLIGHT_TABLE_VIEW: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, index) =>
              index === action.flightGroupIndex ? { ...flightGroup, flights: [...flightGroup.flights, action.payload] } : flightGroup
            )
          }
        }
      }
    }
    case actionTypes.REMOVE_FLIGHT_TABLE_VIEW: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, index) =>
              index === action.flightGroupIndex
                ? { ...flightGroup, flights: flightGroup.flights.filter((c) => c.mediaPlanFlightId !== action.payload.mediaPlanFlightId) }
                : flightGroup
            )
          }
        }
      }
    }
    case actionTypes.DUPLICATE_FLIGHT_TABLE_VIEW: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, index) => {
              if (index === action.flightGroupIndex) {
                const currentFlight = flightGroup.flights.find(flight => flight.mediaPlanFlightId === action.payload.mediaPlanFlightId)
                const newFlight = {
                  ...currentFlight,
                  flightStartDate: null,
                  flightEndDate: null,
                  mediaPlanFlightId: -1 * Math.floor(Math.random() * 10000),
                  subFlights: []
                }
                return { ...flightGroup, flights: [...flightGroup.flights, newFlight] }
              } else {
                return flightGroup
              }
            })
          }
        }
      }
    }
    case actionTypes.VALIDATE_OVERLAP_FLIGHTS: {
      return {
        ...state,
        flightGroupWarnings: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, flightGroupIndex) => {
          if (action.flightGroupIndex === flightGroupIndex) {
            const visibleFlights = flightGroup.flights.filter(x => x.mediaPlanFlightId)
            const hasOverlapFlights = visibleFlights.some((flightDate, index, dates) => {
              if (index + 1 < dates.length) {
                const currentStartDate = moment.utc(flightDate.flightStartDate)
                const nextStartDate = moment.utc(dates[index + 1].flightStartDate)
                const currentEndDate = moment.utc(flightDate.flightEndDate)
                const nextEndDate = moment.utc(dates[index + 1].flightEndDate)
                return currentStartDate.isSame(nextStartDate) ||
                  currentEndDate.isSame(nextEndDate) ||
                  currentEndDate.isSame(nextStartDate) ||
                  nextStartDate.isBefore(currentEndDate) ||
                  currentStartDate.isAfter(nextStartDate) ||
                  nextEndDate.isBefore(currentEndDate)
              } else {
                return false
              }
            })

            return { flightGroupIndex, hasOverlapFlights }
          } else {
            return { flightGroupIndex, hasOverlapFlights: false }
          }
        })
      }
    }
    case actionTypes.PASTE_FLIGHT_TABLE_VALUE: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          parseData: {
            ...state.currentMediaPlanVersion.parseData,
            flightGroups: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, i) => {
              if (action.flightGroupIndex === i) {
                let newFlightGroup = {
                  ...flightGroup,
                  flights: flightGroup.flights.map((flight, index) =>
                    action.flightIndex === index ? action.payload : flight
                  )
                }
                return newFlightGroup = {
                  ...newFlightGroup,
                  flights: sortBy(newFlightGroup.flights, ['flightStartDate'])
                }
              } else {
                return flightGroup
              }
            })
          }
        }
      }
    }
    case actionTypes.VALIDATE_FLIGHT_DATE: {
      return {
        ...state,
        invalidFlights: state.currentMediaPlanVersion.parseData.flightGroups.map((flightGroup, flightGroupIndex) => {
          if (action.flightGroupIndex === flightGroupIndex) {
            const flightDate: IFlightDate = flightGroup.flights[action.flightIndex]
            if (flightDate.flightStartDate !== null &&
              flightDate.flightEndDate !== null &&
              moment(flightDate.flightStartDate).isAfter(moment(flightDate.flightEndDate))) {
              return { flightIndex: action.flightIndex, flightGroupIndex }
            }
            return undefined
          }
          return undefined
        }).filter(c => c)
      }
    }
    case actionTypes.CLEAN_FLIGHT_GROUP_WARNINGS: {
      return {
        ...state,
        flightGroupWarnings: []
      }
    }
    case actionTypes.CLEAN_INVALID_FLIGHTS: {
      return {
        ...state,
        invalidFlights: []
      }
    }
    case actionTypes.WEEK_MODE_ENABLED: {
      return {
        ...state,
        weekViewEnable: action.payload
      }
    }
    case actionTypes.TOGGLE_CALCULATIONS: {
      return {
        ...state,
        enableCalculations: action.payload
      }
    }
    case actionTypes.TOGGLE_GOAL_SEEK_MODAL_VISIBLE: {
      return {
        ...state,
        goalSeek: {
          ...state.goalSeek,
          goalSeekModalData: {
            ...state.goalSeek.goalSeekModalData,
            modalVisible: !state.goalSeek.goalSeekModalData.modalVisible
          }
        }
      }
    }
    case actionTypes.UPDATE_GOAL_SEEK_MODAL_STATE: {
      return {
        ...state,
        goalSeek: {
          ...state.goalSeek,
          goalSeekModalData: action.payload
        }
      }
    }
    case actionTypes.SAVE_NEW_UNIQUE_STRING: {
      return {
        ...state,
        newUniqueStringObject: action.payload
      }
    }
    case actionTypes.GET_REDISTRIBUTIONS: {
      return {
        ...state,
        redistributions: [...action.payload].sort((a, b) => a.redistributionName.localeCompare(b.redistributionName))
      }
    }
    case actionTypes.UPDATE_REDISTRIBUTION: {
      return {
        ...state,
        redistributions: state.redistributions.map(redistribution =>
          redistribution.redistributionId === action.payload.redistributionId
            ? { ...redistribution, ...action.payload }
            : redistribution
        )
      }
    }
    case actionTypes.DELETE_REDISTRIBUTION: {
      return {
        ...state,
        redistributions: state.redistributions.filter(redistribution => redistribution.redistributionId !== action.payload)
      }
    }
    case actionTypes.SET_CURRENT_REDISTRIBUTION: {
      return {
        ...state,
        currentRedistribution: action.payload
      }
    }
    case actionTypes.SET_SELECTED_REDISTRIBUTE_BY_FIELD: {
      return {
        ...state,
        selectedRedistributeByFields: arrayHelpers.push(state.selectedRedistributeByFields, action.payload)
      }
    }

    case actionTypes.REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD: {
      return {
        ...state,
        selectedRedistributeByFields: state.selectedRedistributeByFields.filter((item) => item.instanceId !== action.payload)

      }
    }
    case actionTypes.CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS: {
      return {
        ...state,
        selectedRedistributeByFields: initialState.selectedRedistributeByFields
      }
    }
    case actionTypes.SET_REDISTRIBUTE_MODAL_VISIBLE: {
      return {
        ...state,
        redistributeModalVisible: action.payload
      }
    }
    case actionTypes.SET_CALCULATION_MESSAGE_KEY: {
      return {
        ...state,
        calculationMessageKey: action.payload
      }
    }
    case actionTypes.EXPAND_FLIGHT_GROUP_ROW: {
      const filteredCollapsedFlightGroupRows = state.collapsedFlightGroupRows.filter(i => i !== action.payload)
      return {
        ...state,
        collapsedFlightGroupRows: state.expandedFlightGroupRow ? [...filteredCollapsedFlightGroupRows, state.expandedFlightGroupRow] : [...filteredCollapsedFlightGroupRows],
        expandedFlightGroupRow: action.payload
      }
    }
    case actionTypes.REPLACE_SINGLE_FLIGHT_GROUP_SELECTION: {
      const selectedFlightGroups: IFlightGroup[] = {
        ...omitBy(state.selectedFlightGroups, (flightGroup: IFlightGroup) => (
          flightGroup.mediaPlanFlightGroupId === action.flightGroupId
        )),
        ...(action.payload ? {
          [action.flightGroupId]: action.payload
        } : {})
      }

      return {
        ...state,
        selectedFlightGroups
      }
    }
    case actionTypes.REPLACE_FLIGHT_GROUPS_SELECTION: {
      return {
        ...state,
        selectedFlightGroups: action.payload
      }
    }
    case actionTypes.CLEAR_FLIGHT_GROUP_SELECTION: {
      return {
        ...state,
        selectedFlightGroups: null
      }
    }
    case actionTypes.SET_PLAN_SUBTOTALS: {
      return {
        ...state,
        subtotals: action.payload
      }
    }
    case actionTypes.SET_SUBTOTAL_CONFIGURATIONS: {
      return {
        ...state,
        currentMediaPlanVersion: {
          ...state.currentMediaPlanVersion,
          mediaPlan: {
            ...state.currentMediaPlanVersion?.mediaPlan,
            subtotalConfiguration: action.payload
              ? {
                ...state.currentMediaPlanVersion?.mediaPlan
                  ?.subtotalConfiguration,
                ...action.payload,
                subtotalConfigurationFields: action.payload.subtotalConfigurationFields
                  .filter(field => field.clientMediaPlanFieldId)
                  .map((field, i) => ({ ...field, sortOrder: i }))
              }
              : null
          }
        }
      }
    }
    default:
      return state
  }
}
