import React, { useCallback, useMemo, useState } from 'react'
import { Button, message, Popconfirm, Select, Space, Spin } from 'antd'
import { MSClientHierarchySelection, useMindshareSelector } from '@mindshare/layout'

import { setMediaPlanVersionById, updateMediaPlan } from 'Actions/mediaPlansActions'
import { Client } from 'Apis/generated/clientsApi'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { InfoCircleOutlined } from '@ant-design/icons'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import ModalComponent from 'Components/ModalComponent'
import { useFilteredHierarchies } from 'Hooks/CustomHooks'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { useAppDispatch } from '../../store'
import { RootState } from '../../redux/reducers'

interface IMediaPlanEditModalProps {
  visible: boolean
  hideModal: () => void
  closable?: boolean
  mediaPlan: IMediaPlans
  mediaPlanVersion: IMediaPlanVersion
  mediaPlanTemplate: MediaPlanTemplate
}

export const MediaPlanEditModalContainer = ({
  visible,
  hideModal,
  closable = true,
  mediaPlan,
  mediaPlanVersion,
  mediaPlanTemplate
}: IMediaPlanEditModalProps) => {
  const dispatch = useAppDispatch()
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  const { data: clientSettings } = useClientSettings()

  const [planYear, setPlanYear] = useState<number>(mediaPlan?.planYear)
  const [changeMade, setChangeMade] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const { filteredHierarchies: hierarchies } = useFilteredHierarchies(
    mediaPlanTemplate?.brandHierarchyId,
    mediaPlanTemplate?.businessHierarchyId,
    mediaPlanTemplate?.geographyHierarchyId,
    mediaPlanTemplate?.mediaHierarchyId
  )

  const updatedPlan = useMemo(() => ({
    ...mediaPlan,
    planYear
  }), [mediaPlan, planYear])

  const updateValues = useCallback(async () => {
    try {
      setSaveInProgress(true)

      await dispatch(updateMediaPlan(updatedPlan, currentClient.id))
      await dispatch(setMediaPlanVersionById(updatedPlan.mediaPlanVersionId, currentClient.id, true))

      hideModal()
    } catch (err) {
      message.error(err.httpResponseBody.split('\r\n').shift())
    } finally {
      setSaveInProgress(false)
    }
  }, [dispatch, currentClient.id, updatedPlan, hideModal])

  const saveButtonDisabled = saveInProgress || !changeMade || !planYear

  const isLatestVersion = mediaPlanVersion?.mediaPlan?.latestMediaPlanVersionId === mediaPlanVersion?.mediaPlan?.mediaPlanVersionId

  const handleChange = useCallback((value: number) => {
    setChangeMade(true)
    setPlanYear(value)
  }, [])

  return (
    <ModalComponent
      title='Update plan'
      open={visible}
      onCancel={hideModal}
      maskClosable={closable}
      width={800}
      modalBody={
        <>
          <h5 className='title informational-container'>
            <InfoCircleOutlined />
            <span className='informational-container__text'>
              Hierarchy values displayed here are defined by the latest version
              of the plan and can be changed from the latest version page
            </span>
          </h5>

          <div
            className='hierarchies-container'
            data-testid='media-plan-hierarchies-modal--body'
          >
            <MSClientHierarchySelection
              disabled={{
                brand: true,
                business: true,
                geography: true,
                media: true
              }}
              hierarchies={hierarchies}
              onChange={null}
              selectedGeography={
                isLatestVersion
                  ? mediaPlanVersion?.parseData?.geographyHierarchyValue
                  : mediaPlan?.geographyHierarchyId
              }
              selectedMedia={
                isLatestVersion
                  ? mediaPlanVersion?.parseData?.mediaHierarchyValue
                  : mediaPlan?.mediaHierarchyId
              }
              selectedBusiness={
                isLatestVersion
                  ? mediaPlanVersion?.parseData?.businessHierarchyValue
                  : mediaPlan?.businessHierarchyId
              }
              selectedBrand={
                isLatestVersion
                  ? mediaPlanVersion?.parseData?.brandHierarchyValue
                  : mediaPlan?.brandHierarchyId
              }
              showLabels={true}
            />

            <div>
              <label aria-label='hierarchy-dropdown__label--year'>Year *</label>
              <Select
                allowClear={true}
                className='select-mediaplan-year'
                onChange={handleChange}
                optionFilterProp='children'
                placeholder='Select a plan year'
                showSearch={true}
                value={planYear}
              >
                {clientSettings &&
                  clientSettings.availablePlanYears &&
                  clientSettings.availablePlanYears.map(year => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        </>
      }
      footer={
        <Space>
          {closable && (
            <Button disabled={saveInProgress} onClick={hideModal}>
              Cancel
            </Button>
          )}
          <Popconfirm
            placement='bottom'
            title={popoverMessage.confirmUpdateMediaPlanUnsavedChanges}
            onConfirm={updateValues}
            okText='Yes'
            cancelText='No'
            disabled={saveButtonDisabled}
          >
            <Button
              type="primary"
              disabled={saveButtonDisabled}
              onClick={() => {}}
              aria-label='Update Plan'
            >
              {saveInProgress ? (
                <Spin data-testid='edit-modal-update-loader' />
              ) : (
                'Update plan'
              )}
            </Button>
          </Popconfirm>
        </Space>
      }
    />
  )
}

export default MediaPlanEditModalContainer
