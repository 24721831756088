import React from 'react'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FieldAvailableComponent from './FieldAvailableComponent'
import RichTextWrapperComponent from './RichTextWrapperComponent'

interface IRichTextAvailableComponentProps {
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  onAvailableFieldSelected: (c: string) => void
}

export default function RichTextAvailableComponent ({ availableMediaPlanFields, onAvailableFieldSelected }: IRichTextAvailableComponentProps) {
  return (
    !!availableMediaPlanFields.length && (
      <RichTextWrapperComponent title='Available Fields'>
        <FieldAvailableComponent
          onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue)}
          availableMediaPlanFields={availableMediaPlanFields}
        />
      </RichTextWrapperComponent>
    )
  )
}

