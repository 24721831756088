import { CustomExport, CustomExportControlField } from 'Apis/generated/customExportsApi'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import { CurrencyDisplay, DisplayTypes } from '../../enums'
import { initialSortByField, ISortByField } from './ISortByField'
import { initialDisplayField, IDisplayField } from './IDisplayField'
import { IFilterField } from './IFilterField'

export type ICustomExport = Omit<
CustomExport,
'modifiedOn' | 'modifiedBy' | 'deletedOn' | 'deletedBy' | 'customExportFilters' | 'customExportDisplayFields' | 'customExportSortByFields'
> & {
  customExportFilters?: IFilterField[]
  customExportDisplayFields?: IDisplayField[]
  customExportSortByFields?: ISortByField[]
}

export interface ICustomExportControlFieldExtended extends Omit<CustomExportControlField, 'fieldDataType'> {
  fieldDataType?: FieldDataTypeRead
}

export const sampleCustomExport: ICustomExport = {
  customExportId: 0,
  customExportCurrencyDisplayId: CurrencyDisplay.Local,
  customExportDisplayTypeId: DisplayTypes.Excel,
  customExportLatestFlagId: 0,
  geographyHierarchyId: 0,
  customExportName: '',
  customExportDistinct: false,
  customExportFullHierarchies: false,
  customExportTemplateLocationUrl: null,
  fieldDataTypeOperatorId: 0,
  customExportPlanningDateStartDate: undefined,
  customExportPlanningDateEndDate: undefined,
  customExportFilters: [],
  customExportDisplayFields: [initialDisplayField],
  customExportSortByFields: [initialSortByField]
}
