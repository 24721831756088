
import { useMemo } from 'react'
import { useGetFieldDataTypesQuery } from 'Apis/enhancedFieldDataTypesApi'
import { GetFieldDataTypesApiResponse } from 'Apis/generated/fieldDataTypesApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../redux/types'

export const useFieldDataTypes = (): UseQueryCommand<GetFieldDataTypesApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data = [], isLoading } = useGetFieldDataTypesQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
