/* eslint-disable max-len */
export enum popoverMessage {
  confirmAreYouSure = 'Are you sure?',
  confirmDeleteFrontSheet = 'Delete Front Sheet?',
  confirmDeletePlan = 'Delete plan?',
  confirmRemovePlanFromReport = 'Are you sure you want to remove this plan from the reports, please note - this will set the planning stage to \'Draft\'',
  confirmClearFlight = 'Are you sure you would like to clear all selected data?',
  confirmDeleteCustomExport = 'Delete export?',
  confirmDeleteView = 'Are you sure to delete this view?',
  confirmUnsavedChangesWillBeLost = 'All unsaved changes will be lost. Are you sure?',
  confirmUpdateMediaPlanUnsavedChanges = 'This action will cause any unsaved changes to be lost and may clear existing hierarchy inputs. It is recommended you save your current version before continuing. Happy to proceed?',
  confirmUpdateConnectionFieldsValues = 'At least one value needs to be selected. Are you sure you want to continue, please note - this will set all values to be selected',
  confirmUpdateClientDefinedList = 'Changes to list values will affect associated linked lookups. Do you want to proceed?',
  confirmCopyRedistribution = 'Would you like to copy the redistribution setup when cloning this plan?'
}
