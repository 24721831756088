import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import rootReducer from 'Reducers/index'
import { baseApi } from 'Apis/generated/baseApi'

// TODO - need to remove immutableCheck: false and fix warnings
const middleware = (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
  .concat(baseApi.middleware)

const store = configureStore({
  reducer: rootReducer,
  middleware
})

const testMiddleware = (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
  .concat(baseApi.middleware)

export const testStore = () => configureStore({
  reducer: rootReducer,
  middleware: testMiddleware
})

// Infer the `AppState` and `TypedDispatch` types from the store itself
type AppState = ReturnType<typeof rootReducer>
type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>

export const useAppDispatch = () => useDispatch<TypedDispatch<AppState>>()

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export default store
