import React, { useState, useCallback, useEffect } from 'react'
import { Button, Space, Typography, Row, Col, List, Popconfirm, Tree, Checkbox } from 'antd'

import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { expandHierarchies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { defaultContainerHeight } from 'Components/Redistribution/constants/redistribution'
import ModalComponent from 'Components/ModalComponent'
import { UseLinkedLookup } from 'Components/LinkedLookup/hooks/useLinkedLookupConnectionValues'
import { TreeDataNode } from 'Components/LinkedLookup/containers/LinkedLookupFieldContainer'
import { getSelectedChildrenNodes, getFilteredDataSource, getExpandedKeys, getSelectedChildrenNodesByLookupValue } from 'Components/LinkedLookup/helpers/hierarchyHelper'

const { Paragraph, Text } = Typography

interface IProps {
  visible: boolean
  hideModal: () => void
  currentFieldId: number
  linkedLookupModel: UseLinkedLookup
  dataSource?: TreeDataNode[]
  closable?: boolean
  referencedField: IMediaPlanFieldExtended
}

export const LinkedLookupEditFieldModalContainer = ({ visible, hideModal, currentFieldId, linkedLookupModel, referencedField, dataSource = [], closable = true }: IProps) => {
  const typeIsHierarchy = (referencedField?.fieldDataType as FieldDataTypeRead)?.isHierarchy
  const dataTypeName = (referencedField?.fieldDataType as FieldDataTypeRead)?.dataTypeName
  const availableData = typeIsHierarchy
    ? expandHierarchies(
      { hierarchyList: dataSource },
      dataTypeName
    )
    : dataSource

  const [selectedValues, setSelectedValues] = useState<TreeDataNode[]>(
    typeIsHierarchy
      ? getSelectedChildrenNodesByLookupValue(dataSource, linkedLookupModel.values.linkedLookupValues, currentFieldId, false)
      : availableData.filter(item =>
        linkedLookupModel.values.linkedLookupValues?.some(
          value =>
            item.value === value.referencedValueId &&
          value.dependentValueId === currentFieldId
        )
      )
  )

  const areAllValuesChecked = availableData.filter(
    d => selectedValues.find(s => s.value === d.value)
  ).length === availableData.length
  const filteredDataSource = typeIsHierarchy && getFilteredDataSource(dataSource, selectedValues)

  useEffect(() => {
    if (!selectedValues.length) {
      setAllValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setAllValues = useCallback(() => {
    setSelectedValues(availableData)
  }, [availableData])

  const handleHierarchyChange = useCallback((checkedKeys, info) => {
    const newItem = { title: info.node.title, value: info.node.value, key: info.node.value }
    const parentRootId = info.node.data.rootId
    const hasParent = parentRootId !== info.node.data.id
    const isParent = !!info.node.data.children.length
    const parentNode = dataSource.find(node => node.value === parentRootId)
    if (info.checked) {
      let newValues = [...selectedValues, newItem]
      if (hasParent) {
        const hasParentAllSelected = parentNode.children.filter(
          node => checkedKeys.includes(node.key) || node.key === newItem.value
        ).length === parentNode.children.length
        if (hasParentAllSelected) {
          const selectedChildren = getSelectedChildrenNodes(parentNode.children, checkedKeys)
          newValues = newValues.filter(v => !selectedChildren.map(n => n.key).includes(v.value))
          newValues = [...newValues, parentNode, ...selectedChildren]
        } else {
          const selectedChildren = getSelectedChildrenNodes(parentNode.children, checkedKeys)
          newValues = newValues.filter(v => !selectedChildren.map(n => n.key).includes(v.value))
          newValues = [...newValues, ...selectedChildren]
        }
      } else if (isParent) {
        const selectedChildren = getSelectedChildrenNodes(parentNode.children, checkedKeys)
        newValues = newValues.filter(v => !selectedChildren.map(n => n.key).includes(v.value))
        newValues = [...newValues, ...selectedChildren]
      }
      setSelectedValues(newValues)
    } else {
      let newValues = selectedValues.filter((v) => v.value !== newItem.value)
      if (hasParent) {
        newValues = newValues.filter(v => checkedKeys.includes(v.value))
      } else if (isParent) {
        const childrenKeys = getExpandedKeys(parentNode.children)
        newValues = newValues.filter(v => !childrenKeys.includes(v.value))
      }
      setSelectedValues(newValues)
    }
  }, [selectedValues, dataSource])

  const handleChange = useCallback((checked, item) => {
    if (checked) {
      setSelectedValues([...selectedValues, item])
    } else {
      setSelectedValues(selectedValues.filter((v) => v.value !== item.value))
    }
  }, [selectedValues])

  const handleClick = useCallback(() => {
    if (!areAllValuesChecked) {
      const getLinkedLookupValueId = (lookedupValue: TreeDataNode) =>
        linkedLookupModel.values.linkedLookupValues
          .find(
            (item) => item.dependentValueId === currentFieldId &&
              item.referencedValueId === lookedupValue.value
          )?.linkedLookupValueId || 0

      const selectedFieldValues = selectedValues.map((item) => ({
        dependentValueId: currentFieldId,
        referencedValueId: item.value,
        includeChildrenOnReferencedSide: true,
        linkedLookupValueId: getLinkedLookupValueId(item)
      }))

      linkedLookupModel.setFieldValue(selectedFieldValues, currentFieldId, 'linkedLookupValues')
    } else {
      linkedLookupModel.setFieldValue([], currentFieldId, 'linkedLookupValues')
    }
  }, [areAllValuesChecked, currentFieldId, linkedLookupModel, selectedValues])

  const handleConfirm = () => {
    setAllValues()
    linkedLookupModel.setFieldValue([], currentFieldId, 'linkedLookupValues')
  }

  return (
    <ModalComponent
      title='Edit Connections'
      centered={true}
      open={visible}
      onCancel={hideModal}
      maskClosable={closable}
      width={800}
      className='linked-lookup-edit-modal'
      wrapProps={{ 'data-testid': 'linked-lookup-edit-modal' }}
      modalBody={(
        <>
          <Text><b>Valid For:</b></Text>
          <Row gutter={[20, 16]}>
            <Col span={12}>
              {areAllValuesChecked ? (
                <Paragraph className='linked-lookup-edit-value'>All values</Paragraph>
              )
                : typeIsHierarchy ? (
                  <Tree
                    treeData={filteredDataSource}
                    style={{ height: defaultContainerHeight }}
                    className="linked-lookup-edit-modal-hierarchy-select"
                  />
                )
                  : (
                    <List
                      bordered={true}
                      dataSource={selectedValues}
                      style={{ height: defaultContainerHeight }}
                      renderItem={(item) =>
                        <List.Item key={item.value} data-testid='linked-lookup-edit-value'>
                          <Text>{item.title}</Text>
                        </List.Item>
                      }
                    />
                  )}
            </Col>
            <Col span={12}>
              {typeIsHierarchy ? <Tree
                treeData={dataSource}
                checkable={true}
                onCheck={handleHierarchyChange}
                checkedKeys={selectedValues.map(value => value.value)}
                style={{ height: defaultContainerHeight }}
                className="linked-lookup-edit-modal-hierarchy-select"
              />
                : <List
                  bordered={true}
                  dataSource={dataSource}
                  style={{ height: defaultContainerHeight }}
                  renderItem={(item) => {
                    const checkedValues = !!selectedValues.find(value => value.value === item.value)

                    return (
                      <List.Item key={item.value}>
                        <Checkbox
                          className='linked-lookup-edit-checkbox'
                          onChange={e => {
                            handleChange(
                              e.target.checked,
                              item
                            )
                          }}
                          checked={checkedValues}>
                          {item.title}
                        </Checkbox>
                      </List.Item>
                    )
                  }
                  }
                ></List>}
              <Space style={{ marginTop: 10 }}>
                <Button onClick={setAllValues}>Select all</Button>
                <Button onClick={() => setSelectedValues([])}>Unselect all</Button>
              </Space>
            </Col>
          </Row>
        </>)}
      footer={(
        <Space>
          {closable && (
            <Button onClick={hideModal}>
              Cancel
            </Button>
          )}
          <Popconfirm
            placement='bottom'
            title={popoverMessage.confirmUpdateConnectionFieldsValues}
            onConfirm={() => {
              handleConfirm()
              hideModal()
            }}
            okText='Yes'
            cancelText='No'
            disabled={!!selectedValues.length}
          >
            <Button onClick={() => {
              if (selectedValues.length) {
                hideModal()
                handleClick()
              }
            }} type='primary'>
              Save
            </Button>
          </Popconfirm>
        </Space>
      )}
    />
  )
}

export default LinkedLookupEditFieldModalContainer
