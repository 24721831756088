import React, { useCallback, useMemo, useRef } from 'react'
import { Modal } from 'antd'
import isEmpty from 'lodash/isEmpty'
import xor from 'lodash/xor'
import { isNumber, isBlank } from '@mindshare/layout'
import * as mediaPlanActions from 'Actions/mediaPlansActions'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFieldColumnName, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { useClipboard } from 'Hooks/useClipboard'
import { useValueRef } from 'Hooks/useValueRef'
import {
  createFlightGroupRangeSelection,
  createFlightGroupSelection,
  createRangeFromPoints,
  IPoint
} from 'Helpers/selectionHelper'
import { pick } from 'Helpers/objectHelper'
import {
  canPasteFlightGroup,
  getShiftDistance,
  getStartOfCopy
} from 'Helpers/pasteHelper'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { useAppDispatch } from '../store'
import { IMasteredListsData } from './useMasteredListFieldsData'

export const useFlightGroupCommands = ({
  flightGroups,
  flightGroupFields,
  masteredListsData
}: {
  flightGroups: IFlightGroup[]
  flightGroupFields: IMediaPlanTemplateFields[]
  masteredListsData: IMasteredListsData
}) => {
  const dispatch = useAppDispatch()

  const clipboard = useClipboard<Partial<IFlightGroup>>()

  const lastVisitedPointRef = useRef<IPoint>()

  const flightGroupsRef = useValueRef<IFlightGroup[]>(flightGroups)

  const flightGroupFieldsRef = useValueRef<IMediaPlanTemplateFields[]>(flightGroupFields)

  const masteredListsDataRef = useValueRef<Pick<IMasteredListsData, 'clientCampaigns' | 'clientAgencies' | 'mediaPartners'>>(masteredListsData)

  const checkIsCopied = useCallback(
    (flightGroup: IFlightGroup, flightGroupField?: IMediaPlanTemplateFields) => {
      const copiedFlightGroup = clipboard.read()?.[flightGroup.mediaPlanFlightGroupId] ?? {}
      const columnName = flightGroupField ? getFieldColumnName(flightGroupField) : ''

      return copiedFlightGroup.hasOwnProperty('mediaPlanFlightGroupId') || copiedFlightGroup.hasOwnProperty(columnName)
    },
    [clipboard]
  )

  const checkCanCopySelection = useCallback(
    (flightGroupSelection: Partial<IFlightGroup>): boolean => {
      const flightGroupSelections = Object.values(flightGroupSelection ?? {}).filter(obj => !isEmpty(obj) && !obj.subtotal)
      if (!flightGroupSelections?.length) {
        return false
      }

      if (flightGroupSelections?.every(selectedFlightGroup => selectedFlightGroup.hasOwnProperty('mediaPlanFlightGroupId'))) {
        return true
      }

      const selectedFieldsOfFirst = [...new Set(Object.keys(flightGroupSelections[0] ?? {}))]

      return flightGroupSelections.every(selectedFlightGroup => xor(Object.keys(selectedFlightGroup), selectedFieldsOfFirst).length === 0)
    },
    []
  )

  const canInsertFlightGroup = useMemo(
    () => {
      if ( clipboard.isEmpty) {
        return false
      }
      const onlyFlightGroupsInClipboard = Object.values(clipboard.read())?.every(copiedRow => copiedRow.hasOwnProperty('mediaPlanFlightGroupId'))
      return onlyFlightGroupsInClipboard
    },
    [clipboard]
  )

  const checkCanUpsert = useCallback(
    (upsertAt: IPoint) => {
      if (clipboard.isEmpty) {
        return false
      }

      const copiedFlightGroups = clipboard.read()
      const startsAt = getStartOfCopy(flightGroupsRef.current, flightGroupFieldsRef.current, copiedFlightGroups)
      const shift = getShiftDistance(startsAt, upsertAt)

      return Object.values(copiedFlightGroups).every((copiedFlightGroup) => canPasteFlightGroup(
        copiedFlightGroup,
        flightGroupFieldsRef.current,
        shift,
        masteredListsDataRef.current
      ))
    },
    [clipboard, flightGroupFieldsRef, flightGroupsRef, masteredListsDataRef])

  const clearClipboard = useCallback(() => clipboard.clear(), [clipboard])

  const selectFlightGroups = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      currentPoint: IPoint,
      flightGroupSelection: Partial<IFlightGroup>,
      isSelected: boolean
    ) => {
      const flightGroup: IFlightGroup =
        flightGroupsRef.current[currentPoint.flightGroupIndex]
      const flightGroupField =
        flightGroupFieldsRef.current[currentPoint.flightGroupFieldIndex]
      const shouldToggleSelection = event.ctrlKey || event.metaKey
      const shouldSelectRange = event.shiftKey && !!lastVisitedPointRef.current
      const shouldPreserveSelection =
        (event.button === 2 && isSelected) ||
        flightGroupField?.templateFieldTypeId === TemplateFieldTypes.INTERNAL

      // 1. Open a context menu after clicking on a selected cell or row
      if (shouldPreserveSelection) {
        return
      }

      // 2. Select range of cells or rows
      if (shouldSelectRange) {
        const [inclusiveFrom, inclusiveTo] = createRangeFromPoints(
          lastVisitedPointRef.current,
          currentPoint
        )
        const flightGroupsRange = flightGroupsRef.current.slice(
          inclusiveFrom.flightGroupIndex,
          inclusiveTo.flightGroupIndex
        )
        const flightGroupFieldsRange = flightGroupFieldsRef.current.slice(
          inclusiveFrom.flightGroupFieldIndex,
          inclusiveTo.flightGroupFieldIndex
        )
        const newSelection = createFlightGroupRangeSelection(
          flightGroupsRange,
          flightGroupFieldsRange
        )

        dispatch(
          mediaPlanActions.replaceCurrentFlightGroupSelection(newSelection)
        ); return
      }

      // 3. Remember the current point as a starting point of a range selection
      // eslint-disable-next-line functional/immutable-data
      lastVisitedPointRef.current = currentPoint

      const columnName =
        flightGroupField && getFieldColumnName(flightGroupField)
      const newFlightGroupSelection = createFlightGroupSelection(
        flightGroup,
        columnName,
        flightGroupSelection?.[flightGroup.mediaPlanFlightGroupId],
        shouldToggleSelection
      )

      // 4. Add row or cell to selection if not selected, otherwise remove.
      if (shouldToggleSelection) {
        dispatch(
          mediaPlanActions.replaceFlightGroupSelection(
            newFlightGroupSelection,
            flightGroup?.mediaPlanFlightGroupId
          )
        ); return
      }

      // Clear copied and selected flights
      dispatch(mediaPlanActions.setSelectedFlights([]))
      dispatch(mediaPlanActions.setSelectedDates([]))
      dispatch(mediaPlanActions.copyFlight(null))
      // 5. Replace selection with a single row or cell.
      dispatch(
        mediaPlanActions.replaceCurrentFlightGroupSelection({
          [flightGroup.mediaPlanFlightGroupId]: newFlightGroupSelection
        })
      )
    },
    [flightGroupFieldsRef, flightGroupsRef, dispatch]
  )

  const clearSelection = useCallback(() => {
    dispatch(mediaPlanActions.clearFlightGroupSelection())
    clipboard.clear()
    // eslint-disable-next-line functional/immutable-data
    lastVisitedPointRef.current = undefined
  }, [clipboard, dispatch])

  const copyFlightGroups = useCallback((flightGroupSelection: Partial<IFlightGroup>) => {
    const copiedFlightGroups = flightGroupsRef.current
      .reduce<typeof flightGroupSelection>((previousValue, flightGroup) => {
      const selectedFlightGroup = flightGroupSelection?.[flightGroup.mediaPlanFlightGroupId]

      if (selectedFlightGroup) {
        return {
          ...previousValue,
          [flightGroup.mediaPlanFlightGroupId]: pick<IFlightGroup, keyof IFlightGroup>(flightGroup, ...Object.keys(selectedFlightGroup))
        }
      }

      return previousValue
    }, {})

    clipboard.write(copiedFlightGroups)

    // Clear copied and selected flights
    dispatch(mediaPlanActions.setSelectedFlights([]))
    dispatch(mediaPlanActions.setSelectedDates([]))
    dispatch(mediaPlanActions.copyFlight(null))
  }, [clipboard, flightGroupsRef, dispatch])

  const resetFlightGroups = useCallback((flightGroupSelection: Partial<IFlightGroup>) => {
    Modal.confirm({
      title: 'Are you sure you would like to clear all selected data?',
      okText: 'Yes',
      onOk () {
        dispatch(mediaPlanActions.resetFlightGroups(flightGroupSelection))
      },
      cancelText: 'No'
    })
  }, [dispatch])

  const insertFlightGroups = useCallback((
    { shift, withFlights, flightGroupIndex, flightGroupSelection, eventIndex }:
    { shift: 0 | 1; withFlights: boolean; flightGroupIndex: number; flightGroupSelection?: Partial<IFlightGroup>; eventIndex?: number }
  ) => {
    if (clipboard.isEmpty) {
      return
    }

    const isFlightGroupFieldCopied = Object.values(clipboard.read()).every((copiedFlightGroup) => !copiedFlightGroup.mediaPlanFlightGroupId)

    if (isFlightGroupFieldCopied) {
      return
    }

    const newFlightGroups = Object.values(clipboard.read() ?? {})
      .filter(Boolean)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ mediaPlanFlightGroupId, flights, ...flightGroup }: IFlightGroup) => withFlights
        ? ({ ...flightGroup, flights })
        : flightGroup
      )

    if (typeof eventIndex === 'number') {
      dispatch(mediaPlanActions.insertFlightGroups({
        flightGroups: newFlightGroups,
        insertAt: eventIndex + 1
      }))

      return
    }

    // eslint-disable-next-line functional/immutable-data
    const withHighestOrder = Object.values(flightGroupSelection)
      .filter(Boolean)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .pop()
    const shouldInsertWithKeyboard = !isNumber(shift)

    if (!withHighestOrder) {
      return
    }

    const withHighestOrderIndex = flightGroupsRef.current.findIndex((flightGroup) =>
      flightGroup.mediaPlanFlightGroupId === withHighestOrder.mediaPlanFlightGroupId
    )
    const insertAt = shouldInsertWithKeyboard
      // insert below selected element with the highest sortOrder
      ? withHighestOrderIndex + 1
      // insert above/below the focused element
      : Math.max(flightGroupIndex + shift, 0)

    dispatch(mediaPlanActions.insertFlightGroups({
      flightGroups: newFlightGroups,
      insertAt
    }))
  }, [clipboard, dispatch, flightGroupsRef])

  const upsertFlightGroups = useCallback((flightGroupSelection: Partial<IFlightGroup>) => {
    dispatch(mediaPlanActions.upsertFlightGroups({
      flightGroups: Object.values(clipboard.read()),
      flightGroupFields: flightGroupFieldsRef.current,
      flightGroupSelection
    }))
  }, [clipboard, dispatch, flightGroupFieldsRef])

  const handleUpsertFlightGroups = useCallback((value: string | number, flightGroupSelection: Partial<IFlightGroup>) => {
    if (clipboard.isEmpty) {
      return
    }

    if (!isBlank(value)) {
      Modal.confirm({
        title: 'There\'s already data here. Do you want to replace it?',
        okText: 'Replace',
        onOk () {
          upsertFlightGroups(flightGroupSelection)
        },
        cancelText: 'Cancel'
      })
    } else {
      upsertFlightGroups(flightGroupSelection)
    }
  }, [upsertFlightGroups, clipboard.isEmpty])

  const duplicateFlightGroup = useCallback((flightGroupIndex: number) => {
    dispatch(mediaPlanActions.duplicateFlightGroup(flightGroupIndex))
  }, [dispatch])

  const toggleFlightGroup = useCallback((flightGroupId: number) => {
    dispatch(mediaPlanActions.collapseFlightGroup(flightGroupId))
  }, [dispatch])

  const deleteFlightGroup = useCallback((flightGroupIndex: number) => {
    dispatch(mediaPlanActions.deleteFlightGroup(flightGroupIndex))
  }, [dispatch])

  return useMemo(() => ({
    canInsert: !clipboard.isEmpty,
    canInsertFlightGroup,
    selectFlightGroups,
    resetFlightGroups,
    copyFlightGroups,
    insertFlightGroups,
    upsertFlightGroups: handleUpsertFlightGroups,
    toggleFlightGroup,
    deleteFlightGroup,
    duplicateFlightGroup,
    clearSelection,
    checkIsCopied,
    checkCanUpsert,
    checkCanCopySelection,
    clearClipboard
  }), [
    clipboard.isEmpty,
    canInsertFlightGroup,
    copyFlightGroups,
    deleteFlightGroup,
    duplicateFlightGroup,
    checkIsCopied,
    insertFlightGroups,
    handleUpsertFlightGroups,
    resetFlightGroups,
    selectFlightGroups,
    toggleFlightGroup,
    clearSelection,
    checkCanUpsert,
    checkCanCopySelection,
    clearClipboard
  ])
}
