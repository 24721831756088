import React, { FunctionComponent, memo } from 'react'
import { IMSHierarchies } from '@mindshare/layout'
import classNames from 'classnames'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanTemplateFields, IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { useChosenFields, IClickableFieldProps, useSubtotalFlightFields } from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { useFlightCommands } from 'Components/MediaPlanVersion/hooks/useFlightCommands'
import { useFlightGroupSelectedFlights } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelectedFlights'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import FlightDatesContainer from './FlightDatesContainer'
import SubFlightsContainer from './SubFlightsContainer'
import FlightsContainer from './FlightsContainer'

interface IProps {
  flightGroupId: number
  flightGroupIndex: number
  calculationMode: boolean
  calendarView: CalendarView
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  onAvailableFieldSelected: (mediaPlanFieldSelected: string, flightGroupIndex?: number) => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  isCollapsed: boolean
  collapsedViewField: string
  compressedCalendarView: boolean
  clickableFieldProps: IClickableFieldProps
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  isSubtotalGroup?: boolean
  flightGroup?: IFlightGroup
  availableFlightFields?: IMediaPlanMetaField[]
  displayFlightDates: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightsGridSectionContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    flightGroupId,
    flightGroupIndex,
    calculationMode,
    calendarView,
    unfilteredHierarchies,
    handleAddField,
    masteredListsData,
    tokenHandler,
    onAvailableFieldSelected,
    linkedLookupFieldsHelperValues,
    isCollapsed,
    collapsedViewField,
    compressedCalendarView,
    clickableFieldProps,
    masteredDataHelperValues,
    isSubtotalGroup,
    flightGroup,
    availableFlightFields,
    displayFlightDates,
    financeListFieldsData
  } = props

  const { chosenFlightFields, chosenSubFlightFields } = useChosenFields({ flightGroupIndex, isCollapsed, collapsedViewField })

  const selectedFlightsWithinFlightGroup = useFlightGroupSelectedFlights(flightGroupIndex)
  const { isSelectedFlightCellEmpty } = useFlightCommands(selectedFlightsWithinFlightGroup)
  const subtotalFlightFields = useSubtotalFlightFields({ flightFields: availableFlightFields, isCollapsed, collapsedViewField })
  const flightFields = isSubtotalGroup ? subtotalFlightFields : chosenFlightFields

  const memoizedFlights = useDeepEqualMemoized(flightGroup.flights)

  return (
    <>
      {
        calendarView ? (
          <td className={classNames('flight__container', { '-subtotal': isSubtotalGroup })}>
            {displayFlightDates && !isCollapsed && (
              <FlightDatesContainer
                compressedCalendarView={compressedCalendarView}
                flightGroupId={flightGroupId}
                flightGroupIndex={flightGroupIndex}
              />
            )}
            <FlightsContainer
              flightGroupId={flightGroupId}
              flightGroupIndex={flightGroupIndex}
              handleAddField={handleAddField}
              onAvailableFieldSelected={onAvailableFieldSelected}
              calculationMode={calculationMode}
              unfilteredHierarchies={unfilteredHierarchies}
              masteredListsData={masteredListsData}
              tokenHandler={tokenHandler}
              flightFields={flightFields}
              linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
              isCollapsed={isCollapsed}
              compressedCalendarView={compressedCalendarView}
              clickableFieldProps={clickableFieldProps}
              isSelectedFlightCellEmpty={isSelectedFlightCellEmpty}
              selectedFlightsWithinFlightGroup={selectedFlightsWithinFlightGroup}
              masteredDataHelperValues={masteredDataHelperValues}
              flights={memoizedFlights}
              isSubtotalGroup={isSubtotalGroup}
              financeListFieldsData={financeListFieldsData}
            />
            {!isSubtotalGroup && (
              <SubFlightsContainer
                flightGroupId={flightGroupId}
                flightGroupIndex={flightGroupIndex}
                handleAddField={handleAddField}
                onAvailableFieldSelected={onAvailableFieldSelected}
                calculationMode={calculationMode}
                unfilteredHierarchies={unfilteredHierarchies}
                masteredListsData={masteredListsData}
                tokenHandler={tokenHandler}
                subFlightFields={chosenSubFlightFields}
                isCollapsed={isCollapsed}
                compressedCalendarView={compressedCalendarView}
                clickableFieldProps={clickableFieldProps}
                isSelectedFlightCellEmpty={isSelectedFlightCellEmpty}
                selectedSubFlights={selectedFlightsWithinFlightGroup}
                flights={memoizedFlights}
                isSubtotalGroup={isSubtotalGroup}
                financeListFieldsData={financeListFieldsData}
              />
            )}
          </td>
        ) : null
      }
    </>
  )
}

export default memo(FlightsGridSectionContainer)
