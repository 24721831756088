import React, { useMemo } from 'react'
import { Card } from 'antd'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { CustomExportSortByField } from 'Apis/generated/customExportsApi'
import {
  SortableListBody,
  SortableListHeader,
  SortableListCell,
  SortableListRow
} from 'Components/shared/SortableList'
import { CustomExportSortByFieldItemComponent } from 'Components/CustomExport/components/CustomExportSortByFieldItemComponent'
import { createOptionValue, initialSortByField, updateOrder } from '../constants/entities'
import { useCustomExportOptions } from '../hooks'

export const CustomExportSortByFields = ({
  customExport,
  customExportFields
}) => {
  const { options, selectedOptions } = useCustomExportOptions({
    fields: customExport.values.customExportSortByFields,
    customExportFields
  })

  const filteredOptions = useMemo(() => options.filter(option =>
    customExport.values.customExportDisplayFields.some(
      f => f.clientMediaPlanFieldId === option.field.clientMediaPlanFieldId
    )
  ), [customExport.values.customExportDisplayFields, options])

  const handlePush = () => customExport.pushField('customExportSortByFields', {
    ...initialSortByField,
    customExportId: customExport.values.customExportId,
    sortOrder: customExport.values.customExportSortByFields.length
  })

  const handleRemove = (fieldIndex: number) => () => customExport.removeField(
    'customExportSortByFields',
    fieldIndex,
    updateOrder('sortOrder')
  )

  const handleUpdate = (fieldIndex: number) => (changes: Partial<CustomExportSortByField>) => customExport.updateField(
    'customExportSortByFields',
    fieldIndex,
    changes
  )

  const handleMove = ({ active, over }: DragEndEvent) => {
    const oldIndex = customExport.values.customExportSortByFields.findIndex((i) => i.itemRowId === active.id)
    const newIndex = customExport.values.customExportSortByFields.findIndex((i) => i.itemRowId === over?.id)

    customExport.moveField(
      'customExportSortByFields',
      oldIndex,
      newIndex,
      updateOrder('sortOrder'))
  }

  return (
    <Card className='sortable-list' data-testid='sortable-list'>
      <SortableListHeader>
        <SortableListRow>
          <SortableListCell>
            Sorting order
          </SortableListCell>
          <SortableListCell>
            Sorting
          </SortableListCell>
          <SortableListCell className='actions-cell'>
            {/* Actions */}
          </SortableListCell>
          <SortableListCell className='sortable-list__drag-icon'>
            {/* <DragIcon /> */}
          </SortableListCell>
        </SortableListRow>
      </SortableListHeader>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleMove}>
        <SortableContext
          items={customExport.values.customExportSortByFields.map((item) => item.itemRowId)}
          strategy={verticalListSortingStrategy}
        >
          <SortableListBody>
            {customExport.values.customExportSortByFields.map((item, fieldIndex) => {
              const optionId = createOptionValue(item)
              const isValidSelection = !!customExport.values.customExportDisplayFields.find(
                f =>
                  f.clientMediaPlanFieldId ===
              selectedOptions[optionId]?.field.clientMediaPlanFieldId
              )
              const { field = {}, value = null, label = null } = selectedOptions[optionId] || {}
              const canRemove = !!(field.mediaPlanField?.fieldLabel === 'Plan Name') || !field?.isMandatory
              const fieldValue = isValidSelection ? value : null
              const fieldLabel = isValidSelection ? label : null

              return (
                <CustomExportSortByFieldItemComponent
                  key={fieldIndex}
                  fieldValue={fieldValue}
                  fieldLabel={fieldLabel}
                  fieldIndex={fieldIndex}
                  handleUpdate={handleUpdate}
                  filteredOptions={filteredOptions}
                  item={item}
                  canRemove={canRemove}
                  handleRemove={handleRemove}
                  handlePush={handlePush}
                />
              )
            })}
          </SortableListBody>
        </SortableContext>
      </DndContext>
    </Card>
  )
}

export default CustomExportSortByFields
