import React from 'react'

import { MediaPlanVersionForList } from 'Apis/generated/mediaPlanVersionsApi'
import ModalComponent from 'Components/ModalComponent'
import PlanVersionListComponent from 'Components/PlanVersionListComponent'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { IUserSettings } from 'Constants/entities/IMapSettings'

interface IVersionHistoryModalProps {
  visible: boolean
  hideModal: () => void
  clientId: number
  mediaPlanVersionHistory: MediaPlanVersionForList[]
  downloadExcelPlan: (planName: string, mediaPlanId: number, mediaPlanVersionId: number, clientId: number, expandHierarchies: boolean, displayFlightDates: boolean) => void
  userSettings: IUserSettings
  mediaPlan: IMediaPlans
}
export const VersionHistoryModal = ({
  visible,
  hideModal,
  clientId,
  mediaPlanVersionHistory,
  downloadExcelPlan,
  userSettings,
  mediaPlan
}: IVersionHistoryModalProps) => (
  <ModalComponent
    width='1024px'
    title='Version history'
    open={visible}
    onCancel={hideModal}
    modalBody={(
      <PlanVersionListComponent
        clientId={clientId}
        downloadExcel={downloadExcelPlan}
        mediaPlanVersions={mediaPlanVersionHistory}
        userSettings={userSettings}
        mediaPlan={mediaPlan}
      />
    )}
    footer={null}
  />
)
