import React, { useCallback } from 'react'
import { useMapSettings } from '@mindshare/services'
import {
  useRouteNavigation,
  MSLayoutContentRow,
  LoadingComponent,
  getClientHierarchyTypeDetails
} from '@mindshare/layout'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { CustomExportGeographyHierarchy } from 'Components/CustomExport/components/CustomExportGeographyHierarchy'
import { CustomExportList } from 'Components/CustomExport/components/CustomExportList'
import { expandGeographies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { IMAPSettings } from 'Constants/entities/IMapSettings'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useCustomExportList, useClaimVerification, useDeleteCustomExport } from '../hooks'

export const CustomExportListContainer: React.FC = (): React.ReactElement => {
  const navigate = useRouteNavigation()
  const { data: currentClient } = useCurrentClient()
  const { data: hasExportSetupClaim } = useClaimVerification({ claimName: 'exportSetup', clientName: currentClient.name })
  const { settings, settingsLoading, updateSetting } = useMapSettings<IMAPSettings, 'customExport'>('customExport', currentClient.id, {})
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const { data: customExports = [], isLoading: isCustomExportsLoading } = useCustomExportList({ geographyHierarchyId: settings.geography })
  const { execute: deleteCustomExport } = useDeleteCustomExport()
  const { geography } = settings

  const geographyHierarchyValues = expandGeographies(
    getClientHierarchyTypeDetails('geography', hierarchies)
  )

  const openCreateNewExport = useCallback(() => navigate('/custom-export'), [navigate])

  const openEditCustomExport = useCallback((exportId: number) => navigate(`/custom-export/${exportId}`), [navigate])

  const openRunCustomExport = useCallback((exportId: number) => {
    navigate(`/custom-export/${exportId}/run`)
  }, [navigate])

  return isCustomExportsLoading || settingsLoading ? (
    <LoadingComponent
      appDataSuccess={!!currentClient.id}
      agencyLoadingGif={ currentClient.agency?.agencyLoadingGifLocation}
    />
  ) : (
    <>
      <TitleAndButtonsComponent
        buttons={hasExportSetupClaim ? [{
          buttonClick: openCreateNewExport,
          buttonText: 'Create New Export'
        }] : []}
        subtitle='Your Exports'
        title='Custom Exports'
      />
      <MSLayoutContentRow>
        <CustomExportGeographyHierarchy
          hierarchies={hierarchies}
          geography={geography}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          updateSetting={updateSetting}
        />
      </MSLayoutContentRow>
      <MSLayoutContentRow fillAvailableSpace={true}>
        {customExports.length
          ? (
            <CustomExportList
              currentClient={currentClient}
              dataCustomExports={customExports}
              geographies={geographyHierarchyValues}
              editExport={openEditCustomExport}
              deleteExport={deleteCustomExport}
              onOpenRunCustomExport={openRunCustomExport}
            />
          )
          : (
            <div>
              No exports for {currentClient.name}/{geographyHierarchyValues[geography] || 'Not selected'}
            </div>
          )
        }

      </MSLayoutContentRow>
    </>
  )
}

export default CustomExportListContainer
