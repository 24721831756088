const text = `# Template Example

This page is for displaying how the template configured in the previous screen would look when creating a plan and for setting up calculations and aggregations.

To enter a calculation, you must select the field you want the calculation result to populate from the dropdown. 
Fields are grouped by level. Once you have selected the field, you can click on the fields values you want to go in to the calculation 
and click in the calculation box to enter mathematical operators / text.

Calculations will show field names as their level followed by their application (unaliased) name within curly brackets e.g. {Flight.Clicks}.

### Rules

- A calculated field can refer to any fields at a higher or the same level as itself.
- A number field cannot refer to any string (text) fields.
- If a string field is calculated from other fields it will interpret this as a concatenation of the fields selected and any other text input in the calculation.
- An aggregated field can only sum fields one level below them.
- A field can only refer to fields that have the same or a stricter availability assigned
  - a mandatory field can only refer to other mandatory fields
  - a default field can only refer to default or mandatory fields
  - an available field can refer to available, default and mandatory fields
- A calculated/aggregated field cannot refer to another calculated/aggregated field that hasn't already had its calculation/aggregation set.

Clicking Test calculation will save all successful calculations and the template itself.`

export default text
