import React, { CSSProperties, FunctionComponent, useCallback } from 'react'
import { Button, Tooltip } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { addFlightTableView } from 'Actions/mediaPlansActions'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { createNewFlightFrom } from 'Helpers/flightHelper'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { useAppDispatch } from '../../store'

interface IProps {
  flightFields: IMediaPlanTemplateFields[]
  flightGroupIndex: number
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
}

export const FlightGroupFlightButtonContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    flightFields,
    flightGroupIndex,
    getStickyStyle
  } = props

  const dispatch = useAppDispatch()

  const handleClick = useCallback(() => {
    const flight = createNewFlightFrom({} as IFlight, null, null, flightFields)
    dispatch(addFlightTableView(flight, flightGroupIndex))
  }, [flightFields, flightGroupIndex, dispatch])

  return (
    <td className='plus-button-container' style={getStickyStyle(StickyColumnType.toolbarTable)}>
      <div className='flightGroup__toolbar -bottomAlign'>
        <Tooltip title='Add flight'>
          <Button
            icon={<PlusCircleOutlined />}
            size='small'
            type='link'
            tabIndex={-1}
            onClick={handleClick}
            data-testid='btn-add-flight'
          />
        </Tooltip>
      </div>
    </td>
  )
}

export default FlightGroupFlightButtonContainer
