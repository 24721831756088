import React, { memo } from 'react'
import classNames from 'classnames'
import Tooltip from 'antd/lib/tooltip'

interface IRichTextWrapperComponentProps {
  buttons?: React.JSX.Element
  children: React.JSX.Element
  error?: string
  title: string
}

export const RichTextWrapperComponent = ({ buttons, children, title, error = '' }: IRichTextWrapperComponentProps) => {
  return (
    <Tooltip title={error}>
      <div className={classNames('rich-text', { '--error': !!error })} data-testid={title}>
        <div className='rich-text__title'>
          {title}
          {buttons}
        </div>
        <div className='rich-text__content'>
          {children}
        </div>
      </div>
    </Tooltip>
  )
}

export default memo(RichTextWrapperComponent)
