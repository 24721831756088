import { MediaPlanTemplate, MediaPlanTemplateWithMediaTemplateFields } from 'Apis/generated/templatesApi'
import { CalculationResults } from 'Apis/generated/mediaPlanVersionsApi'
import * as actionTypes from 'Constants/actionTypes'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import * as mediaPlanService from '../../services/mediaPlanService'

interface IGetMediaPlanTemplatesAction {
  type: actionTypes.GET_MEDIA_PLAN_TEMPLATES
  payload: MediaPlanTemplate[]
}

interface IGetMediaPlanTemplatesByIdAction {
  type: actionTypes.GET_MEDIA_PLAN_TEMPLATE_BY_ID
  payload: MediaPlanTemplate
}

interface IClearCurrentMediaPlanTemplate {
  type: actionTypes.CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE
}

export type MediaPlanTemplatesActions =
  | IGetMediaPlanTemplatesAction
  | IGetMediaPlanTemplatesByIdAction
  | IClearCurrentMediaPlanTemplate

export const getMediaPlanTemplates = (clientId) => async dispatch => {
  const payload = await mediaPlanService.get(`mediaPlanTemplates?clientId=${clientId}`)

  dispatch({
    type: actionTypes.GET_MEDIA_PLAN_TEMPLATES,
    payload
  })
}

export const getMediaPlanTemplateById = (id: number, clientId: number) => async (dispatch) => {
  const payload: MediaPlanTemplateWithMediaTemplateFields = await mediaPlanService.get(`mediaPlanTemplates/${id}?clientId=${clientId}`)
  let template: MediaPlanTemplateWithMediaTemplateFields = { ...payload }
  if (payload.mediaPlanTemplateFields && payload.mediaPlanTemplateFields.length > 0) {
    const orderedTemplateFields = [...payload.mediaPlanTemplateFields].sort((a, b) => a.sortOrder - b.sortOrder)
    template = {
      ...payload,
      mediaPlanTemplateFields: orderedTemplateFields
    }
  }
  dispatch({
    type: actionTypes.GET_MEDIA_PLAN_TEMPLATE_BY_ID,
    payload: template
  })
}

export const updateMediaPlanTemplateInStore = (mediaPlanTemplate: MediaPlanTemplate) => ({
  type: actionTypes.GET_MEDIA_PLAN_TEMPLATE_BY_ID,
  payload: mediaPlanTemplate
})

export const runCalculation = async (save: boolean,
  mediaPlanTemplateId: number,
  clientId: number,
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]): Promise<CalculationResults> => {
  try {
    const postMediaPlanService = await mediaPlanService.postJson(
      `mediaPlanTemplates/${mediaPlanTemplateId}/testCalculations${save ? '' : '/nosave'}?clientId=${clientId}`,
      mediaPlanTemplateFields
    )
    return postMediaPlanService
  } catch (error) {
    return { topLevelErrors: ['There was an internal error'], fieldResults: [] }
  }
}

export const clearCurrentMediaPlanTemplate = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE
  })
}

export const cleanUpTemplateView = () => dispatch => {
  dispatch(clearCurrentMediaPlanTemplate())
}

export const cleanUpTemplateExampleContainer = () => dispatch => {
  dispatch(clearCurrentMediaPlanTemplate())
}
