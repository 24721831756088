import React from 'react'
import { MSClientHierarchySelection, IMSHierarchies } from '@mindshare/layout'

interface IProps {
  hierarchies: IMSHierarchies
  geography: number
  updateSetting: () => void
}

export const CustomExportGeographyHierarchy: React.FC<IProps> = ({ hierarchies, geography, updateSetting }: IProps): React.ReactElement => {
  return (
    <div className='custom-export-geography-hierarchy'>
      <MSClientHierarchySelection
        hierarchies={hierarchies}
        selectedGeography={geography}
        onChange={updateSetting}
        showSelectedIcons={true}
        visible={{ geography: true }}
      />
    </div>
  )
}

export default CustomExportGeographyHierarchy
