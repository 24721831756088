import FileSaver from 'file-saver'
import { message } from 'antd'
import {
  PostCustomExportsByCustomExportIdRunApiArg,
  PostCustomExportsByCustomExportIdRunApiResponse
} from 'Apis/generated/customExportsApi'
import { usePostCustomExportsByCustomExportIdRunMutation } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseMutationCommand } from '../../../redux/types'

export const useRunCustomExport = (): UseMutationCommand<
Omit<PostCustomExportsByCustomExportIdRunApiArg & { exportName: string }, 'clientId'>,
PostCustomExportsByCustomExportIdRunApiResponse
> => {
  const { data: currentClient } = useCurrentClient()
  const [runCustomExport, { isLoading }] = usePostCustomExportsByCustomExportIdRunMutation()

  return {
    isLoading,
    execute: async ({ customExportId, exportName, customExportRun }) => {
      try {
        const blob = await runCustomExport({ clientId: currentClient.id, customExportId, customExportRun }).unwrap()
        const fileName = exportName.split('.').join('')

        FileSaver.saveAs(blob, `${fileName}-${customExportId}`)

        return blob
      } catch (error) {
        message.error('Something went wrong')
        throw error
      }
    }
  }
}
