import React, { useMemo, useRef } from 'react'
import { Select } from 'antd'
import { HierarchiesWithLevels } from 'Apis/generated/costMasterDataApi'
import unionBy from 'lodash/unionBy'
import { useFinanceDataAudiences } from 'Components/FinanceData/hooks/useFinanceDataAudiences'
import { useFinanceDataBookingCategories } from 'Components/FinanceData/hooks/useFinanceDataBookingCategories'
import { useFinanceDataProducts } from 'Components/FinanceData/hooks/useFinanceDataProducts'
import { useFinanceDataStations } from 'Components/FinanceData/hooks/useFinanceDataStations'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { transformMasteredHierarchies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { useGetCostMasterDataBuyingRouteHierarchy } from 'Components/CostMasterData/hooks/useGetCostMasterDataBuyingRouteHierarchy'
import { Nullable } from 'Helpers/INullable'

export const usePlanFinanceListFields = ({ geographyHierarchyId, mediaHierarchyId, currentField }: {
  geographyHierarchyId: number
  mediaHierarchyId: number
  currentField: Nullable<IMediaPlanTemplateFields>
}): IMediaPlanVersionFinanceListFields => {
  const { data: financeDataAudiences = [], isLoading: areFinanceDataAudiencesLoading } = useFinanceDataAudiences({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: currentField && (currentField?.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_BUYING_AUDIENCE_LIST &&
        currentField?.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_TARGET_AUDIENCE_LIST) || false
  })
  const {
    data: financeDataBookingCategories = [],
    isLoading: areFinanceDataBookingCategoriesLoading
  } = useFinanceDataBookingCategories({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: currentField && currentField?.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_BOOKING_CATEGORY_LIST || false
  })

  const financeBuyingAudiences = financeDataAudiences.filter((item) => item.isBuyingAudience)

  const getFinanceDataAudiencesCurrentValue = (value) =>
    financeDataAudiences && financeDataAudiences.find(c => c.financeAudienceId === value)?.audienceName

  const getFinanceDataBookingCategoriesCurrentValue = (value) =>
    financeDataBookingCategories && financeDataBookingCategories.find(c => c.financeBookingCategoryId === value)?.bookingCategoryName

  return {
    FinanceTargetAudienceList: {
      data: financeDataAudiences,
      renderOptions: () => (
        financeDataAudiences.map(f => (
          <Select.Option
            key={f.financeAudienceId}
            value={f.financeAudienceId}
          >
            {f.audienceName}
          </Select.Option>
        ))
      ),
      getCurrentValue: getFinanceDataAudiencesCurrentValue,
      isDataLoading: areFinanceDataAudiencesLoading
    },
    FinanceBuyingAudienceList: {
      data: financeBuyingAudiences,
      renderOptions: () => (
        financeBuyingAudiences.map(f => (
          <Select.Option
            key={f.financeAudienceId}
            value={f.financeAudienceId}
          >
            {f.audienceName}
          </Select.Option>
        ))
      ),
      getCurrentValue: getFinanceDataAudiencesCurrentValue,
      isDataLoading: areFinanceDataAudiencesLoading
    },
    FinanceBookingCategoryList: {
      data: financeDataBookingCategories,
      renderOptions: () => (
        financeDataBookingCategories.map(f => (
          <Select.Option
            key={f.financeBookingCategoryId}
            value={f.financeBookingCategoryId}
          >
            {f.bookingCategoryName}
          </Select.Option>
        ))
      ),
      getCurrentValue: getFinanceDataBookingCategoriesCurrentValue,
      isDataLoading: areFinanceDataBookingCategoriesLoading
    }
  }
}

const mergeMasteredHierarchyDataCache = (newData: HierarchiesWithLevels, current: HierarchiesWithLevels) => ({
  hierarchies:
      unionBy(newData?.hierarchies, current?.hierarchies, 'id'),
  hierarchyLevels:
      unionBy(newData?.hierarchyLevels, current?.hierarchyLevels, 'id')
})

export const usePlanMasteredHierarchiesFields = ({ geographyHierarchyId, mediaHierarchyId }) => {
  const {
    data: financeDataProducts,
    isLoading: areFinanceDataProductsLoading
  } = useFinanceDataProducts({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId
  })
  const {
    data: financeDataStations,
    isLoading: areFinanceDataStationsLoading
  } = useFinanceDataStations({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId
  })
  const {
    data: costBuyingRouteData,
    isLoading: areCostBuyingRouteDataLoading
  } = useGetCostMasterDataBuyingRouteHierarchy({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId
  })

  const financeProductsHierarchies = useMemo(() => transformMasteredHierarchies('financeProduct', financeDataProducts), [financeDataProducts])
  const financeStationsHierarchies = useMemo(() => transformMasteredHierarchies('financeStation', financeDataStations), [financeDataStations])
  const costBuyingRouteHierarchies = useMemo(() => transformMasteredHierarchies('costBuyingRoute', costBuyingRouteData), [costBuyingRouteData])

  const financeDataProductsCache = useRef(financeDataProducts)
  const financeDataStationsCache = useRef(financeDataStations)
  const costBuyingRouteDataCache = useRef(costBuyingRouteData)

  // eslint-disable-next-line functional/immutable-data
  financeDataProductsCache.current = mergeMasteredHierarchyDataCache(financeDataProducts, financeDataProductsCache.current)
  // eslint-disable-next-line functional/immutable-data
  financeDataStationsCache.current = mergeMasteredHierarchyDataCache(financeDataStations, financeDataStationsCache.current)
  // eslint-disable-next-line functional/immutable-data
  costBuyingRouteDataCache.current = mergeMasteredHierarchyDataCache(costBuyingRouteData, costBuyingRouteDataCache.current)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allFinanceProductHierarchies = useMemo(() => transformMasteredHierarchies('financeProduct', financeDataProductsCache.current), [financeDataProducts])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allFinanceStationsHierarchies = useMemo(() => transformMasteredHierarchies('financeStation', financeDataStationsCache.current), [financeDataStations])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allCostBuyingRouteHierarchies = useMemo(() => transformMasteredHierarchies('costBuyingRoute', costBuyingRouteDataCache.current), [costBuyingRouteData])

  return useMemo(() => ({
    currentMasteredHierarchies: {
      ...financeProductsHierarchies,
      ...financeStationsHierarchies,
      ...costBuyingRouteHierarchies
    },
    cachedMasteredHierarchies: {
      ...allFinanceProductHierarchies,
      ...allFinanceStationsHierarchies,
      ...allCostBuyingRouteHierarchies
    },
    isLoading: areFinanceDataProductsLoading || areFinanceDataStationsLoading || areCostBuyingRouteDataLoading
  }), [
    financeProductsHierarchies,
    financeStationsHierarchies,
    costBuyingRouteHierarchies,
    allFinanceProductHierarchies,
    allFinanceStationsHierarchies,
    allCostBuyingRouteHierarchies,
    areFinanceDataProductsLoading,
    areFinanceDataStationsLoading,
    areCostBuyingRouteDataLoading
  ])
}
