import React, { useCallback } from 'react'
import { Control, Controller, ControllerRenderProps, UseFormSetValue } from 'react-hook-form'
import { Checkbox, List, Radio, RadioChangeEvent, Space } from 'antd'
import { Client } from 'Apis/generated/clientsApi'
import { MediaPlanTemplatesApiResponse } from 'Apis/generated/templatesApi'
import ClientSelectionComponent from 'Components/ClientSelectionComponent'
import { ITemplateSyncChildTemplate, ITemplateSyncFormData } from './TemplateSyncModalComponent'
import { handleCheckboxChange } from './helpers/common'

interface ITemplateSyncClientTemplatesComponentProps {
  control: Control<ITemplateSyncFormData>
  setValue: UseFormSetValue<ITemplateSyncFormData>
  clients: Client[]
  client: Client
  clientTemplatesList: MediaPlanTemplatesApiResponse
  selectedClientTemplatesList: ITemplateSyncChildTemplate[]
  useExistingTemplate: boolean
}

const TemplateSyncClientTemplatesComponent: React.FC<ITemplateSyncClientTemplatesComponentProps> = ({
  control,
  setValue,
  clients,
  client,
  clientTemplatesList,
  selectedClientTemplatesList,
  useExistingTemplate
}) => {
  const handleRadioChange = useCallback((e: RadioChangeEvent, field: ControllerRenderProps<ITemplateSyncFormData, 'useExistingTemplate'>) => {
    field.onChange(e)
    // After radio change we remove already added templates but only for selected client
    let updatedSelectedClientTemplatesList = selectedClientTemplatesList.filter(i => i.clientId !== client.id)
    // If selected "create new template" we push "dummy" new template item into the list
    if (e.target.value === false) {
      updatedSelectedClientTemplatesList = [
        ...updatedSelectedClientTemplatesList, {
          clientId: client.id,
          clientDisplayName: clients.find(i => i.id === client.id).displayName,
          mediaPlanTemplateId: null,
          mediaPlanTemplateDisplayName: 'New Template',
          isNewTemplate: true
        }
      ]
    }
    setValue('selectedClientTemplates', updatedSelectedClientTemplatesList)
  }, [clients, client, setValue, selectedClientTemplatesList])

  const handleClientSelectionChange = useCallback((clientId: number, field: ControllerRenderProps<ITemplateSyncFormData, 'selectedClientId'>) => {
    field.onChange(clientId)
    // On client dropdown change we restore value of Use Existing Template radio for selected client
    // by checking if it has isNewTemplateValue
    const templatesForSelectedClient = selectedClientTemplatesList.filter(i => i.clientId === clientId)
    setValue('useExistingTemplate', templatesForSelectedClient.length ? !templatesForSelectedClient.some(i => i.isNewTemplate) : undefined)
  }, [selectedClientTemplatesList, setValue])

  return (
    <>
      <Controller name='selectedClientId' control={control} render={({ field }) => (
        <ClientSelectionComponent
          clients={clients}
          currentClient={client}
          clientSelect={(clientId) => handleClientSelectionChange(clientId, field)}
          showDefaultValue={false}
          showLabel={false}
          placeholderText='Search client name...'
        />
      )} />
      {client && (
        <div className='template-sync__radio-group'>
          <Controller name='useExistingTemplate' control={control} render={({ field }) => (
            <Radio.Group {...field} onChange={(e) => handleRadioChange(e, field)}>
              <Space direction='vertical'>
                <Radio
                  value={false}
                  className='template-sync__radio-group__list-item'
                >
                  Create a new template for {client?.displayName}
                </Radio>
                <Radio
                  value={true}
                  className='template-sync__radio-group__list-item'
                >
                  Pick from existing templates
                </Radio>
              </Space>
            </Radio.Group>
          )} />
          {useExistingTemplate && (
            <div className='template-sync__list-wrapper -nested'>
              <List
                dataSource={clientTemplatesList}
                renderItem={(item) => (
                  <List.Item key={item.mediaPlanTemplateId}>
                    <Controller name='selectedClientTemplates' control={control} render={({ field }) => (
                      <Checkbox
                        checked={!!field.value.find(value => value.mediaPlanTemplateId === item.mediaPlanTemplateId && value.clientId === client?.id)}
                        value={{
                          clientId: client.id,
                          clientDisplayName: client.displayName,
                          mediaPlanTemplateId: item.mediaPlanTemplateId,
                          mediaPlanTemplateDisplayName: item.templateName,
                          isNewTemplate: false
                        }}
                        onChange={(e) => handleCheckboxChange(e, field)}
                      >
                        {client.displayName}
                      </Checkbox>
                    )} />
                    <div className='template-sync__list-item -align-right'>{item.templateName}</div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default TemplateSyncClientTemplatesComponent
