import React, { FunctionComponent, useState } from 'react'
import Moment from 'react-moment'
import { MSTable, formatEmailToName, useRouteNavigation } from '@mindshare/layout'
import { Tooltip, Button, Spin, TableColumnProps } from 'antd'
import { EditOutlined, DownloadOutlined } from '@ant-design/icons'

import { MediaPlanVersionForList } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { IUserSettings } from 'Constants/entities/IMapSettings'
import { getUserPreference } from 'Helpers/userPreferenceHelper'

interface IProps {
  clientId: number
  mediaPlanVersions: MediaPlanVersionForList[]
  downloadExcel: (planName: string, mediaPlanId: number, mediaPlanVersionId: number, clientId: number, expandHierarchies: boolean, displayFlightDates: boolean) => void
  userSettings: IUserSettings
  mediaPlan: IMediaPlans
}

const PlanVersionListComponent: FunctionComponent<IProps> = (props: IProps) => {
  const navigate = useRouteNavigation()
  const {
    clientId,
    downloadExcel,
    mediaPlanVersions,
    userSettings,
    mediaPlan
  } = props

  const [downloading, setDownloading] = useState<number>(null)

  const columns: Array<TableColumnProps<MediaPlanVersionForList>> = [
    {
      title: 'Version Number',
      dataIndex: 'versionNumber',
      key: 'versionNumber',
      width: '15%'
    },
    {
      title: 'Plan name',
      dataIndex: 'planName',
      key: 'planName',
      width: '50%'
    },
    {
      title: 'Plan stage',
      key: 'planningStageName',
      width: '10%',
      dataIndex: ['planningStage', 'planningStageName']
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifiedOn',
      key: 'modifiedOn',
      width: '15%',
      render: (text, row: MediaPlanVersionForList) => <Moment format='DD MMM YY'>{row.modifiedOn}</Moment>
    },
    {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: '15%',
      render: (text, row: MediaPlanVersionForList) => <div>{formatEmailToName(row.modifiedBy)}</div>
    },
    {
      title: '',
      key: 'action',
      width: '15%',
      render: (text, row: MediaPlanVersionForList) => {
        const mediaPlanTemplateId = mediaPlan.mediaPlanTemplateId
        const expandHierarchies = mediaPlanTemplateId > 0 && getUserPreference(userSettings?.expandHierarchies, mediaPlanTemplateId)
        const displayFlightDates = mediaPlanTemplateId > 0 && getUserPreference(userSettings?.displayFlightDates, mediaPlanTemplateId)

        return (
          <>
            <div className='version-history-button-container'>
              <Tooltip title='Edit Media Plan'>
                <Button
                  data-testid='edit-media-plan-button'
                  icon={<EditOutlined />}
                  onClick={() => navigate(`/mediaplanversion/${row.mediaPlanVersionId}`, false, { clientId })}
                  type='link'
                />
              </Tooltip>
              <Tooltip title='Download Media Plan'>
                <Button
                  data-testid={downloading === row.mediaPlanVersionId ? 'history-download-spin-icon' : 'history-download-plan-button'}
                  icon={downloading === row.mediaPlanVersionId ? <Spin /> : <DownloadOutlined />}
                  type='link'
                  onClick={async () => {
                    setDownloading(row.mediaPlanVersionId)
                    try {
                      /* eslint-disable @typescript-eslint/no-confusing-void-expression */
                      /* eslint-disable-next-line @typescript-eslint/await-thenable */
                      await downloadExcel(row.planName, row.mediaPlanId, row.mediaPlanVersionId, clientId, !!expandHierarchies, !!displayFlightDates)
                      setDownloading(null)
                    } catch (e) {
                      setDownloading(null)
                    }
                  }}
                />
              </Tooltip>
            </div>
          </>
        )
      }
    }
  ]

  return (
    <div className='list-table version-list-table' style={{ marginTop: 0 }}>
      <MSTable
        columns={columns}
        dataSource={mediaPlanVersions}
        rowKey='mediaPlanVersionId'
      />
    </div>
  )
}

export default PlanVersionListComponent
