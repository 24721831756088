import React, { FunctionComponent, useState, useEffect, useCallback, useMemo } from 'react'
import { Tooltip, TreeSelect, TreeSelectProps } from 'antd'
import classNames from 'classnames'
import { lookupHierarchyTreeValue, IClientHierarchyName, lookupHierarchyTreeNode } from '@mindshare/layout'
import { LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import {
  getLinkedLookupHierarchyOptions,
  getInvalidLinkedLookupHierarchyOptions,
  checkIsHierarchyOptionInvalid
} from 'Components/LinkedLookup/helpers/linkedLookupHierarchyHelper'
import { isFieldOfSameOrHigherLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { assignHierarchiesTreeNodeKeys, findHierarchyEnabledElementPath, IGenericHierarchiesExtended } from '../constants/entities/IHierarchies'

const { TreeNode } = TreeSelect

interface ITreeSelectProps extends TreeSelectProps<any> {
  treeData: IGenericHierarchiesExtended[]
  treeDefaultExpandedKeys?: string[]
  idColumn: string
  valueColumn: string
  value: string | number | string[] | number[] | null | undefined
  isDisabled: boolean
  tooltipTitle?: string
  hierarchyName: string
  setFieldValue: (hierarchyName: IClientHierarchyName | string, id: string | number) => void
  isEnforcedLink: boolean
  onClick?: () => void
  fieldTypeClasses?: string
  collapsedFlightGroup?: boolean
  displayValue?: string
  linkedLookupValues: LinkedLookupValue[]
  availableLinkedLookupOptions: LinkedLookupValue[]
  isFilteredLookupValuesVisible: boolean
  dependentMediaPlanField?: IMediaPlanFieldRow | IMediaPlanTemplateFields
  field?: IMediaPlanFieldRow
  isReferencedFieldMandatory?: boolean
  enforcedErrorMessage?: string
  encouragedErrorMessage?: string
  isEnforcedCombinationError?: boolean
  isLinkedFieldDisabled?: boolean
  isLoadingHierarchies?: boolean
  referencedMediaPlanField?: IMediaPlanFieldRow | IMediaPlanTemplateFields
  hasFieldError?: boolean
}

export const TreeSelectComponent: FunctionComponent<ITreeSelectProps> = ({
  treeData,
  treeDefaultExpandedKeys,
  idColumn,
  valueColumn,
  value,
  isDisabled,
  tooltipTitle,
  hierarchyName,
  setFieldValue,
  isEnforcedLink,
  onClick,
  fieldTypeClasses,
  collapsedFlightGroup,
  displayValue,
  linkedLookupValues,
  availableLinkedLookupOptions,
  isFilteredLookupValuesVisible,
  dependentMediaPlanField,
  field,
  isReferencedFieldMandatory,
  enforcedErrorMessage,
  encouragedErrorMessage,
  isEnforcedCombinationError,
  isLinkedFieldDisabled,
  isLoadingHierarchies,
  referencedMediaPlanField,
  hasFieldError,
  ...props
}) => {
  const [treeSelectValue, setTreeSelectValue] = useState(null)
  const [treeExpandedKeys, setTreeExpandedKeys] = useState([])
  const validNodeOption = isEnforcedLink ? 'Valid' : 'Recommended'
  const invalidNodeOption = isEnforcedLink ? 'Not valid' : 'Not recommended'
  const validOptions = useMemo(
    () =>
      getLinkedLookupHierarchyOptions({
        linkedLookupValues,
        idProp: idColumn,
        availableLinkedLookupOptions,
        hierarchyList: treeData,
        isEnforcedLink,
        referencedMediaPlanField
      }),
    [
      linkedLookupValues,
      idColumn,
      availableLinkedLookupOptions,
      treeData,
      isEnforcedLink,
      referencedMediaPlanField
    ]
  )
  const invalidOptions = useMemo(
    () => assignHierarchiesTreeNodeKeys(
      getInvalidLinkedLookupHierarchyOptions({
        availableLinkedLookupOptions,
        linkedLookupValues,
        idProp: idColumn,
        hierarchyList: treeData,
        isEnforcedLink
      }), idColumn, valueColumn),
    [
      availableLinkedLookupOptions,
      linkedLookupValues,
      idColumn,
      valueColumn,
      treeData,
      isEnforcedLink
    ]
  )
  const isDisplayingLookupOptions =
    isFilteredLookupValuesVisible &&
    (
      !!validOptions.length &&
      !!invalidOptions.length ||
      !!invalidOptions.length
    )
  const isHierarchyOptionInvalid = checkIsHierarchyOptionInvalid(invalidOptions, value, idColumn)
  const isInvalidCombination =
    isFilteredLookupValuesVisible &&
    isHierarchyOptionInvalid &&
    isFieldOfSameOrHigherLevel(dependentMediaPlanField, field)
  const isEnforcedLinkInvalid =
    isEnforcedLink &&
    (!dependentMediaPlanField?.defaultValue
      ? isReferencedFieldMandatory
      : dependentMediaPlanField?.defaultValue && isInvalidCombination)
  const isEncouragedLinkInvalid = !isEnforcedLink && isInvalidCombination && dependentMediaPlanField?.defaultValue
  const error = (isEnforcedLinkInvalid && enforcedErrorMessage) || (isEncouragedLinkInvalid && encouragedErrorMessage) || ''
  const hasError = isLinkedFieldDisabled && !!(isEnforcedCombinationError || isEnforcedLinkInvalid) || hasFieldError

  const className = classNames(
    'ms-select',
    `hierarchy-search-filter__dropdown--${hierarchyName}`,
    { 'hierarchy-search-filter__dropdown--is-disabled': isDisabled },
    { '--error': hasError },
    fieldTypeClasses
  )

  useEffect(() => {
    const lookedUpValue = lookupHierarchyTreeValue(treeData, idColumn, valueColumn, value)

    if (!lookedUpValue && value) {
      setTreeSelectValue(
        displayValue ?? (isLoadingHierarchies ? undefined : '[Unavailable value]')
      )
      return
    }

    setTreeSelectValue(lookedUpValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idColumn, valueColumn, value, treeData])

  useEffect(() => {
    if (treeDefaultExpandedKeys?.length) {
      setTreeExpandedKeys(treeDefaultExpandedKeys)
    }
  }, [treeDefaultExpandedKeys])

  const handleTreeExpand = useCallback((newKeys) => {
    if (newKeys.length > treeExpandedKeys.length) {
      const nodeKey = newKeys[newKeys.length - 1]
      const hierarchy = lookupHierarchyTreeNode(treeData, 'key', nodeKey) || lookupHierarchyTreeNode(invalidOptions, 'key', nodeKey)
      if (hierarchy) {
        const pathToEnabledElement = findHierarchyEnabledElementPath(hierarchy)
        if (pathToEnabledElement.length) {
          setTreeExpandedKeys([...newKeys.slice(0, -1), ...pathToEnabledElement.map(i => i.key)])
          return
        }
      }
    }
    setTreeExpandedKeys(newKeys)
  }, [treeExpandedKeys, treeData, invalidOptions])

  const renderTreeData = useCallback((currentData): React.ReactNode => currentData?.map((data) => {
    const isOptionDisabled = data.options && data.options.disabled

    return (
      <TreeNode
        key={data.key}
        title={data[valueColumn]}
        value={data.key}
        {...data.options}
        disabled={isOptionDisabled || data.disabled}
        className={classNames({ 'not-valid': data.disabled || data.selectable })}
      >
        {data.children && data.children.length > 0 ? renderTreeData(data.children) : []}
      </TreeNode>
    )
  }), [valueColumn])

  const onChange = useCallback((val: string) => {
    setTreeSelectValue(val ? val.split(':')[0] : undefined)
    setFieldValue(hierarchyName, val ? val.split(':')[1].split('-')[0] : undefined)
  }, [hierarchyName, setFieldValue])

  return (
    <Tooltip title={tooltipTitle}>
      {!collapsedFlightGroup ? (
        <TreeSelect
          disabled={isDisabled}
          allowClear={true}
          showSearch={true}
          className={className}
          data-testid={`hierarchy-select--${hierarchyName}`}
          popupMatchSelectWidth={false}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          onChange={onChange}
          placeholder='Select Value'
          value={treeSelectValue}
          treeNodeFilterProp='title'
          onClick={onClick}
          treeExpandedKeys={treeExpandedKeys}
          onTreeExpand={handleTreeExpand}
          {...props}
        > {isDisplayingLookupOptions ? (
            <>
              <TreeNode
                key={validNodeOption}
                title={validNodeOption}
                value={validNodeOption}
                disabled={true}
                selectable={false}
              />
              {renderTreeData(validOptions)}
              <TreeNode
                key={invalidNodeOption}
                title={invalidNodeOption}
                value={invalidNodeOption}
                disabled={true}
                selectable={false}
              />
              {renderTreeData(invalidOptions)}
            </>
          ) : (
            renderTreeData(treeData)
          )}
        </TreeSelect>
      ) : (
        <div className='-value' data-testid='collapse-value'>{error || displayValue}</div>
      )}
    </Tooltip>
  )
}

export default TreeSelectComponent
