import React, { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { Button, Checkbox, Popconfirm } from 'antd'
import moment from 'moment'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { isDateBetween } from '../helpers/calendarHelper'
import AlertConfirmationComponent from './AlertConfirmationComponent'
import DatePicker from './DatePicker'

const { RangePicker } = DatePicker

interface IModalOptions {
  isSubFlightCell: boolean
  selectedDates: string[]
  planStartDate: string
  planEndDate: string
  selectedFlights: string[]
  isMergeCell: boolean
  mergeFlightSelection: (startDate: Date, endDate: Date, mergeByWeek: boolean, mergeByMonth: boolean) => void
  deleteFlight: () => void
  clearFlightData?: () => void
  copyFlight?: () => void
  showCopyPasteButtons?: boolean
  canCopy?: boolean
  canPaste?: boolean
  handlePasteClick?: () => void
}

export const FlightModalOptionsComponent: FunctionComponent<IModalOptions> = (props: IModalOptions) => {
  const {
    isSubFlightCell,
    planStartDate,
    planEndDate,
    selectedFlights,
    mergeFlightSelection,
    isMergeCell,
    selectedDates,
    deleteFlight,
    clearFlightData,
    copyFlight,
    handlePasteClick,
    showCopyPasteButtons = false,
    canCopy = false,
    canPaste = false
  } = props

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [mergeByWeek, setMergeByWeek] = useState(false)
  const [mergeByMonth, setMergeByMonth] = useState(false)

  const validMerge = () => {
    const ids = selectedFlights.map( s => Number(s.split('-')[0]))
    const uniqueIds = ids.filter( (v, i, a) => a.indexOf(v) === i)
    return (uniqueIds.length === 1 && selectedFlights.length > 1) || true
  }

  const doMerge = useCallback(() => {
    mergeFlightSelection(startDate, endDate, mergeByWeek, mergeByMonth)
    setMergeByWeek(false)
    setMergeByMonth(false)
  }, [mergeFlightSelection, startDate, endDate, mergeByWeek, mergeByMonth, setMergeByWeek, setMergeByMonth])

  const onChange = (dates: moment.Moment[]) => {
    if (dates[0] && dates[1]) {
      setStartDate(dates[0].toDate())
      setEndDate(dates[1].toDate())
    }


    if (isMergeCell && !isSubFlightCell && (!moment(selectedDates[0]).isSame(dates[0]) || !moment(selectedDates[1]).isSame(dates[1]))) {
      setShowConfirmation(true)
    }
  }

  useEffect(() => {
    const calendarDates = selectedDates && selectedDates.length > 0 ? selectedDates.map(sf => moment(sf)) : [moment(startDate), moment(endDate)]
    setStartDate(calendarDates[0].toDate())
    setEndDate(calendarDates[1].toDate())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates])

  const Merge = () => (
    <div className='merge'>
      <label>Select the flight dates</label>
      <RangePicker
        presets={[
          { label: 'Today', value: [moment(), moment()] },
          { label: 'This Month', value: [moment().startOf('month'), moment().endOf('month')] }
        ]}
        format='DD/MMM/YYYY'
        onChange={onChange}
        disabledDate={(current) => !isDateBetween(planStartDate, planEndDate, current.toDate())}
        value={[moment(startDate), moment(endDate)]}
      />
      <Button
        type='primary'
        onClick={doMerge}
      >
        Merge Cells
      </Button>
      <Checkbox
        data-testid='split-merge-checkbox'
        className='merge-checkbox'
        checked={mergeByWeek}
        onChange={(event) => setMergeByWeek(event.target.checked)}
        disabled={!!mergeByMonth}
      >
        Merge by week
      </Checkbox>
      <Checkbox
        data-testid='split-merge-by-month-checkbox'
        className='merge-checkbox checkbox-bottom'
        checked={mergeByMonth}
        onChange={(event) => setMergeByMonth(event.target.checked)}
        disabled={!!mergeByWeek}
      >
        Merge by month
      </Checkbox>
      <AlertConfirmationComponent
        showWarning={showConfirmation}
        description={'This operation will remove your subflights'}
      />
    </div>
  )

  return (
    <div className='flight-modal-options' data-testid="flight-modal-options">
      <div>
        {validMerge() && <Merge />}
      </div>
      <div className="flight-modal-options-buttons">
        {
          showCopyPasteButtons && <>
            <Button disabled={!canCopy} onClick={copyFlight}>Copy</Button>

            <Button disabled={!canPaste} onClick={handlePasteClick}>Paste</Button>

            <Popconfirm
              placement='rightTop'
              title={popoverMessage.confirmClearFlight}
              onConfirm={clearFlightData}
              okText='Yes'
              cancelText='No'
              disabled={!isMergeCell}
            >
              <Button disabled={!isMergeCell}>Clear Data</Button>
            </Popconfirm>
          </>
        }
        <Button onClick={deleteFlight}>Delete</Button>
      </div>
    </div>
  )
}

export default FlightModalOptionsComponent
