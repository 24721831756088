const aliasSetup = '/alias-setup'
const plansList = '/plan-list'
const clientDataTerms = '/client-data-terms'
const clientSetup = '/client-setup'
const templateList = '/template-list'
const templates = '/template-create'
const templateEdit = '/template-edit'
const templateExample = '/template-example'
const importList = '/import-list'
const frontSheets = '/front-sheets'
const report = '/report'
const customExports = '/custom-export-list'
const fieldConnections = '/client-data-terms/field-connections'

export const appPaths = {
  clientDataTerms,
  templates,
  templateExample,
  templateList,
  plansList,
  clientSetup,
  templateEdit,
  importList,
  frontSheets,
  report,
  customExports,
  fieldConnections,
  aliasSetup
}
