import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertFromHTML, EditorState, Modifier } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { sanitizeHTMLString } from 'Helpers/stringHelper'
import { getInitialState, getSanitizedRawJsonFromEditor } from 'Helpers/richTextHelpers'

const defaultEditorOptions = {
  options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'history']
}

interface IReactDraftEditorProps {
  value: string
  setContent: (value: string) => void
  onClose: () => void
  options?: object
}

export default function ReactDraftEditor ({ value, setContent, onClose, options }: IReactDraftEditorProps) {
  const [editorState, setEditorState] = useState(getInitialState(value))


  const onEditorStateChange = (newState: EditorState) => {
    setEditorState(newState)
  }


  const handlePastedText = (text: string, html?: string): boolean => {
    // sanitize HTML string before we paste
    const sanitized = sanitizeHTMLString(html || text)
    const blocks = convertFromHTML(sanitized)
    const replaceText = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap).getBlockMap()
    )
    const es = EditorState.createWithContent(replaceText)
    setEditorState(es)

    return true
  }

  const updateValue = () => {
    const rawSanitized = getSanitizedRawJsonFromEditor(editorState)
    setContent(rawSanitized)
    onClose()
  }

  return <div className='rich-text-editor' role="editor" aria-label="Rich text editor" data-testid='rich-text-editor'>
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={options || defaultEditorOptions}
      handlePastedText={handlePastedText}
    />
    <div className="footerButtons">
      <button className='ant-btn ant-btn-primary' onClick={onClose}>Cancel</button>
      <button className='ant-btn ant-btn-secondary' onClick={updateValue}>Update</button>
    </div>
  </div>
}

