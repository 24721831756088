
import React, { FunctionComponent, useMemo, useCallback } from 'react'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { v4 as uuid } from 'uuid'
import { Button, Checkbox, Select, Space, Tooltip, Typography } from 'antd'
import { MSTable } from '@mindshare/layout'
import { DragOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { SubtotalConfigurationField } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { SortableItem } from 'Components/shared/SortableItem'

const tableColumns = [
  {
    className: 'drag-visible',
    dataIndex: 'column',
    key: 'column',
    title: 'Subtotals by',
    width: 320
  },
  {
    className: 'drag-visible',
    dataIndex: 'buttons',
    key: 'buttons',
    title: ''
  },
  {
    className: 'drag-visible',
    dataIndex: 'drag',
    key: 'drag',
    render: () => <DragHandle />,
    title: ''
  }
]

const DragHandle = () => (
  <Tooltip title='Drag group by field'>
    <DragOutlined className='subtotals-modal-body__drag-handle' data-testid='drag-handle' />
  </Tooltip>
)

interface IProps {
  handleAdd: (index: number) => void
  handleChange: (value: number, index: number) => void
  handleDelete: (index: number) => void
  handleSort: (items: SubtotalConfigurationField[]) => void
  options: IMediaPlanTemplateFields[]
  rows: SubtotalConfigurationField[]
  showGrandTotals: boolean
  setShowGrandTotals: (val: boolean) => void
  showSubtotals: boolean
  handleShowSubtotals: (value: boolean) => void
}

export const SubtotalsModalBodyComponent: FunctionComponent<IProps> = ({
  handleAdd,
  handleChange,
  handleDelete,
  handleSort,
  options,
  rows,
  showGrandTotals,
  setShowGrandTotals,
  showSubtotals,
  handleShowSubtotals
}) => {
  const tableDataSource = useMemo(() => rows.map((currentRow, i) => ({
    key: currentRow.subtotalConfigurationFieldId,
    itemRowId: uuid(),
    column: (
      <Select
        onChange={(value: number) => handleChange(value, i)}
        placeholder='Select value...'
        showSearch={true}
        style={{ width: '100%' }}
        value={currentRow.clientMediaPlanFieldId}
        optionFilterProp='children'
      >
        {options
          .filter((option) => !rows.some((row) =>
            row.clientMediaPlanFieldId === option.clientMediaPlanFieldId &&
            row.clientMediaPlanFieldId !== currentRow.clientMediaPlanFieldId
          ))
          .map((field) => (
            <Select.Option
              key={field.clientMediaPlanFieldId}
              value={field.clientMediaPlanFieldId}
            >
              {field.fieldLabel}
            </Select.Option>
          ))}
      </Select>
    ),
    buttons: (
      <>
        <Button
          data-testid='subtotals-add-item'
          icon={<PlusCircleOutlined />}
          size='large'
          type='link'
          tabIndex={-1}
          onClick={() => handleAdd(i)}
        />
        <Button
          data-testid='subtotals-delete-item'
          icon={<MinusCircleOutlined />}
          size='large'
          type='link'
          tabIndex={-1}
          onClick={() => handleDelete(i)}
        />
      </>
    )
  })), [handleAdd, handleChange, handleDelete, options, rows])

  const onSortEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = tableDataSource.findIndex((i) => i.itemRowId === active.id)
      const newIndex = tableDataSource.findIndex((i) => i.itemRowId === over?.id)

      const newData = arrayMove(([] as SubtotalConfigurationField[]).concat(...rows), oldIndex, newIndex).filter(el => !!el)
      handleSort(newData)
    }
  }, [handleSort, rows, tableDataSource])

  const DraggableBodyRow = (containerProps) => {
    const index = tableDataSource.findIndex(x => x.key === containerProps['data-row-key'])
    const item = tableDataSource.find(x => x.key === containerProps['data-row-key'])

    return (
      <SortableItem
        index={index}
        item={item}
        {...containerProps}
      />
    )
  }

  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <Space direction='horizontal' size='small' style={{ display: 'flex' }}>
        <Checkbox
          data-testid='show-subtotals'
          checked={showSubtotals}
          onChange={e => handleShowSubtotals(e.target.checked)}
        />
        <Typography.Text>Show Subtotals</Typography.Text>
      </Space>
      {showSubtotals && (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onSortEnd}>
          <SortableContext
            items={tableDataSource.map((i) => i.itemRowId)}
            strategy={verticalListSortingStrategy}
          >
            <MSTable
              columns={tableColumns}
              components={{
                body: {
                  wrapper: (containerProps) => (
                    <tbody
                      {...containerProps}
                    />
                  ),
                  row: DraggableBodyRow
                }
              }}
              dataSource={tableDataSource}
              pagination={false}
              rowKey='key'
            />
          </SortableContext>
        </DndContext>
      )}
      <Space direction='horizontal' size='small' style={{ display: 'flex' }}>
        <Checkbox
          data-testid='show-grand-totals'
          checked={showGrandTotals}
          onChange={e => {
            setShowGrandTotals(e.target.checked)
          }}
        />
        <Typography.Text>Show Grand Totals</Typography.Text>
      </Space>
    </Space>
  )
}

export default SubtotalsModalBodyComponent
