import React, { useState, FunctionComponent } from 'react'
import { Button, List, message } from 'antd'
import { UpOutlined, DownOutlined, CloseOutlined, CheckOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import Search from 'antd/lib/input/Search'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'

interface IDefineList {
  clientFieldValues: ClientFieldValue[]
  mediaPlanField: IClientMediaPlanField
  handleAddDropdown: (value: string, mediaPlanField: IClientMediaPlanField) => void
  handleItemDisabledToggle: (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField) => void
  handleItemSort: (clientMediaPlanField: IClientMediaPlanField, direction: 'asc' | 'dsc') => void
  handleItemOrder: (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField, direction: 'up' | 'down') => void
}

const SetDropdownValuesComponent: FunctionComponent<IDefineList> = (defineList: IDefineList) => {
  const [optionSelected, setOptionSelected] = useState<ClientFieldValue>()
  const [sortDirection, setSortDirection] = useState<'asc' | 'dsc'>('asc')

  const handleDisableToggle = (): void => {
    if (optionSelected) {
      defineList.handleItemDisabledToggle(optionSelected, defineList.mediaPlanField)
      setOptionSelected({ ...optionSelected, isDisabled: !optionSelected.isDisabled })
    }
  }

  const handleSort = (): void => {
    defineList.handleItemSort(defineList.mediaPlanField, sortDirection)
    setSortDirection(state => state === 'asc' ? 'dsc' : 'asc')
  }

  const handleOrder = (direction: 'up' | 'down'): void => {
    if (optionSelected) {
      defineList.handleItemOrder(optionSelected, defineList.mediaPlanField, direction)
    }
  }

  const renderItems = (item: ClientFieldValue) => {
    let classNames: string[] = []
    if (optionSelected && optionSelected.clientFieldValueId === item.clientFieldValueId) {
      classNames = [...classNames, 'option-selected']
    }
    if (item && item.isDisabled) {
      classNames = [...classNames, 'option-disabled']
    }
    return (
      <List.Item
        onClick={() => {
          setOptionSelected(item)
        }}
        className={classNames && classNames.join(' ')}
      >
        {item.valueDisplayName}
      </List.Item>
    )
  }

  const handleAddValue = (option) => {
    if (option?.isDisabled) {
      if (optionSelected) {
        defineList.handleItemDisabledToggle(optionSelected, defineList.mediaPlanField)
        setOptionSelected({ ...optionSelected, isDisabled: !optionSelected.isDisabled })
      } else {
        defineList.handleItemDisabledToggle(option, defineList.mediaPlanField)
      }
    } else {
      message.warning('Option already exists')
    }
  }

  const onSearch = (value: string) => {
    const trimmedValue = value.trim()
    const exists = defineList.clientFieldValues?.find(item => item.valueDisplayName.toLowerCase() === trimmedValue.toLowerCase())

    if (trimmedValue !== '' && !exists) {
      defineList.handleAddDropdown(trimmedValue, defineList.mediaPlanField)
    } else {
      if (exists) {
        handleAddValue(exists)
      } else {
        message.error('Please enter an option')
      }
    }
  }

  return (
    <>
      <div>
        <Search
          className='field-with-button'
          placeholder='Add element'
          enterButton='Add Option'
          onSearch={onSearch}
        />
      </div>
      <div className='button-dropdown-container'>
        <Button
          key='sort'
          icon={sortDirection === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
          className='ant-btn-secondary'
          size='small'
          onClick={() => {
            handleSort()
          }}
        >
          Sort
        </Button>
        {optionSelected &&
          <>
            <Button
              key='up'
              icon={<UpOutlined />}
              className='ant-btn-secondary'
              size='small'
              onClick={() => {
                handleOrder('up')
              }}
            >
              Up
            </Button>
            <Button
              key='down'
              icon={<DownOutlined />}
              className='ant-btn-secondary'
              size='small'
              onClick={() => {
                handleOrder('down')
              }}
            >
              Down
            </Button>
            <Button
              key='disable'
              icon={optionSelected.isDisabled ? <CheckOutlined /> : <CloseOutlined />}
              className='ant-btn-secondary'
              size='small'
              onClick={handleDisableToggle}
            >
              {optionSelected.isDisabled ? 'Enable' : 'Disable'}
            </Button>
          </>
        }
      </div>
      <div className='list-values-container'>
        <List bordered={true} dataSource={defineList.clientFieldValues} renderItem={renderItems} />
      </div>
    </>
  )
}
export default SetDropdownValuesComponent
