import React from 'react'
import { Checkbox, Input, Select } from 'antd'
import { FieldTag } from 'Apis/generated/mediaPlanFieldsApi'
import TooltipButton from './TooltipButton'

export interface ITemplateTitleComponentProps {
  disabled?: boolean
  inputLabel: 'Template Title' | 'Front Sheet Name'
  inputValue: string
  onTemplateNameChange: (value: string) => void
  onLeftAlignExportChange: (leftAlign: boolean) => void
  onHideSortOrderExportChange: (value: boolean) => void
  placeholder: 'Enter Template Title' | 'Enter Front Sheet Title'
  tags: FieldTag[] | null
  title: 'Create Template' | 'Edit Front Sheet' | null | 'Create Front Sheet' | 'Edit Template'
  tooltip: 'templateEdit' | 'createEditFrontSheetPlanSelection' | 'createEditFrontSheetViewEditor'
  leftAlignNumbersInExcelExport: boolean
  hideSortOrderInExcelExport: boolean
}

export const TemplateTitleComponent: React.FC<ITemplateTitleComponentProps> = (props) => {
  const Option = Select.Option

  return (
    <div className='ms-template-title'>
      <h1>{props.title}</h1>
      <div className='ms-template-title__inner'>
        <div className='ms-template-title__title'>
          <p>{props.inputLabel}</p>
          <Input
            className='ms-input'
            disabled={props.disabled}
            data-testid='title-input'
            placeholder={props.placeholder}
            value={props.inputValue}
            onChange={(e) => props.onTemplateNameChange(e.target.value)}
          />
          <Checkbox
            data-testid='leftAlignNumbersInExcelExport-checkbox'
            disabled={props.disabled}
            className='ms-template-title__checkbox-export'
            checked={props.leftAlignNumbersInExcelExport}
            onChange={(event) => props.onLeftAlignExportChange(event.target.checked)}
          >
            <label>Left align export</label>
          </Checkbox>
          <Checkbox
            data-testid='hideSortOrderInExcelExport-checkbox'
            disabled={props.disabled}
            className='ms-template-title__checkbox-export'
            checked={props.hideSortOrderInExcelExport}
            onChange={(event) => props.onHideSortOrderExportChange(event.target.checked)}
          >
            <label>Hide sort column</label>
          </Checkbox>
        </div>
        {props.tags && (
          <div className='ms-template-title__tags'>
            <label>Select Fields By Tags</label>
            <Select
              optionFilterProp='children'
              className='ms-select'
              placeholder='Tags'
              allowClear={true}
              showSearch={true}
            >
              {props.tags.map(tag =>
                <Option value={tag.fieldTagId} key={tag.fieldTagId}>{tag.fieldTagName}</Option>
              )}
            </Select>
          </div>
        )}
      </div>
      {props.tooltip !== 'templateEdit' && <TooltipButton tooltip={props.tooltip} />}
    </div>
  )
}

export default TemplateTitleComponent
