import React, { FunctionComponent } from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'
import { MSTable } from '@mindshare/layout'
import { ISortColumn } from 'Components/MediaPlanVersion/constants/entities/ISortColumn'
import { ISortItem } from 'Components/MediaPlanVersion/constants/entities/ISortItem'
const { Option } = Select

interface IProps {
  columns: ISortColumn[]
  sortItems: ISortItem[]
  showDeleteButton: boolean
  handleChangeColumn: (index: number, value: string) => void
  handleChangeAscending: (index: number, value: boolean) => void
  handleAdd: (index: number) => void
  handleDelete: (index: number) => void
}

export const MultipleSortComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    columns,
    sortItems,
    showDeleteButton,
    handleChangeColumn,
    handleChangeAscending,
    handleAdd,
    handleDelete
  } = props

  const tableColumns = [
    {
      dataIndex: 'column',
      key: 'column',
      title: 'Column',
      width: '100%'
    },
    {
      dataIndex: 'ascending',
      key: 'ascending',
      title: 'Order',
      width: 130
    },
    {
      dataIndex: 'buttons',
      key: 'buttons',
      title: '',
      width: 120
    }
  ]

  const tableDataSource = sortItems.map((sortItem, sortItemIndex) => {
    return {
      key: sortItemIndex,
      column: (
        <Select
          className='ms-select multipleSort__select'
          placeholder='Select a column'
          showSearch={true}
          value={sortItem.column}
          onChange={(value: string) => {
            handleChangeColumn(sortItemIndex, value)
          }}
        >
          {columns && columns
            .filter((column) => !sortItems.some((item) =>
              item.column === column.name &&
              item.column !== sortItem.column
            ))
            .map((column, columnIndex) => (
              <Option
                key={columnIndex}
                value={column.name}
              >
                {column.label}
              </Option>
            ))}
        </Select>
      ),
      ascending: (
        <Select
          className='ms-select multipleSort__select'
          value={sortItem.ascending ? 1 : 0}
          onChange={(value: number) => {
            handleChangeAscending(sortItemIndex, !!value)
          }}
        >
          <Option key={1} value={1}>Small to Big</Option>
          <Option key={0} value={0}>Big to Small</Option>
        </Select>
      ),
      buttons: (
        <div className='multipleSort__icons'>
          <Button
            data-testid='multiple-sort-add-item'
            icon={<PlusCircleOutlined />}
            size='large'
            type='link'
            tabIndex={-1}
            onClick={() => handleAdd(sortItemIndex)}
          />
          <Button
            data-testid='multiple-sort-delete-item'
            disabled={!showDeleteButton}
            icon={<MinusCircleOutlined />}
            size='large'
            type='link'
            tabIndex={-1}
            onClick={() => handleDelete(sortItemIndex)}
          />
        </div>
      )
    }
  })

  return (
    <div className='multipleSort'>
      <div className="list-table">
        <MSTable columns={tableColumns} dataSource={tableDataSource} pagination={false} />
      </div>
    </div>
  )
}

export default MultipleSortComponent
