import React from 'react'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import { DragOutlined } from '@ant-design/icons'

interface ISortableListCellProps {
  children?: React.JSX.Element | string | null
  className?: string
}

export const SortableListCell = ({
  children,
  className = '',
  ...props
}: ISortableListCellProps) => (
  <div className={classNames('sortable-list__cell', className)} {...props}>
    {children}
  </div>
)

export const SortableListRow = ({ children }) => (
  <div className='sortable-list__row'>
    {children}
  </div>
)

export const SortableListHeader = ({ children }) => (
  <div className='sortable-list__header'>
    {children}
  </div>
)

export const SortableListItem = ({ children }) => (
  <div className='sortable-list__row' data-testid='sortable-list-row'>
    {children}
  </div>
)

export const SortableListBody = ({
  children
}) => (
  <div className='sortable-list__body'>
    {children}
  </div>
)

export const DragIcon = ({
  title = 'Drag',
  disabled = false
}) => (
  <Tooltip title={title}>
    <div>
      <Button
        disabled={disabled}
        data-testid='drag-handle'
        icon={<DragOutlined />}
        className='btn-unset-default sortable-list__drag-button'
      />
    </div>
  </Tooltip>
)
