// UNCOMMENT COMMENTED LINES TO RUN APP WITH MOCK DATA
// INITIALISE MSW BEFORE FIRST RUN - `npx msw init public/ --save`
import { renderReactAppRoot } from '@mindshare/layout'
// import { MSWBrowser } from '@mindshare/services/lib/msw'
// import mswHandlers from './mocks/mswHandlers'
import ProviderWrapper from './ProviderWrapper'
import './scss/app.scss'

async function enableMocking() {
  // if (process.env.NODE_ENV !== 'development') {
  //   return
  // }

  // return MSWBrowser(mswHandlers)
}

enableMocking().then(() => {
  renderReactAppRoot(ProviderWrapper)
})
