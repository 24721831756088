import { batch } from 'react-redux'
import { Guid } from 'guid-typescript'
import { ILoadingResult, IMSHierarchies } from '@mindshare/layout'
import { initialState } from 'Reducers/frontSheetReducer'

import { FrontSheet, FrontSheetPost, FrontSheetViewAggregate, FrontSheetViewGroupBy } from 'Apis/generated/frontSheetsApi'
import * as actionTypes from 'Constants/actionTypes'

import { ILookupPlan } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { IFrontSheetSelectFieldsSaveStatus, ICurrentFrontSheetView } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import * as mediaPlanService from '../../services/mediaPlanService'
import { getMediaPlans } from './mediaPlansActions'

interface IInitialiseFrontSheetListContainer {
  type: actionTypes.INITIALISE_FRONT_SHEET_LIST_CONTAINER
  payload: ILoadingResult
}

interface IInitialiseFrontSheetSelectFieldsContainer {
  type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER
  payload: ILoadingResult
}

interface IInitialiseFrontSheetSelectPlansContainer {
  type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER
  payload: ILoadingResult
}

interface IInitialiseFrontSheetViewContainer {
  type: actionTypes.INITIALISE_FRONT_SHEET_VIEW_CONTAINER
  payload: ILoadingResult
}

interface IGetFrontSheets {
  type: actionTypes.GET_FRONT_SHEETS
  payload: FrontSheet[]
}

interface IGetFrontSheetById {
  type: actionTypes.GET_FRONT_SHEET_BY_ID
  payload: FrontSheet
}

interface IUpdateFrontSheetViewAggregateDate {
  type: actionTypes.UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA
  selectedTab: number
  payload: FrontSheetViewAggregate[]
}

interface IUpdateFrontSheetViewGroupByData {
  type: actionTypes.UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA
  selectedTab: number
  payload: FrontSheetViewGroupBy[]
}

interface IUpdateFrontSheetViewName {
  type: actionTypes.UPDATE_FRONT_SHEET_VIEW_NAME
  selectedTab: number
  payload: string
}

interface IUpdateFrontSheetViewField {
  type: actionTypes.UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD
  selectedTab: number
  payload: IClientMediaPlanField
}

interface IUpdateFrontSheetCheckedPlans {
  type: actionTypes.UPDATE_FRONT_SHEET_CHECKED_PLANS
  selectedTab: number
  payload: number[]
}

interface IUpdateFrontSheetNotes {
  type: actionTypes.UPDATE_FRONT_SHEET_NOTES
  payload: string
}

interface IAddFrontSheetView {
  type: actionTypes.ADD_FRONT_SHEET_VIEW
}

interface IDeleteFrontSheetView {
  type: actionTypes.DELETE_FRONT_SHEET_VIEW
  selectedTab: number
}

interface IGetFrontSheetView {
  type: actionTypes.GET_FRONT_SHEET_VIEW
  payload: ICurrentFrontSheetView
}

interface IResetCurrentFrontSheetState {
  type: actionTypes.RESET_CURRENT_FRONT_SHEET_STATE
}

interface IUpdateFrontSheetSelectFieldsSaveState {
  type: actionTypes.UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE
  payload: IFrontSheetSelectFieldsSaveStatus
}

export type IFrontSheetActions =
  | IInitialiseFrontSheetListContainer
  | IInitialiseFrontSheetSelectFieldsContainer
  | IInitialiseFrontSheetSelectPlansContainer
  | IInitialiseFrontSheetViewContainer
  | IGetFrontSheets
  | IGetFrontSheetById
  | IGetFrontSheetView
  | IUpdateFrontSheetViewAggregateDate
  | IUpdateFrontSheetViewGroupByData
  | IUpdateFrontSheetViewName
  | IUpdateFrontSheetViewField
  | IUpdateFrontSheetCheckedPlans
  | IUpdateFrontSheetNotes
  | IAddFrontSheetView
  | IDeleteFrontSheetView
  | IResetCurrentFrontSheetState
  | IUpdateFrontSheetSelectFieldsSaveState

export const initialiseFrontSheetListContainer = (loadingId: Guid, currentClientId: number) => (dispatch) => {
  batch(async () => {
    try {
      await dispatch(getFrontSheets(currentClientId))
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_LIST_CONTAINER,
        payload: { loadingId, error: '' } as ILoadingResult
      })
    } catch {
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_LIST_CONTAINER,
        payload: {
          loadingId,
          error: 'The Front Sheet list failed to load. Please try refreshing. If that fails, contact administrator.'
        } as ILoadingResult
      })
    }
  })
}

export const initialiseFrontSheetSelectFieldsContainer = (loadingId: Guid, props: { frontSheetId: number; currentClientId: number }) => (
  dispatch
) => {
  batch(async () => {
    try {
      const frontSheet = await dispatch(getFrontSheetById(props.frontSheetId, props.currentClientId))
      if (!frontSheet.frontSheetViews.length) {
        dispatch(addFrontSheetView())
      }
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER,
        payload: { loadingId, error: '' } as ILoadingResult
      })
    } catch {
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER,
        payload: {
          loadingId,
          error: 'The Front Sheet editor failed to load. Please try refreshing. If that fails, contact administrator.'
        } as ILoadingResult
      })
    }
  })
}

export const initialiseFrontSheetSelectPlansContainer = (
  loadingId: Guid,
  hierarchies: IMSHierarchies,
  mediaPlans: ILookupPlan[],
  props: { frontSheetId?: number; currentClientId: number }
) => (
  dispatch
) => {
  batch(async () => {
    try {
      if (!mediaPlans.length) {
        await dispatch(getMediaPlans(props.currentClientId, hierarchies))
      }
      if (props.frontSheetId) {
        await dispatch(getFrontSheetById(props.frontSheetId, props.currentClientId))
      }
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER,
        payload: { loadingId, error: '' } as ILoadingResult
      })
    } catch {
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER,
        payload: {
          loadingId,
          error: 'The Front Sheet editor failed to load. Please try refreshing. If that fails, contact administrator.'
        } as ILoadingResult
      })
    }
  })
}

export const initialiseFrontSheetViewContainer = (
  loadingId: Guid,
  hierarchies: IMSHierarchies,
  mediaPlans: ILookupPlan[],
  props: { frontSheetId: number; currentClientId: number }
) => (
  dispatch
) => {
  batch(async () => {
    try {
      const frontSheet = await dispatch(getFrontSheetById(props.frontSheetId, props.currentClientId)) as FrontSheet
      const mediaPlansAvailable = frontSheet.mediaPlanIds?.every(x => mediaPlans.map(y => y.mediaPlanId).includes(x))


      if (!mediaPlansAvailable) {
        await dispatch(getMediaPlans(props.currentClientId, hierarchies))
      }

      await dispatch(getFrontSheetView(props.frontSheetId, frontSheet.frontSheetViews?.[0].frontSheetViewId, props.currentClientId))
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_VIEW_CONTAINER,
        payload: { loadingId, error: '' } as ILoadingResult
      })
    } catch {
      dispatch({
        type: actionTypes.INITIALISE_FRONT_SHEET_VIEW_CONTAINER,
        payload: {
          loadingId,
          error: 'The Front Sheet view failed to load. Please try refreshing. If that fails, contact administrator.'
        } as ILoadingResult
      })
    }
  })
}

export const cleanUpFrontSheetListContainer = () => dispatch => {
  batch(() => {
    dispatch({
      type: actionTypes.GET_FRONT_SHEETS,
      payload: initialState.frontSheets
    })
    dispatch({
      type: actionTypes.INITIALISE_FRONT_SHEET_LIST_CONTAINER,
      payload: initialState.frontSheetListContainerLoading
    })
  })
}

export const cleanUpFrontSheetSelectFieldsContainer = () => dispatch => {
  batch(() => {
    dispatch({
      type: actionTypes.RESET_CURRENT_FRONT_SHEET_STATE
    })
    dispatch({
      type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER,
      payload: initialState.frontSheetSelectFieldsContainerLoading
    })
  })
}

export const cleanUpFrontSheetSelectPlansContainer = () => dispatch => {
  batch(() => {
    dispatch({
      type: actionTypes.RESET_CURRENT_FRONT_SHEET_STATE
    })
    dispatch({
      type: actionTypes.INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER,
      payload: initialState.frontSheetSelectPlansContainerLoading
    })
  })
}

export const cleanUpFrontSheetViewContainer = () => dispatch => {
  batch(() => {
    dispatch({
      type: actionTypes.RESET_CURRENT_FRONT_SHEET_STATE
    })
    dispatch({
      type: actionTypes.INITIALISE_FRONT_SHEET_VIEW_CONTAINER,
      payload: initialState.frontSheetViewContainerLoading
    })
  })
}

export const getFrontSheets = (clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`frontSheets?clientId=${clientId}`)
  dispatch({
    type: actionTypes.GET_FRONT_SHEETS,
    payload
  })
  return true
}

export const getFrontSheetById = (frontSheetId: number, clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`frontSheets/${frontSheetId}?clientId=${clientId}`)
  dispatch({
    type: actionTypes.GET_FRONT_SHEET_BY_ID,
    payload
  })
  return payload
}

export const getFrontSheetView = (frontSheetId: number, frontSheetViewId: number, clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`frontSheets/${frontSheetId}/frontSheetViews/${frontSheetViewId}?clientId=${clientId}`) as ICurrentFrontSheetView

  dispatch({
    type: actionTypes.GET_FRONT_SHEET_VIEW,
    payload
  })
}

export const updateFrontSheet = (clientId: number, currentFrontSheet: FrontSheet) => async dispatch => {
  try {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: true, saveSuccess: false }))
    const payload = await mediaPlanService.putJson(`frontSheets/${currentFrontSheet.frontSheetId}?clientId=${clientId}`, currentFrontSheet)
    batch(() => {
      dispatch(getFrontSheetById(currentFrontSheet.frontSheetId, clientId))
      dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: true }))
    })
    return payload
  } catch (error) {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    return error.httpResponseBody
  }
}

export const updateFrontSheetNotes = (payload: string) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_FRONT_SHEET_NOTES,
    payload
  })
}

export const saveNewFrontSheet = (clientId: number, requestBody: FrontSheetPost) => async dispatch => {
  try {
    const payload = await mediaPlanService.postJson(`frontSheets?clientId=${clientId}`, requestBody)
    await dispatch(getFrontSheets(clientId))
    return payload
  } catch (error) {
    return error.httpResponseBody
  }
}

export const updateFrontSheetViewName = (selectedTab: number, updatedFrontSheetViewName: string) => dispatch => {
  batch(() => {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    dispatch({
      type: actionTypes.UPDATE_FRONT_SHEET_VIEW_NAME,
      selectedTab,
      payload: updatedFrontSheetViewName
    })
  })
}

export const updateFrontSheetViewLaydownField = (selectedTab: number, updatedFrontSheetViewLaydownField: IClientMediaPlanField) => dispatch => {
  batch(() => {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    dispatch({
      type: actionTypes.UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD,
      selectedTab,
      payload: updatedFrontSheetViewLaydownField
    })
  })
}

export const updateFrontSheetViewAggregateData = (selectedTab: number, updatedFrontSheetViewAggregates: FrontSheetViewAggregate[]) => dispatch => {
  batch(() => {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    dispatch({
      type: actionTypes.UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA,
      selectedTab,
      payload: updatedFrontSheetViewAggregates
    })
  })
}

export const updateFrontSheetViewGroupByData = (selectedTab: number, updatedFrontSheetViewGroupBys: FrontSheetViewGroupBy[]) => dispatch => {
  batch(() => {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    dispatch({
      type: actionTypes.UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA,
      selectedTab,
      payload: updatedFrontSheetViewGroupBys
    })
  })
}

export const updateFrontSheetCheckedPlans = (updatedCheckedPlans: number[]) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_FRONT_SHEET_CHECKED_PLANS,
    payload: updatedCheckedPlans
  })
}

export const deleteFrontSheetView = (selectedTab: number) => dispatch => {
  batch(() => {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    dispatch({
      type: actionTypes.DELETE_FRONT_SHEET_VIEW,
      selectedTab
    })
  })
}

export const addFrontSheetView = () => dispatch => {
  batch(() => {
    dispatch(updateFrontSheetsSelectFieldsSaveState({ saveInProgress: false, saveSuccess: false }))
    dispatch({
      type: actionTypes.ADD_FRONT_SHEET_VIEW
    })
  })
}

export const updateFrontSheetsSelectFieldsSaveState = (newSaveState: IFrontSheetSelectFieldsSaveStatus) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE,
    payload: newSaveState
  })
}

export const deleteFrontSheet = (frontSheetId: number, clientId: number) => async dispatch => {
  const frontSheet = await mediaPlanService.get(`frontSheets/${frontSheetId}?clientId=${clientId}`)

  if (frontSheet) {
    await mediaPlanService.putJson(`frontSheets/${frontSheetId}?clientId=${clientId}`,
      {
        ...frontSheet,
        deletedOn: new Date(0, 0, 0).toISOString()
      }
    )
  }
  dispatch(getFrontSheets(clientId))
}
