import { useMemo } from 'react'
import {
  useGetSyncTemplateUpdateResultByTemplateSyncUsageIdQuery,
  GetSyncTemplateUpdateResultByTemplateSyncUsageIdApiResponse
} from 'Apis/generated/templateSyncApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useSyncTemplateUpdateResult = ({ templateSyncUsageId }): UseQueryCommand<GetSyncTemplateUpdateResultByTemplateSyncUsageIdApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetSyncTemplateUpdateResultByTemplateSyncUsageIdQuery(
    { clientId: currentClient.id, templateSyncUsageId },
    { skip: !templateSyncUsageId }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
