import { isFieldOfSameOrHigherLevel, getFieldLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanVersionMasteredFieldHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { getFieldColumnName, IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const getMasteredFieldVariables = ({ currentField, fields, parseData }: {
  currentField: IMediaPlanVersionField
  fields: IMediaPlanVersionField[]
  parseData: any
}): IMediaPlanVersionMasteredFieldHelperValues => {
  const geographyHierarchyFields = [...fields]
    .filter(
      item =>
        item.clientMediaPlanField.mediaPlanField.fieldDataType
          ?.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY &&
        item.isInPlan &&
        isFieldOfSameOrHigherLevel(item, currentField)
    )
    .sort((a, b) => getFieldLevel(b) - getFieldLevel(a))

  const mediaHierarchyFields = [...fields]
    .filter(
      item =>
        item.clientMediaPlanField.mediaPlanField.fieldDataType
          ?.fieldDataTypeId === FieldDataType.MEDIA_HIERARCHY &&
        item.isInPlan &&
        isFieldOfSameOrHigherLevel(item, currentField)
    )
    .sort((a, b) => getFieldLevel(b) - getFieldLevel(a))

  const getFieldData = (field: IMediaPlanVersionField, flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number) => {
    const currentFieldLevelId = field?.clientMediaPlanField.mediaPlanField.fieldLevelId

    switch (currentFieldLevelId) {
      case FieldLevelType.PLAN: {
        return parseData
      }

      case FieldLevelType.FLIGHT_GROUP: {
        if ([flightGroupIndex].includes(undefined)) {
          return null
        }

        return parseData?.flightGroups[flightGroupIndex]
      }

      case FieldLevelType.FLIGHT: {
        if ([flightGroupIndex, flightIndex].includes(undefined)) {
          return null
        }

        return parseData.flightGroups[flightGroupIndex].flights[flightIndex]
      }

      case FieldLevelType.SUB_FLIGHT: {
        if ([flightGroupIndex, flightIndex, subFlightIndex].includes(undefined)) {
          return null
        }

        return parseData.flightGroups[flightGroupIndex].flights[flightIndex]?.subFlights[subFlightIndex]
      }

      default: {
        return null
      }
    }
  }

  const getHierarchyField = (hierarchyFields: IMediaPlanVersionField[], flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number): IMediaPlanVersionField => {
    const field = hierarchyFields.find((item) => {
      const columnName = getFieldColumnName(item)
      const data = getFieldData(item, flightGroupIndex, flightIndex, subFlightIndex)
      return data?.[columnName]
    })

    return field
  }

  const getIsMasteredHierarchyFieldPickable = (flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number): boolean => {
    const geographyHierarchyField = getHierarchyField(geographyHierarchyFields, flightGroupIndex, flightIndex, subFlightIndex)
    const mediaHierarchyField = getHierarchyField(mediaHierarchyFields, flightGroupIndex, flightIndex, subFlightIndex)

    return !!(currentField && geographyHierarchyField && mediaHierarchyField)
  }

  const getGeographyHierarchyFieldValue = (flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number): number => {
    const geographyHierarchyField = getHierarchyField(geographyHierarchyFields, flightGroupIndex, flightIndex, subFlightIndex)
    const geographyData = getFieldData(geographyHierarchyField, flightGroupIndex, flightIndex, subFlightIndex)
    const geographyColumnName = getFieldColumnName(geographyHierarchyField)

    return geographyData?.[geographyColumnName]
  }

  const getMediaHierarchyFieldValue = (flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number): number => {
    const mediaHierarchyField = getHierarchyField(mediaHierarchyFields, flightGroupIndex, flightIndex, subFlightIndex)
    const mediaData = getFieldData(mediaHierarchyField, flightGroupIndex, flightIndex, subFlightIndex)
    const mediaColumnName = getFieldColumnName(mediaHierarchyField)

    return mediaData?.[mediaColumnName]
  }

  return {
    getIsMasteredHierarchyFieldPickable,
    getGeographyHierarchyFieldValue,
    getMediaHierarchyFieldValue
  }
}
