import React, { useCallback } from 'react'
import { DeleteFilled, StarFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { isFieldAvailableOrDefault, IMediaPlanTemplateFields, getCorrectDisplayField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
interface IProps {
  mediaPlanField: IMediaPlanTemplateFields
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
}

export const FlightTableHeaderComponent = (props: IProps) => {
  const {
    mediaPlanField,
    removePlanField
  } = props
  const handleDelete = useCallback(() => {
    removePlanField(mediaPlanField)
  }, [removePlanField, mediaPlanField])

  const defaultOrAvailable = isFieldAvailableOrDefault(mediaPlanField)
  const fieldLabel = getCorrectDisplayField(mediaPlanField)

  return (
    <div
      data-testid='flightGroup__header'
      className='flightGroup__header'
    >

      <div className='flightGroup__headerButton'/>
      <div className='flightGroup__headerOuterLabel' data-testid='flight-group-label'>
        {defaultOrAvailable ? (
          <Tooltip title='Delete flight field'>
            <i
              className='flightGroup__icon -right'
              data-testid='btn-flight-delete-field'
              onClick={handleDelete}
            >
              <DeleteFilled />
            </i>
          </Tooltip>
        ) : (
          <Tooltip title='Mandatory flight field'>
            <i
              className="flightGroup__icon -right">
              <StarFilled />
            </i>
          </Tooltip>
        )}
        <div className="flightGroup__headerLabel">{fieldLabel}</div>
      </div>

    </div>
  )
}

export default FlightTableHeaderComponent
