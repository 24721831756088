import React from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import ModalComponent from 'Components/ModalComponent'
import { IDraggableModalProps } from 'Containers/MediaPlanVersion/DraggableModalContainer'

export const DraggableModalComponent = ({
  modalBody,
  footer,
  title,
  open,
  onCancel,
  style,
  width = 520,
  ...props
}: IDraggableModalProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: 'draggable'
    })

  const customStyle = {
    transform: transform && CSS.Translate.toString(transform),
    zIndex: isDragging ? 1 : 0
  }

  return (
    <ModalComponent
      footer={footer}
      onCancel={onCancel}
      modalBody={modalBody}
      title={<div {...listeners} {...attributes}>{title}</div>}
      open={open}
      width={width}
      modalRender={(modal) => (
        <div
          ref={setNodeRef}
          className='draggable-modal-container'
          style={
            {
              ...customStyle,
              ...style
            }}
        >
          {modal}
        </div>
      )}
      {...props}
    />
  )
}

export default DraggableModalComponent
