import moment from 'moment'
import { MediaPlanSubFlightMediaPlanFlight, MediaPlanSubFlight } from 'Apis/generated/mediaPlanVersionsApi'
import { MediaPlanSubFlightModelExtended } from 'Containers/MediaPlanVersion/SubFlightFieldContainer'
import { toISOString } from 'Helpers/calendarHelper'
import { generateTemporaryId } from 'Helpers/commonUtils'
import { Nullable } from 'Helpers/INullable'

export interface IFlight extends Omit<MediaPlanSubFlightMediaPlanFlight, 'subFlights'> {
  merge: boolean
  width: string
  subFlights: Nullable<MediaPlanSubFlightModelExtended[]>
}

/**
* Creates a deep copy of Flight,
* assigning a temporary Id to the resulting Flight and its nested entities
**/
export const cloneFlight = (flight: IFlight) => ({
  ...flight,
  mediaPlanFlightId: generateTemporaryId(),
  subFlights: flight.subFlights && flight.subFlights.map(cloneSubFlight)
})

const cloneSubFlight = (subFlight) => ({
  ...subFlight,
  mediaPlanSubFlightId: generateTemporaryId()
})

export const cloneSubFlightShiftingDates = (subFlight: MediaPlanSubFlight, shiftInDays: number) => ({
  ...cloneSubFlight(subFlight),
  subFlightStartDate: toISOString(moment.utc(subFlight.subFlightStartDate).add(shiftInDays, 'day')),
  subFlightEndDate: toISOString(moment.utc(subFlight.subFlightEndDate).add(shiftInDays, 'day'))
})
