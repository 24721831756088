import React from 'react'

type DivElementType = React.JSX.IntrinsicElements['div']

interface IProps extends DivElementType {
  title: string
}

export const FrontSheetTableComponent: React.FC<IProps> = ({ children, title, ...props }) => (
  <section className='single-table-wrapper' {...props}>
    <div className='single-table-header'>
      <h3 className='single-table-title'>{title}</h3>
    </div>
    {children}
  </section>
)

export default FrontSheetTableComponent
