import React, { FunctionComponent, useCallback } from 'react'
import { useMindshareSelector } from '@mindshare/layout'
import { RootState } from 'Reducers/index'
import { message } from 'antd'
import { CalculationResults, CalculationFieldResult } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import TemplateExampleHeaderComponent from 'Components/TemplateExample/TemplateExampleHeaderComponent'
import { useFieldLevels } from 'Hooks/useFieldLevels'
import { useFunctions } from 'Hooks/useFunctions'

interface ICalculationHeader {
  planInfo: React.JSX.Element
  calculationMode: boolean
  setCalculationMode: React.Dispatch<React.SetStateAction<boolean>>
  enableToggle: boolean
  headerTitle: string
  mediaPlanTemplateId: number
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]
  selectedCalculatedField: IMediaPlanTemplateFields
  setMediaPlanTemplateFields: React.Dispatch<React.SetStateAction<IMediaPlanTemplateFields>>
  setCalculatedAggregatedFields: React.Dispatch<React.SetStateAction<IMediaPlanTemplateFields[]>>
  updateCalculationInArray: (result: CalculationFieldResult[]) => void
  runCalculation: (save: boolean, id: number, currentClientId: number, body: any) => Promise<CalculationResults>
}

export const CalculationHeaderContainer: FunctionComponent<ICalculationHeader> = ({
  planInfo,
  calculationMode,
  setCalculationMode,
  enableToggle,
  headerTitle,
  mediaPlanTemplateId,
  mediaPlanTemplateFields,
  setMediaPlanTemplateFields,
  setCalculatedAggregatedFields,
  selectedCalculatedField,
  updateCalculationInArray,
  runCalculation
}) => {
  const currentClientId = useMindshareSelector((state: RootState) => state.app.currentClient.id) as number
  const { data: calculationFunctions } = useFunctions()
  const { data: fieldLevels } = useFieldLevels()

  const expressionHandler = useCallback((value: string) =>
    setMediaPlanTemplateFields({ ...selectedCalculatedField, calculation: value })
  , [selectedCalculatedField, setMediaPlanTemplateFields])

  const onFunctionSelected = useCallback((functionId) =>
    setMediaPlanTemplateFields({ ...selectedCalculatedField, functionId: Number(functionId) })
  , [selectedCalculatedField, setMediaPlanTemplateFields])

  const fieldCalculatedHandler = useCallback((c: string) =>
    setMediaPlanTemplateFields(JSON.parse(c) as IMediaPlanTemplateFields)
  , [setMediaPlanTemplateFields])

  const updateFields = useCallback((selected: IMediaPlanTemplateFields, result: CalculationResults) => {
    const index = mediaPlanTemplateFields.findIndex(c => c.mediaPlanTemplateFieldId === selected.mediaPlanTemplateFieldId)
    const newDefaultValue = result.fieldResults.find(field => field.fieldId === selectedCalculatedField.clientMediaPlanFieldId).result
    const currentField = {
      ...mediaPlanTemplateFields[index],
      calculation: selected.calculation,
      functionId: selected.functionId,
      clientMediaPlanField: {
        ...mediaPlanTemplateFields[index].clientMediaPlanField,
        mediaPlanField: {
          ...mediaPlanTemplateFields[index].clientMediaPlanField.mediaPlanField,
          defaultValue: newDefaultValue
        }
      }
    }
    const mediaPlanField = {
      ...selectedCalculatedField.clientMediaPlanField.mediaPlanField,
      defaultValue: newDefaultValue
    }
    const clientMediaPlanField = { ...selectedCalculatedField.clientMediaPlanField, mediaPlanField }
    const newSelectedField = { ...selectedCalculatedField, clientMediaPlanField }
    const newItems = mediaPlanTemplateFields.map((mpf, i) => i === index ? currentField : mpf)
    setMediaPlanTemplateFields(newSelectedField)
    setCalculatedAggregatedFields(newItems)
    updateCalculationInArray(result.fieldResults)
  }, [
    mediaPlanTemplateFields, selectedCalculatedField,
    setCalculatedAggregatedFields, setMediaPlanTemplateFields, updateCalculationInArray
  ])

  const handleCalculation = useCallback(async (selected: IMediaPlanTemplateFields) => {
    message.loading('Running calculation')
    const result = await runCalculation(true, mediaPlanTemplateId, currentClientId, [selected])
    message.destroy()
    if (result.topLevelErrors.length > 0 || result.fieldResults.some(c => c.error)) {
      const errors = result.topLevelErrors.length > 0 ? result.topLevelErrors : result.fieldResults.map(c => c.error)
      message.error({ content: `${errors && errors.map(e => e)}` })
    } else {
      updateFields(selected, result)
      message.success('Calculation set successfully')
    }
  }, [mediaPlanTemplateId, currentClientId, runCalculation, updateFields])

  return (
    <TemplateExampleHeaderComponent
      calculationMode={calculationMode}
      planInfo={planInfo}
      setCalculationMode={setCalculationMode}
      headerTitle={headerTitle}
      mediaPlanTemplateFields={mediaPlanTemplateFields}
      calculationFunctions={calculationFunctions}
      handleCalculation={handleCalculation}
      fieldCalculatedHandler={fieldCalculatedHandler}
      expressionHandler={expressionHandler}
      selectedCalculatedMediaField={selectedCalculatedField}
      onFunctionSelected={onFunctionSelected}
      fieldLevels={fieldLevels}
      enableToggle={enableToggle}
    />
  )
}

export default CalculationHeaderContainer
