import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { LoadingContainer, MSNavigationBlocker, useRouteNavigation, useRouteParams } from '@mindshare/layout'

import {
  addFrontSheetView,
  cleanUpFrontSheetSelectFieldsContainer,
  deleteFrontSheetView,
  updateFrontSheet,
  updateFrontSheetViewAggregateData,
  updateFrontSheetViewGroupByData,
  updateFrontSheetViewLaydownField,
  updateFrontSheetViewName,
  initialiseFrontSheetSelectFieldsContainer
} from 'Actions/frontSheetActions'
import { RootState } from 'Reducers/index'
import { FrontSheetViewAggregate, FrontSheetViewGroupBy } from 'Apis/generated/frontSheetsApi'
import FrontSheetSelectFieldsComponent from 'Components/FrontSheets/FrontSheetSelectFields/FrontSheetSelectFieldsComponent'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useFrontSheetMasteredHierarchiesData } from 'Components/FrontSheets/hooks/useFrontSheetMasteredData'
import { useFieldLevels } from 'Hooks/useFieldLevels'
import { selectCurrentFrontSheet } from '../../../selectors'
import { useAppDispatch, useAppSelector } from '../../../store'

interface IProps {
  frontSheetId: number
}

export const FrontSheetSelectFieldsContainer: React.FC<IProps> = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()

  const [currentTabView, setCurrentTabView] = useState<number>(0)
  const [editingViewName, setEditingViewName] = useState(false)
  const [newViewName, setNewViewName] = useState<string>('')

  const currentClientID = useAppSelector((state) => state.app.currentClient.id)
  const currentFrontSheet = useAppSelector(selectCurrentFrontSheet)
  const { data: fieldLevels } = useFieldLevels()
  const { data: planHierarchies } = useHierarchies(currentClientID)
  const saveSuccess = useAppSelector((state) => state.frontSheets.frontSheetSelectFieldsSaveStatus.saveSuccess)
  const saveInProgress = useAppSelector((state) => state.frontSheets.frontSheetSelectFieldsSaveStatus.saveInProgress)

  const aggregateData = currentFrontSheet.frontSheetViews?.[currentTabView]?.frontSheetViewAggregates
  const groupByData = currentFrontSheet.frontSheetViews?.[currentTabView]?.frontSheetViewGroupBys
  const isNavigationBlocked = !!(saveInProgress || !saveSuccess)
  const { currentMasteredHierarchies } = useFrontSheetMasteredHierarchiesData()
  const hierarchies = useMemo(() => ({ ...planHierarchies, ...currentMasteredHierarchies }), [currentMasteredHierarchies, planHierarchies])

  useEffect(() => {
    return () => {
      message.destroy()
      dispatch(cleanUpFrontSheetSelectFieldsContainer())
    }
  }, [dispatch])

  const renderSaveErrorMessage = useCallback((error) => {
    message.destroy()
    message.error({ content: error, duration: 10, key: 'messageKey' })
  }, [])

  const updateAggregates = useCallback((newAggregateData: FrontSheetViewAggregate[]) =>
    dispatch(updateFrontSheetViewAggregateData(currentTabView, newAggregateData))
  , [dispatch, currentTabView])

  const updateGroupBys = useCallback((newGroupByData: FrontSheetViewGroupBy[]) =>
    dispatch(updateFrontSheetViewGroupByData(currentTabView, newGroupByData))
  , [dispatch, currentTabView])

  const buttonActions = useMemo(
    () => ({
      aggregate: {
        addRow: () =>
          updateAggregates([
            ...aggregateData,
            {
              ...aggregateData[0],
              clientMediaPlanFieldId: -1,
              frontSheetViewAggregateId: -1,
              sortOrder: aggregateData.length
            }
          ]),
        removeRow: (index: number) =>
          updateAggregates(
            aggregateData
              .filter((x, i) => i !== index)
              .map((x, i) => ({ ...x, sortOrder: i }))
          ),
        updateFieldName: (index: number, val: string) =>
          updateAggregates(
            aggregateData.map((x, i) =>
              i === index ? { ...x, clientMediaPlanFieldId: Number(val) } : x
            )
          ),
        updateSortOrder: (newAggregateData: FrontSheetViewAggregate[]) =>
          updateAggregates(
            newAggregateData.map((x, i) => ({ ...x, sortOrder: i }))
          )
      },
      groupBy: {
        addRow: () =>
          updateGroupBys([
            ...groupByData,
            {
              ...groupByData[0],
              clientMediaPlanFieldId: -1,
              frontSheetViewGroupById: -1,
              sortOrder: groupByData.length,
              subtotalCutOff: false
            }
          ]),
        removeRow: (index: number) =>
          updateGroupBys(
            groupByData
              .filter((x, i) => i !== index)
              .map((x, i) => ({ ...x, sortOrder: i }))
          ),
        updateFieldName: (index: number, val: string) =>
          updateGroupBys(
            groupByData.map((x, i) =>
              i === index
                ? {
                  ...x,
                  clientMediaPlanFieldId: Number(val),
                  hierarchyLevelNumber: null
                }
                : x
            )
          ),
        updateHierarchyLevel: (index: number, value: number) =>
          updateGroupBys(
            groupByData.map((x, i) =>
              i === index ? { ...x, hierarchyLevelNumber: value } : x
            )
          ),
        updateSortOrder: (newGroupByData: FrontSheetViewGroupBy[]) =>
          updateGroupBys(
            newGroupByData.map((x, i) => ({ ...x, sortOrder: i }))
          ),
        updateSubtotal: (index: number) =>
          updateGroupBys(
            groupByData.map((x, i) =>
              i === index
                ? { ...x, subtotalCutOff: true }
                : { ...x, subtotalCutOff: false }
            )
          )
      },
      navigateBack: () =>
        navigate(
          `/edit-front-sheet/${currentFrontSheet.frontSheetId}?clientId=${currentClientID}`,
          false
        ),
      save: async () => {
        message.destroy()
        message.loading({ content: 'Saving...', key: 'savingMessage' })

        const newFrontSheet = {
          ...currentFrontSheet,
          frontSheetViews: currentFrontSheet.frontSheetViews.map((x, i) =>
            i === currentTabView
              ? { ...x, frontSheetViewGroupBys: groupByData }
              : x
          )
        }
        const saveResult = await dispatch(
          updateFrontSheet(currentClientID, newFrontSheet)
        )
        message.destroy()
        if (saveResult) {
          renderSaveErrorMessage(saveResult)
        } else {
          message.success({
            content: 'Successfully saved front sheets',
            key: 'savingMessage'
          })
        }
      },
      tabs: {
        addView: () => {
          dispatch(addFrontSheetView())
        },
        deleteView: () => {
          if (currentTabView > 0) {
            setCurrentTabView(currentTabView - 1)
          }
          dispatch(deleteFrontSheetView(currentTabView))
        },
        editViewName: () => {
          setEditingViewName(true)
          setNewViewName(
            currentFrontSheet.frontSheetViews[currentTabView].frontSheetViewName
          )
        },
        updateViewName: () => {
          dispatch(updateFrontSheetViewName(currentTabView, newViewName))
          setEditingViewName(false)
        },
        updateViewField: (clientMediaPlanField: IClientMediaPlanField) => {
          dispatch(
            updateFrontSheetViewLaydownField(
              currentTabView,
              clientMediaPlanField
            )
          )
        }
      }
    }),
    [
      navigate,
      dispatch,
      aggregateData,
      currentClientID,
      currentFrontSheet,
      currentTabView,
      groupByData,
      newViewName,
      renderSaveErrorMessage,
      updateAggregates,
      updateGroupBys
    ]
  ) // TODO: Deconstruct this memoised object into smaller parts with less dependencies

  return (
    <MSNavigationBlocker isActive={isNavigationBlocked}>
      <FrontSheetSelectFieldsComponent
        aggregateData={aggregateData}
        aggregatedClientMediaPlanFields={currentFrontSheet.aggregatedClientMediaPlanFields}
        buttonActions={buttonActions}
        clientMediaPlanFields={currentFrontSheet.clientMediaPlanFields}
        clientId={currentClientID}
        currentFrontSheet={currentFrontSheet}
        currentTabView={currentTabView}
        editingViewName={editingViewName}
        fieldLevels={fieldLevels}
        groupByData={groupByData}
        hierarchies={hierarchies}
        newViewName={newViewName}
        saveInProgress={saveInProgress}
        saveSuccess={saveSuccess}
        setCurrentTabView={setCurrentTabView}
        setNewViewName={setNewViewName}
      />
    </MSNavigationBlocker>
  )
}


export const FrontSheetSelectFieldsContainerLoading: React.FC = (props) => {
  const frontSheetId = useRouteParams('frontSheetId', Number)
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)

  return (
    <LoadingContainer
      appDataSuccess={true}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={
        (id) => initialiseFrontSheetSelectFieldsContainer(id, { frontSheetId, currentClientId })
      }
      loadingSelector={(state: RootState) => state.frontSheets.frontSheetSelectFieldsContainerLoading}
    >
      <FrontSheetSelectFieldsContainer {...props} frontSheetId={frontSheetId} />
    </LoadingContainer>
  )
}

export default FrontSheetSelectFieldsContainerLoading
