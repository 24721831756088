import { Guid } from 'guid-typescript'
import { ILoadingResult } from '@mindshare/layout'

import { IFrontSheetActions } from 'Actions/frontSheetActions'
import { FrontSheet } from 'Apis/generated/frontSheetsApi'
import * as actionTypes from 'Constants/actionTypes'
import { ICurrentFrontSheetView, IFrontSheetSelectFieldsSaveStatus } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import { newFrontSheetViewAggregateRow, newFrontSheetViewGroupByRow } from 'Components/FrontSheets/constants/entities/FrontSheetViews'

export interface IFrontSheetState {
  currentFrontSheet: FrontSheet
  currentFrontSheetView: ICurrentFrontSheetView
  frontSheetListContainerLoading: ILoadingResult
  frontSheetSelectFieldsContainerLoading: ILoadingResult
  frontSheetSelectPlansContainerLoading: ILoadingResult
  frontSheetViewContainerLoading: ILoadingResult
  frontSheets: FrontSheet[]
  frontSheetSelectFieldsSaveStatus: IFrontSheetSelectFieldsSaveStatus
}

export const initialState: IFrontSheetState = {
  currentFrontSheet: {
    mediaPlanIds: []
  } as any,
  currentFrontSheetView: null,
  frontSheetListContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  frontSheetSelectFieldsContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  frontSheetSelectPlansContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  frontSheetViewContainerLoading: {
    loadingId: Guid.createEmpty(),
    error: ''
  },
  frontSheets: [],
  frontSheetSelectFieldsSaveStatus: { saveInProgress: false, saveSuccess: true }
}

export default function (state: IFrontSheetState = initialState, action: IFrontSheetActions): IFrontSheetState {
  switch (action.type) {
    case actionTypes.INITIALISE_FRONT_SHEET_LIST_CONTAINER: {
      return {
        ...state,
        frontSheetListContainerLoading: action.payload
      }
    }
    case actionTypes.INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER: {
      return {
        ...state,
        frontSheetSelectFieldsContainerLoading: action.payload
      }
    }
    case actionTypes.INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER: {
      return {
        ...state,
        frontSheetSelectPlansContainerLoading: action.payload
      }
    }
    case actionTypes.INITIALISE_FRONT_SHEET_VIEW_CONTAINER: {
      return {
        ...state,
        frontSheetViewContainerLoading: action.payload
      }
    }
    case actionTypes.GET_FRONT_SHEETS: {
      return {
        ...state,
        frontSheets: action.payload
      }
    }
    case actionTypes.GET_FRONT_SHEET_BY_ID: {
      return {
        ...state,
        currentFrontSheet: action.payload
      }
    }
    case actionTypes.GET_FRONT_SHEET_VIEW: {
      return {
        ...state,
        currentFrontSheetView: action.payload
      }
    }
    case actionTypes.UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          frontSheetViews: state.currentFrontSheet.frontSheetViews?.map(
            (x, i) => (i === action.selectedTab ? { ...x, frontSheetViewAggregates: action.payload } : x)
          )
        }
      }
    }
    case actionTypes.UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          frontSheetViews: state.currentFrontSheet.frontSheetViews?.map(
            (x, i) => (i === action.selectedTab ? { ...x, frontSheetViewGroupBys: action.payload } : x)
          )
        }
      }
    }
    case actionTypes.UPDATE_FRONT_SHEET_VIEW_NAME: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          frontSheetViews: state.currentFrontSheet.frontSheetViews?.map(
            (x, i) => (i === action.selectedTab ? { ...x, frontSheetViewName: action.payload } : x)
          )
        }
      }
    }
    case actionTypes.UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          frontSheetViews: state.currentFrontSheet.frontSheetViews?.map(
            (x, i) => (i === action.selectedTab ? { ...x, laydownFieldId: action.payload.clientMediaPlanFieldId } : x)
          )
        }
      }
    }
    case actionTypes.UPDATE_FRONT_SHEET_CHECKED_PLANS: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          mediaPlanIds: action.payload
        }
      }
    }
    case actionTypes.UPDATE_FRONT_SHEET_NOTES: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          notes: action.payload
        }
      }
    }
    case actionTypes.ADD_FRONT_SHEET_VIEW: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          frontSheetViews: [
            ...state.currentFrontSheet.frontSheetViews,
            {
              frontSheetId: state.currentFrontSheet.frontSheetId,
              frontSheetViewId: 0,
              laydownFieldId: null,
              frontSheetViewName: 'New Front Sheet View',
              sortOrder: state.currentFrontSheet.frontSheetViews?.length,
              frontSheetViewAggregates: [
                {
                  ...newFrontSheetViewAggregateRow,
                  clientMediaPlanFieldId:
                    state.currentFrontSheet.aggregatedClientMediaPlanFields?.[0].clientMediaPlanFieldId
                }
              ],
              frontSheetViewGroupBys: [
                {
                  ...newFrontSheetViewGroupByRow,
                  clientMediaPlanFieldId: state.currentFrontSheet.clientMediaPlanFields?.[0].clientMediaPlanFieldId
                }
              ]
            }
          ]
        }
      }
    }
    case actionTypes.DELETE_FRONT_SHEET_VIEW: {
      return {
        ...state,
        currentFrontSheet: {
          ...state.currentFrontSheet,
          frontSheetViews: state.currentFrontSheet.frontSheetViews?.filter((x, i) => i !== action.selectedTab)
        }
      }
    }
    case actionTypes.RESET_CURRENT_FRONT_SHEET_STATE: {
      return {
        ...state,
        currentFrontSheet: initialState.currentFrontSheet
      }
    }
    case actionTypes.UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE: {
      return {
        ...state,
        frontSheetSelectFieldsSaveStatus: action.payload
      }
    }
    default:
      return state
  }
}
