import { useEffect, useMemo } from 'react'
import Selection from '@simonwep/selection-js'
import { fillFlightGroups } from 'Actions/mediaPlansActions'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFieldColumnName, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IPoint } from 'Helpers/selectionHelper'
import { useAppDispatch } from '../store'
import { useValueRef } from './useValueRef'

const toggleRowState = (currentEl: Element, selectedColumnIndexes: number[], selectedRowIndex: number, action: (cell: Element) => void): void => {
  const rowDataKey = currentEl.getAttribute('data-key')
  if (!rowDataKey) return
  const rowNumber = Number(rowDataKey.split('-')[1])
  const cells = document.querySelectorAll('.flightGroup__field')
  cells.forEach(cell => {
    const cellDataKey = cell.getAttribute('data-key')
    if (!cellDataKey) return
    const cellRowNumber = Number(cellDataKey.split('-')[1])
    const cellColumnNumber = Number(cellDataKey.split('-')[2])
    if (cellRowNumber > selectedRowIndex && cellRowNumber === rowNumber && selectedColumnIndexes.some(i => i === cellColumnNumber)) {
      action(cell)
    }
  })
}

export const useFlightGroupAutoFill = ({
  filteredFlightGroups,
  flightGroupFields,
  flightGroupSelection,
  checkCanCopySelection
}: {
  filteredFlightGroups: IFlightGroup[]
  flightGroupFields: IMediaPlanTemplateFields[]
  flightGroupSelection: Partial<IFlightGroup>
  checkCanCopySelection: (flightGroupSelection: Partial<IFlightGroup>) => boolean
}) => {
  const dispatch = useAppDispatch()
  const flightGroupsRef = useValueRef<IFlightGroup[]>(filteredFlightGroups)
  const flightGroupFieldsRef = useValueRef<IMediaPlanTemplateFields[]>(flightGroupFields)

  const fillRect = useMemo((): {
    selectedFieldsIndexes: number[]
    endPoint: IPoint
  } => {
    const isValidSelection = checkCanCopySelection(flightGroupSelection)
    if (!isValidSelection) return null
    let lastFlightGroupIndex = -1
    let lastFlightGroupFieldIndex = -1
    let selectedFieldsIndexes: number[] = []
    flightGroupsRef.current.forEach((fg, fgIndex) => {
      Object.keys(flightGroupSelection).forEach(flightGroupId => {
        if (fg.mediaPlanFlightGroupId.toString() === flightGroupId && fgIndex > lastFlightGroupIndex) {
          lastFlightGroupIndex = fgIndex
        }
      })
    })
    if (lastFlightGroupIndex !== -1) {
      flightGroupFieldsRef.current.forEach((field, flIndex) => {
        Object.keys(flightGroupSelection[flightGroupsRef.current[lastFlightGroupIndex].mediaPlanFlightGroupId]).forEach(flightGroupFieldName => {
          if (getFieldColumnName(field) === flightGroupFieldName && flIndex > lastFlightGroupFieldIndex) {
            selectedFieldsIndexes = [...selectedFieldsIndexes, flIndex]
            lastFlightGroupFieldIndex = flIndex
          }
        })
      })
    }
    return {
      selectedFieldsIndexes,
      endPoint: {
        flightGroupIndex: lastFlightGroupIndex,
        flightGroupFieldIndex: lastFlightGroupFieldIndex
      }
    }
  }, [checkCanCopySelection, flightGroupSelection, flightGroupFieldsRef, flightGroupsRef])

  useEffect(() => {
    let select = null
    if (flightGroupSelection && Object.keys(flightGroupSelection).length > 0) {
      select = Selection.create({
        class: 'selection',
        selectables: ['.flightGroup__field'],
        boundaries: ['.msCalendar']
      }).on('beforestart', evt => {
        const el = evt.oe.target as Element
        return el.classList.contains('fill-handle')
      }).on('move', ({ changed: { removed }, selected }) => {
        for (const el of removed) {
          toggleRowState(
            el,
            fillRect.selectedFieldsIndexes,
            fillRect.endPoint.flightGroupIndex,
            cell => cell.classList.remove('-valid')
          )
        }
        for (const el of selected) {
          toggleRowState(
            el,
            fillRect.selectedFieldsIndexes,
            fillRect.endPoint.flightGroupIndex,
            cell => cell.classList.add('-valid')
          )
        }
      }).on('stop', ({ inst, selected }: Selection.SelectionEvent) => {
        for (const el of selected) {
          toggleRowState(
            el,
            fillRect.selectedFieldsIndexes,
            fillRect.endPoint.flightGroupIndex,
            cell => cell.classList.remove('-valid')
          )
        }
        inst.clearSelection()

        const selectedFlightGroupFieldKeys = selected.map(s => s.getAttribute('data-key')).filter(s => s)
        if (selectedFlightGroupFieldKeys.length > 1) {
          const flightGroupIds = Object.keys(flightGroupSelection)
          const flightGroupsToUpdate = flightGroupsRef.current.slice(
            fillRect.endPoint.flightGroupIndex + 1,
            Number(selectedFlightGroupFieldKeys[selectedFlightGroupFieldKeys.length - 1].split('-')[1]) + 1
          ).map((fg, index) => {
            const currentSelectedFlightValues = Object.values(flightGroupSelection)[index < flightGroupIds.length ? index : index % flightGroupIds.length]
            Object.entries(currentSelectedFlightValues).forEach(([key, value]) => {
              // eslint-disable-next-line functional/immutable-data
              fg[key] = value
            })
            return fg
          })
          if (flightGroupsToUpdate.length > 0) {
            dispatch(fillFlightGroups({
              flightGroups: flightGroupsToUpdate
            }))
          }
        }
      })
    }

    return () => select && select.destroy()
  }, [dispatch, flightGroupSelection, flightGroupsRef, fillRect])

  return useMemo(() => ({
    fillPoint: fillRect?.endPoint
  }), [fillRect])
}
