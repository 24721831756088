import React from 'react'
import { Select } from 'antd'

export const FilterOperatorSelector = ({
  value,
  disabled = false,
  options,
  onChange = null
}) => (
  <Select
    className='select select--fluid'
    placeholder='Operators'
    onChange={onChange}
    data-testid='custom-export-operator-type'
    optionFilterProp='children'
    allowClear={true}
    showSearch={true}
    value={value || null}
    disabled={disabled}
  >
    {options
      .map(el => (
        <Select.Option
          key={el.fieldDataTypeOperatorId}
          value={el.fieldDataTypeOperatorId}
        >
          {el.fieldDataTypeOperator}
        </Select.Option>
      ))}
  </Select>
)
