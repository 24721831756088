const text = `# Media Plan Input

Information on the different sections, functionality and rules present in the plan input page.

------



## Select calendar dates

This information is necessary to build the plan but can be updated at any point by clicking on the set calendar dates button.

### Week view

Will display a calendar with week long blocks for drawing flights in a flowchart style.

### Table view

This will display all flight group and flight inputs in a table rather than a flowchart. 
You can switch to table view at any point BUT must save the plan before switching from table to calendar view.

You must also save the plan for the view you are using for it to be the chosen view when it is opened again or exported.

This option is disabled if the plan has the option to use sub flight fields.

### Start day of the week

Any value can be selected and will only change how the calendar and flowchart/grid for drawing flights will be displayed. 
A flight can still be drawn between any dates you choose.

**Note** - The week number displayed in the plan is not carried through with the plan data to reports. 
Reports will display a week number based on the week type selected in the calendar/date section of the report.

### Start and end date

These define when the plan calendar starts and ends. 
Please note that if the start date does not align with the chosen start day of the week, in the calendar displayed in the plan 
the start date of the first week may be before the start date of the calendar you have input BUT flights can only be input from the start date you have entered.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Creating%20a%20Media%20Plan.mp4) 
to see how to create a new media plan and how to set or edit the calendar dates in the plan.*

## Structure

A field can exist at multiple levels of a plan but its behaviour will be different depending what level it is at e.g. Net Cost.

**Plan level** - fields which are relevant to the whole plan or are a summary of data at a lower level of the plan e.g. the sum of Net Cost in the plan.

**Flight Group level** - fields which can apply to or summarise multiple flights. These are displayed as columns and a single row can apply to multiple flights.

**Flight level** - any data that can be applied to a single flight and not broken down within that flight e.g. flight status.

**Sub Flight level** - any data that can be a break down of the flight level e.g. GRPs input by week within a month-long flight  (not visible/editable in table view).

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Media%20Plan%20Template%20and%20Terminology.mp4) 
to see where each of these elements are in the media plan.*

### Adding fields

To add a field at plan or flight group level click the appropriate button in the toolbar in the middle of the page. 
This will provide a dropdown in the section you have chosen where you can choose a field to add from the list of available fields.

If there are no fields that have been set as available in the template selected then the add buttons won't be able to add anything.

To add a flight or sub flight level field, click the edit list button below the flight level fields. This will add a dropdown where you can select an available field to add.

The edit list button will not show if the chosen template has no available flight or sub flight fields to add.

### Removing fields

To remove a field at any level, click the Trash icon in the top right. This is only possible on default and available fields.

Removing the field will also delete data input in this field.

Removing a calculated field will not delete the formula. If you re-add the field, it will appear empty. The calculation must be triggered for the value to display. 
To do this, you will have to update one of the values that is used in this calculation.

------



## Flight section

### Calendar view - Drawing flights

When inputting/editing a flight, if you select dates that overlap with another flight in that flight group, you will overwrite that flight.

When a flight is drawn all flight level fields for that flight group will merge across the same dates. 
Sub flights will remain unmerged until you actively merge them. Each sub flight field must be merged separately.

Flights can be drawn in 2 different ways:

**Drag to select** - you must start the drag from *outside* the grid (above, below or to the side) 
and only select one row for a flight level field. If you select more than one row you will only be given the option to delete the contents of your selection. 
Once you have released the drag a modal will display and you can edit/confirm the start/end dates of the flight. 
The dates will default to the start and end date of the cells selected on the grid but can be edited.

**Right click** - right click a cell you want to be contained in the flight, select the dates you want and click merge. 
If you want to draw a flight/sub flight that is only a week long you must right click that week and click merge for the flight/sub flight to be present.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Creating%20a%20new%20flight%20in%20MAP.mp4) 
to see how to add a flight using the above described two options: drag to select or right click, 
and [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Creating%20a%20new%20Sub-Flight%20in%20MAP.mp4) 
to see how to add sub flights to your flight.*

### Calendar view - Delete flight

To delete a flight, right click on it and select delete. Bear in mind that this cannot be undone.

### Calendar view - Edit flight dates

To edit the start and end dates of your flight: right click the flight, update the date(s) in the pop up and click merge.

If you update dates for a flight that has sub flights present, you will be notified that changing these dates will delete the sub flights. 
This is because there is no way of knowing how to move/remove the sub flights or distribute their values based on the flight dates.

### Note

When you click on a flight to enter a value, if that flight width goes further than the width of your window, 
the horizontal scroll bar will move to centre the chosen flight.

### Table view - Create flight

When in table view you will see each flight as a row against its parent flight group with flight start and end dates columns. 
You cannot see or edit sub flights.

Every flight group will have at least one flight row present. If a flight has not yet been created then all the flight level information will appear empty. 
To add a new flight to a flight group click the + button furthest right in the flight group section, before the flight start date column. 
This will add a flight to the chosen flight group.

### Table view - Edit flight dates

If you edit the dates for a flight that you have drawn sub flights for in the calendar view, you will be notified that editing these dates will delete the child sub flights. 
This is because there is no way of knowing how to move/remove the sub flights or distribute their values based on the flight dates. 
If you are using sub flights in your plan then the table view probably isn't the right view to use.

### Table view - Delete flight

To delete a flight click the X button to the right of the flight row. Bear in mind that this cannot be undone. 
If you delete the last flight present for a flight group, a single flight row will be present with all fields empty.

------



## Flight group section

### Add flight group

A new empty flight group can be added by clicking the + button to the left of the first flight group field. This will be added to the end of the plan

### Duplicate flight group

The duplicate button to the far left of the flight group will copy the flight group you have selected (including the flights) 
and add it as a new flight group at the bottom of the plan.

### Delete flight group

The delete button next to the left of the flight group will remove the selected flight group. This cannot be undone.

### Expand/Collapse all

You can expand or collapse all flight group columns using the buttons in the tool bar between the plan and flight group levels. 
You can also expand or collapse individual flight groups by clicking on the field name.

### Sort order

The first column in the flight group section will always be the Sort Order, it defines the order in which you see the flight groups. 
Changing this value and clicking out of the cell will move the chosen flight group up or down the list of flight groups, dependent on the number input.

 By default all flight groups added will be given a sort order value of the last flight group sort order +10.

We recommend leaving an increment of around 10 between each flight group sort order value so you have the ability to insert flight groups in between existing flight groups. 
If you have 5 flight groups with sort order 1,2,3,4,5 and you add a new flight group that you want to go in at the second position you will need to increase all the existing 
sort order values from 5 down to 2 to make space for the new flight group.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Adding%20Flight%20Groups.mp4) 
to see how to add, duplicate, remove and expand or collapse flight groups.*

------



## Calculations

### Calculation mode

To view and update calculations present in the plan you must enter calculation mode. 
To do this click the down arrow next to 'Your calculations' below the plan header. 
Once in calculation mode you **cannot** draw flights or enter data in plan fields, you can only edit calculations. 
To close calculation mode click the up arrow.

To enter a calculation, you must select the field you want the calculation result to populate from the dropdown. 
Fields are grouped by level. Once you have selected the field, you can click on the fields values you want to go in to the 
calculation and click in the calculation box to enter mathematical operators / text.

Calculations will show field names as their level followed by their application (unaliased name) within curly brackets e.g. {Flight.Clicks}.

**Set Calculation** will run and test all the calculations and save those that run successfully. 
It will not save the plan itself so if the calculation results change within the plan these results will not be saved until you save the plan.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Editing%20Calculations%20in%20MAP%20.mp4) 
to see how to enter calculation mode, edit a calculation and close the calculation mode.*

### Rules

- A calculated field can refer to any fields at a higher or the same level as itself.
- A number field cannot refer to any string (text) fields.
- If a string field is calculated from other fields it will interpret this as a concatenation of the fields selected and any other text input in the calculation.
- An aggregated field can only sum fields one level below them.
- A field can only refer to fields that have the same or a stricter availability assigned
  - a mandatory field can only refer to other mandatory fields
  - a default field can only refer to default or mandatory fields
  - an available field can refer to available, default and mandatory fields
- A calculated/aggregated field cannot refer to another calculated/aggregated field that hasn't already had its calculation/aggregation set.

### Other info

Each time a user sets a calculation or enters a value in a cell **all** the calculations in the plan must be run again to ensure 
there are no errors in them e.g. a division by zero occurs or circular reference is created.

------



## Planning Stage

There are 3 options for planning stage

- Draft
- Finalized
- Approved

### Rules

- You cannot update a plan to Finalized or Approved without filling in all mandatory fields
- You cannot update a plan to Finalized or Approved until you have saved the plan
- If you save the plan (update the current version or save as new version) the planning stage will be reset to Draft as the tool considers this save as an update/change

Only Finalized and Approved plans will be pushed into reports. Draft plans will only be accessible within the web tool 
and not present in reports e.g. if you have a plan with 4 versions, version 1 is approved and versions 2, 3 and 4 are draft 
then only version 1 will be available in reports.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/MAP%20Plan%20Stage.mp4) 
to see how to use the Planning Stages to ensure that accurate data is recorded in the reports.*

------



## Plan save

When a plan is saved it is just saving plan input data that you can currently see (not calculation formulas). 
If a field is showing Invalid Operation, no data will be saved for this field.

### Update current version

This will overwrite the version you are currently working on and will not increase the version number. This cannot be undone.

### Save as new version

Will save the plan as a version number one higher than the version you were working on.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Saving%20a%20media%20Plan.mp4) 
to see how to save a media plan - either by updating the current version of the plan or by saving a new version of the plan.*

### Note - editing a historic plan version

If you are editing the historic version of a plan, the update current version will update the version you chose to edit. 
Selecting save plan as new version will make the plan you save the latest version of that plan. If you choose a plan with versions 1-10 available, 
edit version 5 and select save as new version, version 5 will remain the same and a version 11 will be created.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/MAP%20Plan%20Version.mp4) 
to see how to view or edit a historic version of a plan, and how to promote it to become the latest version of the plan.*

------



## Input types

Calculated fields cannot be typed in, they will appear filled in grey. 
They will display calculation results and the calculation can be edited in the calculation dropdown in the header.

**Manual input** - user can type in any value in the cell provided it follows the data type rules for that field e.g. 
you can't put letters in Net Cost. For cost fields decimal places (.) and thousand separators(,) are added automatically. 
Percentage fields should be entered as their percentage value, not their decimal equivalent e.g.10% should be input as 10 
(the percentage sign will be inserted automatically).

**Date picker** - For date fields you can select a date from a date picker.

**Dropdown** - user can select any value from the list provided, these are present for several field types:

- Client defined list
- Media partner
- Unique string (user can also type a new value, which will then be added to the global list and appear in the dropdown for that client from then on)

**Tree view** - hierarchies will use a tree view input. It appears the same as dropdown until you click in the cell. 
You will see the structure of the hierarchy as you drill down through levels using the arrow icon next to each value. 
You can select a value from any level. If for your client you have been instructed to input a value from a certain level then you should follow this instruction. 
If a value from a different level is selected it will mean that hierarchy will appear blank in the cube reports

------

*[Click here](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/SitePages/MAP-Training-Videos.aspx) 
to see the full list of bite-sized videos and corresponding 1 page guides of MAP features to help you better understand how to use MAP effectively.*`

export default text
