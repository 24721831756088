import { IColourScheme, IMSStyleCfg } from '@mindshare/layout'

export const colourScheme = (colours: IColourScheme) => `
    .ant-badge .ant-badge-dot, .ant-badge-count {
      background-color: ${colours.primary} !important;
    }`

const appStyles = ({ primary, secondary, tertiary }: IColourScheme) => `
    .flightGroup__headerButton .anticon {
      color: ${primary};
      border: none;
    }

    .flightGroup__header, .flightGroup__totals td,
    .flightHeader__month, .flightHeader__week {
      background-color: ${secondary} !important;
    }

    .template-example-header-container h1 .ant-btn svg,
    .version-list-table .ant-table-cell .ant-btn,
    .goal-seek-number,
    .list-table .virtual-table .ant-table-header .ant-table-thead .ant-table-cell.filter-active .ant-dropdown-trigger {
      color: ${primary};
    }

    .template-example-header-buttons .btn-toggle-mode svg,
    .flightGroup__toolbar .ant-btn,
    .template-plan-field-item,
    .template-plan-field-item .template-plan-columns .item-icons span,
    .rich-text .rich-text__header-button {
      color: ${secondary} !important;
    }

    .header-label-display,
    .flightGroup__toolbar,
    .flight__container .select-create-wrapper .select-create .select-create__control,
    .flight__container .select-create-wrapper .select-create .select-create__control:hover,
    .flight__container .select-create-wrapper .select-create .select-create__control:focus,
    .flight__cell, .flightHeader__title, .flightGroup__headerButton,
    .rich-text .rich-text__title {
      background-color: ${tertiary} !important;
    }

    .flightGroup__container.-selected::after,
    .flightGroup__field.-selected::after {
      background: ${primary}1a !important;
    }
    .flightGroup__container.-selected .-selected::after {
      content:none;
    }

    .flightGroup__container.-copied::after,
    .flightGroup__field.-copied::after {
      border: 2px dashed ${primary};
    }
    .flightGroup__container.-copied .-copied::after {
      content:none;
    }

    .flight__gridItem.-selected::after {
      background: ${primary}1a !important;
    }

    .plan-level-container .container-grid .container-row-item .input-item-label,
    .ms-tabs .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
    .ms-tabs .ant-tabs-content .ant-tabs-tabpane .ant-card .ant-card-body .table-column-titles {
      background-color: ${secondary} !important;
      border-color: ${secondary} !important;
    }

    .flight__container .flight__chosenItem .flight__grid .merge {
      background-color: ${tertiary};
    }

    .flight__container .flight__grid .-available {
      background-color: ${primary}1a !important;
    }
`

export const agencyStyleCfg: IMSStyleCfg = {
  colourScheme,
  appStyles
}
