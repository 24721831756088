import { CalculationFieldResult, CalculationResults } from 'Apis/generated/mediaPlanVersionsApi'
import { formatLowerCaseFirstLetter } from '@mindshare/layout'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { getValueOfCorrectType } from 'Helpers/flightHelper'
import { IMediaPlanVersion } from './IMediaPlanVersion'

export interface IValidationResult {
  fieldResults: CalculationFieldResult[]
}

export const hasCalculationErrors = (calculationFieldResults: CalculationResults) => {
  return calculationFieldResults.topLevelErrors?.length > 0 || calculationFieldResults.fieldResults?.some(c => c.error)
}

export const parseCalculationResult = (calculationResult: CalculationResults, currentMediaPlan: IMediaPlanVersion) => {
  let newPlanData = { ...currentMediaPlan.parseData }
  calculationResult.fieldResults?.forEach(field => {
    const mediaPlanField = currentMediaPlan.mediaPlanVersionFields.find((m) => m.clientMediaPlanFieldId === field.fieldId)
    const column = formatLowerCaseFirstLetter(mediaPlanField?.clientMediaPlanField.mediaPlanField.columnName)
    const resultValue = getValueOfCorrectType(field.result, mediaPlanField?.clientMediaPlanField.mediaPlanField.fieldDataTypeId)
    if (field.fieldLevel === FieldLevelType.PLAN) {
      newPlanData = { ...newPlanData, [column]: resultValue }
    } else if (field.fieldLevel === FieldLevelType.FLIGHT_GROUP) {
      const flightGroupIndex = currentMediaPlan.parseData.flightGroups.findIndex(flightGroup =>
        flightGroup.mediaPlanFlightGroupId === field.instanceId
      )
      newPlanData = {
        ...newPlanData,
        flightGroups: newPlanData.flightGroups.map((flightGroup, index) => index === flightGroupIndex ? { ...flightGroup, [column]: resultValue } : flightGroup)
      }
    } else if (field.fieldLevel === FieldLevelType.FLIGHT) {
      const flightGroups = newPlanData.flightGroups.map(flightGroup => {
        const newFlights = flightGroup.flights.map(f => f.mediaPlanFlightId === field.instanceId ? { ...f, [column]: resultValue } : f)
        return { ...flightGroup, flights: newFlights }
      })

      newPlanData = { ...newPlanData, flightGroups }
    } else if (field.fieldLevel === FieldLevelType.SUB_FLIGHT) {
      const flightGroups = newPlanData.flightGroups.map(flightGroup => {
        const flights = flightGroup.flights.map((flight) => {
          const subFlights = flight.subFlights.map(subFlight =>
            subFlight.mediaPlanSubFlightId === field.instanceId ? { ...subFlight, [column]: resultValue } : subFlight
          )
          return { ...flight, subFlights }
        })

        return { ...flightGroup, flights }
      })

      newPlanData = { ...newPlanData, flightGroups }
    }
  })
  // eslint-disable-next-line functional/immutable-data
  newPlanData.calculatorHash = calculationResult.calculatorHash
  return newPlanData
}

export const genericCalculationFailedErrorMessage = 'There is a problem with the calculations setup or the data in this plan.'
