import { useEffect } from 'react'
import { useValueRef } from 'Hooks/useValueRef'

export const useEventListener = (eventName, handler, element: Node) => {
  const callbackRef = useValueRef(handler)

  useEffect(() => {
    const callback = callbackRef.current

    element.addEventListener(eventName, callback)

    return () => {
      element.removeEventListener(eventName, callback)
    }
  }, [eventName, callbackRef, element])
}
