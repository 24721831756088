import { useMemo } from 'react'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import { createAvailableLinkedLookupOptionsSelector } from '../../../selectors/linkedLookupSelector'
import { useAppSelector } from '../../../store'

export const useAvailableLinkedLookupOptions = params => {
  const selectFieldValue = useMemo(createAvailableLinkedLookupOptionsSelector, [])

  const availableLinkedLookupOptions = useAppSelector(state => selectFieldValue(state, params))

  const memoizedAvailableLinkedLookupOptions = useDeepEqualMemoized(availableLinkedLookupOptions)

  return memoizedAvailableLinkedLookupOptions
}
