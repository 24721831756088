import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { Button } from 'antd'
import { SubtotalConfiguration, SubtotalConfigurationField } from 'Apis/generated/mediaPlanVersionsApi'
import { setSubtotalConfiguration } from 'Actions/mediaPlansActions'
import SubtotalsModalBodyComponent from 'Components/MediaPlanVersion/SubtotalsModalBodyComponent'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { compare } from 'Helpers/sortHelper'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { generateTemporaryId } from 'Helpers/commonUtils'
import { DraggableModalContainer } from 'Containers/MediaPlanVersion/DraggableModalContainer'
import { useAppDispatch, useAppSelector } from '../../store'

interface IProps {
  fields: IMediaPlanTemplateFields[]
  hideModal: () => void
  visible: boolean
}

const title = (
  <div className='draggable-container'>
    Subtotals Configuration
  </div>
)

const emptyItem: SubtotalConfigurationField = {
  subtotalConfigurationFieldId: generateTemporaryId(),
  clientMediaPlanFieldId: null,
  sortOrder: 0
}

const initialConfig: SubtotalConfiguration = {
  subtotalConfigurationId: generateTemporaryId(),
  includeGrandTotal: false,
  subtotalConfigurationFields: []
}

const fieldIsFlightGroupAndInPlan = (field: IMediaPlanTemplateFields): boolean => {
  const { clientMediaPlanField } = field
  return field.isInPlan && clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP && !clientMediaPlanField.isApportioned
}

export const SubtotalsModalContainer: FunctionComponent<IProps> = ({ fields, hideModal, visible }) => {
  const dispatch = useAppDispatch()
  const subtotalConfiguration = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion?.mediaPlan?.subtotalConfiguration)
  const [subtotalCurrentConfig, setSubtotalCurrentConfig] = useState(
    subtotalConfiguration
      ? {
        ...subtotalConfiguration,
        subtotalConfigurationFields: subtotalConfiguration
          .subtotalConfigurationFields.length
          ? [...subtotalConfiguration.subtotalConfigurationFields].sort(
            (a, b) => a.sortOrder - b.sortOrder
          )
          : []
      }
      : initialConfig
  )
  const flightGroupFieldsInPlan = fields.filter(fieldIsFlightGroupAndInPlan).sort((a, b) => compare(a.fieldLabel, b.fieldLabel, true))
  const [showSubtotals, setShowSubtotals] = useState<boolean>(!!subtotalCurrentConfig.subtotalConfigurationFields.length)

  const handleAdd = useCallback((index: number) => {
    if (flightGroupFieldsInPlan.filter((option) => !subtotalCurrentConfig.subtotalConfigurationFields.some((row) =>
      row.clientMediaPlanFieldId === option.clientMediaPlanFieldId
    )).length === 0) {
      return
    }
    setSubtotalCurrentConfig((state) => ({
      ...state,
      subtotalConfigurationFields: [
        ...state.subtotalConfigurationFields.slice(0, index + 1),
        {
          ...emptyItem,
          subtotalConfigurationFieldId: generateTemporaryId()
        },
        ...state.subtotalConfigurationFields.slice(index + 1)
      ]
    }))
  }, [flightGroupFieldsInPlan, subtotalCurrentConfig.subtotalConfigurationFields])

  const handleDelete = useCallback((index: number) => {
    setSubtotalCurrentConfig(state => ({
      ...state,
      subtotalConfigurationFields:
        state.subtotalConfigurationFields.length > 1
          ? state.subtotalConfigurationFields.filter((_, i) => i !== index)
          : [
            {
              ...emptyItem,
              subtotalConfigurationFieldId: generateTemporaryId()
            }
          ]
    }))
  }, [])

  const handleChange = useCallback((value: number, index: number) => {
    setSubtotalCurrentConfig((state) => ({
      ...state,
      subtotalConfigurationFields: [
        ...state.subtotalConfigurationFields.slice(0, index),
        {
          ...state.subtotalConfigurationFields[index],
          clientMediaPlanFieldId: value,
          subtotalConfigurationFieldId: generateTemporaryId()
        },
        ...state.subtotalConfigurationFields.slice(index + 1)
      ]
    }))
  }, [])

  const handleIncludeGrandTotalChange = useCallback((val: boolean) => {
    setSubtotalCurrentConfig((state) => ({ ...state, includeGrandTotal: val }))
  }, [])

  const handleSort = useCallback((items: SubtotalConfigurationField[]) => {
    setSubtotalCurrentConfig((state) => ({
      ...state,
      subtotalConfigurationFields: items
    }))
  }, [])

  const handleApply = useCallback(() => {
    dispatch(setSubtotalConfiguration(subtotalCurrentConfig, true))
    hideModal()
  }, [dispatch, hideModal, subtotalCurrentConfig])

  const handleShowSubtotals = useCallback((value: boolean) => {
    setShowSubtotals(value)

    if (value) {
      setSubtotalCurrentConfig((state) => ({
        ...state,
        subtotalConfigurationFields: [{ ...emptyItem, subtotalConfigurationFieldId: generateTemporaryId() }]
      }))
    } else {
      setSubtotalCurrentConfig((state) => ({
        ...state,
        subtotalConfigurationFields: []
      }))
    }
  }, [])

  const footer = useMemo(() => (
    <>
      <Button onClick={hideModal}>Cancel</Button>
      <Button type='primary' onClick={handleApply}>Apply</Button>
    </>
  ), [handleApply, hideModal])

  return (
    <DraggableModalContainer
      data-testid='subtotals-modal'
      footer={footer}
      onCancel={hideModal}
      title={title}
      open={visible}
      modalBody={
        <SubtotalsModalBodyComponent
          handleAdd={handleAdd}
          handleChange={handleChange}
          handleDelete={handleDelete}
          handleSort={handleSort}
          options={flightGroupFieldsInPlan}
          rows={subtotalCurrentConfig.subtotalConfigurationFields}
          showGrandTotals={subtotalCurrentConfig.includeGrandTotal}
          setShowGrandTotals={handleIncludeGrandTotalChange}
          showSubtotals={showSubtotals}
          handleShowSubtotals={handleShowSubtotals}
        />
      }
    />
  )
}

export default SubtotalsModalContainer
