import React, { FunctionComponent, memo, useCallback, useMemo } from 'react'
import { getClientHierarchyValue, getHierarchyValue, IHierarchyName, IClientHierarchyName, IMSHierarchies } from '@mindshare/layout'

import { Hierarchy, HierarchyLevel } from 'Apis/generated/financeDataApi'
import {
  getFieldColumnName
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { INewUniqueStringObject, IUpdateDataValuesFnParams } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'

import RenderByTypeComponent from 'Components/RenderByTypeComponent'
import { getValueOfCorrectType } from 'Helpers/flightHelper'
import { WizardProgress } from 'Components/GoalSeek/constants/entities/IGoalSeekData'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import {
  useFieldError,
  useFieldId,
  useFieldValue,
  useIsFieldSelectedForRedistribution,
  useIsFieldValidToRedistribute
} from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'

import { HIERARCHY_TYPES_MAP } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { useAvailableLinkedLookupOptions } from 'Components/MediaPlanVersion/hooks/useAvailableLinkedLookupOptions'
import { useSelectedParentHierarchies } from 'Components/MediaPlanVersion/hooks/useSelectedParentHierarchies'
import { useFilteredHierarchies } from 'Hooks/CustomHooks'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  selectGoalSeekModalVisible,
  selectGoalSeekWizardProgress,
  selectNewUniqueStringObject,
  selectPlanEndDate,
  selectPlanStartDate,
  selectRedistributeMode
} from '../../selectors'
import {
  disabledApprove,
  saveNewUniqueString,
  updateGoalSeekModalState,
  updatePlanFlightGroupValue,
  updatePlanFlightValue,
  updatePlanLevelValue,
  updatePlanSubFlightValue,
  validateFlightDate,
  validateOverlapFlights
} from '../../redux/actions/mediaPlansActions'
import { IFieldContainerProps } from './FieldTypeSwitchContainer'

interface IProps extends IFieldContainerProps {
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  isMasteredHierarchyFieldPickable?: boolean
  currentMasteredHierarchies?: Record<string, Hierarchy[] | HierarchyLevel[]>
  cachedMasteredHierarchies?: Partial<IMSHierarchies>
  isLoadingHierarchies?: boolean
}

export const FieldContainer: FunctionComponent<IProps> = ({
  calculationMode,
  defaultClass,
  endDate,
  field,
  flightGroupIndex,
  flightIndex,
  subFlightIndex,
  unfilteredHierarchies,
  masteredListsData,
  merge,
  startDate,
  tokenHandler,
  valid,
  collapsedFlightGroup,
  onClearClipboard,
  linkedLookupFieldsHelperValues,
  expandedHierarchiesValues,
  isCollapsed,
  financeListFieldsData,
  isMasteredHierarchyFieldPickable,
  currentMasteredHierarchies,
  cachedMasteredHierarchies,
  isLoadingHierarchies
}: IProps) => {
  const dispatch = useAppDispatch()
  const columnName = getFieldColumnName(field)

  const {
    getIsLinkedFieldDisabled,
    getClosestReferencedMediaPlanField,
    linkedLookupConnection,
    isEnforcedLink,
    getIsFilteredLookupValuesVisible,
    getReferencedMediaPlanField
  } = linkedLookupFieldsHelperValues?.[field.clientMediaPlanField.mediaPlanFieldId] ?? {
    getIsLinkedFieldDisabled: () => false,
    isEnforcedLink: false,
    dependentMediaPlanField: undefined,
    getClosestReferencedMediaPlanField: () => undefined,
    getIsFilteredLookupValuesVisible: () => false,
    getReferencedMediaPlanField: () => undefined
  }

  const referencedMediaPlanField = getReferencedMediaPlanField(field)
  const closestReferencedMediaPlanField = getClosestReferencedMediaPlanField(flightGroupIndex, flightIndex, subFlightIndex)
  const fieldLabel = referencedMediaPlanField?.fieldLabel || referencedMediaPlanField?.clientMediaPlanField.mediaPlanField?.fieldLabel
  const errorMessage = `Please select a value in field ${fieldLabel}`
  const linkedLookupReferencedFieldColumnName = getFieldColumnName(referencedMediaPlanField)

  const { data: currentClient } = useCurrentClient()

  const planStartDate = useAppSelector(selectPlanStartDate)
  const planEndDate = useAppSelector(selectPlanEndDate)

  const instanceId = useFieldId({
    fieldLevelId: field.clientMediaPlanField.mediaPlanField.fieldLevelId,
    flightGroupIndex,
    flightIndex,
    subFlightIndex
  })
  const fieldValue = useFieldValue({
    fieldLevelId: field.clientMediaPlanField.mediaPlanField.fieldLevelId,
    columnName,
    flightGroupIndex,
    flightIndex,
    subFlightIndex
  })
  const linkedLookupFieldValue = useFieldValue({
    fieldLevelId: closestReferencedMediaPlanField?.clientMediaPlanField.mediaPlanField.fieldLevelId,
    columnName: linkedLookupReferencedFieldColumnName,
    flightGroupIndex,
    flightIndex
  })
  const isLinkedFieldDisabled = getIsLinkedFieldDisabled(linkedLookupFieldValue, field)
  const isFilteredLookupValuesVisible = getIsFilteredLookupValuesVisible(linkedLookupFieldValue)

  const availableLinkedLookupOptions = useAvailableLinkedLookupOptions({
    flightGroupIndex,
    flightIndex,
    linkedLookupConnection,
    referencedMediaPlanField: closestReferencedMediaPlanField
  })

  const selectedHierarchiesIDs = useSelectedParentHierarchies({
    fieldLevelId: field.clientMediaPlanField.mediaPlanField.fieldLevelId,
    flightGroupIndex
  })

  const { filteredHierarchies: planHierarchies } = useFilteredHierarchies(
    selectedHierarchiesIDs.brand,
    selectedHierarchiesIDs.business,
    selectedHierarchiesIDs.geography,
    selectedHierarchiesIDs.media
  )

  const hierarchies = useMemo(
    () => currentMasteredHierarchies ? { ...planHierarchies, ...currentMasteredHierarchies } : { ...planHierarchies },
    [currentMasteredHierarchies, planHierarchies]
  )

  const expandedHierarchyValue = useMemo(() => {
    if (!field.isExpandedHierarchyField || !expandedHierarchiesValues) {
      return null
    }
    const expandedHierarchyNode = expandedHierarchiesValues[field.expandedHierarchyTypeId][field.expandedHierarchyLevel]
    const hierarchyType = HIERARCHY_TYPES_MAP[field.expandedHierarchyTypeId]
    const isMasteredHierarchyType =
      Number(field.expandedHierarchyTypeId) ===
        FieldDataType.FINANCE_STATION_HIERARCHY ||
      Number(field.expandedHierarchyTypeId) ===
        FieldDataType.FINANCE_PRODUCT_HIERARCHY ||
      Number(field.expandedHierarchyTypeId) ===
        FieldDataType.COST_BUYING_ROUTE_HIERARCHY

    const expandedHierarchyDisplayValue =
      expandedHierarchyNode &&
      (isMasteredHierarchyType
        ? getHierarchyValue(
          unfilteredHierarchies,
          hierarchyType as IHierarchyName,
          expandedHierarchyNode.id
        )
        : getClientHierarchyValue(
          unfilteredHierarchies,
          hierarchyType as IClientHierarchyName,
          expandedHierarchyNode.id
        ))

    return expandedHierarchyDisplayValue ?? ''
  }, [field, expandedHierarchiesValues, unfilteredHierarchies])

  const error = useFieldError({
    instanceId,
    fieldLevelId: field.clientMediaPlanField.mediaPlanField.fieldLevelId,
    clientMediaPlanFieldId: field.clientMediaPlanFieldId
  })

  const goalSeekModalVisible = useAppSelector(selectGoalSeekModalVisible) as boolean
  const goalSeekWizardProgress = useAppSelector(selectGoalSeekWizardProgress) as WizardProgress
  const newUniqueStringObject = useAppSelector(selectNewUniqueStringObject) as INewUniqueStringObject

  const redistributeMode = useAppSelector(selectRedistributeMode)
  const isFieldSelectedForRedistribution = useIsFieldSelectedForRedistribution({ instanceId, columnName })
  const isFieldValidToRedistribute = useIsFieldValidToRedistribute({
    columnName,
    flightGroupIndex,
    flightIndex,
    fieldLevelId: field.clientMediaPlanField.mediaPlanField.fieldLevelId,
    financeList: financeListFieldsData,
    masteredListsData,
    hierarchies
  })

  const updateDataValues = useCallback(
    (newValue: string | number) => {
      const valueOfCorrectType = getValueOfCorrectType(
        newValue,
        field.clientMediaPlanField.mediaPlanField.fieldDataTypeId
      )
      const newPropertyValue = { [columnName]: valueOfCorrectType }

      if (
        field.clientMediaPlanField.mediaPlanField.fieldLevelId ===
        FieldLevelType.PLAN
      ) {
        dispatch(updatePlanLevelValue(newPropertyValue))
      } else if (
        field.clientMediaPlanField.mediaPlanField.fieldLevelId ===
        FieldLevelType.FLIGHT_GROUP
      ) {
        dispatch(
          updatePlanFlightGroupValue(newPropertyValue, flightGroupIndex)
        )
      } else if (
        field.clientMediaPlanField.mediaPlanField.fieldLevelId ===
        FieldLevelType.FLIGHT
      ) {
        dispatch(
          updatePlanFlightValue(newPropertyValue, flightGroupIndex, flightIndex)
        )
        dispatch(validateOverlapFlights(flightGroupIndex))
        dispatch(validateFlightDate(flightGroupIndex, flightIndex))
      } else if (
        field.clientMediaPlanField.mediaPlanField.fieldLevelId ===
        FieldLevelType.SUB_FLIGHT
      ) {
        dispatch(
          updatePlanSubFlightValue(
            newPropertyValue,
            flightGroupIndex,
            flightIndex,
            subFlightIndex
          )
        )
      }
      dispatch(disabledApprove())
    },
    [
      field.clientMediaPlanField.mediaPlanField.fieldDataTypeId,
      field.clientMediaPlanField.mediaPlanField.fieldLevelId,
      columnName,
      dispatch,
      flightGroupIndex,
      flightIndex,
      subFlightIndex
    ]
  )

  const createNewUniqueString = useCallback(
    (
      clientId: number,
      clientMediaPlanFieldId: number,
      uniqueStringValue: string,
      updateDataValuesFn: (
        selectedValue: string,
        id: number,
        flightGroupIndex?: number,
        flightIndex?: number,
        subFlightIndex?: number,
        startDate?: Date,
        endDate?: Date
      ) => void,
      updateDataValuesFnParams: IUpdateDataValuesFnParams,
      mediaPlanFieldId: number
    ) => {
      dispatch(saveNewUniqueString({
        clientId,
        clientMediaPlanFieldId,
        uniqueStringValue,
        updateDataValuesFn,
        updateDataValuesFnParams,
        mediaPlanFieldId
      }))
    }, [dispatch])

  const setGoalSeekCell = useCallback(
    (mediaPlanField, value) => dispatch(updateGoalSeekModalState(mediaPlanField, value, instanceId)),
    [dispatch, instanceId]
  )

  return (
    <RenderByTypeComponent
      calculationMode={calculationMode}
      redistributeMode={redistributeMode}
      isFieldSelectedForRedistribution={isFieldSelectedForRedistribution}
      isFieldValidToRedistribute={isFieldValidToRedistribute}
      clientId={currentClient.id}
      defaultClass={defaultClass}
      endDate={endDate}
      error={error}
      flightGroupIndex={flightGroupIndex}
      flightIndex={flightIndex}
      goalSeekMode={goalSeekModalVisible}
      goalSeekWizardProgress={goalSeekWizardProgress}
      hierarchies={hierarchies}
      unfilteredHierarchies={unfilteredHierarchies}
      masteredListsData={masteredListsData}
      mediaPlanField={field}
      merge={merge}
      createNewUniqueString={createNewUniqueString}
      planEndDate={planEndDate}
      planStartDate={planStartDate}
      startDate={startDate}
      subFlightIndex={subFlightIndex}
      tokenHandler={tokenHandler}
      updateDataValues={updateDataValues}
      valid={valid}
      value={expandedHierarchyValue ?? fieldValue}
      setGoalSeekCell={setGoalSeekCell}
      newUniqueStringObject={newUniqueStringObject}
      instanceId={instanceId}
      collapsedFlightGroup={collapsedFlightGroup}
      onClearClipboard={onClearClipboard}
      isLinkedFieldDisabled={isLinkedFieldDisabled}
      availableLinkedLookupOptions={availableLinkedLookupOptions}
      isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
      isEnforcedLink={isEnforcedLink}
      errorMessage={errorMessage}
      linkedLookupConnection={linkedLookupConnection}
      isMasteredHierarchyFieldPickable={isMasteredHierarchyFieldPickable}
      isCollapsed={isCollapsed}
      financeListFieldsData={financeListFieldsData}
      cachedMasteredHierarchies={cachedMasteredHierarchies}
      isLoadingHierarchies={isLoadingHierarchies}
      referencedMediaPlanField={referencedMediaPlanField}
    />
  )
}

export default memo(FieldContainer)
