import React, { useEffect, useState } from 'react'
import { Input, Tooltip } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

export interface IFieldAlias {
  placeholder: string
  value: string
  approved: boolean
  onAliasChange: (newValue: string) => void
}

const FieldAliasComponent = (fieldAlias: IFieldAlias) => {
  const [currentAlias, setFieldAlias] = useState<string>(fieldAlias.value)
  useEffect(() => {
    setFieldAlias(fieldAlias.value)
  }, [fieldAlias.value])

  const approvalStatusIcon = (
    <Tooltip title={ fieldAlias.approved ? 'Alias is approved' : 'Alias is not approved' }>
      { fieldAlias.approved
        ? <CheckCircleOutlined className='approval-status-icon__approved' data-testid='approved-icon' />
        : <ExclamationCircleOutlined className='approval-status-icon__not-approved' data-testid='not-approved-icon' />}
    </Tooltip>
  )
  return (
    <span className='alias-input-container'>
      <Input
        placeholder={fieldAlias.placeholder}
        value={currentAlias}
        onChange={event => setFieldAlias(event.currentTarget.value)}
        onBlur={event => fieldAlias.onAliasChange(event.currentTarget.value.trim())}
        addonAfter={fieldAlias.value && approvalStatusIcon}
      />
    </span>
  )
}

export default FieldAliasComponent

