import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Button, Switch, Divider, Row, Col, Typography, Checkbox } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { CollapsedViewContainer } from 'Containers/MediaPlanVersion/CollapsedViewContainer'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { DraggableModalContainer } from 'Containers/MediaPlanVersion/DraggableModalContainer'
import { CalendarView } from './constants/entities/IMediaPlanVersion'

interface IProps {
  hideModal: () => void
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]
  collapsedViewField: string
  visible: boolean
  calendarView: CalendarView
  applySettings: () => void
  collapseCalculated: boolean
  collapseNonMandatory: boolean
  collapseRows: boolean
  displayFlightDates: boolean
  expandedHierarchyMode: boolean
  compressedCalendarViewMode: boolean
  setUserPreferences: (values) => void
}

const { Text } = Typography

const title = (
  <div className='draggable-container'>
    Settings
  </div>
)

export const SettingsModalComponent: FunctionComponent<IProps> = ({
  hideModal,
  mediaPlanTemplateFields,
  collapsedViewField,
  visible,
  calendarView,
  applySettings,
  collapseCalculated,
  collapseNonMandatory,
  collapseRows,
  displayFlightDates,
  expandedHierarchyMode,
  compressedCalendarViewMode,
  setUserPreferences
}: IProps) => {
  const toggleDisplayFlightDatesSettings = useCallback(
    () => setUserPreferences(currentValues => ({ ...currentValues, displayFlightDates: !displayFlightDates })),
    [setUserPreferences, displayFlightDates]
  )

  const toggleExpandHierarchiesSettings = useCallback(
    () => setUserPreferences(currentValues => ({ ...currentValues, expandHierarchies: !expandedHierarchyMode })),
    [setUserPreferences, expandedHierarchyMode]
  )

  const toggleCompressedCalendarViewSettings = useCallback(
    () => setUserPreferences(currentValues => ({ ...currentValues, compressedCalendarView: !compressedCalendarViewMode })),
    [setUserPreferences, compressedCalendarViewMode]
  )

  const toggleCollapseCalculatedSettings = useCallback(
    () => setUserPreferences(currentValues => ({ ...currentValues, collapseCalculated: !collapseCalculated })),
    [setUserPreferences, collapseCalculated]
  )

  const toggleCollapseNonMandatorySettings = useCallback(
    () => setUserPreferences(currentValues => ({ ...currentValues, collapseNonMandatory: !collapseNonMandatory })),
    [setUserPreferences, collapseNonMandatory]
  )

  const toggleCollapseRowsSettings = useCallback(
    () => setUserPreferences(currentValues => ({ ...currentValues, collapseRows: !collapseRows })),
    [setUserPreferences, collapseRows]
  )

  const footer = useMemo(() => (
    <>
      <Button onClick={hideModal}>Cancel</Button>
      <Button type='primary' onClick={applySettings}>Apply</Button>
    </>
  ), [hideModal, applySettings])

  const body = useMemo(() => (
    <div className='settings-container' data-testid='edit-settings'>
      <Row className="settings-item">
        <Col span={16}>
          <Text className='settings-title'>Display flight dates</Text>
        </Col>
        <Col span={8}>
          <Switch
            size='small'
            onClick={toggleDisplayFlightDatesSettings}
            data-testid='display-flight-dates'
            checked={displayFlightDates}
          />
        </Col>
      </Row>
      <Row className="settings-item">
        <Col span={16}>
          <Text className='settings-title'>Expand hierarchy values</Text>
        </Col>
        <Col span={8}>
          <Switch
            size='small'
            onClick={toggleExpandHierarchiesSettings}
            data-testid='expand-hierarchy-values'
            checked={expandedHierarchyMode}
          />
        </Col>
      </Row>
      <Row className="settings-item">
        <Col span={16}>
          <Text className='settings-title'>Use compressed calendar view</Text>
        </Col>
        <Col span={8}>
          <Switch
            size='small'
            onClick={toggleCompressedCalendarViewSettings}
            data-testid='compressed-calendar-view'
            checked={compressedCalendarViewMode}
          />
        </Col>
      </Row>
      <Row className="settings-item">
        <CollapsedViewContainer
          fields={mediaPlanTemplateFields}
          collapsedViewField={collapsedViewField}
          setUserPreferences={setUserPreferences}
        />
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <div className='settings__info'>
            <InfoCircleOutlined />
            <Text className='settings__info--content'>Default settings for the template and applied when the plan is opened</Text>
          </div>
        </Col>
      </Row>
      <Row className="settings-item">
        <Col span={16}>
          <Text className='settings-title'>Collapse calculated columns</Text>
        </Col>
        <Col span={8}>
          <Checkbox
            onClick={toggleCollapseCalculatedSettings}
            data-testid='collapse-calculated'
            checked={collapseCalculated}
          />
        </Col>
      </Row>
      <Row className="settings-item">
        <Col span={16}>
          <Text className='settings-title'>Collapse non-mandatory columns</Text>
        </Col>
        <Col span={8}>
          <Checkbox
            onClick={toggleCollapseNonMandatorySettings}
            data-testid='collapse-non-mandatory'
            checked={collapseNonMandatory}
          />
        </Col>
      </Row>
      <Row className="settings-item" hidden={calendarView === 'table'}>
        <Col span={16}>
          <Text className='settings-title'>Collapse flight-group rows</Text>
        </Col>
        <Col span={8}>
          <Checkbox
            onClick={toggleCollapseRowsSettings}
            data-testid='collapse-flight-group-rows'
            checked={collapseRows}
          />
        </Col>
      </Row>
    </div>
  ), [
    displayFlightDates,
    toggleDisplayFlightDatesSettings,
    expandedHierarchyMode,
    toggleExpandHierarchiesSettings,
    compressedCalendarViewMode,
    toggleCompressedCalendarViewSettings,
    mediaPlanTemplateFields,
    collapsedViewField,
    setUserPreferences,
    collapseCalculated,
    toggleCollapseCalculatedSettings,
    collapseNonMandatory,
    toggleCollapseNonMandatorySettings,
    collapseRows,
    toggleCollapseRowsSettings,
    calendarView
  ])

  return (
    <DraggableModalContainer
      footer={footer}
      onCancel={hideModal}
      modalBody={body}
      title={title}
      open={visible}
    />
  )
}

export default SettingsModalComponent
