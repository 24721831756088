import React, { useEffect, useState } from 'react'
import { LoadingContainer, useRouteParams } from '@mindshare/layout'
import { message } from 'antd'

import { RootState } from 'Reducers/index'
import {
  cleanUpFrontSheetViewContainer,
  getFrontSheetView,
  initialiseFrontSheetViewContainer,
  updateFrontSheet,
  updateFrontSheetNotes
} from 'Actions/frontSheetActions'

import { FrontSheetViewDisplay } from 'Apis/generated/frontSheetsApi'
import FrontSheetViewComponent from 'Components/FrontSheets/FrontSheetView/FrontSheetViewComponent'
import { ILookupPlan } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectCurrentFrontSheet, selectCurrentFrontSheetView } from '../../../selectors'

export type MediaPlanNamesAndIds = {
  mediaPlanId: number
  mediaPlanVersionId: number
  name: string
}

export const FrontSheetViewContainer: React.FC = (): React.ReactElement => {
  const currentClientId = useAppSelector(state => state.app.currentClient.id)
  const currentFrontSheet = useAppSelector(selectCurrentFrontSheet)
  const currentFrontSheetView = useAppSelector(selectCurrentFrontSheetView) as FrontSheetViewDisplay
  const mediaPlans = useAppSelector(state => state.mediaPlans.mediaPlans) as ILookupPlan[]
  const dispatch = useAppDispatch()
  const [savingFrontSheet, setSavingFrontSheet] = useState(false)
  const [mediaPlanNamesAndIds, setMediaPlanNamesAndIds] = useState<MediaPlanNamesAndIds[]>(null)

  useEffect(() => {
    setMediaPlanNamesAndIds(currentFrontSheet?.mediaPlanIds?.map(x => {
      const plan = mediaPlans.find(y => y.mediaPlanId === x)
      return { mediaPlanId: plan.mediaPlanId, mediaPlanVersionId: plan.mediaPlanVersionId, name: plan.planName }
    }))
    return () => dispatch(cleanUpFrontSheetViewContainer())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveFunctions = {
    renderErrorMessage: (errorMsg: string): void => {
      message.destroy()
      message.error({
        content: errorMsg,
        duration: 10,
        key: 'updateFrontSheet'
      })
      setSavingFrontSheet(false)
    },
    updateExistingFrontSheet: async () => {
      setSavingFrontSheet(true)
      const saveResult = await dispatch(updateFrontSheet(currentClientId, currentFrontSheet))
      if (saveResult && typeof saveResult === 'string') {
        saveFunctions.renderErrorMessage(saveResult)
      } else {
        setSavingFrontSheet(false)
        message.destroy()
      }
    }
  }

  return (
    <FrontSheetViewComponent
      currentClientId={currentClientId}
      currentFrontSheet={currentFrontSheet}
      currentFrontSheetView={currentFrontSheetView}
      getFrontSheetView={(frontSheetViewId: number) => {
        message.destroy()
        message.loading({ content: 'Loading...', key: 'loadingMessage' })
        dispatch(getFrontSheetView(currentFrontSheet.frontSheetId, frontSheetViewId, currentClientId))
      }}
      mediaPlanNamesAndIds={mediaPlanNamesAndIds}
      onNotesUpdate={(n) => dispatch(updateFrontSheetNotes(n))}
      saveFrontSheet={saveFunctions.updateExistingFrontSheet}
      savingFrontSheet={savingFrontSheet}
    />
  )
}

export const FrontSheetViewLoadingContainer: React.FC = (props) => {
  const frontSheetId = useRouteParams('frontSheetId', Number)
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const mediaPlans = useAppSelector(state => state.mediaPlans.mediaPlans) as ILookupPlan[]

  return (
    <LoadingContainer
      appDataSuccess={true}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={
        (id) => initialiseFrontSheetViewContainer(id, hierarchies, mediaPlans, { frontSheetId, currentClientId: currentClient?.id })
      }
      loadingSelector={(state: RootState) => state.frontSheets.frontSheetViewContainerLoading}
    >
      <FrontSheetViewContainer {...props} />
    </LoadingContainer>
  )
}

export default FrontSheetViewLoadingContainer
