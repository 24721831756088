import { getClientHierarchyValue, formatEmailToName, IMSHierarchies } from '@mindshare/layout'
import { MediaPlanGet } from 'Apis/generated/mediaPlansApi'
import { Nullable } from 'Helpers/INullable'
import { IMediaPlanVersion } from '../../../MediaPlanVersion/constants/entities/IMediaPlanVersion'

export interface IMediaPlans extends MediaPlanGet {
  planStatusList?: string
  mediaPlanVersion?: IMediaPlanVersion
}

export interface ILookupPlan extends IMediaPlans {
  planClientGeographyHierarchyValue: string
  planClientMediaHierarchyValue: string
  modifiedByFormatted: string
  planningStageModifiedByFormatted: string
}

export const fromMediaPlansToLookUpPlans = (mediaPlans: Nullable<IMediaPlans[]>, hierarchies: Partial<IMSHierarchies>): ILookupPlan[] => mediaPlans?.map((item) => {
  const planClientGeographyHierarchyValue = getClientHierarchyValue(hierarchies, 'geography', Number(item.geographyHierarchyId))
  const planClientMediaHierarchyValue = getClientHierarchyValue(hierarchies, 'media', Number(item.mediaHierarchyId))
  const modifiedByFormatted: string = formatEmailToName(item.modifiedBy)
  const planningStageModifiedByFormatted: string = item.planningStageModifiedBy == null ? '' : formatEmailToName(item.planningStageModifiedBy)

  return {
    ...item,
    planClientGeographyHierarchyValue,
    planClientMediaHierarchyValue,
    modifiedByFormatted,
    planningStageModifiedByFormatted
  }
})
