import React, { FunctionComponent, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { IMSHierarchies, useMindshareSelector } from '@mindshare/layout'

import { setFlightPasteModalStartDate, setSelectedDates, setSelectedFlights, expandFlightGroupRow } from 'Actions/mediaPlansActions'
import { MediaPlanSubFlight } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightCellComponent from 'Components/MediaPlanVersion/FlightCellComponent'
import { useSubFlightSelectHelper } from 'Components/MediaPlanVersion/hooks/useSubFlightSelectHelper'
import { getCompressedFlightWidth } from 'Helpers/calendarHelper'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { RootState } from '../../redux/reducers'
import { selectCopiedFlight } from '../../selectors'
import { useAppDispatch, useAppSelector } from '../../store'

export interface MediaPlanSubFlightModelExtended extends MediaPlanSubFlight {
  merge: boolean
  width: string
  flightIndex: number
  subFlightIndex: number
}
interface IProps {
  valid: boolean
  calculationMode: boolean
  flightGroupId: number
  flightGroupIndex: number
  planStartDate: string
  planEndDate: string
  mediaPlanField: IMediaPlanTemplateFields
  masteredListsData: IMasteredListsData
  unfilteredHierarchies: IMSHierarchies
  tokenHandler: (token: string) => void
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => void
  clearFlightData: (flightGroupIndex: number, flightIndex: number) => void
  copySelectedFlight: () => void
  mergeSubFlightSelection: (
    startDate: Date,
    enDate: Date,
    mergeByWeek: boolean,
    flightGroupIndex: number,
    flightIndex: number,
    mergeByMonth: boolean,
    clientMediaPlanFieldId: number
  ) => void
  isSelectedFlightCellEmpty: boolean
  isCollapsed?: boolean
  compressedCalendarView: boolean
  selectedSubFlights: string[]
  isSubtotalGroup?: boolean
  flights?: IFlight[]
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const SubFlightFieldContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    valid,
    flightGroupId,
    flightGroupIndex,
    calculationMode,
    mediaPlanField,
    tokenHandler,
    masteredListsData,
    unfilteredHierarchies,
    planStartDate,
    planEndDate,
    deleteFlight,
    clearFlightData,
    copySelectedFlight,
    mergeSubFlightSelection,
    isSelectedFlightCellEmpty,
    isCollapsed,
    compressedCalendarView,
    selectedSubFlights,
    isSubtotalGroup,
    flights,
    financeListFieldsData
  } = props

  const dispatch = useAppDispatch()

  const subFlightsDates = useMindshareSelector((state: RootState) =>
    state.mediaPlans.currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]?.flights?.map((flight, flightIndex) =>

      flight?.subFlights?.map((subFlight, subFlightIndex) => ({
        subFlightStartDate: subFlight.subFlightStartDate,
        subFlightEndDate: subFlight.subFlightEndDate,
        clientMediaPlanFieldId: subFlight.clientMediaPlanFieldId,
        flightIndex,
        subFlightIndex,
        merge: subFlight.merge,
        width: subFlight.width
      })) || []
    ).flat() || []) as MediaPlanSubFlightModelExtended[]

  const subtotalsSubFlights: MediaPlanSubFlightModelExtended[] = useMemo(() => (flights?.map((flight, flightIndex) =>
    flight?.subFlights?.map((subFlight, subFlightIndex) => ({
      subFlightStartDate: subFlight.subFlightStartDate,
      subFlightEndDate: subFlight.subFlightEndDate,
      clientMediaPlanFieldId: subFlight.clientMediaPlanFieldId,
      flightIndex,
      subFlightIndex,
      merge: subFlight.merge,
      width: subFlight.width
    })) || []
  ).flat() || []), [flights])

  const copiedFlight = useAppSelector(selectCopiedFlight)

  const {
    firstSelectedFlightIndex,
    lastSelectedFlightIndex,
    firstSelectedSubFlight,
    lastSelectedSubFlight,
    firstSelectedSubFlightIndex,
    lastSelectedSubFlightIndex
  } = useSubFlightSelectHelper({
    selectedSubFlights,
    subFlightsDates
  })

  const expressionKey = (
    subFlightDate: MediaPlanSubFlightModelExtended,
    mediaPlanVersionFieldId: number
  ) =>
    `${
      mediaPlanVersionFieldId > 0
        ? mediaPlanVersionFieldId
        : subFlightDate.clientMediaPlanFieldId
    }-${subFlightDate.flightIndex}-${flightGroupIndex}-${
      subFlightDate.clientMediaPlanFieldId
    }-${subFlightDate.subFlightIndex}`

  const openFlightPasteModalWithStartDate = useCallback((startDate: string) => {
    dispatch(setFlightPasteModalStartDate(startDate))
  }, [dispatch])

  const subFlights = isSubtotalGroup ? subtotalsSubFlights : subFlightsDates

  return (
    <div className='flight__grid -readonly'>
      {
        subFlights.filter(sfd => sfd.clientMediaPlanFieldId === mediaPlanField.clientMediaPlanFieldId).map((subFlightDate, index) => {
          const key = expressionKey(subFlightDate, mediaPlanField.mediaPlanVersionFieldId)
          const isPartOfSelectedFlight = selectedSubFlights?.length === 1 &&
            !isSelectedFlightCellEmpty &&
            subFlightDate.flightIndex >= Number(selectedSubFlights[0].split('-')[1]) &&
            subFlightDate.flightIndex <= Number(selectedSubFlights[selectedSubFlights.length - 1].split('-')[1]) &&
            flightGroupIndex.toString() === selectedSubFlights[selectedSubFlights.length - 1].split('-')[2]
          const isCellSelected = selectedSubFlights.includes(key)
          const isPartOfCopiedFlight = subFlightDate.flightIndex === copiedFlight?.flightIndex && flightGroupIndex === copiedFlight?.flightGroupIndex
          const isCellWithinSelectedRange = (
            subFlightDate.flightIndex >= Number(firstSelectedFlightIndex) &&
            subFlightDate.flightIndex <= Number(lastSelectedFlightIndex) &&
            subFlightDate.subFlightIndex >= Number(firstSelectedSubFlightIndex) &&
            subFlightDate.subFlightIndex <= Number(lastSelectedSubFlightIndex)
          )

          const width = compressedCalendarView ? getCompressedFlightWidth(subFlightDate.width) : subFlightDate.width

          return (
            <div
              className={classNames(
                'flight__gridItem',
                { '-selected': isPartOfSelectedFlight || isCellSelected },
                { '-copied': isPartOfCopiedFlight },
                { '-compressed': compressedCalendarView }
              )}
              data-key={key}
              key={key}
              style={{ width }}
              onContextMenu={() => {
                if (isCellWithinSelectedRange || isSubtotalGroup) {
                  return
                }
                dispatch(setSelectedDates([subFlightDate.subFlightStartDate, subFlightDate.subFlightEndDate]))
                dispatch(setSelectedFlights([key]))
              }}
              onClick={() => {
                if (isSubtotalGroup) {
                  return
                }
                dispatch(setSelectedFlights([key]))
                dispatch(setSelectedDates([subFlightDate.subFlightStartDate, subFlightDate.subFlightEndDate]))
                if (subFlightDate.merge) {
                  if (isCollapsed) {
                    dispatch(expandFlightGroupRow(flightGroupId))
                  }
                }
              }}
            >
              <FlightCellComponent
                key={index}
                calculationMode={calculationMode}
                merge={subFlightDate.merge}
                unfilteredHierarchies={unfilteredHierarchies}
                masteredListsData={masteredListsData}
                tokenHandler={tokenHandler}
                valid={valid}
                startDate={subFlightDate.subFlightStartDate}
                endDate={subFlightDate.subFlightEndDate}
                planStartDate={planStartDate}
                planEndDate={planEndDate}
                mediaPlanField={mediaPlanField}
                clearFlightData={clearFlightData}
                deleteFlight={deleteFlight}
                copySelectedFlight={copySelectedFlight}
                mergeFlightSelection={mergeSubFlightSelection}
                flightGroupIndex={flightGroupIndex}
                flightIndex={subFlightDate.flightIndex}
                subFlightIndex={subFlightDate.subFlightIndex}
                canCopy={isPartOfSelectedFlight}
                firstSelectedFlight={firstSelectedSubFlight}
                lastSelectedFlight={lastSelectedSubFlight}
                isCellWithinSelectedRange={isCellWithinSelectedRange}
                canPaste={!!copiedFlight}
                openFlightPasteModalWithStartDate={openFlightPasteModalWithStartDate}
                isSubtotalGroup={isSubtotalGroup}
                financeListFieldsData={financeListFieldsData}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default SubFlightFieldContainer
