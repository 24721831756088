import React from 'react'
import moment from 'moment'
import { Dropdown } from 'antd'
import { IMSHierarchies } from '@mindshare/layout'

import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import RenderByTypeComponent from 'Components/RenderByTypeComponent'
import { dateFormat } from 'Helpers/calendarHelper'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import FlightModalOptionsComponent from '../FlightModalOptionsComponent'

interface IDayInput {
  value: string
  subFlightIndex: number
  flightIndex: number
  daySelected: boolean
  valid: boolean
  merge: boolean
  startDate: string
  endDate: string
  planStartDate: string
  planEndDate: string
  flightGroupIndex: number
  calculationMode: boolean
  hierarchies?: Partial<IMSHierarchies>
  mediaPlanField: IMediaPlanTemplateFields
  masteredListsData: IMasteredListsData
  error: string
  tokenHandler: (token: string) => void
  updateDataValues?: (selectedValue: string | number) => void
  mergeFlightSelection: (
    startDate: Date,
    endDate: Date,
    mergeByWeek: boolean,
    flightGroupIndex: number,
    flightIndex: number,
    clientMediaPlanFieldId?: number,
    isSubFlight?: boolean
  ) => void
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number, isSubFlight: boolean) => void
  isTemplate: boolean
}

export const DayInput: React.FunctionComponent<IDayInput> = (props: IDayInput) => {
  const {
    flightIndex,
    daySelected,
    startDate,
    endDate,
    value,
    merge,
    planStartDate,
    planEndDate,
    flightGroupIndex,
    valid,
    mergeFlightSelection,
    calculationMode,
    hierarchies,
    mediaPlanField,
    updateDataValues,
    tokenHandler,
    deleteFlight,
    masteredListsData,
    subFlightIndex,
    error,
    isTemplate
  } = props

  const isSubFlight = mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.SUB_FLIGHT
  const menu = [{
    key: 'context-menu',
    label: (
      <FlightModalOptionsComponent
        planStartDate={planStartDate}
        planEndDate={planEndDate}
        selectedFlights={[]}
        isSubFlightCell={false}
        deleteFlight={ () => deleteFlight(flightGroupIndex, flightIndex, subFlightIndex, isSubFlight)}
        selectedDates={[moment(startDate).format(dateFormat), moment(endDate).format(dateFormat)]}
        mergeFlightSelection={ (selectedStartDate, selectedEndDate, mergeByWeek) =>
          mergeFlightSelection(selectedStartDate,
            selectedEndDate,
            mergeByWeek,
            flightGroupIndex,
            flightIndex,
            mediaPlanField.clientMediaPlanFieldId,
            isSubFlight)
        }
        isMergeCell={true}
      />
    )
  }]

  return (
    <Dropdown menu={{ items: menu }} trigger={['contextMenu']}>
      <div className={`${merge ? 'merge' : ''}`} data-testid='flight-values'>
        <RenderByTypeComponent
          valid={valid}
          masteredListsData={masteredListsData}
          calculationMode={calculationMode}
          hierarchies={hierarchies}
          mediaPlanField={mediaPlanField}
          tokenHandler={tokenHandler}
          updateDataValues={updateDataValues}
          value={value}
          defaultClass={daySelected ? 'selected' : ''}
          key={`${flightGroupIndex} - ${flightIndex}`}
          flightIndex={flightIndex}
          startDate={startDate}
          endDate={endDate}
          flightGroupIndex={flightGroupIndex}
          subFlightIndex={subFlightIndex}
          error={error}
          merge={merge}
          isTemplate={isTemplate}
        />
      </div>
    </Dropdown>
  )
}

export default DayInput
