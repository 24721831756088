import React, { FC, memo, useMemo } from 'react'
import classNames from 'classnames'
import { LinkedLookup, LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import { getClientHierarchyValue, getHierarchyValue, IMSHierarchies, IHierarchyName, IClientHierarchyName, lookupHierarchyTreeBranch } from '@mindshare/layout'
import { getFieldDataType, IMediaPlanFieldContainerProps, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { isFinanceProductHierarchyType, isFinanceStationHierarchyType, isCostBuyingRouteHierarchyType } from 'Components/shared/constants/entities/IFieldMetaData'
import { useMediaPlanFieldHierarchies } from 'Hooks/useMediaPlanFieldHierarchies'
import { TreeSelectComponent } from 'Components/Hierarchies/components/TreeSelectComponent'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { getHierarchyFirstExpandedBranch } from 'Components/Hierarchies/constants/entities/IHierarchies'

interface IProps extends IMediaPlanFieldContainerProps {
  onChange: (hierarchyId: string | number) => void
  value: number
  collapsedFlightGroup?: boolean
  hierarchies: Partial<IMSHierarchies>
  unfilteredHierarchies: Partial<IMSHierarchies>
  masteredFieldTooltip?: string
  fieldTypeClasses?: string
  cachedMasteredHierarchies: Partial<IMSHierarchies>
  isFilteredLookupValuesVisible: boolean
  linkedLookupConnection: LinkedLookup
  availableLinkedLookupOptions: LinkedLookupValue[]
  isEnforcedLink: boolean
  linkedLookupFieldTooltip: string
  isLinkedFieldDisabled: boolean
  isLoadingHierarchies?: boolean
  referencedMediaPlanField?: IMediaPlanFieldRow | IMediaPlanTemplateFields
}

export const MediaPlanHierarchyFieldContainer: FC<IProps> = ({
  mediaPlanField,
  error,
  value,
  disabled,
  onChange,
  onClick,
  collapsedFlightGroup,
  hierarchies,
  unfilteredHierarchies,
  masteredFieldTooltip,
  fieldTypeClasses,
  cachedMasteredHierarchies,
  isFilteredLookupValuesVisible,
  linkedLookupConnection,
  availableLinkedLookupOptions,
  isEnforcedLink,
  linkedLookupFieldTooltip,
  isLinkedFieldDisabled,
  isLoadingHierarchies,
  referencedMediaPlanField
}) => {
  const { dataTypeName } = getFieldDataType(mediaPlanField)
  const { valueProp, idProp, hierarchyList, hierarchyType } = useMediaPlanFieldHierarchies({ currentField: mediaPlanField, hierarchies })

  const isMasteredHierarchyType = isFinanceProductHierarchyType(dataTypeName) || isFinanceStationHierarchyType(dataTypeName) || isCostBuyingRouteHierarchyType(dataTypeName)

  const displayValue = isMasteredHierarchyType
    ? (getHierarchyValue(hierarchies, hierarchyType as IHierarchyName, Number(value)) ||
      (value ? getHierarchyValue(cachedMasteredHierarchies, hierarchyType as IHierarchyName, Number(value)) : undefined)
    )
    : getClientHierarchyValue(unfilteredHierarchies, hierarchyType as IClientHierarchyName, Number(value))

  const treeDefaultExpandedKeys = useMemo(() => {
    if (!mediaPlanField.requiredHierarchyLevelId) {
      return []
    }
    return value
      ? lookupHierarchyTreeBranch(
        hierarchyList,
        idProp,
        value
      ).map(node => node.key)
      : getHierarchyFirstExpandedBranch(hierarchyList)
        .map(node => node.key)
  }, [mediaPlanField.requiredHierarchyLevelId, hierarchyList, idProp, value])

  return (
    <TreeSelectComponent
      treeData={hierarchyList}
      idColumn={idProp}
      valueColumn={valueProp}
      value={error || value}
      isDisabled={disabled || isLinkedFieldDisabled}
      tooltipTitle={collapsedFlightGroup ? displayValue : error || masteredFieldTooltip || linkedLookupFieldTooltip}
      hierarchyName={dataTypeName}
      setFieldValue={(hierarchyName, id) => onChange(id)}
      isEnforcedLink={isEnforcedLink}
      onClick={onClick}
      fieldTypeClasses={classNames('hierarchy-dropdown', fieldTypeClasses, { '-error': error })}
      data-testid={mediaPlanField.fieldLabel}
      treeDefaultExpandedKeys={treeDefaultExpandedKeys}
      collapsedFlightGroup={collapsedFlightGroup}
      displayValue={displayValue}
      linkedLookupValues={linkedLookupConnection?.linkedLookupValues}
      availableLinkedLookupOptions={availableLinkedLookupOptions}
      isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
      isLoadingHierarchies={isLoadingHierarchies}
      referencedMediaPlanField={referencedMediaPlanField}
    />
  )
}

export default memo(MediaPlanHierarchyFieldContainer)
