import React, { FunctionComponent } from 'react'
import { useMindshareSelector } from '@mindshare/layout'
import { Client } from 'Apis/generated/clientsApi'
import { RootState } from '../../redux/reducers'

const ReportContainer: FunctionComponent = () => {
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  return (
    <iframe src={currentClient.mptReportUrl} width="100%" height="100%"/>
  )
}
export default ReportContainer
