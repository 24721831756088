export enum FieldColour {
  Success = '#757585',
  Failure = '#FF4446'
}

export enum FieldStatus {
  Success = 'Success',
  Failure = 'Failed conflict',
  PartialFailure = 'Partial failure'
}

export enum TemplateSyncOptionType {
  OverrideDefaultValue = 50
}
