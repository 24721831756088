import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { linkedLookupsInitialValues } from 'Components/LinkedLookup/constants/entities'
import * as actionTypes from 'Constants/actionTypes'

interface ILinkedLookupListSet {
  type: actionTypes.SET_LINKED_LOOKUPS_LIST
  payload: LinkedLookup[]
}

interface ILinkedLookupListUnset {
  type: actionTypes.UNSET_LINKED_LOOKUPS_LIST
}

interface ILinkedLookupListAdd {
  type: actionTypes.ADD_LINKED_LOOKUPS_TO_LIST
  payload: { linkedLookup: typeof linkedLookupsInitialValues }
}

interface ILinkedLookupListRemove {
  type: actionTypes.REMOVE_LINKED_LOOKUPS_FROM_LIST
  payload: { id: number }
}

interface ILinkedLookupListUpdate {
  type: actionTypes.UPDATE_LINKED_LOOKUPS_LIST
  payload: {
    linkedLookupId: number
    linkedLookup: Partial<LinkedLookup>
  }
}

export type ILinkedLookupListAction =
  | ILinkedLookupListSet
  | ILinkedLookupListUnset
  | ILinkedLookupListAdd
  | ILinkedLookupListRemove
  | ILinkedLookupListUpdate

export const setLinkedLookupList = (data: ILinkedLookupListSet['payload']) => dispatch => {
  dispatch({ type: actionTypes.SET_LINKED_LOOKUPS_LIST, payload: data })
}

export const unsetLinkedLookupList = () => dispatch => {
  dispatch({ type: actionTypes.UNSET_LINKED_LOOKUPS_LIST })
}

export const addToLinkedLookupList = (data: ILinkedLookupListAdd['payload']) => dispatch => {
  dispatch({ type: actionTypes.ADD_LINKED_LOOKUPS_TO_LIST, payload: data })
}

export const removeFromLinkedLookupList = (data: ILinkedLookupListRemove['payload']) => dispatch => {
  dispatch({ type: actionTypes.REMOVE_LINKED_LOOKUPS_FROM_LIST, payload: data })
}

export const updateLinkedLookupList = (data: ILinkedLookupListUpdate['payload']) => dispatch => {
  dispatch({ type: actionTypes.UPDATE_LINKED_LOOKUPS_LIST, payload: data })
}
