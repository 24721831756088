import React, { useMemo } from 'react'
import { Select } from 'antd'
import { compare } from 'Helpers/sortHelper'
import { isCostBuyingRouteHierarchyType } from 'Components/shared/constants/entities/IFieldMetaData'

const { Option } = Select

export const CustomExportFieldSelector = ({
  options,
  onChange,
  value,
  disabled = false,
  optionDisabled = false
}) => {
  const sortedOptions = useMemo(
    () =>
      [...options].sort((a, b) =>
        compare(a.label, b.label)
      ),
    [options]
  )

  const handleChange = (option) => {
    const [fieldName, fieldId] = option.value.split(':')
    const changes = fieldName === 'clientMediaPlanFieldId'
      ? { [fieldName]: Number(fieldId), customExportControlFieldId: undefined }
      : { [fieldName]: Number(fieldId), clientMediaPlanFieldId: undefined }

    onChange(changes)
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      labelInValue={true}
      className='select select--fluid'
      placeholder='Select field'
      data-testid='custom-export-field-selector'
      showSearch={true}
      optionFilterProp='children'
      disabled={disabled}
    >
      {sortedOptions.map((option) => (
        <Option key={option.value} value={option.value} disabled={optionDisabled && isCostBuyingRouteHierarchyType(option.field.mediaPlanField?.fieldDataType?.dataTypeName)}>
          {option.label}
        </Option>
      ))}
    </Select>
  )
}
