import React from 'react'
import { Typography, List } from 'antd'
import { MediaPlanFieldSyncResult } from 'Apis/generated/templateSyncApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { FieldColour, FieldStatus } from 'Components/TemplateSync/enums/fieldResult'

const { Text } = Typography

interface ITemplateSynsFieldResultProps {
  fieldSyncResults: MediaPlanFieldSyncResult[]
  clientMediaPlanFields: IClientMediaPlanField[]
}

export const TemplateSyncFieldResultComponent: React.FC<ITemplateSynsFieldResultProps> = ({ fieldSyncResults, clientMediaPlanFields }) => (
  <List
    dataSource={fieldSyncResults}
    data-testid='sync-field-result-list'
    renderItem={field => {
      const fieldLabel = clientMediaPlanFields.find(c => c.mediaPlanField.mediaPlanFieldLevelId === field.mediaPlanFieldLevelId)?.mediaPlanField.fieldLabel

      return (
        <List.Item>
          <Text style={{ color: `${field.success ? FieldColour.Success : FieldColour.Failure}` }}>
            {fieldLabel}
          </Text>
          <Text
            style={{
              color: `${field.success ? FieldColour.Success : FieldColour.Failure}`
            }}
          >
            {field.success ? FieldStatus.Success : FieldStatus.Failure}
          </Text>
        </List.Item>
      )
    }}
  />
)

export default TemplateSyncFieldResultComponent
