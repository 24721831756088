import React, { FunctionComponent } from 'react'
import Moment from 'react-moment'
import { Switch, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { MSTable, formatEmailToName } from '@mindshare/layout'

import { MediaPlanTemplateFieldAlias } from 'Apis/generated/mediaPlanTemplateFieldsApi'
import { compare } from 'Helpers/sortHelper'
import CheckboxComponent from 'Components/CheckboxComponent'

export interface IAliasTableProps {
  data: MediaPlanTemplateFieldAlias[]
  updateStatus: (clientMediaPlanFieldId: number, approve: boolean) => Promise<void>
}

const TemplateFieldAliasTable: FunctionComponent<IAliasTableProps> = (props) => {
  const { data, updateStatus } = props


  const compareClientLabelAlias = (a: MediaPlanTemplateFieldAlias, b: MediaPlanTemplateFieldAlias) => {
    const compA = a.clientMediaPlanFieldLabel && a.clientMediaPlanFieldLabel !== a.mediaPlanFieldLabel ? a.templateFieldLabel : ''
    const compB = b.clientMediaPlanFieldLabel && b.clientMediaPlanFieldLabel !== b.mediaPlanFieldLabel ? b.templateFieldLabel : ''

    return compare(compA, compB)
  }

  const columns: Array<ColumnProps<MediaPlanTemplateFieldAlias>> = [
    {
      key: 'ColumnName',
      title: 'Column name (MPT Real name)',
      sorter: (a, b) => compare(a.mediaPlanFieldLabel, b.mediaPlanFieldLabel),
      render: (alias: MediaPlanTemplateFieldAlias) => (
        <Tooltip title={alias.fieldDescription}>
          <div>
            {
              alias.mediaPlanFieldLabel
            }
          </div>
        </Tooltip>
      )
    },
    {
      key: 'ClientLabel',
      title: 'Client Label (Alias)',
      sorter: compareClientLabelAlias,
      render: (alias: MediaPlanTemplateFieldAlias) => (
        <>
          {
            alias.clientMediaPlanFieldLabel !== alias.mediaPlanFieldLabel && (
              <>{alias.clientMediaPlanFieldLabel}</>
            )
          }
        </>
      )
    },
    {
      key: 'TemplateLabel',
      title: 'Template Label (Alias)',
      sorter: (a, b) => compare(a.templateFieldLabel, b.templateFieldLabel),
      dataIndex: 'templateFieldLabel'
    },
    {
      key: 'FieldLevel',
      title: 'Field Level',
      sorter: (a, b) => compare(a.fieldLevel.fieldLevelName, b.fieldLevel.fieldLevelName),
      dataIndex: ['fieldLevel', 'fieldLevelName']
    },
    {
      key: 'DataType',
      title: 'Data Type',
      sorter: (a, b) => compare(a.fieldDataType.dataTypeName, b.fieldDataType.dataTypeName),
      dataIndex: ['fieldDataType', 'dataTypeName']
    },
    {
      key: 'MediaPlanFieldType',
      title: 'Disabled',
      dataIndex: 'isDisabled',
      sorter: (a, b) => compare(a.isDisabled, b.isDisabled),
      render: (isDisabled: boolean) => (
        <CheckboxComponent
          dataTestId='disabled'
          disabled={true}
          checked={isDisabled}
        />
      )
    },
    {
      key: 'mediaPlanTemplateId',
      title: 'Template ID',
      sorter: (a, b) => compare(a.mediaPlanTemplateId, b.mediaPlanTemplateId),
      dataIndex: 'mediaPlanTemplateId'
    },
    {
      key: 'templateName',
      title: 'Template Name',
      sorter: (a, b) => compare(a.templateName, b.templateName),
      dataIndex: 'templateName'
    },
    {
      key: 'SubmittedBy',
      title: 'Submitted By',
      sorter: (a, b) => compare(a.submittedBy, b.submittedBy),
      dataIndex: 'submittedBy',
      render: (submittedBy: string) => (
        <>{formatEmailToName(submittedBy)}</>
      )
    },
    {
      key: 'SubmittedOn',
      title: 'Submitted On',
      sorter: (a, b) => compare(new Date(a.submittedOn), new Date(b.submittedOn)),
      defaultSortOrder: 'descend',
      dataIndex: 'submittedOn',
      render: (date: string) => (
        <Moment format='DD MMM YY h:mmA'>{date}</Moment>
      )
    },
    {
      key: 'ApprovedBy',
      title: 'Approved By',
      sorter: (a, b) => compare(a.approvedBy || '', b.approvedBy || ''),
      dataIndex: 'approvedBy',
      render: (approvedBy: string) => (
        <>{approvedBy && formatEmailToName(approvedBy)}</>
      )
    },
    {
      key: 'ApprovedOn',
      title: 'Approved On',
      sorter: (a, b) => compare(new Date(a.approvedOn), new Date(b.approvedOn)),
      dataIndex: 'approvedOn',
      render: (approvedOn: string) => (
        <>{approvedOn && <Moment format='DD MMM YY h:mmA'>{approvedOn}</Moment>}</>
      )
    },
    {
      key: 'Status',
      title: 'Status',
      sorter: (a, b) => compare(new Date(a.approvedOn), new Date(b.approvedOn)),
      render: (alias: MediaPlanTemplateFieldAlias) => (
        <Switch
          data-fieldid={alias.mediaPlanTemplateFieldId}
          checked={!!alias.approvedOn}
          onChange={checked => updateStatus(alias.mediaPlanTemplateFieldId, checked)}
          size='small'
        />
      )
    }
  ]

  return (
    <MSTable
      data-testid='templateFieldAliasTable'
      rowKey='mediaPlanTemplateFieldId'
      columns={columns}
      dataSource={data}
      dynamicPagination={{ minRows: 6 }}
    />
  )
}

export default TemplateFieldAliasTable
