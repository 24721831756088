import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  SortableListItem,
  SortableListCell,
  DragIcon
} from 'Components/shared/SortableList'
import { findOperatorByOperatorId, getOperatorsByFieldDataTypeId } from '../constants/entities'
import { CustomExportFieldActions } from './CustomExportFieldActions'
import { CustomExportFieldSelector } from './CustomExportFieldSelector'
import { FilterOperatorSelector } from './FilterOperatorSelector'
import { CustomExportDefaultValue } from './CustomExportDefaultValue'

export const CustomExportFilterFieldItemComponent = ({
  fieldIndex,
  item,
  canRemove,
  handleRemove,
  handlePush,
  value,
  label,
  options,
  handleFieldChange,
  isRunCustomExport,
  fieldDataType,
  handleOperatorChange,
  operators,
  handleDefaultValueChange,
  hierarchies,
  masteredListsData,
  clientFieldValues,
  customExport
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.itemRowId })

  const style: React.CSSProperties = {
    transition,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    ...(isDragging ? { position: 'relative' } : {})
  }
  const selectedValue = (value || label) ? { value, label } : undefined

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <SortableListItem>
        <SortableListCell>
          <CustomExportFieldSelector
            options={options}
            value={selectedValue}
            onChange={handleFieldChange}
            disabled={isRunCustomExport}
            optionDisabled={true}
          />
        </SortableListCell>
        <SortableListCell>
          <FilterOperatorSelector
            disabled={!fieldDataType}
            value={item.fieldDataTypeOperatorId}
            onChange={handleOperatorChange}
            options={getOperatorsByFieldDataTypeId(operators, fieldDataType?.fieldDataTypeId)}
          />
        </SortableListCell>
        <SortableListCell>
          <CustomExportDefaultValue
            value={item.customExportFilterDefaultValue}
            onChange={handleDefaultValueChange}
            fieldDataType={fieldDataType}
            operator={
              findOperatorByOperatorId(operators, item.fieldDataTypeOperatorId)}
            hierarchies={hierarchies}
            masteredListsData={masteredListsData}
            clientFieldValues={clientFieldValues}
            geographyHierarchyId={customExport.values.geographyHierarchyId}
          />
        </SortableListCell>
        {!isRunCustomExport && (
          <>
            <SortableListCell className='actions-cell'>
              <CustomExportFieldActions
                onRemove={handleRemove(fieldIndex)}
                onPush={handlePush}
                canRemove={canRemove}
              />
            </SortableListCell>
            <SortableListCell className='sortable-list__drag-icon'>
              <div ref={setActivatorNodeRef} {...listeners}>
                <DragIcon />
              </div>
            </SortableListCell>
          </>
        )}
      </SortableListItem>
    </div>
  )
}

export default CustomExportFilterFieldItemComponent
