import React from 'react'
import { useMindshareSelector } from '@mindshare/layout'
import { IMediaPlanTemplateFields, availableFieldsByLevel } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import RichTextAvailableComponent from 'Components/RichTextAvailableComponent'
import { filterRichText } from 'Helpers/richTextHelpers'
import { RootState } from '../../redux/reducers'
import PlanLevelRichTextFieldContainer from './PlanLevelRichTextFieldContainer'

interface IProps {
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  richTextPlanLevelFields: IMediaPlanTemplateFields[]
}

const PlanLevelRichTextFieldsContainer = ({
  removePlanField,
  onAvailableFieldSelected,
  richTextPlanLevelFields
}: IProps) => {
  const availablePlanFields = useMindshareSelector((state: RootState) =>
    availableFieldsByLevel(state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields, FieldLevelType.PLAN).filter(filterRichText)
  ) as IMediaPlanTemplateFields[]

  return (
    <>
      {
        richTextPlanLevelFields.map((mediaPlanField) => {
          const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId

          if (id === 0) {
            return (
              <RichTextAvailableComponent
                key={mediaPlanField.clientMediaPlanFieldId}
                availableMediaPlanFields={availablePlanFields}
                onAvailableFieldSelected={onAvailableFieldSelected} />
            )
          } else {
            return (
              <PlanLevelRichTextFieldContainer
                key={mediaPlanField.clientMediaPlanFieldId}
                mediaPlanField={mediaPlanField}
                removePlanField={removePlanField}
              />
            )
          }
        })
      }
    </>
  )
}

export default PlanLevelRichTextFieldsContainer
