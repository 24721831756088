import { useEffect, useRef, useState, useCallback, useLayoutEffect, useMemo } from 'react'
import { message } from 'antd'
import { IMSHierarchies } from '@mindshare/layout'
import { filterClientHierarchies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { NotificationType } from 'Constants/enums/NotificationType'
import { useHierarchies } from './useHierarchies'
import { useCurrentClient } from './useCurrentClient'

export const useFilteredHierarchies = (brandHierarchyId: number, businessHierarchyId: number, geographyHierarchyId: number, mediaHierarchyId: number): {
  filteredHierarchies: IMSHierarchies
  isLoadingHierarchies: boolean
} => {
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies, isLoading: isLoadingHierarchies } = useHierarchies(currentClient?.id)
  const [filteredHierarchies, setFilteredHierarchies] = useState(hierarchies)

  useEffect(() => {
    const filteredBrandHierarchies = filterClientHierarchies(hierarchies, FieldDataType.BRAND_HIERARCHY, brandHierarchyId)
    setFilteredHierarchies(state => ({
      ...state,
      clientBrandHierarchies: filteredBrandHierarchies as any,
      clientBrandHierarchyLevels: hierarchies.clientBrandHierarchyLevels
    }))
  }, [brandHierarchyId, hierarchies])

  useEffect(() => {
    const filteredBusinessHierarchies = filterClientHierarchies(hierarchies, FieldDataType.BUSINESS_HIERARCHY, businessHierarchyId)
    setFilteredHierarchies(state => ({
      ...state,
      clientBusinessHierarchies: filteredBusinessHierarchies as any,
      clientBusinessHierarchyLevels: hierarchies.clientBusinessHierarchyLevels
    }))
  }, [businessHierarchyId, hierarchies])

  useEffect(() => {
    const filteredGeographyHierarchies = filterClientHierarchies(hierarchies, FieldDataType.GEOGRAPHY_HIERARCHY, geographyHierarchyId)
    setFilteredHierarchies(state => ({
      ...state,
      clientGeographyHierarchies: filteredGeographyHierarchies as any,
      clientGeographyHierarchyLevels: hierarchies.clientGeographyHierarchyLevels
    }))
  }, [geographyHierarchyId, hierarchies])

  useEffect(() => {
    const filteredMediaHierarchies = filterClientHierarchies(hierarchies, FieldDataType.MEDIA_HIERARCHY, mediaHierarchyId)
    setFilteredHierarchies(state => ({
      ...state,
      clientMediaHierarchies: filteredMediaHierarchies as any,
      clientMediaHierarchyLevels: hierarchies.clientMediaHierarchyLevels
    }))
  }, [mediaHierarchyId, hierarchies])

  return useMemo(() => ({ filteredHierarchies, isLoadingHierarchies }), [filteredHierarchies, isLoadingHierarchies])
}

export const useNotificationMessage = (
  show: boolean,
  messageStr: string,
  type: NotificationType
) => {
  useEffect(() => {
    if (show) {
      switch (type) {
        case NotificationType.SUCCESS:
          message.success(messageStr)
          break
        case NotificationType.ERROR:
          message.error(messageStr)
          break
      }
    }
  }, [show, messageStr, type])
}


/**
 * Helper hook to return an isReady boolean when the browser is idle,
 * using setTimeout to push to eventLoop to set ready at next browser idle state
*/
export const useIsReadyWhenIdle = () => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setIsReady(true), 0)
    return () => clearTimeout(timeout)
  }, [])
  return isReady
}

/**
 * Helper hook to work out what properties have changed
 * @param props object of properties
 * @param consoleLabel label to show in the console
 * @param verbose default: false - if true this will print the old vs new properties that have changed
*/
export const useWhatHasChanged = (props: object, consoleLabel: string, verbose: boolean = false) => {
  const prevAmount = useRef({ ...props }).current
  useEffect(() => {
    const keys = Object.keys(props)
    keys.forEach((key) => {
      if (prevAmount[key] !== props[key]) {
        // eslint-disable-next-line no-console
        console.log(`${consoleLabel} - ${key}${verbose ? `${JSON.stringify(prevAmount[key])} -> ${JSON.stringify(props[key])}` : ''}`)
      }
    })

    return () => {
      keys.forEach((key) => {
        // eslint-disable-next-line functional/immutable-data
        prevAmount[key] = props[key]
      })
    }
  }, [consoleLabel, prevAmount, props, verbose])
}

/**
 * Helper hook to observe the resize change of an element
 * e.g. const [ref, sizes] = useResizeObserver()
 * set ref as reference on element you want to observe the resize change
 * e.g. <div className='observed-element' ref={ref}>...</div>
 *
 * @returns [setNode, observerEntry]
*/
export const useResizeObserver = () => {
  const [observerEntry, setObserverEntry] = useState<any>({})
  const [node, nodeRefCallback] = useState(null)
  const observer = useRef(null)

  const disconnect = useCallback(() => observer.current?.disconnect(), [])

  const observe = useCallback(() => {
    // eslint-disable-next-line functional/immutable-data
    observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry))
    if (node) observer.current.observe(node)
  }, [node])

  useLayoutEffect(() => {
    observe()
    return disconnect
  }, [disconnect, observe])

  return [nodeRefCallback, observerEntry]
}

export const useModal = (initialMode = false) => {
  const [isOpen, setModalOpen] = useState(initialMode)
  const open = () => setModalOpen(true)
  const close = () => setModalOpen(false)
  return { open, close, isOpen }
}
