import { ControllerRenderProps } from 'react-hook-form'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ITemplateSyncFormData, ITemplateSyncOptionPerField } from '../TemplateSyncModalComponent'

type SelectedTemplatesField =
  | ControllerRenderProps<ITemplateSyncFormData, 'selectedLinkedTemplates'>
  | ControllerRenderProps<ITemplateSyncFormData, 'selectedClientTemplates'>

export const emptyItem: ITemplateSyncOptionPerField = {
  fieldLabel: '',
  fieldLevelId: null,
  mediaPlanFieldLevelId: null
}

export const handleCheckboxChange = (e: CheckboxChangeEvent, field: SelectedTemplatesField) => {
  field.onChange(
    e.target.checked
      ? [...field.value, e.target.value]
      : field.value.filter(item => item.mediaPlanTemplateId !== e.target.value.mediaPlanTemplateId)
  )
}
