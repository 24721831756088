import { useMemo } from 'react'
import { useGetFinanceDataStationsQuery, GetFinanceDataStationsApiResponse } from 'Apis/generated/financeDataApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useFinanceDataStations = ({ clientGeographyHierarchyId, clientMediaHierarchyId, shouldSkip = false }): UseQueryCommand<GetFinanceDataStationsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetFinanceDataStationsQuery(
    { clientId: currentClient.id, clientGeographyHierarchyId, clientMediaHierarchyId },
    { skip: !currentClient.id || shouldSkip }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
