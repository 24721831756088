import moment from 'moment'
import { eachWeekOfInterval, eachMonthOfInterval } from 'date-fns'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import {
  isBlank,
  formatLowerCaseFirstLetter,
  getClientHierarchyValue,
  IMSHierarchies,
  getClientHierarchyTypeDetails,
  getHierarchyTypeDetails,
  getHierarchyValue
} from '@mindshare/layout'
import { buildEmptyFlightGrid, IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IMediaPlanMetaField, findByFieldLevelId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { NUMBER_FIELD_DATA_TYPES, LOOKUP_FIELD_DATA_TYPES, FieldDataType, MASTERED_FIELD_DATA_TYPES } from 'Constants/enums/FieldDataType'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { generateTemporaryId } from 'Helpers/commonUtils'
import {
  isFinanceProductHierarchyType,
  isFinanceStationHierarchyType,
  isFinanceTargetAudienceListType,
  isFinanceBuyingAudienceListType,
  isFinanceBookingCategoryList,
  isCostBuyingRouteHierarchyType
} from 'Components/shared/constants/entities/IFieldMetaData'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import {
  isDateBetween,
  flightWidthCalculation,
  getWeeksBetweenByWeekDay,
  calculateWidthFlight,
  subFlightWidthCalculation,
  findSubFlightBetweenDates,
  weekDays,
  dateToString,
  dateDifference,
  dateMax,
  dateMin,
  toISOString,
  dateFormat
} from './calendarHelper'

export const calculateSubFlightMerge = (
  flightGroup: IFlightGroup,
  clientMediaPlanFieldId: number,
  startDate: Date,
  endDate: Date,
  parsedMediaPlanData: any,
  mediaPlanVersionFields: IMediaPlanMetaField[]
) => {
  // The merge logic for flights and subflights is practically the same
  // We can use 'calculateFlightMerge', but for that we need to adjust the interface to match 'IFlight'
  const subFlightsAsFlights = (flightGroup.flights
    .map(flight => flight.subFlights).filter(sb => sb) as any).flat()
    .filter(c => c.clientMediaPlanFieldId === clientMediaPlanFieldId)
    .map(sb => ({ ...sb, flightStartDate: sb.subFlightStartDate, flightEndDate: sb.subFlightEndDate }))

  const updatedFlightList = calculateFlightMerge(
    subFlightsAsFlights,
    null,
    startDate,
    endDate,
    flightGroup,
    parsedMediaPlanData,
    mediaPlanVersionFields
  )

  const subFlightField = mediaPlanVersionFields.find(c => c.clientMediaPlanFieldId === clientMediaPlanFieldId)
  const subFlights = fromFlightToSubFlight(updatedFlightList, subFlightField)
  return subFlights
}

/**
 * Calculate the gap and return the list of flights according to a given start and end date
 * Should be used for merging flights and subflights
 * @param flights the list of rendered (stubs for empty cells, and real ones) flights to be updated
 * @param selectedFlight flight that will be updated (re-created within new date range)
 * @param startDate the proposed new start date
 * @param endDate the proposed new end date
 * @param flightGroup current flight group
 * @param parsedMediaPlanData the current plan media version data
 * @param mediaPlanVersionFields the array of the plan version fields of the plan version
 * @returns an updated Array of flights from the startDate to end endDate
 */
export const calculateFlightMerge = (
  flights: IFlight[],
  selectedFlight: IFlight,
  startDate: Date,
  endDate: Date,
  flightGroup: IFlightGroup,
  parsedMediaPlanData: any,
  mediaPlanVersionFields: IMediaPlanMetaField[]
): IFlight[] => {
  // As flight/subFlight beginning and end presented as strings, while startDate/endDate are Date objects
  // it's better to convert them to the one standard to avoid differential treatment
  // which can lead to complications with utc/non-utc comparisons
  const startDateStr = dateToString(startDate)
  const endDateStr = dateToString(endDate)

  const unaffectedFlights = flights.filter((f) => {
    if (f === selectedFlight) {
      // Skip the original flight as we do not want to keep it.
      // By not including it into 'unaffectedFlights' the original will be removed.
      // A new flight will be created with updated date range and field values from the original.
      return false
    }
    if (isDateBetween(startDateStr, endDateStr, f.flightStartDate) || isDateBetween(startDateStr, endDateStr, f.flightEndDate)) {
      // This flight entirely falls within the new range of selectedFlight
      return false
    }
    if (isDateBetween(f.flightStartDate, f.flightEndDate, startDateStr) || isDateBetween(f.flightStartDate, f.flightEndDate, endDateStr)) {
      // This flight partially overlaps with the one that is being updated (re-created).
      return false
    }
    // What is left are the real flights which are not being affected by this change.
    return true
  })

  const newFlight = createNewFlightFrom(selectedFlight, startDateStr, endDateStr, mediaPlanVersionFields)
  const updatedFlightList = [...unaffectedFlights, newFlight]
    .sort((a, b) => dateDifference(a.flightStartDate, b.flightStartDate))

  const planStartDate = toISOString(dateMin(flights[0].flightStartDate, parsedMediaPlanData.planStartDate))
  const planEndDate = toISOString(dateMax(flights[flights.length - 1].flightEndDate, parsedMediaPlanData.planEndDate))
  const contiguousFlightList = fillGapsWithDummyFlights(
    updatedFlightList,
    planStartDate,
    planEndDate,
    mediaPlanVersionFields,
    flightGroup
  )

  // Calculate a display width (px) from flight duration (days)
  const finalFlightList = flightWidthCalculation(contiguousFlightList)
  return finalFlightList
}

export function createNewFlightFrom (
  existingFlight: IFlight,
  newStartDate: string,
  newEndDate: string,
  mediaPlanVersionFields: IMediaPlanMetaField[]
) {
  const defaultFlightValues = mediaPlanVersionFields
    .filter(f => f.defaultValue !== null && f.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT)
    .reduce((acc, next) => ({ ...acc, [formatLowerCaseFirstLetter(next.clientMediaPlanField.mediaPlanField.columnName)]: getDefaultValue(next) }), {})

  return {
    ...defaultFlightValues,
    ...existingFlight,
    merge: true,
    flightStartDate: newStartDate,
    flightEndDate: newEndDate,
    subFlights: [],
    mediaPlanFlightId: generateTemporaryId()
  }
}

export function fillGapsWithDummyFlights (
  flights: IFlight[],
  planStartDate: string,
  planEndDate: string,
  mediaPlanVersionFields: IMediaPlanMetaField[],
  flightGroup: IFlightGroup
) {
  let result = []

  for (let i = 0; i < flights.length + 1; i++) {
    const gapStartDate = i === 0
      ? moment(planStartDate).add(-1, 'day')
      : moment(flights[i - 1].flightEndDate)
    const gapEndDate = i < flights.length
      ? moment(flights[i].flightStartDate)
      : moment(planEndDate).add(1, 'day')

    // rounding to fix an issue with daylight saving time change detected as a gap
    const gapWidth = Math.round(dateDifference(gapEndDate.toDate(), gapStartDate.toDate()) - 1)
    if (gapWidth > 0) {
      const fillerStartDate = gapStartDate.add(1, 'day').toDate()
      const fillerEndDate = gapEndDate.add(-1, 'day').toDate()
      const filler = buildEmptyFlightGrid(
        fillerStartDate,
        fillerEndDate,
        mediaPlanVersionFields,
        flightGroup)

      result = [...result, ...filler]
    } else if (gapWidth < 0) {
      // It would be nice to throw it, but can't do it, lots of unit tests are against
      // throw new Error('Invalid data. Flights must not overlap or go out the plan range')
    }

    if (i < flights.length) {
      result = [...result, flights[i]]
    }
  }

  return result
}


export const getDefaultValue = (field: IMediaPlanMetaField) =>
  getValueOfCorrectType(field.defaultValue, field.clientMediaPlanField.mediaPlanField.fieldDataTypeId)

export const getValueOfCorrectType = (value: number | string, fieldDataTypeId: number) => {
  const typeIsNumber = NUMBER_FIELD_DATA_TYPES.includes(fieldDataTypeId)
  const typeIsLookup = LOOKUP_FIELD_DATA_TYPES.includes(fieldDataTypeId)
  const typeIsMasteredField = MASTERED_FIELD_DATA_TYPES.includes(fieldDataTypeId)

  if (typeIsNumber) {
    return (!isBlank(value)) ? Number(value) : null
  } else if (typeIsLookup || typeIsMasteredField) {
    return value ? Number(value) : null
  } else {
    return value !== undefined ? value : null
  }
}

export const getValueOrLookupText = (value: any, field: IMediaPlanMetaField, hierarchies: IMSHierarchies, masteredListsData: IMasteredListsData, financeList = {}) => {
  const { clientCampaigns, clientAgencies, mediaPartners } = masteredListsData
  const clientField = field.clientMediaPlanField
  const fieldDataType = clientField.mediaPlanField.fieldDataType as FieldDataTypeRead
  const clientFieldValues = clientField.clientFieldValues
  const typeIsMediaPartner = fieldDataType.fieldDataTypeId === FieldDataType.MEDIA_PARTNERS
  const typeIsClientCampaign = fieldDataType.fieldDataTypeId === FieldDataType.CLIENT_CAMPAIGNS
  const typeIsClientAgency = fieldDataType.fieldDataTypeId === FieldDataType.CLIENT_AGENCY_LIST
  const typeIsUniqueString = fieldDataType.fieldDataTypeId === FieldDataType.UNIQUE_STRING
  const typeIsClientDefinedList = fieldDataType.fieldDataTypeId === FieldDataType.CLIENT_DEFINED_LIST
  const typeIsList = typeIsUniqueString || typeIsClientDefinedList
  const typeIsHierarchy = fieldDataType.isHierarchy
  const typeIsFinanceTargetAudienceList = isFinanceTargetAudienceListType(fieldDataType.dataTypeName)
  const typeIsFinanceBuyingAudienceList = isFinanceBuyingAudienceListType(fieldDataType.dataTypeName)
  const typeIsFinanceBookingCategoryList = isFinanceBookingCategoryList(fieldDataType.dataTypeName)
  const financeListValues = financeList?.[field.clientMediaPlanField.mediaPlanField.columnName]?.data

  if (typeIsMediaPartner && mediaPartners && mediaPartners.length) {
    return mediaPartners.find(x => x.clientMediaPartnerId === value)?.mediaPartnerValue
  } else if (typeIsClientCampaign && clientCampaigns && clientCampaigns.length) {
    return clientCampaigns.find(x => x.clientCampaignId === value)?.clientCampaignName
  } else if (typeIsClientAgency && clientAgencies && clientAgencies.length) {
    return clientAgencies.find(x => x.agencyId === value)?.agencyDisplayName
  } else if (typeIsList && clientFieldValues) {
    return clientFieldValues.find(x => x.clientFieldValueId === value)?.valueDisplayName
  } else if ((typeIsFinanceTargetAudienceList || typeIsFinanceBuyingAudienceList) && financeListValues?.length) {
    return financeListValues.find(x => x.financeAudienceId === value)?.audienceName
  } else if (typeIsFinanceBookingCategoryList && financeListValues?.length) {
    return financeListValues.find(x => x.financeBookingCategoryId === value)?.bookingCategoryName
  } else if (typeIsHierarchy) {
    const isMasteredHierarchyType =
      isFinanceProductHierarchyType(fieldDataType.dataTypeName) ||
      isFinanceStationHierarchyType(fieldDataType.dataTypeName) ||
      isCostBuyingRouteHierarchyType(fieldDataType.dataTypeName)
    if (isMasteredHierarchyType) {
      const { hierarchyType, hierarchyList } = getHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)
      if (hierarchyList?.length) {
        return getHierarchyValue(hierarchyList, hierarchyType, Number(value))
      }
    } else {
      const { hierarchyType, hierarchyList } = getClientHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)
      if (hierarchyList?.length) {
        return getClientHierarchyValue(hierarchyList, hierarchyType, Number(value))
      }
    }
  }
  return value
}

export const getSortableValue = (
  data: any,
  fields: IMediaPlanMetaField[],
  hierarchies: IMSHierarchies,
  masteredListsData: IMasteredListsData,
  columnName: string,
  financeList: IMediaPlanVersionFinanceListFields
) => {
  const field: IMediaPlanMetaField = fields.find(
    x => x.clientMediaPlanField.mediaPlanField.columnName === columnName
  )
  const value =
    data[
      formatLowerCaseFirstLetter(
        field.clientMediaPlanField.mediaPlanField.columnName
      )
    ]
  return getValueOrLookupText(
    value,
    field,
    hierarchies,
    masteredListsData,
    financeList
  )
}

export const getMergeFlightDates = (startDate: Date, endDate: Date, startDayOfWeek: string) => {
  const data = []
  const weekIntervals = eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: weekDays[startDayOfWeek] })

  for (let i = 0; i < weekIntervals.length; i++) {
    const start = new Date(startDate > weekIntervals[i] ? startDate : weekIntervals[i])

    let end: Date

    // if last interval, use end date
    if (i === weekIntervals.length - 1) {
      end = new Date(endDate)
    } else {
      // otherwise use next week interval - 1 day
      end = new Date(weekIntervals[i + 1])
      end.setDate(end.getDate() - 1)
    }

    // eslint-disable-next-line functional/immutable-data
    data.push({ start, end })
  }

  return data
}

export const defineDefaultFlight = (
  flightStartDate: string,
  flightEndDate: string,
  flightFields: IMediaPlanMetaField[],
  currentMediaPlan: IMediaPlanVersion): any => {
  const result = getWeeksBetweenByWeekDay(flightStartDate, flightEndDate)
  let flights = result.map((date) => {
    let flight = {
      flightStartDate: date.flightStartDate.format(dateFormat),
      flightEndDate: date.flightEndDate.format(dateFormat)
    }
    flight = calculateWidthFlight(flight)
    return flight
  })
  const subFlights = findByFieldLevelId(flightFields, FieldLevelType.SUB_FLIGHT)
  if (subFlights.length > 0) {
    const chosenSubFlights = currentMediaPlan.parseData.flightGroups[0].chosenFlightFields
      .map(c => subFlights.find(m => m.clientMediaPlanFieldId === c.clientMediaPlanFieldId))
      .filter(c => c)
    const subFlightsCreated = subFlightWidthCalculation(
      result.map(date => ({
        subFlightStartDate: date.flightStartDate.format(dateFormat),
        subFlightEndDate: date.flightEndDate.format(dateFormat)
      }))
    )
    let subFlightsData = []
    chosenSubFlights.forEach((csf) => {
      const subFlightsWithDefaultValue = subFlightsCreated.map(subFlight => ({
        ...subFlight,
        [formatLowerCaseFirstLetter(
          csf.clientMediaPlanField.mediaPlanField.columnName
        )]: '',
        clientMediaPlanFieldId: csf.clientMediaPlanFieldId
      }))
      subFlightsData = subFlightsData.concat(subFlightsWithDefaultValue)
    })

    flights = flights.map((flight) => ({ ...flight, subFlights: subFlightsData.filter(c => findSubFlightBetweenDates(c, flight.flightStartDate, flight.flightEndDate)) }))
  }

  return flights
}

export const fromFlightToSubFlight = (newFlights, subFlightField: IMediaPlanMetaField) => newFlights.map(nf => {
  const isMissingSubFlighId = !nf.hasOwnProperty('mediaPlanSubFlightId')
  const isMissingClientId = !nf.hasOwnProperty('clientMediaPlanFieldId')
  const columnName = formatLowerCaseFirstLetter(subFlightField.clientMediaPlanField.mediaPlanField.columnName)
  const newSubFlight = {
    ...nf,
    mediaPlanSubFlightId: isMissingSubFlighId
      ? nf.mediaPlanFlightId
      : nf.mediaPlanSubFlightId, // Both values are interchangeblse as they are just random id's
    clientMediaPlanFieldId: isMissingClientId
      ? subFlightField.clientMediaPlanFieldId
      : nf.clientMediaPlanFieldId,
    subFlightStartDate: nf.flightStartDate,
    subFlightEndDate: nf.flightEndDate,
    [formatLowerCaseFirstLetter(
      subFlightField.clientMediaPlanField.mediaPlanField.columnName
    )]:
      nf[columnName] === undefined
        ? getDefaultValue(subFlightField)
        : nf[columnName]
  }
  // eslint-disable-next-line functional/immutable-data
  delete newSubFlight.mediaPlanFlightId
  // eslint-disable-next-line functional/immutable-data
  delete newSubFlight.subFlights
  // eslint-disable-next-line functional/immutable-data
  delete newSubFlight.flightStartDate
  // eslint-disable-next-line functional/immutable-data
  delete newSubFlight.flightEndDate

  return newSubFlight
})

const getIsFlight = (flightKey: string) => {
  const indices = flightKey.split('-')
  const isFlight = indices.length === 3
  return isFlight
}

const getFlightIndex = (flightKey: string) => {
  const indices = flightKey.split('-')
  const flightIndex = Number(indices[1])
  return flightIndex
}

export const getFlightGroupIndex = (flightKey: string) => {
  const indices = flightKey.split('-')
  const flightGroupIndex = Number(indices[2])
  return flightGroupIndex
}

const getSubFlightIndex = (flightKey: string) => {
  const indices = flightKey.split('-')
  const isFlight = getIsFlight(flightKey)
  const subFlightIndex = !isFlight ? Number(indices[4]) : 0
  return subFlightIndex
}

export const getFlightData = (flightGroups: IFlightGroup[], dataKey: string) => {
  const flightIndex = getFlightIndex(dataKey)
  const flightGroupIndex = getFlightGroupIndex(dataKey)
  const isFlight = getIsFlight(dataKey)
  const subFlightIndex = getSubFlightIndex(dataKey)
  const data = isFlight ? flightGroups[flightGroupIndex].flights[flightIndex] : flightGroups[flightGroupIndex].flights[flightIndex]?.subFlights[subFlightIndex]
  const flightData = flightGroups[flightGroupIndex].flights[flightIndex]
  return {
    data,
    flightData,
    flightIndex,
    flightGroupIndex,
    isFlight
  }
}

export const validateDatesOverlapWithFlights = (startDate: string, endDate: moment.Moment, flights: IFlight[]) => {
  const nonEmptyFlights = flights.filter(flight => Boolean(flight.mediaPlanFlightId))
  return nonEmptyFlights.some(flight => (
    isDateBetween(flight.flightStartDate, flight.flightEndDate, startDate) ||
    isDateBetween(flight.flightStartDate, flight.flightEndDate, endDate)
  ))
}

export const getMergeFlightDatesByMonth = (startDate: Date, endDate: Date) => {
  const data = []
  const monthIntervals = eachMonthOfInterval({ start: startDate, end: endDate })

  for (let i = 0; i < monthIntervals.length; i++) {
    const start = new Date(startDate > monthIntervals[i] ? startDate : monthIntervals[i])

    let end: Date

    // if last interval, use end date
    if (i === monthIntervals.length - 1) {
      end = new Date(endDate)
    } else {
      // otherwise use next month interval - 1 day
      end = new Date(monthIntervals[i + 1])
      end.setDate(end.getDate() - 1)
    }

    // eslint-disable-next-line functional/immutable-data
    data.push({ start, end })
  }

  return data
}
