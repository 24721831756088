import React, { useState, useCallback, useMemo } from 'react'
import Moment from 'react-moment'
import { Button, Tooltip } from 'antd'
import type { TableProps } from 'antd'
import type { SorterResult } from 'antd/es/table/interface'
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { MSVirtualTable, MSLayoutTitleRow } from '@mindshare/layout'
import { Client } from 'Apis/generated/clientsApi'
import { useGetSyncLinkedTemplatesByBaseMediaPlanTemplateIdQuery } from 'Apis/enhancedTemplateSyncApi'
import { TemplateSyncUsage } from 'Apis/generated/templateSyncApi'
import { compare } from 'Helpers/sortHelper'
import { ISyncTemplateSettings } from 'Constants/entities/IMapSettings'
import { TemplateSyncResultModalComponent } from 'Components/TemplateSync/TemplateSyncResultModalComponent'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'

interface ITemplateUsageTabProps {
  clients: Client[]
  filterFunction: (dataIndex: keyof ISyncTemplateSettings, placeholderString: string) => object
  clientMediaPlanFields: IClientMediaPlanField[]
  clientId: number
  templateId: number
}

const TemplateUsageTabContainer: React.FC<ITemplateUsageTabProps> = ({ clients, filterFunction, clientMediaPlanFields, clientId, templateId }) => {
  const { data: templates, refetch } = useGetSyncLinkedTemplatesByBaseMediaPlanTemplateIdQuery({ clientId, baseMediaPlanTemplateId: templateId })

  const [visible, setVisible] = useState(false)
  const [templateSyncUsageId, setTemplateSyncUsageId] = useState<number>()
  const [sortResult, setSortResult] = useState<SorterResult<TemplateSyncUsage>>({})

  const showModal = useCallback(() => setVisible(true), [])
  const hideModal = useCallback(() => setVisible(false), [])

  const handleOpenModal = useCallback((id: number) => {
    showModal()
    setTemplateSyncUsageId(id)
  }, [showModal])

  const onChange: TableProps<TemplateSyncUsage>['onChange'] = useCallback((_pagination, _filters, sorter) => {
    const { order, columnKey } = sorter as SorterResult<TemplateSyncUsage>
    setSortResult({ order, columnKey })
  }, [])

  const sortedTemplates = useMemo(() => {
    if (!sortResult.order || !sortResult.columnKey) return templates

    return [...templates].sort((a, b) => {
      const key = sortResult.columnKey as string
      const fieldA = a[key]
      const fieldB = b[key]
      const direction = sortResult.order === 'ascend'

      if (sortResult.columnKey === 'clientName') {
        const clientNameA = clients.find((c) => c.id === a.destinationClientId)?.displayName
        const clientNameB = clients.find((c) => c.id === b.destinationClientId)?.displayName

        return compare(clientNameA, clientNameB, direction)
      }

      return compare(fieldA, fieldB, direction)
    })
  }, [sortResult.order, sortResult.columnKey, templates, clients])

  if (templates && templates.length > 0) {
    const columns = [
      {
        className: 'client-name-column-sort',
        title: 'Client Name',
        dataIndex: 'clientName',
        key: 'clientName',
        sorter: (a, b) => compare(a.clientName, b.clientName),
        ...filterFunction('clientName', 'Client Name')
      },
      {
        className: 'destination-template-name',
        title: 'Client Template Name',
        dataIndex: 'destinationTemplateName',
        key: 'destinationTemplateName',
        sorter: (a, b) => compare(a.destinationTemplateName, b.destinationTemplateName)
      },
      {
        className: 'sync-status',
        title: 'State of last sync with base template',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => compare(a.status, b.status)
      },
      {
        className: 'last-updated',
        title: 'Last updated',
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        sorter: (a, b) => compare(new Date(a.lastUpdated), new Date(b.lastUpdated)),
        renderCellContent: (lastUpdated: string) => <Moment format='DD MMM YY h:mm:ss'>{lastUpdated}</Moment>
      },
      {
        title: '',
        dataIndex: 'buttons',
        className: 'button-cell',
        key: 'buttons'
      }
    ]

    const data = templates.map((item) => {
      const clientName = clients.find((c) => c.id === item.destinationClientId)?.displayName

      return {
        key: item.destinationTemplateId,
        clientName,
        destinationTemplateName: item.destinationTemplateName,
        status: item.status,
        lastUpdated: item.lastUpdated,
        buttons: (
          <Tooltip title='Info'>
            <Button
              type='link'
              icon={<InfoCircleOutlined />}
              data-testid='info-icon'
              onClick={() => handleOpenModal(item.templateSyncUsageId)}
            />
          </Tooltip>
        )
      }
    })

    return (
      <div className='template-usage-container'>
        <MSLayoutTitleRow extraClass='template-usage-container__header' extraContent={(
          <Tooltip title='Refresh'>
            <Button
              type='link'
              icon={<ReloadOutlined />}
              data-testid='reload-icon'
              onClick={refetch}
            />
          </Tooltip>
        )}>
          Template usage
        </MSLayoutTitleRow>
        <div className='list-table template-usage-list-table'>
          <MSVirtualTable
            columns={columns}
            dataSource={data}
            onChange={onChange}
          />
        </div>
        <TemplateSyncResultModalComponent
          visible={visible}
          hideModal={hideModal}
          templateSyncUsageId={templateSyncUsageId}
          templates={sortedTemplates}
          setTemplateSyncUsageId={setTemplateSyncUsageId}
          clientMediaPlanFields={clientMediaPlanFields}
        />
      </div>
    )
  }
  return (
    <div className='template-usage-container'>
      <MSLayoutTitleRow extraClass='template-usage-container__header'>Template usage</MSLayoutTitleRow>
      <div className='list-table'>
        <p>
        No linked templates.
        </p>
      </div>
    </div>
  )
}

export default TemplateUsageTabContainer
