import React, { CSSProperties, FunctionComponent } from 'react'
import { useMindshareSelector } from '@mindshare/layout'
import { removePlanField } from 'Actions/mediaPlansActions'
import CalendarComponent from 'Components/Calendar/CalendarComponent'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IMediaPlanTemplateFields, fieldsInPlanByLevel, availableFieldsByLevel } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import FlightsHeaderComponent from 'Components/MediaPlanVersion/FlightsHeaderComponent'
import { flightStartDate, flightEndDate } from 'Components/MediaPlanVersion/constants/entities/FlightDates'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { RootState } from '../../redux/reducers'
import { useAppDispatch } from '../../store'

interface IProps {
  handleAddField: (fieldType: FieldLevelType) => void
  getStickyStyle?: (type: StickyColumnType, id?: number) => CSSProperties
  compressedCalendarView: boolean
}

export const FlightsHeaderContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    handleAddField,
    getStickyStyle,
    compressedCalendarView
  } = props

  const startDayOfWeek = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.startDayOfWeek || 'Monday') as string
  const planStartDate = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planStartDate || '') as string
  const planEndDate = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planEndDate || '') as string
  const calendarView = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.calendarView || '') as CalendarView

  const flightFields = useMindshareSelector((state: RootState) =>
    fieldsInPlanByLevel(state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields, FieldLevelType.FLIGHT)
  ) as IMediaPlanTemplateFields[]

  const hasAvailableFlightFields = useMindshareSelector((state: RootState) =>
    availableFieldsByLevel(
      state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields,
      FieldLevelType.FLIGHT
    ).length > 0
  ) as boolean
  const dispatch = useAppDispatch()

  const removeField = (mediaPlanField: IMediaPlanTemplateFields) => dispatch(removePlanField(mediaPlanField))

  const sortedFlightFields = flightFields && [...flightFields, flightStartDate, flightEndDate].sort((a, b) => a.sortOrder - b.sortOrder)

  if (calendarView !== 'table') {
    return (
      <div className='flight-level-calendar'>
        <CalendarComponent
          calendarView={calendarView}
          startDate={planStartDate}
          endDate={planEndDate}
          dayStartOfWeek={startDayOfWeek}
          compressedCalendarView={compressedCalendarView}
        />
      </div>
    )
  } else {
    return (
      <FlightsHeaderComponent
        hasAvailableFlightFields={hasAvailableFlightFields}
        flightFields={sortedFlightFields}
        removePlanField={removeField}
        handleAddField={handleAddField}
        getStickyStyle={getStickyStyle}
      />
    )
  }
}

export default FlightsHeaderContainer

