import { CustomExportLatestFlag } from 'Apis/generated/customExportsApi'
import { getLatestFlagLabel } from 'Components/CustomExport/constants/entities/ILatestFlag'
import { baseApi as api } from '../apis/generated/customExportsApi'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    postCustomExportsByCustomExportIdRun: {
      query: (queryArg) => ({
        url: `/customExports/${queryArg.customExportId}/run`,
        method: 'POST',
        body: queryArg.customExportRun,
        params: { clientId: queryArg.clientId },
        responseHandler: (response) => response.blob()
      })
    },
    getCustomExportsLatestFlags: {
      query: (queryArg) => ({
        url: '/customExports/latestFlags',
        params: { clientId: queryArg.clientId }
      }),
      transformResponse: (response: CustomExportLatestFlag[]) => response.map(el => ({
        ...el,
        customExportLatestFlagName: getLatestFlagLabel(el.customExportLatestFlagName)
      })),
      providesTags: ['latestFlags']
    },
    postCustomExportsUploadTemplate: {
      query ({ body, clientId }) {
        const formData = new FormData()
        formData.append('file', body.file)

        return {
          url: 'customExports/uploadTemplate',
          params: { clientId },
          method: 'POST',
          body: formData
        }
      }
    },
    getCustomExportsDisplayTypes: { providesTags: ['displayTypes'] },
    getCustomExportsCurrencyDisplays: { providesTags: ['currencyDisplays'] },
    getCustomExportsFieldDataTypeOperators: { providesTags: ['fieldDataTypeOperators'] },
    getCustomExportsClientMediaPlanFields: { providesTags: ['customExportsClientMediaPlanFields'] },
    getCustomExportsControlFields: { providesTags: ['controlFields'] },
    getCustomExports: { providesTags: ['customExportsList'] },
    getCustomExportsById: { providesTags: ['customExportItem'] },
    postCustomExports: { invalidatesTags: ['customExportsList', 'customExportItem'] },
    putCustomExportsById: { invalidatesTags: ['customExportsList', 'customExportItem'] }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  usePostCustomExportsByCustomExportIdRunMutation,
  useGetCustomExportsLatestFlagsQuery,
  useGetCustomExportsDisplayTypesQuery,
  useGetCustomExportsControlFieldsQuery,
  useGetCustomExportsCurrencyDisplaysQuery,
  useGetCustomExportsClientMediaPlanFieldsQuery,
  useGetCustomExportsFieldDataTypeOperatorsQuery,
  useGetCustomExportsQuery,
  usePostCustomExportsMutation,
  useGetCustomExportsByIdQuery,
  useLazyGetCustomExportsByIdQuery,
  usePutCustomExportsByIdMutation,
  usePostCustomExportsUploadTemplateMutation
} = enhancedRtkApi
