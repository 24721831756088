const text = `# Front Sheet View
This displays your Front sheet.

## Edit Front Sheet
This will take you to the same page as you are directed to when you create a new Front Sheet. 
From this page you can include any additional plans and make any amendments to the features that have previously been set up in the Front Sheet.

## List of included plans
The list of included plans in the Front Sheet. To view and edit a plan shown in your Front Sheet, simply click on the eye icon. This will open your plan in a new tab.

## Views Tab
If you have included more than one view in your Front Sheet, you will see a tab for each view including the name of the view.

## Download
Clicking download will export an excel version of your chosen Front Sheet to your computer. 
The excel export will include a sheet per view and a sheet for each plan included in the Front Sheet.

## Notes
Type in any notes you would like to make and select Save Notes.

## Back To Front Sheets
This will take you back to the Front Sheet List page. This page will display all Front Sheets you have access to.
`

export default text
