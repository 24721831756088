import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { Button, Collapse, Space } from 'antd'
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'

export type ITemplateSyncAccordionProps = {
  items: Array<Omit<ComponentProps<typeof Collapse>['items'][number], 'key'> & { key: string }>
}

const TemplateSyncAccordion: React.FC<ITemplateSyncAccordionProps> = ({ items }) => {
  const [activeItems, setActiveItems] = useState([items[0].key])

  const getShowHide = useCallback((key: string) => activeItems.includes(key)
    ? <Button type='link' style={{ border: 'none' }}>Hide<ShrinkOutlined /></Button>
    : <Button type='link' style={{ border: 'none' }}>Show<ArrowsAltOutlined /></Button>,
  [activeItems])

  const onAccordionChange = useCallback((keys: string | string[]) => {
    if (typeof keys !== 'string') {
      setActiveItems(keys)
    } else {
      setActiveItems([keys])
    }
  }, [])

  const collapseItems: ComponentProps<typeof Collapse>['items'] = useMemo(() => {
    return items.map((item) => ({
      ...item,
      extra: getShowHide(item.key),
      showArrow: false
    }))
  }, [items, getShowHide])

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Collapse defaultActiveKey={items[0].key} onChange={(keys: string | string[]) => onAccordionChange(keys)} items={collapseItems} />
    </Space>
  )
}

export default TemplateSyncAccordion
