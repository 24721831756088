import * as React from 'react'
import { CSSProperties } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { IFrontSheetsCalendarFlightRow } from 'Components/FrontSheets/constants/entities/IFrontSheets'

interface IFrontSheetCalendarFlightGroupRowProps {
  flightGroupBlockIdx: number
  flightRowIdx: number
  row: IFrontSheetsCalendarFlightRow
  laydownField?: IClientMediaPlanField
  columnsLength: number
  getStickyStyle: (index: number) => CSSProperties
}

const FrontSheetCalendarFlightGroupRow = ({ flightGroupBlockIdx, flightRowIdx, row, laydownField, columnsLength, getStickyStyle }: IFrontSheetCalendarFlightGroupRowProps) => {
  return (
    <tr
      className="flightGroup__flightRow"
      key={`flightRowIdx_${flightRowIdx}`}
      data-testid={`flightGroupRow_${flightGroupBlockIdx}_${flightRowIdx}`}
    >
      {row.labels.map((label, labelIdx) => (
        <td
          key={`cellIdx_${labelIdx}`}
          className={classNames('flightGroup__fieldLabel', { '-textCenter': label.isAggregate })}
          style={getStickyStyle(labelIdx)}
        >
          <Tooltip title={label.value} >
            <span data-testid={`flightGroupFieldLabel_${flightGroupBlockIdx}_${flightRowIdx}_${labelIdx}`}>
              {label.value || <>&nbsp;</>}
            </span>
          </Tooltip>
        </td>
      ))}
      <td className='flightGroup__fieldLabel -textRight' style={getStickyStyle(columnsLength)}>
        <Tooltip title={laydownField?.fieldLabel}>
          <span
            data-testid={`flightGroupLaydownField_${flightGroupBlockIdx}_${flightRowIdx}`}
          >
            {laydownField?.fieldLabel || 'flights'}
          </span>
        </Tooltip>
      </td>
      <td
        key={`flightRowIdx_${flightRowIdx}`}
        data-testid={`flightGroupCells_${flightGroupBlockIdx}_${flightRowIdx}`}
        className="flight__cells"
      >
        {row.flightCells.filter((cell) => cell.daysDiff > 0).map((cell, cellIdx) => (
          <Tooltip key={`cellIdx_${cellIdx}`} title={cell.value} >
            <div
              className={cell.isFlight ? 'flight__cell' : ''}
              style={{ width: `${cell.daysDiff * 10}px` }}
            >
              <span data-testid={cell.isFlight ? `flightCell_${flightGroupBlockIdx}_${flightRowIdx}_${cell.flightIndex}` : undefined}>
                {cell.value || <>&nbsp;</>}
              </span>
            </div>
          </Tooltip>
        ))}
      </td>
    </tr>
  )
}

export default FrontSheetCalendarFlightGroupRow
