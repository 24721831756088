import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { isFieldOfSameOrHigherLevel, getFieldLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { getFieldColumnName, IMediaPlanVersionField, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { IMediaPlanVersionLinkedLookupHelperValues, ILinkedLookupVariablesProps } from '../entities/IMediaPlanVersionLinkedLookupHelperValues'

export const getLinkedLookupVariables = ({ currentField, fields, linkedLookups, parseData }: ILinkedLookupVariablesProps): IMediaPlanVersionLinkedLookupHelperValues => {
  const linkedLookupConnection = linkedLookups.find(
    item =>
      currentField?.clientMediaPlanField.mediaPlanFieldId === item.dependentMediaPlanFieldId
  )

  const getFieldData = (field: IMediaPlanVersionField, flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number) => {
    const currentFieldLevelId = field?.clientMediaPlanField.mediaPlanField.fieldLevelId

    switch (currentFieldLevelId) {
      case FieldLevelType.PLAN: {
        return parseData
      }

      case FieldLevelType.FLIGHT_GROUP: {
        if ([flightGroupIndex].includes(undefined)) {
          return null
        }

        return parseData?.flightGroups[flightGroupIndex]
      }

      case FieldLevelType.FLIGHT: {
        if ([flightGroupIndex, flightIndex].includes(undefined)) {
          return null
        }

        return parseData.flightGroups[flightGroupIndex].flights[flightIndex]
      }

      case FieldLevelType.SUB_FLIGHT: {
        if ([flightGroupIndex, flightIndex, subFlightIndex].includes(undefined)) {
          return null
        }

        return parseData.flightGroups[flightGroupIndex].flights[flightIndex]?.subFlights[subFlightIndex]
      }

      default: {
        return null
      }
    }
  }

  const referencedMediaPlanFields = fields.filter(
    item =>
      item.clientMediaPlanField.mediaPlanFieldId === linkedLookupConnection?.referencedMediaPlanFieldId && isFieldOfSameOrHigherLevel(item, currentField)
  ).sort((f1, f2) => getFieldLevel(f2) - getFieldLevel(f1))

  const getClosestReferencedMediaPlanField = (flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number) => {
    const field = referencedMediaPlanFields.find((item) => {
      const columnName = getFieldColumnName(item)
      const data = getFieldData(item, flightGroupIndex, flightIndex, subFlightIndex)
      return data?.[columnName]
    })

    return field
  }

  const getReferencedMediaPlanField = (field: IMediaPlanFieldRow | IMediaPlanTemplateFields) => {
    const referencedMediaPlanField = [...fields].sort((f1, f2) => getFieldLevel(f2) - getFieldLevel(f1)).find(
      item =>
        item.clientMediaPlanField.mediaPlanFieldId === linkedLookupConnection?.referencedMediaPlanFieldId && isFieldOfSameOrHigherLevel(item, field)
    )

    return referencedMediaPlanField
  }

  const getIsReferencedFieldMandatory = (field: IMediaPlanFieldRow | IMediaPlanTemplateFields) =>
    field?.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY

  const isEnforcedLink = linkedLookupConnection?.enforced

  const getIsLinkedFieldDisabled = (fieldValue: string | number, field: IMediaPlanFieldRow | IMediaPlanTemplateFields) => {
    const referencedMediaPlanField = getReferencedMediaPlanField(field)
    const isReferencedFieldMandatory = getIsReferencedFieldMandatory(referencedMediaPlanField)

    return !!(
      referencedMediaPlanField &&
      isReferencedFieldMandatory &&
      isEnforcedLink &&
      !fieldValue
    )
  }

  const getIsFilteredLookupValuesVisible = (fieldValue: string | number) => !!fieldValue

  return {
    getIsLinkedFieldDisabled,
    getClosestReferencedMediaPlanField,
    linkedLookupConnection,
    getIsFilteredLookupValuesVisible,
    isEnforcedLink,
    getReferencedMediaPlanField
  }
}
