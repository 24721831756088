import { ClientMediaPlanDataAction } from 'Actions/clientMediaPlanFieldActions'
import * as actionTypes from 'Constants/actionTypes'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'

export interface IMediaPlanFieldsState {
  clientMediaPlanFields: IClientMediaPlanField[]
}

const initialState: IMediaPlanFieldsState = {
  clientMediaPlanFields: []
}

export default function (state: IMediaPlanFieldsState = initialState, action: ClientMediaPlanDataAction): IMediaPlanFieldsState {
  switch (action.type) {
    case actionTypes.SET_CLIENT_MEDIA_FIELD_DATA: {
      return {
        ...state,
        clientMediaPlanFields: action.payload
      }
    }
    default:
      return state
  }
}
