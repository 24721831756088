import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Button, message, Tooltip } from 'antd'
import { MSLayoutContentRow, useRouteNavigation, useRouteParams } from '@mindshare/layout'
import {
  addToCollapsedFlightGroups,
  removeFromCollapsedFlightGroups,
  expandAllFlightGroups,
  collapseAllFlightGroups
} from 'Actions/appActions'
import { cleanUpTemplateExampleContainer, getMediaPlanTemplateById, runCalculation, updateMediaPlanTemplateInStore } from 'Actions/mediaPlanTemplatesActions'
import { updateMediaPlanTemplates } from 'Actions/templatesActions'
import CalculationHeaderContainer from 'Containers/CalculationHeaderContainer'
import TemplateExamplePlanLevelComponent from 'Components/TemplateExample/TemplateExamplePlanLevelComponent'
import TemplateExampleFlightGroupLevelComponent from 'Components/TemplateExample/TemplateExampleFlightGroupLevelComponent'
import MediaPlanVersionToolbarComponent from 'Components/MediaPlanVersion/MediaPlanVersionToolbarComponent'
import TemplateExamplePlanLevelRichTextFieldsComponent from 'Components/TemplateExample/TemplateExamplePlanLevelRichTextFieldsComponent'
import {
  IMediaPlanTemplateFields,
  buildMediaPlanTemplateFields,
  changeDefaultValue,
  isFieldAllowForCalculation,
  isAggregatedOrCalculated,
  findByFieldLevelId,
  isFieldAvailable,
  isFieldValidForClick,
  isFieldValidForCalculation
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { filterRichText, filterRichTextHeaderFields, filterRichTextFooterFields } from 'Helpers/richTextHelpers'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useMasteredListFieldsData } from 'Hooks/useMasteredListFieldsData'
import { appPaths } from 'Constants/appPaths'
import { selectCurrentMediaPlanTemplate, selectCollapsedFlightGroups } from '../../../selectors'
import { useAppDispatch, useAppSelector } from '../../../store'

export const TemplateExampleContainer: React.FunctionComponent = () => {
  const mediaPlanTemplateId = useRouteParams('mediaPlanTemplateId', Number)
  const mediaPlanTemplate = useAppSelector(selectCurrentMediaPlanTemplate)
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies, isLoading: isHierarchiesLoading } = useHierarchies(currentClient?.id)
  const masteredListsData = useMasteredListFieldsData(currentClient?.id)
  const { isMasteredListsDataLoading } = masteredListsData
  const collapsedFlightGroups = useAppSelector(selectCollapsedFlightGroups)
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()

  const [selectedCalculatedField, setMediaPlanTemplateFields] = useState<IMediaPlanTemplateFields>()
  const [planLevelFields, setPlanLevelFields] = useState<IMediaPlanTemplateFields[]>([])
  const [flightGroupFields, setFlightGroupFields] = useState<IMediaPlanTemplateFields[]>([])
  const [calculatedAggregatedFields, setCalculatedAggregatedFields] = useState<IMediaPlanTemplateFields[]>([])
  const [availablePlanFields, setAvailablePlanFields] = useState<IMediaPlanTemplateFields[]>([])
  const [availableFlightGroupFields, setAvailableFlightGroupFields] = useState<IMediaPlanTemplateFields[]>([])
  const [flightFields, setFlightFields] = useState<IMediaPlanTemplateFields[]>([])
  const [availableFlightFields, setAvailableFlightFields] = useState<IMediaPlanTemplateFields[]>([])
  const [loading, setLoading] = useState(true)
  const richTextPlanLevelFields = planLevelFields.filter(filterRichText)
  const richTextHeaderFields = useMemo(() => filterRichTextHeaderFields(richTextPlanLevelFields), [richTextPlanLevelFields])
  const richTextFooterFields = useMemo(() => filterRichTextFooterFields(richTextPlanLevelFields), [richTextPlanLevelFields])

  const backToEditTemplate = useCallback(() =>
    navigate(`${appPaths.templateEdit}/${mediaPlanTemplateId}`, false, { clientId: currentClient.id })
  , [navigate, currentClient.id, mediaPlanTemplateId])

  const updateTemplate = useCallback(async () => {
    await updateMediaPlanTemplates(mediaPlanTemplate, currentClient.id)
    message.success('Template updated successfully')
  }, [currentClient.id, mediaPlanTemplate])

  const isCurrentFieldValidForClick = useCallback((item: IMediaPlanTemplateFields) =>
    isFieldValidForClick(item, selectedCalculatedField) && isFieldValidForCalculation(item, selectedCalculatedField)
  , [selectedCalculatedField])

  const tokenHandler = useCallback((token: string) => {
    if (selectedCalculatedField) {
      if (!selectedCalculatedField.calculation) {
        // eslint-disable-next-line functional/immutable-data
        selectedCalculatedField.calculation = ''
      }
      // eslint-disable-next-line functional/immutable-data
      selectedCalculatedField.calculation += token
      setMediaPlanTemplateFields(Object.assign({}, selectedCalculatedField))
    }
  }, [selectedCalculatedField])

  const removeAvailableFieldFromList = useCallback((selectedAvailableField: IMediaPlanTemplateFields, availableArray: IMediaPlanTemplateFields[]) => {
    const availableIndex = availableArray.findIndex(a => a.clientMediaPlanFieldId === selectedAvailableField.clientMediaPlanFieldId)
    // eslint-disable-next-line functional/immutable-data
    availableArray.splice(availableIndex, 1)
    if ((selectedAvailableField.templateFieldTypeId === TemplateFieldTypes.CALCULATED || selectedAvailableField.templateFieldTypeId === TemplateFieldTypes.AGGREGATED) &&
      !calculatedAggregatedFields.some(c => c.clientMediaPlanFieldId === selectedAvailableField.clientMediaPlanFieldId)) {
      // eslint-disable-next-line functional/immutable-data
      calculatedAggregatedFields.push(selectedAvailableField)
      setCalculatedAggregatedFields([...calculatedAggregatedFields])
    }
  }, [calculatedAggregatedFields])

  const onAvailableFieldSelected = useCallback((
    c: string,
    mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void, availableFields: IMediaPlanTemplateFields[]
  ) => {
    const selectedAvailableField = JSON.parse(c) as IMediaPlanTemplateFields
    const indexFound = mediaPlanFields.findIndex(p => p.clientMediaPlanFieldId === 0)
    const newMediaPlanFields = mediaPlanFields.map((m, index) => index === indexFound ? selectedAvailableField : m)
    setFunction(newMediaPlanFields)
    removeAvailableFieldFromList(selectedAvailableField, availableFields)
  }, [removeAvailableFieldFromList])

  const handleAddField = useCallback((fieldType: FieldLevelType) => {
    const newItemField = buildMediaPlanTemplateFields()
    switch (fieldType) {
      case FieldLevelType.FLIGHT_GROUP:

        if (availableFlightGroupFields.length > 0 && !flightGroupFields.some(c => c.mediaPlanTemplateFieldId === 0)) {
          setFlightGroupFields([...flightGroupFields, newItemField])
        }
        break
      case FieldLevelType.PLAN:
        if (availablePlanFields.length > 0 && !planLevelFields.some(c => c.mediaPlanTemplateFieldId === 0)) {
          setPlanLevelFields([...planLevelFields, newItemField])
        }
        break
      case FieldLevelType.FLIGHT:

        if (availableFlightFields.length > 0 && !flightFields.some(c => c.mediaPlanTemplateFieldId === 0)) {
          setFlightFields([...flightFields, newItemField])
        }
        break

      default:
        break
    }
  }, [availableFlightFields.length, availableFlightGroupFields.length, availablePlanFields.length, flightFields, flightGroupFields, planLevelFields])

  const removePlanField = useCallback((mediaPlanField: IMediaPlanTemplateFields) => {
    if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.PLAN) {
      const newPlanFields = planLevelFields.filter(c => c.mediaPlanTemplateFieldId !== mediaPlanField.mediaPlanTemplateFieldId)
      const newAvailableFields = [...availablePlanFields, mediaPlanField]
      setAvailablePlanFields(newAvailableFields)
      setPlanLevelFields(newPlanFields)
    } else if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP) {
      const newFlightGroupFields = flightGroupFields.filter(c => c.mediaPlanTemplateFieldId !== mediaPlanField.mediaPlanTemplateFieldId)
      const newAvailableFlightGroupFields = [...availableFlightGroupFields, mediaPlanField]
      setAvailableFlightGroupFields(newAvailableFlightGroupFields)
      setFlightGroupFields(newFlightGroupFields)
    } else if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT ||
     mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.SUB_FLIGHT) {
      const newFlightFields = flightFields.filter(c => c.mediaPlanTemplateFieldId !== mediaPlanField.mediaPlanTemplateFieldId)
      const newAvailableFlightFields = [...availableFlightFields, mediaPlanField]
      setFlightFields(newFlightFields)
      setAvailableFlightFields(newAvailableFlightFields)
    }
  }, [availableFlightFields, availableFlightGroupFields, availablePlanFields, flightFields, flightGroupFields, planLevelFields])

  const updateCalculationField = useCallback((mediaPlanField: IMediaPlanTemplateFields) => {
    if (mediaPlanField.clientMediaPlanFieldId === selectedCalculatedField.clientMediaPlanFieldId) {
      // eslint-disable-next-line functional/immutable-data
      mediaPlanField.calculation = selectedCalculatedField.calculation
      const mediaPlanIndex = mediaPlanTemplate.mediaPlanTemplateFields.findIndex(mf => mf.clientMediaPlanFieldId === selectedCalculatedField.clientMediaPlanFieldId)
      const updateTemplateMetadata = {
        ...mediaPlanTemplate,
        mediaPlanTemplateFields: mediaPlanTemplate.mediaPlanTemplateFields.map(
          (mf, index) => index === mediaPlanIndex ? { ...mf, calculation: selectedCalculatedField.calculation, functionId: selectedCalculatedField.functionId } : mf
        )
      }
      dispatch(updateMediaPlanTemplateInStore(updateTemplateMetadata))
    }
  }, [dispatch, mediaPlanTemplate, selectedCalculatedField?.calculation, selectedCalculatedField?.clientMediaPlanFieldId, selectedCalculatedField?.functionId])

  const updateCalculationInArray = useCallback((result) => {
    result.forEach((currentResult) => {
      if (flightGroupFields.some(c => c.clientMediaPlanFieldId === currentResult.fieldId)) {
        const mediaPlanField = flightGroupFields.find(c => c.clientMediaPlanFieldId === currentResult.fieldId)
        // eslint-disable-next-line functional/immutable-data
        mediaPlanField.clientMediaPlanField.mediaPlanField.defaultValue = currentResult.result
        changeDefaultValue(mediaPlanField, hierarchies, masteredListsData, mediaPlanTemplate.mediaPlanTemplateFields, currentClient.id)
        updateCalculationField(mediaPlanField)
        setFlightGroupFields([...flightGroupFields])
      } else if (planLevelFields.some(c => c.clientMediaPlanFieldId === currentResult.fieldId)) {
        const mediaPlanField = planLevelFields.find(c => c.clientMediaPlanFieldId === currentResult.fieldId)
        // eslint-disable-next-line functional/immutable-data
        mediaPlanField.clientMediaPlanField.mediaPlanField.defaultValue = currentResult.result
        changeDefaultValue(mediaPlanField, hierarchies, masteredListsData, mediaPlanTemplate.mediaPlanTemplateFields, currentClient.id)
        updateCalculationField(mediaPlanField)
        setPlanLevelFields([...planLevelFields])
      } else if (flightFields.some(c => c.clientMediaPlanFieldId === currentResult.fieldId)) {
        const mediaPlanField = flightFields.find(c => c.clientMediaPlanFieldId === currentResult.fieldId)
        // eslint-disable-next-line functional/immutable-data
        mediaPlanField.clientMediaPlanField.mediaPlanField.defaultValue = currentResult.result
        changeDefaultValue(mediaPlanField, hierarchies, masteredListsData, mediaPlanTemplate.mediaPlanTemplateFields, currentClient.id)
        updateCalculationField(mediaPlanField)
        setFlightFields([...flightFields])
      }
    })
  }, [currentClient.id, flightFields, flightGroupFields, hierarchies, masteredListsData, mediaPlanTemplate, planLevelFields, updateCalculationField])

  const initialiseState = useCallback(async () => {
    if (mediaPlanTemplate && mediaPlanTemplate.mediaPlanTemplateFields && loading && !isHierarchiesLoading && !isMasteredListsDataLoading) {
      // eslint-disable-next-line functional/immutable-data
      mediaPlanTemplate.mediaPlanTemplateFields.sort((a, b) => a.sortOrder - b.sortOrder)
      await Promise.all(mediaPlanTemplate.mediaPlanTemplateFields.map(async p => {
        await changeDefaultValue(p, hierarchies, masteredListsData, mediaPlanTemplate.mediaPlanTemplateFields, currentClient.id)
      }))
      setLoading(false)
      const fieldsNotTypeAvailable = mediaPlanTemplate.mediaPlanTemplateFields.filter(c => !isFieldAvailable(c))
      const availableFields = mediaPlanTemplate.mediaPlanTemplateFields.filter(c => isFieldAvailable(c))
      setPlanLevelFields(findByFieldLevelId(fieldsNotTypeAvailable, FieldLevelType.PLAN))
      setFlightGroupFields(findByFieldLevelId(fieldsNotTypeAvailable, FieldLevelType.FLIGHT_GROUP))
      const flights = findByFieldLevelId(fieldsNotTypeAvailable, FieldLevelType.FLIGHT)
      const subFlights = findByFieldLevelId(fieldsNotTypeAvailable, FieldLevelType.SUB_FLIGHT)
      setFlightFields(flights.concat(subFlights))
      setCalculatedAggregatedFields(mediaPlanTemplate.mediaPlanTemplateFields.filter(c => isFieldAllowForCalculation(c)).filter(c => isAggregatedOrCalculated(c)))
      setAvailablePlanFields(findByFieldLevelId(availableFields, FieldLevelType.PLAN))
      setAvailableFlightGroupFields(findByFieldLevelId(availableFields, FieldLevelType.FLIGHT_GROUP))
      const flightsAvailable = findByFieldLevelId(availableFields, FieldLevelType.FLIGHT)
      const subFlightsAvailable = findByFieldLevelId(availableFields, FieldLevelType.SUB_FLIGHT)
      setAvailableFlightFields(flightsAvailable.concat(subFlightsAvailable))
    }
  }, [currentClient.id, hierarchies, isHierarchiesLoading, masteredListsData, isMasteredListsDataLoading, loading, mediaPlanTemplate])

  const valid = calculatedAggregatedFields.length > 0 ? calculatedAggregatedFields.every(c => c.calculation) : mediaPlanTemplate.mediaPlanTemplateId > 0

  const getMediaPlanTemplate = useCallback(async () => {
    if (currentClient && currentClient.id > 0) {
      await dispatch(getMediaPlanTemplateById(mediaPlanTemplateId, currentClient.id))
      setLoading(true)
    }
  }, [dispatch, currentClient, mediaPlanTemplateId])

  const setCollapsedFlightGroups = useCallback((add: boolean, id: number) => {
    if (add) {
      dispatch(addToCollapsedFlightGroups(id))
    } else {
      dispatch(removeFromCollapsedFlightGroups(id))
    }
  }, [dispatch])

  const setCollapsedAllFlightGroups = useCallback(() => {
    const flightGroupIds = mediaPlanTemplate.mediaPlanTemplateFields.map(item => item.mediaPlanTemplateFieldId)
    dispatch(collapseAllFlightGroups(flightGroupIds))
  }, [dispatch, mediaPlanTemplate.mediaPlanTemplateFields])

  const setExpandAllFlightGroups = useCallback(() => {
    dispatch(expandAllFlightGroups())
  }, [dispatch])

  useEffect(() => {
    getMediaPlanTemplate()
  }, [getMediaPlanTemplate])

  useEffect(() => {
    if (loading && mediaPlanTemplate.mediaPlanTemplateId > 0) {
      initialiseState()
    }
  }, [loading, mediaPlanTemplate.mediaPlanTemplateId, initialiseState])

  useEffect(() => () => dispatch(cleanUpTemplateExampleContainer()), [dispatch])

  return (
    <div className='template-example-container'>
      <MSLayoutContentRow extraClass='calculation-header sticky-row'>
        <CalculationHeaderContainer
          calculationMode={true}
          setCalculationMode={null}
          headerTitle='Template Example'
          planInfo={null}
          mediaPlanTemplateFields={calculatedAggregatedFields}
          mediaPlanTemplateId={mediaPlanTemplateId}
          selectedCalculatedField={selectedCalculatedField}
          setCalculatedAggregatedFields={setCalculatedAggregatedFields}
          setMediaPlanTemplateFields={setMediaPlanTemplateFields}
          updateCalculationInArray={updateCalculationInArray}
          runCalculation={runCalculation}
          enableToggle={false}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
        <TemplateExamplePlanLevelComponent
          mediaPlanFields={planLevelFields}
          availableMediaPlanFields={availablePlanFields}
          onAvailableFieldSelected={onAvailableFieldSelected}
          tokenHandler={tokenHandler}
          isFieldValidForClick={isCurrentFieldValidForClick}
          setFunction={setPlanLevelFields}
          calculationMode={true}
          masteredListsData={masteredListsData}
          removePlanField={removePlanField}
          errors={[]}
        />
      </MSLayoutContentRow>

      {richTextHeaderFields.length > 0 && <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
        <TemplateExamplePlanLevelRichTextFieldsComponent
          onAvailableFieldSelected={onAvailableFieldSelected}
          setFunction={setPlanLevelFields}
          availablePlanFields={availablePlanFields}
          removePlanField={removePlanField}
          richTextPlanLevelFields={richTextHeaderFields}
        />
      </MSLayoutContentRow>}

      <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
        <MediaPlanVersionToolbarComponent
          disabledCalendar={true}
          handleAddField={handleAddField}
          openByDefault={false}
          setCollapsedAllFlightGroups={setCollapsedAllFlightGroups}
          setExpandAllFlightGroups={setExpandAllFlightGroups}
          calculationMode={false}
          isTemplate={true}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow widthOverride='calc(100% - 10px)'>
        <TemplateExampleFlightGroupLevelComponent
          mediaPlanFields={flightGroupFields}
          availableFlightGroupFields={availableFlightGroupFields}
          tokenHandler={tokenHandler}
          isFieldValidForClick={isCurrentFieldValidForClick}
          availableMediaPlanFields={availablePlanFields}
          onAvailableFieldSelected={onAvailableFieldSelected}
          setFunction={setFlightGroupFields}
          setFunctionForFlights={setFlightFields}
          calculationMode={true}
          isTemplate={true}
          hierarchies={hierarchies}
          masteredListsData={masteredListsData}
          collapsedFlightGroups={collapsedFlightGroups}
          setCollapsedFlightGroups={setCollapsedFlightGroups}
          handleAddField={handleAddField}
          availableFlightFields={availableFlightFields}
          mediaPlanFieldsFlights={flightFields}
          startDayOfWeek={''}
          planStartDate={'2020-01-01'}
          planEndDate={'2020-01-31'}
          calendarView={'week'}
          flightGroupErrors={[]}
          flightErrors={[]}
          removePlanField={removePlanField}
        />
      </MSLayoutContentRow>

      {richTextFooterFields.length > 0 && <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
        <TemplateExamplePlanLevelRichTextFieldsComponent
          onAvailableFieldSelected={onAvailableFieldSelected}
          setFunction={setPlanLevelFields}
          availablePlanFields={availablePlanFields}
          removePlanField={removePlanField}
          richTextPlanLevelFields={richTextFooterFields}
        />
      </MSLayoutContentRow>}

      <MSLayoutContentRow widthOverride='calc(100% - 10px)' extraClass='sticky-row'>
        <div className='template-example-buttons'>
          <Button type='primary' className='button-back' onClick={backToEditTemplate}>Back / Amend Template</Button>
          <Tooltip title={valid ? 'Save Template' : 'Some calculated fields are missing the calculation'}>
            <Button
              type='primary'
              className='button-update-template'
              onClick={updateTemplate}
              disabled={!valid}
            >Save Template</Button>
          </Tooltip>
        </div>
      </MSLayoutContentRow>
    </div>
  )
}

export default TemplateExampleContainer
