import * as React from 'react'
import { CSSProperties } from 'react'
import { ViewDisplayColumn } from 'Apis/generated/frontSheetsApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { IFrontSheetsCalendarFlightGroup } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import FrontSheetCalendarFlightGroupRow from './FrontSheetCalendarFlightGroupRow'
import FrontSheetCalendarSubTotalRow from './FrontSheetCalendarSubTotalRow'
import FrontSheetCalendarGrandTotalRow from './FrontSheetCalendarGrandTotalRow'

interface FrontSheetCalendarBodyProps {
  flightGroups: IFrontSheetsCalendarFlightGroup[]
  columns: ViewDisplayColumn[]
  laydownField: IClientMediaPlanField
  getStickyStyle: (index: number) => CSSProperties
  grandTotal: string[]
}

function FrontSheetCalendarBody (
  props: FrontSheetCalendarBodyProps
) {
  const { flightGroups, columns, laydownField, getStickyStyle, grandTotal } = props
  return (
    <>
      {flightGroups.map((flightGroupBlock, flightGroupBlockIdx) => (
        <tbody
          key={`flightGroupBlockIdx_${flightGroupBlockIdx}`}
          data-testid={`flightGroupBlock_${flightGroupBlockIdx}`}
        >
          {flightGroupBlock.groups.map((row, flightRowIdx) => (
            <FrontSheetCalendarFlightGroupRow
              key={`flightRowIdx_${flightGroupBlockIdx}_${flightRowIdx}`}
              flightGroupBlockIdx={flightGroupBlockIdx}
              flightRowIdx={flightRowIdx}
              columnsLength={columns.length}
              getStickyStyle={getStickyStyle}
              row={row}
              laydownField={laydownField}
            />
          ))}
          {
            flightGroupBlock.subTotals && (
              <FrontSheetCalendarSubTotalRow
                columns={columns}
                flightGroupBlock={flightGroupBlock}
                flightGroupBlockIdx={flightGroupBlockIdx}
                getStickyStyle={getStickyStyle}
              />
            )
          }
        </tbody>
      ))}
      {
        grandTotal && (
          <tbody>
            <FrontSheetCalendarGrandTotalRow
              columns={columns}
              getStickyStyle={getStickyStyle}
              grandTotal={grandTotal}
            />
          </tbody>
        )
      }
    </>
  )
}

export default FrontSheetCalendarBody
