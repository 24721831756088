import { ITemplatesAction } from 'Actions/templatesActions'
import { TemplateFieldType } from 'Apis/generated/templateFieldTypesApi'
import { TemplateFieldAvailability } from 'Apis/generated/templateFieldAvailabilitiesApi'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import * as actionTypes from 'Constants/actionTypes'
import { IValidationResult } from 'Components/TemplateFieldSelection/constants/entities/IValidationResult'

export interface ITemplatesState {
  mediaPlanField: IMediaPlanFieldExtended[]
  templateFieldType: TemplateFieldType[]
  templateAvailabilityType: TemplateFieldAvailability[]
  templateMetaData: any
  errors: IValidationResult[]
}

const initialState: ITemplatesState = {
  mediaPlanField: [],
  templateFieldType: [],
  templateAvailabilityType: [],
  templateMetaData: [],
  errors: []
}

export default function (state: ITemplatesState = initialState, action: ITemplatesAction): ITemplatesState {
  switch (action.type) {
    case actionTypes.SET_TEMPLATE_DATA_FIELDS: {
      return {
        ...state,
        mediaPlanField: action.payload
      }
    }
    case actionTypes.TEMPLATE_FIELD_TYPE: {
      return {
        ...state,
        templateFieldType: action.payload
      }
    }
    case actionTypes.TEMPLATE_AVAILABILITY_TYPE: {
      return {
        ...state,
        templateAvailabilityType: action.payload
      }
    }
    case actionTypes.TEMPLATE_META_DATA: {
      return {
        ...state,
        templateMetaData: action.payload
      }
    }
    case actionTypes.SET_TEMPLATE_ERRORS: {
      return {
        ...state,
        errors: action.payload
      }
    }
    default:
      return state
  }
}
