import React, { useEffect, useState } from 'react'
import { useMindshareSelector } from '@mindshare/layout'
import { message } from 'antd'
import { DndContext } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import type { Coordinates } from '@dnd-kit/utilities'

import { getGoalSeekData, resetGoalSeekModalState, updateGoalSeekModalState } from 'Actions/mediaPlansActions'
import { RootState } from 'Reducers/index'
import GoalSeekModalComponent from 'Components/GoalSeekModalComponent'
import { IGoalSeekData } from 'Components/GoalSeek/constants/entities/IGoalSeekData'
import { useAppDispatch, useAppSelector } from '../../store'

interface IProps {
  setGoalSeekModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const GoalSeekModalContainer: React.FC<IProps> = (props) => {
  const [{ x: left, y: top }, setCoordinates] = useState<Coordinates>({ x: 1110, y: 203 })
  const style = { top, left }
  const dispatch = useAppDispatch()
  const {
    setGoalSeekModalVisible
  } = props
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)
  const goalSeekModalData = useMindshareSelector((state: RootState) => state.mediaPlans.goalSeek.goalSeekModalData) as IGoalSeekData
  const messageKey = 'messageKey'

  const updateGoalValue = (value: any) => dispatch(updateGoalSeekModalState(null, value, null, true))

  // Cleanup
  useEffect(() => () => {
    message.destroy()
    dispatch(resetGoalSeekModalState())
  }, [dispatch])

  const saveFunctions = {
    renderErrorMessage: (errors: string[]) => {
      let errorMessage = ''
      message.destroy()
      message.error({ content: errors.map(error => errorMessage += `${error}\n`).toString(), duration: 10, key: messageKey })
    },
    sendGoalSeekData: async () => {
      message.destroy()
      message.loading({ content: 'Loading...', key: messageKey })
      const goalSeekData = { ...goalSeekModalData }
      // eslint-disable-next-line functional/immutable-data
      delete goalSeekData.modalVisible
      // eslint-disable-next-line functional/immutable-data
      delete goalSeekData.wizardProgress
      const saveResult = await dispatch(getGoalSeekData(goalSeekData, currentClientId))
      message.destroy()
      if (saveResult && saveResult.topLevelErrors.length) {
        saveFunctions.renderErrorMessage(saveResult.topLevelErrors)
      } else {
        message.success({ content: 'Goal seek completed', key: messageKey })
      }
    }
  }

  return (
    <DndContext
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) =>
          ({
            x: x + delta.x,
            y: y + delta.y
          })
        )
      }}
      modifiers={[restrictToWindowEdges]}
    >
      <div className='goal-seek-modal-container'>
        <GoalSeekModalComponent
          goal={goalSeekModalData.goal}
          goalField={goalSeekModalData.goalField}
          variableField={goalSeekModalData.variableField}
          sendGoalSeekData={saveFunctions.sendGoalSeekData}
          setGoalSeekModalVisible={setGoalSeekModalVisible}
          updateGoalValue={updateGoalValue}
          wizardProgress={goalSeekModalData.wizardProgress}
          style={style}
        />
      </div>
    </DndContext>
  )
}

export default GoalSeekModalContainer
