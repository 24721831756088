
import { useMemo } from 'react'
import { useGetFunctionsQuery } from 'Apis/enhancedFunctionsApi'
import { GetFunctionsApiResponse } from 'Apis/generated/functionsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../redux/types'

export const useFunctions = (): UseQueryCommand<GetFunctionsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data: functions = [], isLoading } = useGetFunctionsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )
  const calculationFunctions = useMemo(() => functions.filter(c => c.functionId === 10), [functions])

  return useMemo(
    () => ({ data: calculationFunctions, isLoading }),
    [calculationFunctions, isLoading]
  )
}
