import React, { useMemo, useCallback } from 'react'
import { MSTable, useRouteNavigation } from '@mindshare/layout'

import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { compare } from 'Helpers/sortHelper'
import { generateTemporaryId } from 'Helpers/commonUtils'
import { filterMediaPlanFieldsById } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { linkedLookupsInitialValues } from 'Components/LinkedLookup/constants/entities'
import { LinkedLookupActionType } from 'Components/LinkedLookup/constants/enums/LinkedLookupActionType'
import { TypeSelectComponent } from 'Components/LinkedLookup/components/TypeSelectComponent'
import { FieldNameSelectComponent } from 'Components/LinkedLookup/components/FieldNameSelectComponent'
import { useLinkedLookupOptions } from 'Components/LinkedLookup/hooks/useLinkedLookupOptions'
import { useCreateLinkedLookup } from 'Components/LinkedLookup/hooks/useCreateLinkedLookup'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { appPaths } from 'Constants/appPaths'
import { ILinkedLookupList } from '../constants/entities/ILinkedLookupList'
import { linkedLookupsSorter } from '../helpers/linkedLookupHelper'
import { LinkedLookupFieldActions } from './LinkedLookupFieldActions'
import { DependentOnFieldSelectComponent } from './DependentOnFieldSelectComponent'

type LinkedLookupListTableProps = {
  currentlySavedlinkedLookups: LinkedLookup[]
  linkedLookupsModel: Readonly<ILinkedLookupList>
  linkedLookupFields: {
    lookupMediaPlanFields: IMediaPlanFieldExtended[]
    mediaPlanFieldsWithHierarchies: IMediaPlanFieldExtended[]
  }
}

export const LinkedLookupListTable = (
  { currentlySavedlinkedLookups, linkedLookupsModel, linkedLookupFields }: LinkedLookupListTableProps
) => {
  const navigate = useRouteNavigation()
  const { execute: createLinkedLookup } = useCreateLinkedLookup()
  const dataSource = useMemo(() => linkedLookupsModel.values?.filter((item) => !item.deletedOn), [linkedLookupsModel.values])

  const { options: dependentOptions, selectedOptions: dependentSelectedOptions } = useLinkedLookupOptions({
    fields: linkedLookupsModel.values,
    linkedLookupFields: linkedLookupFields.lookupMediaPlanFields,
    columnName: 'dependentMediaPlanFieldId'
  })
  const { selectedOptions: referencedSelectedOptions } = useLinkedLookupOptions({
    fields: linkedLookupsModel.values,
    linkedLookupFields: linkedLookupFields.mediaPlanFieldsWithHierarchies,
    columnName: 'referencedMediaPlanFieldId'
  })

  const saveLinkedLookup = useCallback(async (data: LinkedLookup) => {
    const linkedLookupDto = { ...data, linkedLookupId: 0 }

    try {
      const response = await createLinkedLookup({
        linkedLookup: linkedLookupDto
      })

      return response
    } catch (err) { }
  }, [createLinkedLookup])

  const handleButtonClick = useCallback(async (actionType: LinkedLookupActionType, id?: number) => {
    const isEditMode = id > 0

    if (actionType === LinkedLookupActionType.EDIT) {
      if (isEditMode) {
        navigate(`${appPaths.fieldConnections}/${id}/values`)
      } else {
        const data = linkedLookupsModel.values.find((item: LinkedLookup) => item.linkedLookupId === id)
        const savedLookup = await saveLinkedLookup(data)

        if (savedLookup && id) {
          linkedLookupsModel.removeField(id)
        }
        if (savedLookup) {
          linkedLookupsModel.addField(savedLookup)
          navigate(`${appPaths.fieldConnections}/${savedLookup.linkedLookupId}/values`)
        }
      }
    } else if (actionType === LinkedLookupActionType.DELETE) {
      linkedLookupsModel.removeField(id)
    } else if (actionType === LinkedLookupActionType.ADD) {
      linkedLookupsModel.addField({ ...linkedLookupsInitialValues, linkedLookupId: generateTemporaryId() })
    }
  }, [navigate, saveLinkedLookup, linkedLookupsModel])

  const columns = [
    {
      title: 'Field Name',
      dataIndex: 'dependentMediaPlanFieldId',
      key: 'dependentMediaPlanFieldId',
      render: (text, row) => {
        const filteredOptions = filterMediaPlanFieldsById(dependentOptions, row.referencedMediaPlanFieldId)
        const { label = null } = dependentSelectedOptions[row.dependentMediaPlanFieldId] || {}
        const isEditMode = row.linkedLookupId > 0

        return (
          <FieldNameSelectComponent
            disabled={isEditMode}
            onChange={value => {
              linkedLookupsModel.updateField(row.linkedLookupId, {
                dependentMediaPlanFieldId: value
              })
            }
            }
            value={label}
            options={filteredOptions}
          />
        )
      },
      sorter: linkedLookupsSorter(dependentSelectedOptions, 'dependent')
    },
    {
      title: 'Enforced vs Encouraged',
      dataIndex: 'enforced',
      key: 'enforced',
      render: (text, row) => (
        <TypeSelectComponent
          onChange={value =>
            linkedLookupsModel.updateField(row.linkedLookupId, {
              enforced: value === 'Enforced'
            })
          }
          value={row.enforced}
        />
      ),
      sorter: (a, b) => compare(a.enforced, b.enforced)
    },
    {
      title: 'Dependent On',
      dataIndex: 'referencedMediaPlanFieldId',
      key: 'referencedMediaPlanFieldId',
      render: (text, row) => {
        const filteredOptions = filterMediaPlanFieldsById(linkedLookupFields.mediaPlanFieldsWithHierarchies, row.dependentMediaPlanFieldId)
        const { label = null } = referencedSelectedOptions[row.referencedMediaPlanFieldId] || {}
        const isEditMode = row.linkedLookupId > 0

        return (
          <DependentOnFieldSelectComponent
            disabled={isEditMode}
            onChange={value => {
              linkedLookupsModel.updateField(row.linkedLookupId, {
                referencedMediaPlanFieldId: value
              })
            }
            }
            value={label}
            options={filteredOptions}
          />
        )
      },
      sorter: linkedLookupsSorter(referencedSelectedOptions, 'referenced')
    },
    {
      title: '',
      dataIndex: 'buttons',
      className: 'button-cell',
      key: 'buttons',
      render: (text, row) => {
        const isNewField = row.dependentMediaPlanFieldId <= 0 || row.referencedMediaPlanFieldId <= 0
        const isOldFieldAndChanged = row.linkedLookupId > 0 && currentlySavedlinkedLookups.find(
          l => l.linkedLookupId === row.linkedLookupId
        )?.enforced !== row.enforced
        const getEditTooltipError = () => {
          if (isNewField) {
            return 'Field Name and Dependent On values should be selected'
          }
          if (isOldFieldAndChanged) {
            return 'Submit changes to be able to edit'
          }
        }

        return (
          <LinkedLookupFieldActions
            onRemove={() =>
              handleButtonClick(LinkedLookupActionType.DELETE, row.linkedLookupId)
            }
            onAdd={() => handleButtonClick(LinkedLookupActionType.ADD)}
            onEdit={() => handleButtonClick(LinkedLookupActionType.EDIT, row.linkedLookupId)}
            canEdit={!isNewField && !isOldFieldAndChanged}
            editTooltipError={getEditTooltipError()}
          />
        )
      }
    }
  ]

  return (
    <div className='list-table linked-lookup-list-table'>
      <MSTable
        rowKey='linkedLookupId'
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default LinkedLookupListTable
