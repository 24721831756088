import { useMemo } from 'react'
import {
  useGetSyncTemplateOptionsQuery,
  GetSyncTemplateOptionsApiResponse
} from 'Apis/generated/templateSyncApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useSyncTemplateOptions = (): UseQueryCommand<GetSyncTemplateOptionsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetSyncTemplateOptionsQuery(
    { clientId: currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
