const text = `# Client Setup

Area to configure/maintain the client setup in the tool.

------



## Client Setup

This is a list of all fields available in the tool (not client-specific) that can be used for a client. 
A field won't be available in templates/plans until it has been added here.

If a field has already been set up for the current client it will not be displayed in this list.

**Name** - is the generic field name, which can be aliased for a client in the next step.

**Definition** - clarifies what data should be stored in the application for this field

**Tags** - can be used to narrow down the search for fields

**Level** - determines what level of a plan the field is available at

**Type** - determines how the field can be input and stored within the tool. These are definitions of the different types available:

1. Integer - values input must be integers. All cost fields are stored as integers
2. String - values input are stored as strings and therefore treated as text
3. Decimal - values can contain decimal places
4. Percentage - values can contain decimal places, are treated as numbers and represent percentage values
5. Date - date picker will be available to user to select input date
6. Client defined list - user will see a dropdown list of options in a plan. This list is created/edited in client setup
7. Unique string - user will see a dropdown list of options in a plan and be able to manually type to add values to the list from within a plan. 
This list is created and can also be edited in client setup
8. Big string - a string field that allows for more characters than the regular string input
9. Hierarchies - 4 different client hierarchies
10. Media partners - dropdown list of client-specific media partners. List is maintained in client admin (UI currently not available in Media Activity Plan)

### Notes

If you want a field, e.g. Net Media Cost, to be available at every level of a plan then you must select the field at all 4 levels

------



## Client data terms

This lists all the fields selected in the previous section so all the fields available for the current client and allows you to configure them for that client.

Some fields will appear in this list by default. These fields are mandatory for the tool, meaning every plan for every client must contain this field e.g. Plan Name.

**Name** - is the generic field name

**Alias** - for if you want the field to appear with a different name for this client. 
Please bear in mind that the actual data contained in the field must match the definition for the field. 
If a field is aliased it will need to be approved to be visible with the alias name in templates/plans. 
An approval status icon is displayed next an alias to denote whether it is approved or not.

**Mandatory** - set a field to be mandatory in all plans for the client.

**Apportionment** - set a field to be the field that other fields are apportioned by. This can only be set for a single field and it must be at flight level.

**Apportioned** - select if a field is to be apportioned within reporting. 
Anything at plan or flight group level will be apportioned by the field selected as the apportionment field (above). 
Fields from flight and sub flight will be apportioned by day. Based on their definitions and data types, only certain fields are allowed to be apportioned. 
You cannot tick the Apportioned box if a field is not apportionable.

**Disabled** - if a field is no longer relevant, mark it as disabled so it is not available for adding to new templates. 
This will have no effect on existing plans or any future plans created using a template that existed before the field was disabled.

**Tags** - can be used to narrow down the search for fields

**Level** - determines what level of a plan the field is available at

**Type** - determines how the field can be input and stored within the tool. 
For client defined lists and unique strings there is a button here which provides a modal for setting these lists.

**Define list** button - for unique strings and client defined lists you can click the link provided in the type column 
and you will be provided with a modal that will allow you to:

- Add values
- Remove values
- Sort values (select the value you want to sort, then click up/down)

Click save to confirm you are finished and navigate back to the client setup page, however, 
the list will only be saved to the database and available in plans when you click submit on the client setup page after.

### Notes

All client defined lists must have been provided to be able to submit the client setup. 
If you click submit and are not taken through to create a template then there is a client defined list field you have not provided values for.`

export default text
