import { useMemo } from 'react'
import { useGetFinanceDataAudiencesQuery, GetFinanceDataAudiencesApiResponse } from 'Apis/generated/financeDataApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useFinanceDataAudiences = ({ clientGeographyHierarchyId, clientMediaHierarchyId, shouldSkip }): UseQueryCommand<GetFinanceDataAudiencesApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetFinanceDataAudiencesQuery(
    { clientId: currentClient.id, clientGeographyHierarchyId, clientMediaHierarchyId },
    { skip: !currentClient.id || shouldSkip }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
