import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { sanitizeHTMLString } from './stringHelper'

export const getRichTextOutputHtml = (value: string) => ({
  __html: value ? sanitizeHTMLString(draftToHtml(JSON.parse(value))) : '<p></p>'
})

export const getInitialState = (defaultValue: string) => {
  if (defaultValue) {
    const contentState = convertFromRaw(JSON.parse(defaultValue))
    return EditorState.createWithContent(contentState)
  }

  return EditorState.createEmpty()
}


export const getSanitizedRawJsonFromEditor = (editorState: EditorState) => {
  const htmlStr = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  const sanitizedHTML = sanitizeHTMLString(htmlStr)

  if (sanitizedHTML.trim() === '<p></p>') {
    return undefined
  }

  const blocksFromHtml = htmlToDraft(sanitizedHTML)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return JSON.stringify(convertToRaw(contentState))
}

export const filterRichText = (item: IMediaPlanTemplateFields) => {
  const { fieldDataTypeId } = item.clientMediaPlanField?.mediaPlanField || {}
  return !fieldDataTypeId || fieldDataTypeId === FieldDataType.RICH_TEXT
}

export const filterRichTextHeaderFields = (richTextPlanLevelFields: IMediaPlanTemplateFields[]) =>
  richTextPlanLevelFields.filter((mediaPlanField) => !mediaPlanField.isFooter)

export const filterRichTextFooterFields = (richTextPlanLevelFields: IMediaPlanTemplateFields[]) =>
  richTextPlanLevelFields.filter((mediaPlanField) => mediaPlanField.isFooter)
