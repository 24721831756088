import { formatLowerCaseFirstLetter, isNumeral, IMSHierarchies, formatUpperCaseFirstLetter } from '@mindshare/layout'
import moment from 'moment'
import { SubtotalCalculationResult, GroupingField, FlightGroupSubtotal, SubtotalCalculationGroup, FlightGrandTotal, FlightSubtotal } from 'Apis/generated/mediaPlanVersionsApi'
import { SubtotalConfigurationField } from 'Apis/generated/mediaPlansApi'
import { getCachedFlightWidth, DEFAULT_FLIGHT_WIDTH, dateToString, toISOString, dateMin } from 'Helpers/calendarHelper'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { generateTemporaryId } from 'Helpers/commonUtils'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { getSortableValue } from 'Helpers/flightHelper'
import { getUnaffectedFlights, getMonthIntervals } from 'Components/Subtotals/helpers/subtotalFlightsHelper'
import { buildEmptySubFlights } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { compare } from 'Helpers/sortHelper'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface ISortSubtotalField extends SubtotalConfigurationField {
  columnName?: string
}

interface IFlightExtended extends IFlight {
  month?: number
}

const sortFlightGroups = (
  subtotalFields: SubtotalConfigurationField[],
  hierarchies: IMSHierarchies,
  mediaPlanVersionFields: IMediaPlanVersionField[],
  masteredListsData: IMasteredListsData,
  flightGroups: IFlightGroup[],
  financeList?: IMediaPlanVersionFinanceListFields
): IFlightGroup[] => {
  const filteredSubtotalFields: ISortSubtotalField[] = subtotalFields?.map((field) => {
    const currentField = mediaPlanVersionFields.find((f) => f.clientMediaPlanFieldId === field.clientMediaPlanFieldId)
    if (currentField) {
      return {
        ...field,
        columnName: currentField.clientMediaPlanField.mediaPlanField.columnName
      }
    }
    return field
  }) || []

  const orderedFlightGroups = [...flightGroups]
    .sort((a, b) => {
      for (const subtotalField of filteredSubtotalFields) {
        const first = getSortableValue(
          a,
          mediaPlanVersionFields,
          hierarchies,
          masteredListsData,
          subtotalField.columnName,
          financeList
        )
        const second = getSortableValue(
          b,
          mediaPlanVersionFields,
          hierarchies,
          masteredListsData,
          subtotalField.columnName,
          financeList
        )
        let result = compare(first, second)

        if (first === undefined && second === undefined) result = 0
        else if (first === undefined) result = 1
        else if (second === undefined) result = -1
        if (result) {
          return result
        }
      }
      return undefined
    })
    .map((flightGroup, index) => {
      return { ...flightGroup, sortOrder: (index + 1) * 10 }
    })

  return orderedFlightGroups
}

const initialFlightGroupId = generateTemporaryId()
const getMediaPlanFlightGroupId = (index: number) => initialFlightGroupId + index

const getFlightGroupSubtotals =
  (
    groupingFields: GroupingField[],
    flights: IFlight[],
    mediaPlanVersionFields: IMediaPlanVersionField[],
    flightGroupSubtotals: FlightGroupSubtotal[],
    subtotalFlightGroupFields: IMediaPlanVersionField[],
    flightGroup: IFlightGroup,
    flightGroupIndex: number
  ) => {
    if (!flightGroup) return null

    let flightGroupToAdd: IFlightGroup = {
      mediaPlanFlightGroupId: getMediaPlanFlightGroupId(flightGroupIndex),
      subtotal: true,
      flights,
      sortOrder: flightGroup.sortOrder + 1
    }

    groupingFields.forEach((field) => {
      const currentField = mediaPlanVersionFields.find((f) => f.clientMediaPlanFieldId === field.clientMediaPlanFieldId)
      const fieldColumnName = formatLowerCaseFirstLetter(currentField?.clientMediaPlanField.mediaPlanField.columnName)
      const groupingFieldColumnName = `subtotal${formatUpperCaseFirstLetter(fieldColumnName)}`
      flightGroupToAdd = { ...flightGroupToAdd, [fieldColumnName]: flightGroup[fieldColumnName], [groupingFieldColumnName]: field.groupingValue }
    })

    flightGroupSubtotals.forEach((s) => {
      const subtotalField = subtotalFlightGroupFields.find((e) => s.clientMediaPlanFieldId === e.clientMediaPlanFieldId)
      const fieldColumnName = formatLowerCaseFirstLetter(subtotalField?.clientMediaPlanField.mediaPlanField.columnName)
      const subtotalFieldColumnName = `subtotal${formatUpperCaseFirstLetter(fieldColumnName)}`

      flightGroupToAdd = {
        ...flightGroupToAdd,
        [subtotalFieldColumnName]: isNumeral(s.subtotal) ? Number(s.subtotal) : s.subtotal
      }
    })

    return flightGroupToAdd
  }

const calculateFlightsWithSubtotals = (
  subtotalFlights: FlightSubtotal[],
  flightsToAdd: IFlightExtended[],
  flights: IFlight[],
  planStartDate: string,
  planEndDate: string
) => {
  if (!flights) return []

  const flightSubtotals: FlightGrandTotal[] = subtotalFlights.map((f) => ({
    ...f,
    startDate: dateToString(f.startDate),
    endDate: dateToString(f.endDate)
  }))
  const monthIntervals = getMonthIntervals(planStartDate, planEndDate)
  const unaffectedFlights = getUnaffectedFlights(monthIntervals, flightSubtotals) as IFlight[]
  const formattedUnaffectedFlights: IFlightExtended[] = unaffectedFlights.map((f) => ({
    ...f,
    flightStartDate: dateToString(f.flightStartDate),
    flightEndDate: dateToString(f.flightEndDate)
  }))

  const updatedFlightList = [...formattedUnaffectedFlights, ...flightsToAdd].sort((a, b) => a.month - b.month)
  const planStartDateISO = toISOString(dateMin(flights[0]?.flightStartDate, planStartDate))

  return updatedFlightList.map((item: IFlight, idx: number) => {
    return (
      idx === 0
        ? {
          ...item,
          flightStartDate: planStartDateISO
        }
        : item
    )
  }
  )
}

const getFlightSubtotals = (
  subtotal: SubtotalCalculationGroup,
  mediaPlanVersionFields: IMediaPlanVersionField[],
  flights: IFlight[],
  flightGroup: IFlightGroup,
  planStartDate: string,
  planEndDate: string
) => {
  const flightsToAdd: IFlightExtended[] = []

  subtotal.flightSubtotals?.forEach((item) => {
    const subtotalFlightField = mediaPlanVersionFields.find((e) => item.clientMediaPlanFieldId === e.clientMediaPlanFieldId)
    const subtotalFieldColumnName = formatLowerCaseFirstLetter(subtotalFlightField?.clientMediaPlanField.mediaPlanField.columnName)
    const startDateStr = dateToString(item.startDate)
    const endDateStr = dateToString(item.endDate)

    const foundIndex = flightsToAdd.findIndex(x => x.flightStartDate === startDateStr && x.flightEndDate === endDateStr)
    if (foundIndex === -1) {
      // eslint-disable-next-line functional/immutable-data
      flightsToAdd.push({
        flightEndDate: endDateStr,
        flightStartDate: startDateStr,
        subFlights: [],
        [subtotalFieldColumnName]: isNumeral(item.subtotal) ? Number(item.subtotal) : item.subtotal,
        merge: true,
        month: item.month,
        width: ''
      })
    } else {
      // eslint-disable-next-line functional/immutable-data
      flightsToAdd[foundIndex] = {
        ...flightsToAdd[foundIndex],
        [subtotalFieldColumnName]: isNumeral(item.subtotal) ? Number(item.subtotal) : item.subtotal
      }
    }
  })

  const calculatedFlightList = calculateFlightsWithSubtotals(subtotal.flightSubtotals, flightsToAdd, flights, planStartDate, planEndDate)
  const finalFlightList = calculatedFlightList.map((f) => {
    const flightWidth = getCachedFlightWidth(f.flightStartDate, f.flightEndDate, DEFAULT_FLIGHT_WIDTH)

    return {
      ...f,
      width: `${flightWidth}px`
    }
  })

  finalFlightList.forEach((f) => {
    const startDate = moment.utc(f.flightStartDate)
    const endDate = moment.utc(f.flightEndDate)

    // eslint-disable-next-line functional/immutable-data
    f.subFlights = buildEmptySubFlights(
      startDate,
      endDate,
      mediaPlanVersionFields,
      flightGroup
    )
  })

  return finalFlightList
}

export const getFlightGroupsWithSubtotals =
  (
    flightGroups: IFlightGroup[],
    subtotals: SubtotalCalculationResult,
    mediaPlanVersionFields: IMediaPlanVersionField[],
    planStartDate: string,
    planEndDate: string,
    subtotalConfigurationFields: SubtotalConfigurationField[],
    hierarchies: IMSHierarchies,
    masteredListsData: IMasteredListsData,
    financeList: IMediaPlanVersionFinanceListFields
  ): IFlightGroup[] => {
    const originalSortedFlightGroups = sortFlightGroups(subtotalConfigurationFields, hierarchies, mediaPlanVersionFields, masteredListsData, flightGroups, financeList)
    const newFlightGroups = [...originalSortedFlightGroups]

    if (flightGroups.length <= 1) {
      return newFlightGroups
    }

    if (!subtotals?.subtotalGroups) {
      return newFlightGroups
    }

    subtotals.subtotalGroups.forEach((subtotal) => {
      const flightGroup = [...flightGroups].sort((a, b) => b.sortOrder - a.sortOrder).find((item) => subtotal.flightGroupIds.includes(item.mediaPlanFlightGroupId))
      const filteredMediaPlanVersionFields = mediaPlanVersionFields.filter(
        field =>
          subtotal.groupingFields.some(
            item => field.clientMediaPlanFieldId === item.clientMediaPlanFieldId
          )
      )
      const subtotalFlightGroupFields = mediaPlanVersionFields.filter(field =>
        subtotal.flightGroupSubtotals.some(
          item => field.clientMediaPlanFieldId === item.clientMediaPlanFieldId
        )
      )
      const originalIndex = flightGroups.findIndex((item) => item.mediaPlanFlightGroupId === flightGroup?.mediaPlanFlightGroupId)
      const flightGroupIndex = newFlightGroups.findIndex((item) => item.mediaPlanFlightGroupId === flightGroup?.mediaPlanFlightGroupId)
      const flightsToAdd = getFlightSubtotals(
        subtotal,
        mediaPlanVersionFields,
        flightGroups[originalIndex]?.flights,
        flightGroups[originalIndex],
        planStartDate,
        planEndDate
      )
      const flightGroupToAdd = getFlightGroupSubtotals(
        subtotal.groupingFields,
        flightsToAdd,
        filteredMediaPlanVersionFields,
        subtotal.flightGroupSubtotals,
        subtotalFlightGroupFields,
        flightGroup,
        originalIndex
      )

      if (flightGroupToAdd) {
        // eslint-disable-next-line functional/immutable-data
        newFlightGroups.splice(flightGroupIndex + 1, 0, flightGroupToAdd)
      }
    })

    return newFlightGroups
  }
