import React, { FC } from 'react'
import { Button, Tooltip } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

interface IProps {
  canRemove?: boolean
  onRemove?: () => void
  onPush: () => void
}

export const CustomExportFieldActions: FC<IProps> = ({
  canRemove = true,
  onPush,
  onRemove = undefined
}) => (
  <div className='icon-group'>
    {canRemove && (
      <Tooltip title='Remove'>
        <Button
          className='btn-unset-default minus-icon icon-btn'
          data-testid='minus-icon'
          icon={<MinusCircleOutlined/>}
          onClick={onRemove}
        />
      </Tooltip>
    )}
    <Tooltip title='Add'>
      <Button
        className='btn-unset-default plus-icon icon-btn'
        icon={<PlusCircleOutlined/>}
        data-testid='plus-icon'
        onClick={onPush}
      />
    </Tooltip>
  </div>
)
