import { useMemo } from 'react'
import { IFlightGroupSelection } from 'Components/MediaPlanVersion/constants/entities/IFlightGroupSelection'
import { createFlightGroupSelectionSelector } from '../../../selectors'
import { useAppSelector } from '../../../store'

export const useFlightGroupSelection = (params: IFlightGroupSelection) => {
  const selectFlightGroupSelection = useMemo(createFlightGroupSelectionSelector, [])

  return useAppSelector(state => selectFlightGroupSelection(state, params))
}
