import React, { useCallback, useEffect, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { getClientHierarchyValue, getClientHierarchyBranch, formatEmailToName } from '@mindshare/layout'
import { dateToString } from 'Helpers/calendarHelper'
import { PlanningStageOptions } from 'Constants/enums/PlanningStageOptions'

export const MediaPlanBreadcrumbs = ({ hierarchies, mediaPlan, mediaPlanVersion, clientPlanningStages, planningStageId, setUpdatePlanModalVisible }) => {
  const [initialPlanningStage, setInitialPlanningStage] = useState(0)
  const handleOpenModal = useCallback(() => {
    setUpdatePlanModalVisible(true)
  }, [setUpdatePlanModalVisible])
  useEffect(() => {
    if (planningStageId && !initialPlanningStage) {
      setInitialPlanningStage(planningStageId)
    }
  }, [planningStageId, initialPlanningStage])
  if (hierarchies && mediaPlan) {
    const planYear = mediaPlan.planYear > 0
      ? mediaPlan.planYear
      : ''
    const planningStage = clientPlanningStages.length > 0 && initialPlanningStage > 0
      ? clientPlanningStages.find(c => c.planningStageId === initialPlanningStage).planningStageName
      : ''

    const { planningStageModifiedBy, planningStageModifiedOn, mediaPlanId } = mediaPlan
    const planningStageText = `${planningStage}${
      (initialPlanningStage === PlanningStageOptions.Approved || initialPlanningStage === PlanningStageOptions.Finalized) && planningStageModifiedBy && planningStageModifiedOn
        ? ` (by ${formatEmailToName(planningStageModifiedBy)} on ${dateToString(planningStageModifiedOn)})` : ''
    }`

    return (
      <>
        <BreadcrumbItem testId='header-plan-id' label='Plan ID' text={mediaPlanId} />
        <HierarchyBreacrumbItem hierarchyName='Brand' {...{ hierarchies, mediaPlan, mediaPlanVersion }} />
        <HierarchyBreacrumbItem hierarchyName='Business' {...{ hierarchies, mediaPlan, mediaPlanVersion }} />
        <HierarchyBreacrumbItem hierarchyName='Geography' {...{ hierarchies, mediaPlan, mediaPlanVersion }} />
        <HierarchyBreacrumbItem hierarchyName='Media' {...{ hierarchies, mediaPlan, mediaPlanVersion }} />
        <BreadcrumbItem testId='header-hierarchy-year' label='Plan Year' text={planYear} />
        <Button
          className='btn-unset-default'
          data-testid='open-plan-update-modal'
          icon={<EditOutlined />}
          onClick={handleOpenModal}
          type='link'
        />
        <BreadcrumbItem testId='header-planning-stage' label='Stage' text={planningStageText} />
      </>
    )
  } else {
    return (
      <Button
        className='btn-unset-default'
        data-testid='open-plan-update-modal'
        icon={<EditOutlined />}
        onClick={handleOpenModal}
        type='link'
      />
    )
  }
}
export default MediaPlanBreadcrumbs

const BreadcrumbItem = ({ testId, label, text, tooltip = text }) => text ? (
  <Tooltip title={tooltip}>
    <span data-testid={testId}><b>{label}:</b> {text}</span>
  </Tooltip>
) : null

const HierarchyBreacrumbItem = ({ hierarchyName, hierarchies, mediaPlan, mediaPlanVersion }) => {
  const hierarchyname = hierarchyName.toLowerCase()
  const isLatestVersion = mediaPlanVersion.mediaPlan?.latestMediaPlanVersionId === mediaPlanVersion.mediaPlan?.mediaPlanVersionId
  const id = isLatestVersion ? mediaPlanVersion.parseData?.[`${hierarchyname}HierarchyValue`] : mediaPlan[`${hierarchyname}HierarchyId`]
  const value = id > 0
    ? getClientHierarchyValue(hierarchies, hierarchyname, id)
    : ''
  const tooltip = value
    ? getClientHierarchyBranch(hierarchies, hierarchyname, id)
      .map((node) => getClientHierarchyValue(node, hierarchyName))
      .join(' / ')
    : ''

  return (
    <BreadcrumbItem
      testId={`header-hierarchy-${hierarchyname}`}
      label={hierarchyName}
      text={value}
      tooltip={tooltip}
    />
  )
}
