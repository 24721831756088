import React, { memo, useCallback } from 'react'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, useSensors, useSensor, PointerSensor, MouseSensor, TouchSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import type { DragEndEvent } from '@dnd-kit/core'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'

interface IMediaPlanTableDndContextProviderProps {
  children: React.ReactNode
  flightGroupsIds: number[]
  flightGroups: IFlightGroup[]
  handleSort: (flightGroups: IFlightGroup[]) => void
  selectedFlightGroups: Partial<IFlightGroup>
}

export const MediaPlanTableDndContextProvider: React.FC<IMediaPlanTableDndContextProviderProps> = ({
  children,
  flightGroupsIds,
  flightGroups,
  handleSort,
  selectedFlightGroups
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  )

  const onSortEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (!over || !selectedFlightGroups) return

    let updatedFlightGroups = [...flightGroups]
    const movingFlightGroups = updatedFlightGroups.filter((f) => Object.keys(selectedFlightGroups).includes(f.mediaPlanFlightGroupId?.toString()))
    updatedFlightGroups = updatedFlightGroups.filter((f) => !Object.keys(selectedFlightGroups).includes(f.mediaPlanFlightGroupId?.toString()))
    let newIndex = updatedFlightGroups.findIndex((i) => i.mediaPlanFlightGroupId === over?.id)
    const overFlightGroupIndex = flightGroups.findIndex((t) => t.mediaPlanFlightGroupId === over?.id)
    const activeFlightGroupIndex = flightGroups.findIndex((t) => t.mediaPlanFlightGroupId === active.id)

    if (activeFlightGroupIndex > overFlightGroupIndex) {
      newIndex--
    }

    // eslint-disable-next-line functional/immutable-data
    updatedFlightGroups.splice(newIndex + 1, 0, ...movingFlightGroups)
    const sortedFlightGroups = updatedFlightGroups.map((f, idx) => ({ ...f, sortOrder: (idx + 1) * 10 }))

    handleSort(sortedFlightGroups)
  }, [handleSort, flightGroups, selectedFlightGroups])

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onSortEnd}
      sensors={sensors}
    >
      <SortableContext
        items={flightGroupsIds}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  )
}

export default memo(MediaPlanTableDndContextProvider)
