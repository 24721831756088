import React from 'react'
import { Collapse, List, Tooltip } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

interface IProps {
  currentClientId: number
  mediaPlanNamesAndIds: Array<{ mediaPlanId: number; mediaPlanVersionId: number; name: string }>
}

export const FrontSheetIncludedPlansComponent: React.FC<IProps> = ({ currentClientId, mediaPlanNamesAndIds }): React.ReactElement => {
  return (
    <Collapse
      className='front-sheet-view-plan-list'
      defaultActiveKey={['1']}
      expandIconPosition='end'
      items={[{
        label: 'List of included plans',
        key: '1',
        children: (
          <List
            dataSource={mediaPlanNamesAndIds || []}
            loading={!mediaPlanNamesAndIds}
            renderItem={plan => (
              <List.Item className='front-sheet-view-plan-list__list-item' data-testid={`plan-item-${plan.mediaPlanId}`}>
                <span>
                  {String(plan.mediaPlanId)}
                </span>
                <span>{plan.name}</span>
                <a
                  data-testid='view-plan'
                  href={`/mediaplanversion/${plan.mediaPlanVersionId}?clientId=${currentClientId}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Tooltip title='View Plan'> <EyeOutlined /></Tooltip>
                </a>
              </List.Item>
            )}
          />
        )
      }]}
    >
    </Collapse>
  )
}

export default FrontSheetIncludedPlansComponent
