import { useMemo } from 'react'
import { CustomExportControlField } from 'Apis/generated/customExportsApi'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { oneOf } from 'Helpers/conditionsHelper'
import { UseQueryCommand } from '../../../redux/types'
import { useClientMediaPlanFields } from './useClientMediaPlanFields'
import { useControlFields } from './useControlFields'

export const useCustomExportFields = (): UseQueryCommand<Array<IClientMediaPlanField | CustomExportControlField>> => {
  const { data: clientMediaPlanFields = [], isLoading: areClientMediaPlanFieldsLoading } = useClientMediaPlanFields()
  const { data: controlFields = [], isLoading: areControlFieldsLoading } = useControlFields()

  // select from result
  const customExportFields = useMemo(() => {
    const unsupportedDataTypes = [FieldDataType.RICH_TEXT, FieldDataType.BIG_STRING]
    const supportedClientMediaPlanFields = clientMediaPlanFields.filter((field) => {
      const isUnsupportedDataType = unsupportedDataTypes.includes(field.mediaPlanField.fieldDataTypeId)

      return !field.isDisabled || isUnsupportedDataType
    })

    const supportedControlFields = controlFields.filter((field) => {
      return !unsupportedDataTypes.includes(field.fieldDataTypeId)
    })

    return [...supportedClientMediaPlanFields, ...supportedControlFields]
  }, [clientMediaPlanFields, controlFields])

  return {
    data: customExportFields,
    isLoading: oneOf(areClientMediaPlanFieldsLoading, areControlFieldsLoading)
  }
}
