import React from 'react'
import { IMSHierarchies } from '@mindshare/layout'
import { CalculationFieldResult } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import TemplateExamplePlanLevelItemComponent from './TemplateExamplePlanLevelItemComponent'

interface IExamplePlanLevel {
  data?: any
  errors: CalculationFieldResult[]
  calculationMode: boolean
  mediaPlanFields: IMediaPlanTemplateFields[]
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  hierarchies?: IMSHierarchies
  masteredListsData: IMasteredListsData
  updateDataValues?: (selectedValue: string | number) => void
  tokenHandler: (token: string) => void
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  onAvailableFieldSelected: (c: string, mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[]) => void
  isFieldValidForClick: (mediaPlanTemplateFields: IMediaPlanTemplateFields) => boolean
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
}

export const TemplateExamplePlanLevelComponent: React.FunctionComponent<IExamplePlanLevel> = (examplePlanLevel: IExamplePlanLevel) => {
  const {
    errors,
    mediaPlanFields,
    tokenHandler,
    isFieldValidForClick,
    availableMediaPlanFields,
    onAvailableFieldSelected,
    setFunction,
    calculationMode,
    updateDataValues,
    hierarchies,
    masteredListsData,
    removePlanField
  } = examplePlanLevel

  const filterPlanFields = (item: IMediaPlanTemplateFields) => (
    item.clientMediaPlanField?.mediaPlanField?.fieldDataTypeId !== FieldDataType.RICH_TEXT
  )

  const sortedMediaPlanFields = mediaPlanFields && [...mediaPlanFields].filter(filterPlanFields).sort((a, b) => a.sortOrder - b.sortOrder)
  const filteredAvailablePlanFields = availableMediaPlanFields && [...availableMediaPlanFields].filter(filterPlanFields)

  return (
    <div className='plan-level-container'>
      <div className='container-grid'>
        {
          sortedMediaPlanFields.map(item => {
            const valid = isFieldValidForClick(item)
            const defaultValue = item.mediaPlanTemplateId > 0 ? item.clientMediaPlanField.mediaPlanField.defaultValue : ''
            const error = errors.find( c => c.fieldId === item.clientMediaPlanFieldId)

            return (
              <TemplateExamplePlanLevelItemComponent
                calculationMode={calculationMode}
                removePlanField={removePlanField}
                key={item.mediaPlanTemplateFieldId ? item.mediaPlanTemplateFieldId : item.mediaPlanVersionFieldId}
                value={defaultValue}
                tokenHandler={tokenHandler}
                valid={valid}
                mediaPlanFields={mediaPlanFields}
                setFunction={setFunction}
                availableMediaPlanFields={filteredAvailablePlanFields}
                onAvailableFieldSelected={onAvailableFieldSelected}
                updateDataValues={updateDataValues}
                hierarchies={hierarchies}
                mediaPlanField={item}
                masteredListsData={masteredListsData}
                error={error && error.error}
                isTemplate={true}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default TemplateExamplePlanLevelComponent
