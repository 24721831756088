import React, { FunctionComponent } from 'react'
import moment from 'moment'
import { dateFormat } from 'Helpers/calendarHelper'
import FlightModalOptionsComponent from './FlightModalOptionsComponent'

interface IProps {
  merge: boolean
  startDate: string
  endDate: string
  flightIndex: number
  subFlightIndex: number
  flightGroupIndex: number
  clientMediaPlanFieldId: number
  planStartDate: string
  planEndDate: string
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => void
  clearFlightData: (flightGroupIndex: number, flightIndex: number) => void
  copySelectedFlight: () => void
  handlePasteClick: () => void
  mergeFlightSelection: (
    startDate: Date,
    enDate: Date,
    mergeByWeek: boolean,
    flightGroupIndex: number,
    flightIndex: number,
    mergeByMonth: boolean,
    clientMediaPlanFieldId?: number
  ) => void
  canCopy: boolean
  canPaste: boolean
}

export const FlightCellContextMenuComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    merge,
    endDate,
    startDate,
    flightIndex,
    planStartDate,
    planEndDate,
    subFlightIndex,
    deleteFlight,
    clearFlightData,
    copySelectedFlight,
    handlePasteClick,
    mergeFlightSelection,
    flightGroupIndex,
    clientMediaPlanFieldId,
    canCopy,
    canPaste
  } = props

  const handleMergeFlightSelection = (selectedStartDate: Date, selectedEndDate: Date, mergeByWeek: boolean, mergeByMonth: boolean) =>
    mergeFlightSelection(
      selectedStartDate,
      selectedEndDate,
      mergeByWeek,
      flightGroupIndex,
      flightIndex,
      mergeByMonth,
      clientMediaPlanFieldId
    )

  return (
    <FlightModalOptionsComponent
      planStartDate={planStartDate}
      planEndDate={planEndDate}
      selectedFlights={[]}
      deleteFlight={() => deleteFlight(flightGroupIndex, flightIndex, subFlightIndex)}
      clearFlightData={() => clearFlightData(flightGroupIndex, flightIndex)}
      selectedDates={[moment(startDate).format(dateFormat), moment(endDate).format(dateFormat)]}
      mergeFlightSelection={handleMergeFlightSelection}
      isMergeCell={merge}
      isSubFlightCell={!!(subFlightIndex !== undefined && subFlightIndex >= 0)}
      copyFlight={copySelectedFlight}
      showCopyPasteButtons={true}
      canCopy={canCopy}
      canPaste={canPaste}
      handlePasteClick={handlePasteClick}
    />
  )
}

export default FlightCellContextMenuComponent
