import React, { useCallback, useMemo, useState } from 'react'
import { MSFeatureSwitch, MSTabs, useRouteParams, getColumnSearchWithSettingsFunction, useMindshareSelector } from '@mindshare/layout'
import { useGetUserPermissionsQuery } from 'Apis/enhancedUserPermissionsApi'
import { useMapSettings } from '@mindshare/services'
import { RootState } from 'Reducers/index'
import { Client } from 'Apis/generated/clientsApi'
import TooltipButton from 'Components/TooltipButton'
import { initialPermissions } from 'Hooks/useUserPermissions'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { IMAPSettings } from 'Constants/entities/IMapSettings'
import { useAppSelector } from '../../../store'
import TemplateEditTabContainer from './TemplateEditTabContainer'
import TemplateUsageTabContainer from './TemplateUsageTabContainer'

const tabs = {
  CREATE_TEMPLATE: 'Create template',
  EDIT_TEMPLATE: 'Edit template',
  TEMPLATE_USAGE: 'Template usage'
}

const TemplateFieldContainer: React.FunctionComponent = (): React.ReactElement => {
  const templateId = useRouteParams('templateId', Number)

  const [selectedTab, setSelectedTab] = useState('0')
  const onTabChange = useCallback((key) => setSelectedTab(key), [])
  const proceedToUsageTab = useCallback(() => setSelectedTab('1'), [])

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const [templateName, setTemplateName] = useState('')

  const { data: currentClient } = useCurrentClient()
  const { data: userPermissions = initialPermissions } = useGetUserPermissionsQuery({ clientName: currentClient.name })
  const { adminAuthorised, clientAdmin } = userPermissions
  const { settings, updateSetting } = useMapSettings<IMAPSettings, 'syncTemplate'>('syncTemplate', currentClient.id, {})
  const clients = useMindshareSelector((state: RootState) => state.app.clients) as Client[]
  const clientMediaPlanFields = useAppSelector((state) => state.mediaPlanDataTerms.clientMediaPlanFields)

  const isEditMode = templateId > 0

  const isSyncAvailable = isEditMode && (adminAuthorised || clientAdmin)

  const TemplateEditTabContent = useMemo(() =>
    <TemplateEditTabContainer
      templateId={templateId}
      templateName={templateName}
      setTemplateName={setTemplateName}
      hasUnsavedChanges={hasUnsavedChanges}
      setHasUnsavedChanges={setHasUnsavedChanges}
    />, [templateId, templateName, hasUnsavedChanges])

  return (
    <MSFeatureSwitch name='TEMPLATE_SYNC' fallback={TemplateEditTabContent}>
      <div className='template-field-container'>
        <h1>{isEditMode ? `Template > ${templateName}` : 'Create Template'}</h1>
        <TooltipButton
          tooltip='templateEdit'
          isPublishDisabled={hasUnsavedChanges}
          isPublishVisible={isSyncAvailable}
          onAfterPublish={proceedToUsageTab}
        />
        <MSTabs onChange={onTabChange} activeKey={selectedTab.toString()}
          items={[
            {
              label: isEditMode ? tabs.EDIT_TEMPLATE : tabs.CREATE_TEMPLATE,
              key: '0',
              children: TemplateEditTabContent
            },
            (isSyncAvailable ? {
              label: tabs.TEMPLATE_USAGE,
              key: '1',
              children: (<TemplateUsageTabContainer
                clients={clients}
                clientId={currentClient.id}
                templateId={templateId}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                filterFunction={getColumnSearchWithSettingsFunction(settings, updateSetting)}
                clientMediaPlanFields={clientMediaPlanFields}
              />)
            } : null)
          ].filter(Boolean)}>
        </MSTabs>
      </div>
    </MSFeatureSwitch>
  )
}

export default TemplateFieldContainer
