/**
 * Custom DatePicker component that is compatible with Moment date library.
 * For more information, please visit https://ant.design/docs/react/use-custom-date-library
 */
import { DatePicker } from 'antd'
import type { Moment } from 'moment'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig)

export default MyDatePicker
