import React, { ChangeEvent } from 'react'
import { Input, Select } from 'antd'
import {
  MSNumberInput,
  MSCurrencyInput,
  MSPercentInput,
  MSHierarchyTreeSelect,
  getClientHierarchyTypeDetails,
  IMSHierarchies
} from '@mindshare/layout'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { CustomExportFilterDefaultValue, CustomExportFieldDataTypeOperator } from 'Apis/generated/customExportsApi'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { filterClientHierarchies, expandHierarchies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { toISOString, toMomentDate } from 'Helpers/calendarHelper'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import DatePicker from '../../DatePicker'
import { DisplayValueByOperator } from './DisplayValueByOperator'
import { RangeGrid } from './RangeGrid'
import { MasteredListsFieldSelector } from './MasteredListsFieldSelector'

export const CustomExportDefaultValue = ({
  value,
  onChange,
  //
  fieldDataType,
  operator,
  hierarchies,
  masteredListsData,
  clientFieldValues = [],
  geographyHierarchyId
}: {
  value: CustomExportFilterDefaultValue[] | null
  onChange: (value: Array<Pick<CustomExportFilterDefaultValue, 'defaultValue' | 'customExportFilterDefaultValueId'>>) => void
  //
  fieldDataType: FieldDataTypeRead
  operator: CustomExportFieldDataTypeOperator
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  clientFieldValues?: ClientFieldValue[]
  geographyHierarchyId?: number
}) => {
  if (!fieldDataType || !operator) {
    return <Input placeholder="Enter value" disabled={true} />
  }

  const handleChange = (prev: CustomExportFilterDefaultValue) => (next: string | number) => {
    onChange([{
      defaultValue: next?.toString() ?? '',
      customExportFilterDefaultValueId: prev?.customExportFilterDefaultValueId ?? 0
    }])
  }

  const handleChangeFrom = (prevFrom: CustomExportFilterDefaultValue, prevTo: CustomExportFilterDefaultValue) => (nextFrom: string | number) => {
    onChange([
      {
        defaultValue: nextFrom?.toString() ?? '',
        customExportFilterDefaultValueId: prevFrom?.customExportFilterDefaultValueId ?? 0
      },
      prevTo
    ])
  }

  const handleChangeTo = (prevFrom: CustomExportFilterDefaultValue, prevTo: CustomExportFilterDefaultValue) => (nextTo: string | number) => {
    onChange([
      prevFrom,
      {
        defaultValue: nextTo?.toString() ?? '',
        customExportFilterDefaultValueId: prevTo?.customExportFilterDefaultValueId ?? 0
      }
    ])
  }

  const handleChangeMultiple = (prev: CustomExportFilterDefaultValue[] = []) => (next: string[] | number[]) => {
    const updateValues = next.map((nextDefaultValue) => {
      const existingValue = prev.find(it => String(it.defaultValue) === String(nextDefaultValue))
      const newValue = { defaultValue: String(nextDefaultValue), customExportFilterDefaultValueId: 0 }

      return existingValue || newValue
    }, [])

    onChange(updateValues)
  }

  switch (fieldDataType.fieldDataTypeId) {
    case FieldDataType.DECIMAL: {
      const [from, to] = value

      return (
        <DisplayValueByOperator
          operator={operator}
          single={(
            <MSNumberInput
              onChange={handleChange(from)}
              value={from?.defaultValue}
            />
          )}
          range={(
            <RangeGrid
              from={(
                <MSNumberInput
                  onChange={handleChangeFrom(from, to)}
                  value={from?.defaultValue}
                />
              )}
              to={(
                <MSNumberInput
                  onChange={handleChangeTo(from, to)}
                  value={to?.defaultValue}
                />
              )}
            />
          )}
        />
      )
    }

    case FieldDataType.INTEGER: {
      const [from, to] = value

      return (
        <DisplayValueByOperator
          operator={operator}
          single={(
            <MSNumberInput
              onChange={handleChange(from)}
              value={from?.defaultValue}
            />
          )}
          range={(
            <RangeGrid
              from={(
                <MSNumberInput
                  onChange={handleChangeFrom(from, to)}
                  value={from?.defaultValue}
                />
              )}
              to={(
                <MSNumberInput
                  onChange={handleChangeTo(from, to)}
                  value={to?.defaultValue}
                />
              )}
            />
          )}
          multi={(
            <Select
              className='select select--fluid'
              open={false}
              mode='tags'
              placeholder='Type to create value'
              value={value.map(v => v.defaultValue)}
              onChange={handleChangeMultiple(value)}
            />
          )}
        />
      )
    }

    case FieldDataType.UNIQUE_STRING: {
      const [from] = value

      return (
        <DisplayValueByOperator
          operator={operator}
          single={(
            <Input
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(from)(event.target.value)}
              value={from?.defaultValue}
            />
          )}
          multi={
            <Select
              className='select select--fluid'
              open={false}
              mode='tags'
              placeholder='Type to create value'
              value={value.map(v => v.defaultValue)}
              onChange={handleChangeMultiple(value)}
            />
          }
        />
      )
    }

    case FieldDataType.STRING: {
      const [from] = value

      return (
        <DisplayValueByOperator
          operator={operator}
          single={(
            <Input
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(from)(event.target.value)}
              value={from?.defaultValue}
            />
          )}
        />
      )
    }

    case FieldDataType.PERCENTAGE: {
      const [from, to] = value
      return (
        <DisplayValueByOperator
          operator={operator}
          single={(
            <MSPercentInput
              onChange={handleChange(from)}
              value={from?.defaultValue}
            />
          )}
          range={(
            <RangeGrid
              from={(
                <MSPercentInput
                  onChange={handleChangeFrom(from, to)}
                  value={from?.defaultValue}
                />
              )}
              to={(
                <MSPercentInput
                  onChange={handleChangeTo(from, to)}
                  value={to?.defaultValue}
                />
              )}
            />
          )}
        />
      )
    }

    case FieldDataType.DATE: {
      const [from, to] = value

      const handleDateChange = (date) => onChange([
        {
          defaultValue: toISOString(date),
          customExportFilterDefaultValueId: from?.customExportFilterDefaultValueId ?? 0
        },
        {
          defaultValue: toISOString(date),
          customExportFilterDefaultValueId: to?.customExportFilterDefaultValueId ?? 0
        }
      ])

      const handleStartDateChange = (date) => {
        onChange([
          {
            defaultValue: toISOString(date),
            customExportFilterDefaultValueId: from?.customExportFilterDefaultValueId ?? 0
          },
          to
        ])
      }

      const handleEndDateChange = (date) => {
        onChange([
          from,
          {
            defaultValue: toISOString(date),
            customExportFilterDefaultValueId: to?.customExportFilterDefaultValueId ?? 0
          }
        ])
      }

      return (
        <DisplayValueByOperator
          operator={operator}
          single={
            <DatePicker
              placeholder="Enter date"
              format='DD-MMM-YYYY'
              value={toMomentDate(from?.defaultValue)}
              onChange={handleDateChange}
            />
          }
          range={
            <RangeGrid
              from={(
                <DatePicker
                  className='select select--fluid'
                  placeholder="Enter start date"
                  format='DD-MMM-YYYY'
                  value={toMomentDate(from?.defaultValue)}
                  onChange={handleStartDateChange}
                />
              )}
              to={
                <DatePicker
                  className='select select--fluid'
                  placeholder="Enter end date"
                  format='DD-MMM-YYYY'
                  value={toMomentDate(to?.defaultValue)}
                  onChange={handleEndDateChange}
                />
              }
            />
          }
        />
      )
    }

    case FieldDataType.CLIENT_DEFINED_LIST: {
      const [current] = value

      const props = {
        placeholder: 'Select Value',
        allowClear: true,
        showSearch: true,
        optionLabelProp: 'label',
        optionFilterProp: 'children',
        options: clientFieldValues.map(it => ({ value: String(it.clientFieldValueId), label: it.valueDisplayName }))
      }

      return (
        <DisplayValueByOperator
          operator={operator}
          single={
            <Select
              {...props}
              value={String(current?.defaultValue ?? '')}
              onChange={handleChange(current)}
            />
          }
          multi={
            <Select
              {...props}
              value={value?.map(it => String(it.defaultValue))}
              mode='multiple'
              onChange={handleChangeMultiple(value)}
            />
          }
        />
      )
    }

    case FieldDataType.BUSINESS_HIERARCHY:
    case FieldDataType.GEOGRAPHY_HIERARCHY:
    case FieldDataType.MEDIA_HIERARCHY:
    case FieldDataType.BRAND_HIERARCHY: {
      const isGeographyHierarchy = fieldDataType.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY
      const filteredGeographyHierarchies = filterClientHierarchies(hierarchies, FieldDataType.GEOGRAPHY_HIERARCHY, geographyHierarchyId)
      const {
        valueProp,
        idProp,
        hierarchyList
      } = getClientHierarchyTypeDetails(
        fieldDataType.dataTypeName,
        isGeographyHierarchy
          ? ({
            clientGeographyHierarchies: filteredGeographyHierarchies
          } as IMSHierarchies)
          : hierarchies
      )
      const values = value.map((it) => Number(it.defaultValue))
      const expandedHierarchyList = expandHierarchies({ hierarchyList }, fieldDataType.dataTypeName)

      const props = {
        testIdPrefix: 'hierarchy-select',
        treeData: hierarchyList,
        idColumn: idProp,
        valueColumn: valueProp,
        hierarchyName: fieldDataType.dataTypeName,
        placeholder: 'Select Value',
        setFieldValue: (name: string, hierarchyId: number | number[]) => {
          if (Array.isArray(hierarchyId)) {
            handleChangeMultiple(value)(hierarchyId)
          } else {
            handleChange(value[0])(hierarchyId)
          }
        }
      }

      return (
        <DisplayValueByOperator
          operator={operator}
          single={
            <MSHierarchyTreeSelect
              {...props}
              selectedValue={values[0]}
            />
          }
          multi={
            <MSHierarchyTreeSelect
              {...props}
              selectedValue={values}
              multiple={true}
              maxTagCount={expandedHierarchyList.length}
            />
          }
        />
      )
    }

    case FieldDataType.MEDIA_PARTNERS:
    case FieldDataType.CLIENT_CAMPAIGNS:
    case FieldDataType.CLIENT_AGENCY_LIST: {
      const [prev] = value
      const values = value.map((it) => Number(it.defaultValue))

      return (
        <DisplayValueByOperator
          operator={operator}
          single={
            <MasteredListsFieldSelector
              masteredListsData={masteredListsData}
              fieldDataTypeId={fieldDataType.fieldDataTypeId}
              onChange={(id: number) => handleChange(prev)(id)}
              value={values[0]}
            />
          }
          multi={
            <MasteredListsFieldSelector
              masteredListsData={masteredListsData}
              fieldDataTypeId={fieldDataType.fieldDataTypeId}
              onChange={(id: number[]) => handleChangeMultiple(value)(id)}
              value={values}
              mode='multiple'
            />
          }
        />
      )
    }

    case FieldDataType.CURRENCY: {
      const [from, to] = value

      return (
        <DisplayValueByOperator
          operator={operator}
          single={(
            <MSCurrencyInput
              onChange={handleChange(from)}
              value={from?.defaultValue}
            />
          )}
          range={(
            <RangeGrid
              from={(
                <MSCurrencyInput
                  onChange={handleChangeFrom(from, to)}
                  value={from?.defaultValue}
                  placeholder="Set FROM value"
                />
              )}
              to={(
                <MSCurrencyInput
                  onChange={handleChangeTo(from, to)}
                  value={to?.defaultValue}
                  placeholder="Set TO value"
                />
              )}
            />
          )}
        />
      )
    }

    default: {
      return (
        <Input
          placeholder="Enter value"
          disabled={true}
        />
      )
    }
  }
}

