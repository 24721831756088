import React, { useCallback, useEffect, useMemo } from 'react'
import {
  IClientHierarchy,
  TOP_LEVEL_HIERARCHIES,
  IClientHierarchyName,
  ITopLevelHierarchiesMap,
  getClientHierarchyNode,
  IMSHierarchies,
  useMsNotifications
} from '@mindshare/layout'

import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'
import { IClientSettingExtended } from 'Components/Client/constants/entities/IClientSetting'
import { useFilteredHierarchies } from './CustomHooks'
import { useHierarchies } from './useHierarchies'
import { useCurrentClient } from './useCurrentClient'

export const getSelectedHierarchyIDs = (
  templateOrPlan: MediaPlanTemplate | IMediaPlans
): ITopLevelHierarchiesMap<number | null> =>
  TOP_LEVEL_HIERARCHIES
    .reduce((selection, hierarchyname) => ({
      ...selection,
      [hierarchyname]: (templateOrPlan ? templateOrPlan[`${hierarchyname}HierarchyId`] : null)
    }), {})

export const getRequiredHierarchies = (
  templateOrPlan: MediaPlanTemplate | IMediaPlans,
  clientSettings: IClientSettingExtended
): ITopLevelHierarchiesMap => {
  const selected = getSelectedHierarchyIDs(templateOrPlan)
  return Object.keys(selected).reduce((selection, hierarchyname) => {
    const hierarchyName = hierarchyname[0].toUpperCase() + hierarchyname.slice(1).toLowerCase()

    return {
      ...selection,
      [hierarchyname]: !!selected[hierarchyname] || clientSettings[`is${hierarchyName}Required`]
    }
  }, {})
}

export const getSelectedHierarchies = (
  templateOrPlan: MediaPlanTemplate | IMediaPlans,
  hierarchies: IMSHierarchies
): ITopLevelHierarchiesMap<IClientHierarchy | false | undefined> =>
  TOP_LEVEL_HIERARCHIES
    .reduce((selection, hierarchyname) => {
      const hierarchyID = templateOrPlan ? templateOrPlan[`${hierarchyname}HierarchyId`] : null
      return {
        ...selection,
        [hierarchyname]: hierarchyID
          ? getClientHierarchyNode(hierarchies, hierarchyname, Number(hierarchyID)) || false
          : undefined
      }
    }, {})

export const checkMissingHierarchies = (
  templateOrPlan: MediaPlanTemplate | IMediaPlans,
  hierarchies: IMSHierarchies
): IClientHierarchyName[] => {
  const selectedTemplateHierarchies = getSelectedHierarchies(templateOrPlan, hierarchies)
  return Object.keys(selectedTemplateHierarchies)
    .reduce((missing, hierarchyname) =>
      selectedTemplateHierarchies[hierarchyname] === false ? [...missing, hierarchyname] : missing
    , [])
}


export const useMediaPlanHierarchyValidation = (
  mediaPlanTemplate: MediaPlanTemplate,
  mediaPlan?: IMediaPlans,
  showValidationAlert?: boolean
) => {
  const showMissingHierarchiesMessage = useShowMissingHierarchiesMessage()
  const { data: currentClient } = useCurrentClient()
  const { data: clientSettings } = useClientSettings()
  const { data: allHierarchies } = useHierarchies(currentClient?.id)
  const { filteredHierarchies: templateFilteredhierarchies } = useFilteredHierarchies(
    mediaPlanTemplate?.brandHierarchyId,
    mediaPlanTemplate?.businessHierarchyId,
    mediaPlanTemplate?.geographyHierarchyId,
    mediaPlanTemplate?.mediaHierarchyId
  )
  const requiredTemplateHierarchies = getRequiredHierarchies(mediaPlanTemplate, clientSettings)

  const missingSelectedTemplateHierarchies = useMemo(() =>
    checkMissingHierarchies(mediaPlanTemplate, allHierarchies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [
    mediaPlanTemplate?.geographyHierarchyId, mediaPlanTemplate?.mediaHierarchyId,
    mediaPlanTemplate?.businessHierarchyId, mediaPlanTemplate?.brandHierarchyId,
    allHierarchies
  ])

  useEffect(() => {
    if (showValidationAlert && missingSelectedTemplateHierarchies.length) {
      showMissingHierarchiesMessage({
        missingHierarchies: missingSelectedTemplateHierarchies
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValidationAlert, missingSelectedTemplateHierarchies])

  const missingSelectedPlanHierarchies = useMemo(() =>
    checkMissingHierarchies(mediaPlan, templateFilteredhierarchies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [
    mediaPlan?.geographyHierarchyId, mediaPlan?.mediaHierarchyId,
    mediaPlan?.businessHierarchyId, mediaPlan?.brandHierarchyId,
    templateFilteredhierarchies
  ])

  useEffect(() => {
    const replacedHierarchies = missingSelectedPlanHierarchies.filter((h) => !missingSelectedTemplateHierarchies.includes(h))
    if (showValidationAlert && replacedHierarchies.length) {
      showMissingHierarchiesMessage({
        missingHierarchies: replacedHierarchies,
        childName: 'media plan',
        parentName: 'template'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValidationAlert, missingSelectedPlanHierarchies, missingSelectedTemplateHierarchies])

  const hierarchyValidationPassed = useMemo(() => {
    if (!mediaPlan) return undefined

    let valid = mediaPlan.mediaPlanTemplateId > 0 && mediaPlan.planYear > 0

    if (valid) {
      TOP_LEVEL_HIERARCHIES
        .forEach((hierarchy) => {
          const hierarchyName = hierarchy[0].toUpperCase() + hierarchy.slice(1).toLowerCase()
          if (
            clientSettings[`is${hierarchyName}Required`] ||
            requiredTemplateHierarchies[hierarchy]) {
            const isDeleted = missingSelectedPlanHierarchies.includes(hierarchy) ||
              (missingSelectedTemplateHierarchies.includes(hierarchy) &&
              mediaPlan[`${hierarchy}HierarchyId`] === mediaPlanTemplate[`${hierarchy}HierarchyId`])

            valid = valid && (!isDeleted && mediaPlan[`${hierarchy}HierarchyId`] > 0)
          }
        })
    }

    return valid
  }, [clientSettings, mediaPlan, mediaPlanTemplate, requiredTemplateHierarchies, missingSelectedTemplateHierarchies, missingSelectedPlanHierarchies])

  return {
    hierarchyValidationPassed,
    requiredTemplateHierarchies,
    missingSelectedTemplateHierarchies
  }
}
export default useMediaPlanHierarchyValidation


interface IMissingHierarchiesMessage {
  missingHierarchies: string[]
  childName?: string
  parentName?: string
}
export const MissingHierarchiesMessage = ({
  missingHierarchies,
  childName = 'template',
  parentName = 'client'
}: IMissingHierarchiesMessage) => (
  <>
  The {`${childName}`} chosen references a hierarchy value which has been deleted from the {`${parentName}`} hierarchy tree:<br/>
  &nbsp;<em>{missingHierarchies.map((h) => `${h[0].toUpperCase()}${h.slice(1).toLowerCase()}`).join(', ')}</em><br/>
  Please update the {`${childName}`} before saving.
  </>
)

export const useShowMissingHierarchiesMessage = () => {
  const { showMSAlert } = useMsNotifications()

  return useCallback((props: IMissingHierarchiesMessage) => {
    const key = `${Math.random() * 1000}`
    showMSAlert({ key, message: <MissingHierarchiesMessage {...props} /> })
  }, [showMSAlert])
}
