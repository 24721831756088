const text = `# Media Plan List

This page will display all plans you have access to in modified date order (most recent first). 
It can be filtered by any combination of the 4 hierarchies (or the number of hierarchies present for the chosen client).

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Plans%20List.mp4) 
to find guidance on how to navigate the Plan Lists page.*

------



## Create new plan

### Template selection

This selection will determine the template used to structure the plan. It cannot be changed once the plan is created. 
Changes to the template itself after the plan has been created will not be applied to the plan.

### Hierarchy value selection

Settings for the client determine which hierarchies are mandatory to fill in at this stage. 
If a hierarchy value is mandatory, you must fill it in to progress to the media plan. 
The save button will be unavailable until you have filled in the mandatory hierarchy values.

Values selected here are used to filter and secure the plan e.g. if you want the plan to be accessible to people with access to Switzerland, 
you should select Switzerland from the geography hierarchy field. The value selected will not affect what is available to use in the plan.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/Creating%20a%20Media%20Plan.mp4) to see how to create a new media plan.*

------



## Edit

This will take you to the latest version of the plan you have selected.

------



## Duplicate

Will copy the latest version of the plan selected and add it to the system as a new plan. The contents of the plan will be identical. 
The only differences could/would be the updated by, plan id and version number.

------



## Version history

Will open a small pop up that display historical versions of the selected plan. From here you can

- Edit a historic version of your chosen plan
- Download a historic version of your chosen plan

If you have chosen to edit an old plan, you can update it or save as new version. Saving as a new version will make it the latest version of this plan. 
If you choose a plan with versions 1-10 available, edit version 5 and select save as new version, version 5 will remain the same and a version 11 will be created.

*Watch [this video](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/MAP%20Support%20Videos/MAP%20Plan%20Version.mp4) 
to see how to view or edit a historic version of a plan, and how to promote it to become the latest version of the plan.*

------



## Delete

This will delete the selected plan and all historic versions of it, you must confirm you actually want to delete the plan. 
Once it is deleted you will not be able to find that plan again.

------



## Download media plan

Will export an excel version of your chosen plan.

------

*[Click here](https://insidemedia.sharepoint.com/sites/Media-Planning/MAP/SitePages/MAP-Training-Videos.aspx) 
to see the full list of bite-sized videos and corresponding 1 page guides of MAP features to help you better understand how to use MAP effectively.*`

export default text
