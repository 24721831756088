import omit from 'lodash/omit'
import { MediaPlanTemplate, MediaPlanTemplateWithMediaTemplateFields } from 'Apis/generated/templatesApi'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { ISelectedHierarchies } from 'Components/TemplateFieldSelection/constants/entities/ISelectedHierarchies'
import { createMediaPlanTemplate } from 'Components/MediaPlanTemplate/constants/entities/IMediaPlanTemplate'

export const getModifiedMediaPlanTemplateFieldsPartial = (mediaPlanTemplateFields = []): IMediaPlanFieldRow[] =>
  mediaPlanTemplateFields.map((f) => omit(f, 'itemRowId'))

export const getModifiedMediaPlanTemplate = (
  mediaPlanTemplate: MediaPlanTemplateWithMediaTemplateFields,
  data: IMediaPlanFieldRow[],
  templateName: string,
  mediaPlanTemplateId: number,
  leftAlignNumbersInExcelExport: boolean,
  selectedHierarchies: ISelectedHierarchies,
  hideSortOrderInExcelExport: boolean
): MediaPlanTemplate => {
  const mediaPlanTemplateFieldsPartial = getModifiedMediaPlanTemplateFieldsPartial(data)

  const modifiedMediaPlanTemplate = createMediaPlanTemplate({
    ...mediaPlanTemplate,
    templateName,
    mediaPlanTemplateId,
    mediaPlanTemplateFields: mediaPlanTemplateFieldsPartial,
    brandHierarchyId: selectedHierarchies.brand,
    businessHierarchyId: selectedHierarchies.business,
    geographyHierarchyId: selectedHierarchies.geography,
    mediaHierarchyId: selectedHierarchies.media,
    leftAlignNumbersInExcelExport,
    hideSortOrderInExcelExport
  })

  return modifiedMediaPlanTemplate
}

export const getBaseMediaPlanTemplate = (mediaPlanTemplate: MediaPlanTemplateWithMediaTemplateFields, mediaPlanTemplateFields: IMediaPlanFieldRow[]): MediaPlanTemplate => {
  const mediaPlanTemplateFieldsPartial = getModifiedMediaPlanTemplateFieldsPartial(mediaPlanTemplateFields)
  const baseMediaPlanTemplate = {
    ...mediaPlanTemplate,
    mediaPlanTemplateFields: mediaPlanTemplateFieldsPartial
  }

  return baseMediaPlanTemplate
}

